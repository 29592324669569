import React from 'react';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
import ToggleVideo from '@molecules/MeetingRoom/CallControls/Buttons/ToggleVideo';
import ToggleAudio from '@molecules/MeetingRoom/CallControls/Buttons/ToggleAudio';
import HangUpCallButton from '@molecules/MeetingRoom/CallControls/Buttons/HangUpButton';
// import { ReactComponent as ShareDisplayIcon } from '@src/icons/share-display.svg';
// import { ReactComponent as AddUserIcon } from '@src/icons/add-user.svg';
import { ReactComponent as MenuIcon } from '@src/icons/menu.svg';
import DeviceSelectionMenu from '../CallControls/DeviceSelectionMenu';

const MenuBar = () => {
  return (
    <Box
      as="footer"
      backgroundColor={{ base: 'transparent', md: 'white' }}
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      zIndex={10}
      display="flex"
      height={{ base: '56px', sm: '72px' }}
      p={{ base: 0, sm: '0 1.43em' }}
    >
      <Box
        w="full"
        d="flex"
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="wrap"
        position="relative"
      >
        <Box
          flex="1 1 0%"
          position={{ base: 'relative', sm: 'absolute' }}
          inset={{ sm: 0 }}
          d="flex"
          justifyContent="center"
          alignItems="center"
          mt={{ base: -6, md: 0 }}
        >
          <HStack spacing={4}>
            <ToggleAudio
              variant="solid"
              boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12)"
              color="dove-gray.500"
              _focus={{ boxShadow: '0px 0px 18px 2px rgba(0, 0, 0, 0.25)' }}
            />

            <HangUpCallButton />

            <ToggleVideo
              variant="solid"
              boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12)"
              color="dove-gray.500"
              _focus={{ boxShadow: '0px 0px 18px 2px rgba(0, 0, 0, 0.25)' }}
            />
          </HStack>
        </Box>
        <Box d={{ base: 'none', lg: 'flex' }} alignItems="center">
          <HStack>
            {/* <Button
              colorScheme="white"
              size="lg"
              color="dove-gray.500"
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={12}
            >
              <Icon
                as={ShareDisplayIcon}
                w={{ base: 5, sm: 6 }}
                h={{ base: 5, sm: 6 }}
                d="block"
              />
              <Text fontSize="xs" pt={1}>
                {t('common.share')}
              </Text>
            </Button> */}
            {/* <Button
              aria-label="add user"
              colorScheme="white"
              size="lg"
              color="dove-gray.500"
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={12}
            >
              <Icon
                as={AddUserIcon}
                w={{ base: 5, sm: 6 }}
                h={{ base: 5, sm: 6 }}
              />
              <Text fontSize="xs" pt={1}>
                {t('common.add')}
              </Text>
            </Button> */}

            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="menu"
                color="dove-gray.500"
                colorScheme="white"
                size="lg"
                w={12}
              >
                <Icon
                  as={MenuIcon}
                  w={{ base: 5, sm: 6 }}
                  h={{ base: 5, sm: 6 }}
                />
              </MenuButton>
              <DeviceSelectionMenu />
            </Menu>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuBar;
