import React, { useMemo } from 'react';
import { chakra, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MaskshadowSvg } from '@src/icons/card-issuer/mask-shadow.svg';
import { defaultCardStyle } from './style';

export type DefaultCreditCardProps = Omit<
  PaymentMethodType,
  'id' | 'isDefault'
>;

export default function DefaultCreditCard({
  cardLast4,
  cardBrand,
  expMonth,
  expYear,
}: DefaultCreditCardProps) {
  const { t } = useTranslation();
  const {
    shadow: ShadowIcon,
    background: BackgroundIcon,
    icon: BaseIcon,
    backgroundStyle,
  } = defaultCardStyle[cardBrand as keyof typeof defaultCardStyle];
  const cardExpiry = useMemo(() => {
    const _expYear = String(expYear);
    return `${expMonth} / ${_expYear.substr(_expYear.length - 2)}`;
  }, [expMonth, expYear]);
  return (
    <chakra.div
      pos="relative"
      w={{ base: '319px', lg: '359px' }}
      h={{ base: '219.67px', lg: '277px' }}
      color="white"
    >
      <chakra.div position="absolute" overflow="hidden" top={0} left={0}>
        <ShadowIcon w="full" h="full" pos="relative" zIndex={0} />
        <Icon
          as={MaskshadowSvg}
          w="full"
          h="full"
          position="absolute"
          left={0}
          right="auto"
          bottom={-5}
        />
        <BackgroundIcon
          pos="absolute"
          h="auto"
          right={0}
          insetY={0}
          my="auto"
          {...backgroundStyle}
        />
        <BaseIcon
          position="absolute"
          left={12}
          top={16}
          right={0}
          h={10}
          w="auto"
        />
        <chakra.div pos="absolute" my="auto" right={9} top="46%">
          <chakra.span
            fontSize="3xl"
            verticalAlign="middle"
            letterSpacing="0.25rem"
            opacity={0.8}
            fontWeight="bold"
          >
            •••• ••••
          </chakra.span>{' '}
          <chakra.span
            fontSize="3xl"
            fontWeight="normal"
            verticalAlign="middle"
            letterSpacing="0.25rem"
          >
            {cardLast4}
          </chakra.span>
        </chakra.div>

        <chakra.div
          pos="absolute"
          bottom={10}
          right={10}
          d="flex"
          flexDirection="column"
        >
          <chakra.span
            fontWeight="medium"
            fontSize="9px"
            textAlign="right"
            lineHeight="13px"
            textTransform="capitalize"
          >
            {t('my_payments.valid_thru')}
          </chakra.span>
          <chakra.span
            fontSize="xs"
            fontWeight="light"
            textAlign="right"
            lineHeight="18px"
          >
            {cardExpiry}
          </chakra.span>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
}
