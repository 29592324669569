import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import Route from '@src/PrivateRoute';
import ScheduledMeetings from '@src/pages/MyMeetings/ScheduledMeetings';
import MeetingHistory from '@src/pages/MyMeetings/MeetingHistory';
import { usePathIndex } from '@src/hooks';

const MyMeetingsPage = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { index, onChange } = usePathIndex({
    paths: [{ path, exact: true }, { path: `${path}/history` }],
    prefixLocale: false,
  });

  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py="5">
        <Heading ml={3} color="blue.100" textAlign="left">
          {index === 0
            ? t('my_meetings.my_meetings')
            : t('my_meetings.meetings_history')}
        </Heading>
      </Box>

      <Tabs isManual isLazy index={index} onChange={onChange}>
        <TabList
          maxW="5xl"
          mx="auto"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'normal' }}
        >
          <Tab>{t('my_meetings.meetings_scheduled')}</Tab>
          <Tab>{t('my_meetings.meetings_history')}</Tab>
        </TabList>
        <TabPanels
          pos="relative"
          _before={{
            content: '""',
            pos: 'absolute',
            insetX: 0,
            h: { base: 36, lg: 56 },
            background:
              'linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)',
          }}
        >
          <TabPanel pos="relative">
            <Route path={path} exact prefixLocale={false}>
              <ScheduledMeetings />
            </Route>
          </TabPanel>
          <TabPanel pos="relative">
            <Route path={`${path}/history`} exact prefixLocale={false}>
              <MeetingHistory />
            </Route>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SidebarLayout>
  );
};

export default MyMeetingsPage;
