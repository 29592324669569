import React, { useEffect, useState } from 'react';
import { useVideoContext } from '@src/context/VideoContext';
import { Avatar, Box, Icon, Stack, Link } from '@chakra-ui/react';
import { ReactComponent as LegarooIcon } from '@src/icons/logo.svg';
import DeviceSelectionScreen from './DeviceSelectionScreen';
import MeetingWith from './MeetingWith';
import DateFormat from '@src/components/atoms/DateFormat';
import MediaErrorNotification from '@src/components/molecules/MeetingRoom/MediaErrorNotification';
const currentDate = new Date();

const PreJoinScreens = ({ viewer }: { viewer: User }) => {
  const { getAudioAndVideoStreams } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error | null>();

  useEffect(() => {
    if (!mediaError) {
      setTimeout(() => {
        getAudioAndVideoStreams().catch((error) => {
          setMediaError(error);
        });
      });
    }
  }, [getAudioAndVideoStreams, mediaError]);

  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="center"
      h="full"
      minH="screen"
    >
      <Box
        position="absolute"
        top={{ base: 0, lg: 6 }}
        mx="auto"
        maxW="6xl"
        w="full"
        zIndex="10"
      >
        <Box
          d="flex"
          justifyContent="space-between"
          px={{ base: 3, md: 6, xl: 0 }}
          py={{ base: 4, lg: 0 }}
          alignItems="center"
        >
          <Link href="/">
            <Icon as={LegarooIcon} w={{ base: 32, lg: 40 }} h="auto" />
          </Link>
          <Box d="flex" alignItems="center">
            <DateFormat
              date={currentDate}
              format="p EEEE', 'd MMM"
              d={{ base: 'none', sm: 'block' }}
              color="primary"
              fontSize="sm"
              mr={3}
            />
            <Avatar
              src={viewer.profile?.avatar as string}
              name={viewer.profile?.firstName as string}
            />
          </Box>
        </Box>
      </Box>
      <Box mx="auto" mt={{ base: 8, md: 0 }}>
        {mediaError && (
          <MediaErrorNotification
            error={mediaError}
            dismissError={() => setMediaError(null)}
          />
        )}
        <Stack
          position="relative"
          flex="1"
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: 6, md: 12 }}
          align="center"
        >
          <Box
            d={{ base: 'block', lg: 'flex' }}
            w={{
              base: '100vw',
              lg: '888px',
            }}
            h={{ base: '100vh', lg: 'auto' }}
            maxWidth={{ base: '100vw', lg: 'xl', xl: '2xl' }}
            rounded={{ lg: 'xl' }}
            boxShadow="sm"
            position={{ base: 'fixed', lg: 'relative' }}
            top={{ base: 0 }}
            m="auto"
            zIndex="0"
          >
            <Box w="full" flex="1" bg="white" rounded="xl" zIndex="3">
              <DeviceSelectionScreen user={viewer} />
            </Box>
          </Box>
          <Box
            d={{ base: 'none', lg: 'flex' }}
            alignItems="center"
            px={{ base: 3, md: 0 }}
          >
            <MeetingWith viewer={viewer} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default PreJoinScreens;
