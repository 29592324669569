import React from 'react';
import { useViewerQuery } from '@src/apollo/hooks';
import LawyerProfile from './LawyerProfile';
import UserProfile from './UserProfile';

const ProfilePage = () => {
  const { data, loading } = useViewerQuery();
  if (loading && data?.viewer) {
    return null;
  }
  if (data?.viewer?.roles?.includes('lawyer')) {
    return <LawyerProfile viewer={data?.viewer as User} />;
  }
  return <UserProfile viewer={data?.viewer as User} />;
};

export default ProfilePage;
