import React from 'react';
import {
  Box,
  Icon,
  Heading,
  Text,
  VStack,
  HStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WorldWideIcon } from '@src/icons/worldwide-secure.svg';

export const HowItWorkSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      d="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      py={{ sm: 10, md: 20 }}
      height={{ sm: 'auto' }}
      zIndex={4}
      className="pull-up clip-image-top-bottom-left"
    >
      <Box
        d="flex"
        flexDir="column"
        justifyItems="center"
        alignItems="center"
        mb={{ sm: 4, md: 10 }}
      >
        <Text
          p={4}
          pb={0}
          as="strong"
          color="black"
          mb={{ sm: -2, md: -4 }}
          fontSize={{ base: 'md', lg: 'lg' }}
          textTransform="uppercase"
        >
          {t('lawyer_landing.subtitle')}
        </Text>

        <Heading
          as="h2"
          pt={{ md: 4 }}
          pb={4}
          px={10}
          color="black"
          lineHeight="45px"
          fontWeight="bold"
          fontSize={{ base: '34px', lg: '4xl' }}
          textAlign={{ base: 'center', md: 'inherit' }}
        >
          {t('lawyer_landing.title')}
        </Heading>
      </Box>

      <Box w={{ base: '80%' }} py="8">
        <SimpleGrid spacing={10} columns={{ sm: 1, md: 3 }}>
          <Box
            spacing={4}
            justifyItems="left"
            minHeight={{ base: '250px', md: 'auto' }}
          >
            <Box
              d="block"
              position="absolute"
              borderRadius="xl"
              backgroundColor="blue.100"
              w={{ base: '195px', md: '180px' }}
              h={{ base: '218px', md: '220px' }}
              left={{ base: '16', md: '10%' }}
            />
            <VStack
              d="block"
              top="8"
              zIndex="2"
              position="relative"
              left={{ base: '3rem', md: '25px' }}
              width={{ base: '252px', md: 'auto' }}
            >
              <HStack w="100%">
                <Icon
                  w={{ base: '84px', md: '55px' }}
                  h={{ base: '84px', md: '55px' }}
                  padding="10px"
                  borderRadius={'lg'}
                  backgroundColor="blue.500"
                  as={WorldWideIcon}
                  color={'white'}
                />
                <Text
                  fontWeight="bold"
                  color="blue.500"
                  fontSize="md"
                  lineHeight={6}
                  textTransform="uppercase"
                  pl={{ base: '5', md: 'auto' }}
                >
                  {t('lawyer_landing.features.1.header')}
                </Text>
              </HStack>

              <Text
                pt={{ base: '2', md: 'auto' }}
                color="gray.800"
                fontSize="sm"
                textAlign="left"
                lineHeight="6"
              >
                {t('lawyer_landing.features.1.description')}
              </Text>
            </VStack>
          </Box>

          <Box
            spacing={4}
            justifyItems="left"
            minHeight={{ base: '250px', md: 'auto' }}
          >
            <Box
              d="block"
              position="absolute"
              w={{ base: '195px', md: '180px' }}
              h={{ base: '220px' }}
              left={{ base: '16', md: '38%' }}
              backgroundColor="orange.100"
              borderRadius={'lg'}
              zIndex="1"
            />
            <VStack
              d="block"
              top="25px"
              position="relative"
              left={{ base: '3rem', md: '25px' }}
              width={{ base: '252px', md: 'auto' }}
              zIndex="2"
            >
              <HStack w="100%">
                <Icon
                  padding="10px"
                  borderRadius={'lg'}
                  backgroundColor="orange.500"
                  as={WorldWideIcon}
                  color={'white'}
                  w={{ base: '84px', md: 'auto' }}
                  h={{ base: '84px', md: '55px' }}
                />
                <Text
                  fontWeight="bold"
                  color="orange.500"
                  fontSize="base"
                  lineHeight={6}
                  pl={{ base: '5', md: 'auto' }}
                  textTransform="uppercase"
                >
                  {t('lawyer_landing.features.2.header')}
                </Text>
              </HStack>
              <Text
                color="gray.800"
                fontSize="sm"
                textAlign="left"
                pt={{ base: '2', md: 'auto' }}
              >
                {t('lawyer_landing.features.2.description')}
              </Text>
            </VStack>
          </Box>

          <Box
            spacing={4}
            justifyItems="left"
            minHeight={{ base: '250px', md: 'auto' }}
          >
            <Box
              d="block"
              position="absolute"
              w={{ base: '195px', md: '180px' }}
              h={{ base: '232px', md: '220px' }}
              left={{ base: '16', md: '66%' }}
              backgroundColor="gray.100"
              borderRadius={'lg'}
              zIndex="1"
            />
            <VStack
              d="block"
              top="25px"
              position="relative"
              left={{ base: '3rem', md: '25px' }}
              width={{ base: '252px', md: 'auto' }}
              zIndex="2"
            >
              <HStack w="100%">
                <Icon
                  padding="10px"
                  borderRadius={'lg'}
                  backgroundColor="gray.500"
                  as={WorldWideIcon}
                  color={'white'}
                  w={{ base: '89px', md: 'auto' }}
                  h={{ base: '84px', md: '55px' }}
                />
                <Text
                  fontWeight="bold"
                  color="gray.500"
                  fontSize="base"
                  lineHeight={6}
                  textTransform="uppercase"
                  pl={{ base: '5', md: 'auto' }}
                >
                  {t('lawyer_landing.features.3.header')}
                </Text>
              </HStack>
              <Text
                color="gray.800"
                fontSize="sm"
                textAlign="left"
                pt={{ base: '2', md: 'auto' }}
              >
                {t('lawyer_landing.features.3.description')}
              </Text>
            </VStack>
          </Box>
        </SimpleGrid>
      </Box>
      <Box
        w="100%"
        h={{ base: 'auto', md: '650px' }}
        d="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        mt={{ base: '20', md: '100px' }}
      >
        <Box
          w={{ sm: '100%', lg: '60%' }}
          h={{ sm: '800px', md: '100%' }}
          backgroundColor={{ base: 'blue.600', md: 'transparent' }}
          backgroundSize={{ base: 'cover', md: '100%' }}
          backgroundImage={{
            base: 'none',
            md: "url('/images/background-lawyer-landing.svg')",
          }}
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: '0', md: '50%' }}
          d="flex"
          flexDir="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          minH="400px"
          py={{ base: '6rem', md: '100px', lg: '20px' }}
          className="clip-background-top-right-bottom-right"
        >
          <Heading
            as="h2"
            p={4}
            py={{ base: 8, md: 4 }}
            w="70%"
            mt={-6}
            color="white"
            fontWeight="bold"
            fontSize={{ base: '34px', lg: '4xl' }}
            lineHeight={{ base: '44px', md: 'inherit' }}
            textAlign={{ base: 'center', md: 'inherit' }}
          >
            {t('lawyer_landing.how_it_works.title')}
          </Heading>
          <VStack w={{ base: '90%', md: '70%' }} my="4">
            <HStack>
              <Heading
                as="h2"
                fontSize={{ base: '2xl', lg: '4xl' }}
                fontWeight="bold"
                color="orange.500"
                p={4}
                mt={-6}
              >
                1
              </Heading>
              <Text color="white" fontSize="base" lineHeight={6}>
                {t('lawyer_landing.how_it_works.steps.step_1')}
              </Text>
            </HStack>
            <HStack>
              <Heading
                as="h2"
                fontSize={{ base: '2xl', lg: '4xl' }}
                fontWeight="bold"
                color="orange.500"
                p={4}
                mt={-6}
              >
                2
              </Heading>
              <Text color="white" fontSize="base" lineHeight={6}>
                {t('lawyer_landing.how_it_works.steps.step_2')}
              </Text>
            </HStack>
            <HStack>
              <Heading
                as="h2"
                fontSize={{ base: '2xl', lg: '4xl' }}
                fontWeight="bold"
                color="orange.500"
                p={4}
                mt={-6}
              >
                3
              </Heading>
              <Text color="white" fontSize="base" lineHeight={6}>
                {t('lawyer_landing.how_it_works.steps.step_3')}
              </Text>
            </HStack>
          </VStack>
        </Box>
        <Box
          w={{ sm: '100%', lg: '40%' }}
          h={{ base: '450px', md: 'auto' }}
          backgroundSize={{ base: '72%', md: '80%' }}
          backgroundImage="url('/images/lawyer-landing.svg')"
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: '75% 20%', md: '50%' }}
        ></Box>
      </Box>
    </Box>
  );
};

export default HowItWorkSection;
