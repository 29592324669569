import { RefObject } from 'react';
import {
  ControllerStateAndHelpers,
  UseMultipleSelectionReturnValue,
} from 'downshift';
import { createContext } from '@chakra-ui/utils';
import { FormControlOptions } from '@chakra-ui/form-control';

// eslint-disable-next-line
type SelectMultipleContext<Item = any> = ControllerStateAndHelpers<Item> &
  Partial<UseMultipleSelectionReturnValue<Item>> &
  FormControlOptions & {
    inputRef: RefObject<HTMLInputElement>;
    // eslint-disable-next-line
    controlProps: any;
  };
const [SelectProvider, useSelect] = createContext<SelectMultipleContext>({
  strict: false,
  name: 'DownshiftContext',
});

export { SelectProvider, useSelect };
