import React, { FC } from 'react';
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useViewerQuery } from '@src/apollo/hooks';
import SearchForm from '@src/components/molecules/SearchForm';
import NextMeeting from '@src/components/molecules/NextMeeting';
import LawyerStats from '@src/components/molecules/LawyerStats';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';
import HomeCard from '@components/molecules/HomeCard';
import Link from '@components/atoms/Link';
import { useNextMeeting } from '@src/hooks';

const LawyerHome: FC = () => {
  const { t } = useTranslation('home');
  const { data } = useViewerQuery();
  const nextMeeting = useNextMeeting();
  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py={16} px={6}>
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Heading textAlign="center">
            {t('home.hi')},{' '}
            <Box as="span" fontWeight="light">
              {data?.viewer?.profile?.firstName}
            </Box>
          </Heading>
          <Text fontWeight="bold" fontSize="lg" pt={1}>
            {t('home.lawyer.subtitle')}
            <Text
              as="span"
              borderBottom="solid 3px"
              borderBottomColor={'orange.500'}
            >
              {t('home.lawyer.for_lawyers')}
            </Text>
          </Text>
        </Box>
      </Box>
      <Box position="relative">
        <Box pos="absolute" w="full" h={{ base: 20, lg: 56 }} />
        <Box maxW="xl" py={{ base: 10, lg: 14 }} mx="auto">
          <LawyerStats />
          {nextMeeting && (
            <Box
              maxW="lg"
              w="full"
              mx="auto"
              position="relative"
              mt={6}
              px={{ base: 6, lg: 0 }}
            >
              <NextMeeting {...nextMeeting} />
              <Box px={6} pt={6}>
                <Link to="/my-meetings" color="orange.600" fontWeight="bold">
                  <Box as="span">
                    {t('home.view_scheduled_meetings')}
                    <Icon w={5} h={5} as={ArrowForward} />
                  </Box>
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        background="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
        pb={6}
      >
        <Box maxW="2xl" mx="auto" py={16} px={6} textAlign="left">
          <Text fontWeight="bold" fontSize="lg" pt={1}>
            {t('home.lawyer.find_a_colleague')}
          </Text>
          <SearchForm />
        </Box>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          maxW="5xl"
          mx="auto"
          spacing={12}
          px={{ base: 4, md: 0 }}
          pb={4}
        >
          <HomeCard
            title={t('home.lawyer.increase_meetings')}
            image="/images/increase_meetings.png"
          >
            <Box mt={6} fontSize={{ base: 'sm', lg: 'md' }}>
              <Text color="black" fontWeight="light" whiteSpace="break-spaces">
                {t('home.lawyer.paragraph1')}
              </Text>
              <Button
                as={Link}
                to="/profile"
                variant="outline"
                colorScheme="blue"
                ml="auto"
                mt={6}
              >
                {t('home.go_to_my_profile')}
              </Button>
            </Box>
          </HomeCard>

          <HomeCard
            title={t('home.lawyer.quality_experience')}
            image="/images/quality.png"
          >
            <VStack mt={6} spacing={6} fontSize={{ base: 'sm', lg: 'md' }}>
              <Text color="black" fontWeight="light" whiteSpace="break-spaces">
                {t('home.lawyer.paragraph2')}
              </Text>
            </VStack>
          </HomeCard>
        </Stack>
      </Box>
    </SidebarLayout>
  );
};

export default LawyerHome;
