import React from 'react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { ReactComponent as StepOne } from '@src/icons/step1.svg';
import { ReactComponent as StepTwo } from '@src/icons/step2.svg';
import { ReactComponent as StepThree } from '@src/icons/step3.svg';

export interface PaymentStepProps {
  stepNumberText: string;
  stepHeaderText: string;
  currentStep: number;
  stepNumber: number;
}

const PaymentStep = ({
  stepNumberText,
  stepHeaderText,
  currentStep,
  stepNumber,
}: PaymentStepProps) => {
  const isActive = currentStep === stepNumber;
  const pastStep = currentStep > stepNumber;
  const stepIcon =
    stepNumber === 1 ? StepOne : stepNumber === 2 ? StepTwo : StepThree;
  return (
    <Flex
      p="0px 15px"
      borderRight={isActive ? '1px solid #D2E3FB' : 'none'}
      borderLeft={stepNumber === 2 ? '1px solid #D2E3FB' : 'none'}
      alignItems="center"
    >
      <Box
        m={isActive ? 2 : 0}
        backgroundColor={
          isActive ? 'blue.600' : pastStep ? 'skyBlue.100' : 'blue:400'
        }
        w={'36px'}
        h={'36px'}
        rounded={'full'}
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          as={stepIcon}
          color={isActive || pastStep ? 'white' : 'grey'}
          w={'auto'}
          h={isActive ? '20px' : '22px'}
        />
      </Box>

      {isActive && (
        <Box d="flex" flexDir="column">
          <Text fontWeight="500" color="orange.500">
            {stepNumberText}
          </Text>
          <Text color="blue.600" fontWeight="700">
            {stepHeaderText}
          </Text>
        </Box>
      )}
    </Flex>
  );
};

export default PaymentStep;
