import { Meeting, MeetingError } from '@src/video';
import { useEffect } from 'react';
import { Callback } from '@src/@types/twilio';

export default function useHandleRoomDisconnectionErrors(
  meeting: Meeting,
  onError: Callback
) {
  useEffect(() => {
    const onDisconnected = (meeting: Meeting, error: MeetingError) => {
      if (error) {
        onError(error);
      }
    };

    meeting.on('disconnected', onDisconnected);
    return () => {
      meeting.off('disconnected', onDisconnected);
    };
  }, [meeting, onError]);
}
