import React, { useImperativeHandle, Fragment } from 'react';
import {
  BoxProps,
  chakra,
  HTMLChakraProps,
  forwardRef,
  useStyles,
  useFormControl,
  FormControlOptions,
} from '@chakra-ui/react';
import { useSelect } from './useDownshift';
import { SearchInput } from './SearchInput';
import { callAllHandlers } from '@chakra-ui/utils';

export function SelectValueContainer({ children, ...props }: BoxProps) {
  return (
    <chakra.div
      d="flex"
      alignItems="center"
      flex="1 1 0%"
      flexWrap="wrap"
      pos="relative"
      overflow="hidden"
      {...props}
    >
      {children}
    </chakra.div>
  );
}

export type ArrowIndicatorProps = HTMLChakraProps<'div'>;
export const ArrowIndicator = (props: ArrowIndicatorProps) => {
  return (
    <chakra.div
      pos="absolute"
      insetY={0}
      right={0}
      pr={2}
      display="flex"
      alignItems="center"
      pointerEvents="none"
      color="gray.500"
      {...props}
    />
  );
};

export type SelectSearchInputProps = HTMLChakraProps<'input'>;
// eslint-disable-next-line
export const SelectSearchInput = forwardRef<SelectSearchInputProps, 'input'>(
  (props: SelectSearchInputProps, ref) => {
    const {
      getInputProps,
      isDisabled,
      inputRef,
      selectedItems,
      getDropdownProps,
    } = useSelect();
    const input = useFormControl<HTMLInputElement>({ isDisabled, ...props });
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef?.current?.focus();
      },
    }));
    const placeholder =
      selectedItems && selectedItems.length > 0 ? '' : props.placeholder;
    return (
      <Fragment>
        {isDisabled && placeholder && (
          <chakra.span
            pos="absolute"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {placeholder}
          </chakra.span>
        )}
        <chakra.div
          zIndex={1}
          m={0.5}
          pb={0.5}
          pt={0.5}
          visibility={isDisabled ? 'hidden' : 'visible'}
        >
          <SearchInput
            tabIndex={-1}
            isDisabled={isDisabled}
            type="text"
            autoCapitalize="none"
            {...input}
            {...getInputProps({
              ref: inputRef,
              onChange: props.onChange,
              ...getDropdownProps?.({
                placeholder,
                ref: inputRef,
              }),
            })}
          />
        </chakra.div>
      </Fragment>
    );
  }
);

export type SelectButtonProps = HTMLChakraProps<'button'> & FormControlOptions;
export const SelectButton = forwardRef<SelectButtonProps, 'button'>(
  (props: SelectButtonProps, ref) => {
    const { onClick } = props;
    const {
      getToggleButtonProps,
      inputRef,
      isDisabled,
      isOpen,
      getDropdownProps,
    } = useSelect();
    const button = useFormControl({ isDisabled, ...props });
    const styles = useStyles();
    return (
      <chakra.button
        __css={styles.button}
        ref={ref}
        {...button}
        {...getToggleButtonProps({
          ...getDropdownProps?.({ preventKeyAction: isOpen }),
          // eslint-disable-next-line
          onClick: callAllHandlers(onClick, (event: any) => {
            event.stopPropagation();
            inputRef?.current?.focus();
          }),
        })}
      />
    );
  }
);

export type SelectControlProps = HTMLChakraProps<'div'>;
export const SelectControl = forwardRef<SelectControlProps, 'div'>(
  (props, ref) => {
    const { isDisabled, controlProps } = useSelect();
    const ownProps = useFormControl({ isDisabled, ...props });
    const styles = useStyles();
    return (
      <chakra.div
        ref={ref}
        __css={styles.control}
        {...controlProps}
        {...ownProps}
      />
    );
  }
);
