import React from 'react';
import ReactMarkdown from 'react-markdown';
import Footer from '@src/components/organisms/RooLayout/Layout/Footer';
import Header from '@src/components/organisms/RooLayout/Layout/Header';
import { Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function Privacy() {
  const { t } = useTranslation();
  return (
    <Box bg="white">
      <Header
        theme={{
          color: 'gray.900',
          backgroundColor: 'gray.200',
          colorScheme: 'blue',
        }}
      />
      <Box as="main" minH="100vh">
        <Box pb={52} bg="gray.200">
          <Box py={16}>
            <Box
              pos="relative"
              maxW="6xl"
              mx="auto"
              px={{ base: 4, sm: 6, lg: 8 }}
            >
              <Heading
                textAlign="center"
                fontWeight="extrabold"
                fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}
                letterSpacing="-0.025em"
              >
                {t('common.privacy_policy')}
              </Heading>
            </Box>
          </Box>
        </Box>
        <Box mb={12}>
          <Box mt={-52}>
            <Box
              pos="relative"
              maxW="6xl"
              mx="auto"
              pb={12}
              px={{ base: 4, sm: 6, lg: 8 }}
            >
              <Box bg="white" rounded="2xl" shadow="xl">
                <Box
                  gridColumn={{ lg: 'span 2 / span 2' }}
                  py={10}
                  px={{ base: 6, sm: 10 }}
                  p={{ xl: 12 }}
                >
                  <ReactMarkdown>{t('privacy_policy.user')}</ReactMarkdown>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
