import React from 'react';
import { useRoomTokenContext } from '@src/context/RoomTokenContext';
import { useVideoContext } from '@src/context/VideoContext';
import LocalVideoPreview from './LocalVideoPreview';
import { Box, HStack } from '@chakra-ui/react';
import CallSettings from '@src/components/molecules/MeetingRoom/CallControls/Buttons/CallSettings';
import ToggleVideo from '@molecules/MeetingRoom/CallControls/Buttons/ToggleVideo';
import ToggleAudio from '@molecules/MeetingRoom/CallControls/Buttons/ToggleAudio';
import MeetingInfo from '@molecules/MeetingRoom/CallControls/Buttons/MeetingInfo';

const DeviceSelectionScreen = ({ user }: { user: User }) => {
  const { loading } = useRoomTokenContext();
  const { isAcquiringLocalStreams, isConnecting } = useVideoContext();
  const disableButtons = loading || isAcquiringLocalStreams || isConnecting;

  return (
    <Box>
      <LocalVideoPreview
        identity={user?.profile?.firstName as string}
        avatarUrl={user?.profile?.avatar as string}
      />
      <Box
        position="absolute"
        zIndex={10}
        bottom={0}
        transform={{
          base: 'translateY(-0.5rem)',
          md: 'translateY(-1rem)',
          lg: 'translateY(-1.5rem)',
        }}
        mx="auto"
        w="full"
      >
        <Box d="flex" justifyContent="center" width="100%">
          <HStack
            background="linear-gradient(81.12deg, rgba(35, 6, 215, 0.47) 19.37%, rgba(12, 11, 11, 0) 90.87%);"
            spacing={4}
            h={{ base: '50px', md: '70px' }}
            rounded="30px"
            px={{ base: '50px', md: '90px' }}
            color="white"
            mb="35px"
          >
            <ToggleAudio isDisabled={disableButtons} />

            <ToggleVideo isDisabled={disableButtons} />

            <CallSettings />
            <MeetingInfo viewer={user} />
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceSelectionScreen;
