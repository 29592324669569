import React, { useMemo } from 'react';
import { TextField, TextFieldProps } from '../TextField';
import { useFormValidation } from '@src/hooks';

export type PasswordFieldProps<FieldValue> = Omit<
  TextFieldProps<FieldValue>,
  'name'
> & {
  name?: string;
};

export const PasswordField = ({
  name = 'password',
  label = 'Password',
  placeholder = 'Enter your password',
  fieldProps,
  ...props
}: // eslint-disable-next-line
PasswordFieldProps<any>) => {
  const { validPassword } = useFormValidation();
  const _fieldProps = useMemo(
    () => ({
      validate: validPassword(),
      type: 'password',
      ...fieldProps,
    }),
    [fieldProps, validPassword]
  );
  return (
    <TextField
      name={name}
      label={label}
      placeholder={placeholder}
      // validate={validate}
      fieldProps={_fieldProps}
      {...props}
    />
  );
};
