import { useState } from 'react';
import { useInterval } from '@chakra-ui/react';
import { calcTimeDelta, CountdownTimeDelta } from '@src/utils';

export interface UseCountdownProps {
  date: Date;
  intervalDelay?: number;
  onComplete?(timeDelta: CountdownTimeDelta): void;
  now?: Date;
  precision?: number;
}

export function useCountdown({
  date,
  onComplete,
  precision = 0,
  intervalDelay = 1000,
}: UseCountdownProps) {
  const [timeDelta, setTimeDelta] = useState<CountdownTimeDelta>(() => {
    return calcTimeDelta(date, { precision });
  });
  const [delay, setDelay] = useState<number | null>(intervalDelay);

  useInterval(() => {
    if (timeDelta.completed) {
      setDelay(null);
      onComplete?.(timeDelta);
      return;
    }
    const currentTimeDelta = calcTimeDelta(date, { precision });
    setTimeDelta(currentTimeDelta);
  }, delay);

  return timeDelta;
}
