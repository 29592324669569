import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Heading,
} from '@chakra-ui/react';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import Route from '@src/PrivateRoute';
import PaymentHistory from '@src/pages/MyPayments/PaymentHistory';
import PaymentMethods from '@src/pages/MyPayments/PaymentMethods';
import { usePathIndex } from '@src/hooks';

const MyPaymentsPage = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { index, onChange } = usePathIndex({
    paths: [{ path, exact: true }, { path: `${path}/payment-methods` }],
    prefixLocale: false,
  });

  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py="5">
        <Heading ml={3} color="blue.100" textAlign="left">
          {index === 0 ? t('common.my_payments') : t('my_payments.saved_cards')}
        </Heading>
      </Box>

      <Tabs isManual index={index} onChange={onChange}>
        <TabList
          maxW="5xl"
          mx="auto"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'normal' }}
        >
          <Tab>{t('my_payments.payment_history')}</Tab>
          <Tab>{t('my_payments.saved_cards')}</Tab>
        </TabList>
        <TabPanels
          pos="relative"
          _before={{
            content: '""',
            pos: 'absolute',
            insetX: 0,
            h: { base: 36, lg: 56 },
            background:
              'linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)',
          }}
        >
          <TabPanel pos="relative">
            <Route path={path} exact prefixLocale={false}>
              <PaymentHistory />
            </Route>
          </TabPanel>
          <TabPanel pos="relative">
            <Route path={`${path}/payment-methods`} prefixLocale={false}>
              <PaymentMethods />
            </Route>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SidebarLayout>
  );
};

export default MyPaymentsPage;
