import React, { useMemo } from 'react';
import { Box, Avatar, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { isLawyer } from '@src/utils';
import LanguageSelect from '@src/components/atoms/LanguageSelect';
import Link from '@components/atoms/Link';
export interface UserMenuProps {
  viewer: Viewer;
  onChangeTab: (tab: number) => void;
}

const TabOne = ({ viewer, onChangeTab }: UserMenuProps) => {
  const { profile } = viewer;
  const { t } = useTranslation();
  const isViewerLawyer = useMemo(() => {
    return isLawyer(viewer);
  }, [viewer]);
  return (
    <Box py={6}>
      <Box py={4} px={8}>
        <Box
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Avatar
            size="sm"
            name={`${profile?.firstName} ${profile?.lastName}`}
            src={profile?.avatar as string}
            onClick={() => onChangeTab(1)}
          />
          <LanguageSelect />
        </Box>
      </Box>

      <Box px={8} py={4}>
        <VStack align="flex-end" textAlign="right">
          {!isViewerLawyer && (
            <Link to="/lawyers" fontSize="12px" fontWeight="600">
              {t('common.join_as_lawyer')}
            </Link>
          )}
          {/* <Link to="/about" fontSize="12px" fontWeight="600">
            {t('common.about_us')}
          </Link> */}
          <Link to="/terms" fontWeight="400">
            {t('common.termsOfUse')}
          </Link>
          <Link to="/privacy-policy" fontWeight="400">
            {' '}
            {t('common.privacy_policy')}
          </Link>
          <Link to="/contact" fontWeight="400">
            {' '}
            {t('common.contact_us')}
          </Link>
        </VStack>
      </Box>
    </Box>
  );
};
export default TabOne;
