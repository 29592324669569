import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  validPhone as validPhoneUtils,
  composeValidators,
  minLength,
  maxLength,
  required as requiredUtils,
  validPassword as validPasswordUtils,
  validEmail as validEmailUtils,
  minValue,
  mustBeNumber,
} from '@src/utils';

export function useFormValidation() {
  const { t } = useTranslation();
  const validPhone = useCallback(() => {
    return composeValidators(
      validPhoneUtils(t('validate.invalid_phone')),
      minLength(5, t('validate.min_length', { count: 5 })),
      maxLength(18, t('validate.max_length', { count: 18 }))
    );
  }, [t]);

  const validPassword = useCallback(() => {
    return composeValidators(
      requiredUtils(t('validate.required')),
      validPasswordUtils(t('validate.invalid_password'))
    );
  }, [t]);

  const validEmail = useCallback(() => {
    return composeValidators(
      requiredUtils(t('validate.required')),
      validEmailUtils(t('validate.invalid_email'))
    );
  }, [t]);

  const validHourlyRate = useCallback(() => {
    return composeValidators(
      mustBeNumber(t('validate.must_be_number')),
      minValue(1, t('validate.greater_than', { name: '0' }))
    );
  }, [t]);

  const required = useCallback(() => requiredUtils(t('validate.required')), [
    t,
  ]);

  return {
    validPhone,
    validPassword,
    validEmail,
    required,
    validHourlyRate,
  };
}
