import {
  VisaIcon,
  MastercardIcon,
  VisaShadowIcon,
  MastercardShadowIcon,
  VisaBackgroundIcon,
  MastercardBackgroundIcon,
} from './icons';

export const style = {
  amex: {
    icon: VisaIcon,
    cardBg: 'linear-gradient(25deg, #0f509e, #1399cd)',
    iconBtnStyle: {
      bg: '#08418F',
      _hover: {
        bg: '#08418F',
      },
    },
  },
  dinersclub: {
    icon: VisaIcon,
    cardBg: 'linear-gradient(25deg, #0f509e, #1399cd)',
    iconBtnStyle: {
      bg: '#08418F',
      _hover: {
        bg: '#08418F',
      },
    },
  },
  discover: {
    icon: VisaIcon,
    cardBg: 'linear-gradient(25deg, #0f509e, #1399cd)',
    iconBtnStyle: {
      bg: '#08418F',
      _hover: {
        bg: '#08418F',
      },
    },
  },
  jcb: {
    icon: VisaIcon,
    cardBg: 'linear-gradient(25deg, #0f509e, #1399cd)',
    iconBtnStyle: {
      bg: '#08418F',
      _hover: {
        bg: '#08418F',
      },
    },
  },
  unionpay: {
    icon: VisaIcon,
    cardBg: 'linear-gradient(25deg, #0f509e, #1399cd)',
    iconBtnStyle: {
      bg: '#08418F',
      _hover: {
        bg: '#08418F',
      },
      _focus: { boxShadow: 'none' },
    },
  },
  mastercard: {
    icon: MastercardIcon,
    cardBg: '#E25141',
    iconBtnStyle: {
      bg: '#C24132',
      _hover: {
        bg: '#C24132',
      },
      _focus: { boxShadow: '0 0 0 3px rgb(221, 56, 37, 0.65)' },
      _active: { bg: '#C24132' },
    },
  },
  visa: {
    icon: VisaIcon,
    cardBg: '#0D5ED7',
    iconBtnStyle: {
      bg: '#08418F',
      _hover: {
        bg: '#08418F',
      },
      _focus: { boxShadow: 'outline' },
    },
  },
};

export const defaultCardStyle = {
  mastercard: {
    shadow: MastercardShadowIcon,
    background: MastercardBackgroundIcon,
    icon: MastercardIcon,
    backgroundStyle: {
      transform: 'translateY(10px) translateX(-14%)',
      w: { base: '54%', lg: 'auto' },
    },
  },
  visa: {
    shadow: VisaShadowIcon,
    background: VisaBackgroundIcon,
    icon: VisaIcon,
    backgroundStyle: {
      transform: 'translateY(10px) translateX(-2%)',
      w: { base: '70%', lg: 'auto' },
    },
  },
};

export default style;
