import { useCallback, useEffect, useState } from 'react';
import { useHistory, matchPath, useParams } from 'react-router-dom';

export type Path = {
  path: string;
  exact?: boolean;
};
export interface UsePathIndexProps {
  paths: Path[];
  prefixLocale?: boolean;
  exact?: boolean;
}

export function usePathIndex({
  paths,
  prefixLocale = true,
}: UsePathIndexProps) {
  const { locale } = useParams<BaseRouteParams>();
  const history = useHistory();

  const pathname = history.location.pathname;

  const [index, setIndex] = useState(() => {
    return paths.findIndex(({ path, exact }) =>
      matchPath(history.location.pathname, {
        path: prefixLocale ? `/:locale${path}` : path,
        exact,
      })
    );
  });

  const onChange = useCallback(
    (index: number) => {
      history.push({
        pathname: paths[index].path.replace(':locale', locale),
      });
      setIndex(index);
    },
    [history, locale, paths]
  );

  useEffect(() => {
    const newIndex = paths.findIndex(({ path, exact }) =>
      matchPath(pathname, {
        path: prefixLocale ? `/:locale${path}` : path,
        exact,
      })
    );
    setIndex(newIndex);
  }, [paths, pathname, setIndex, prefixLocale]);

  return { onChange, index };
}
