import React, { useEffect, ReactNode, useState } from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { LocalAudioStream, AudioStream, RemoteAudioStream } from '@src/video';
import { ReactComponent as Membrana } from '@src/icons/membrana.svg';
import useIsTrackEnabled from '@src/hooks/meeting/useIsTrackEnabled';
import useMediaStreamTrack from '@src/hooks/meeting/useMediaStreamTrack';
const AudioContext = window.AudioContext || window.webkitAudioContext;

let audioContext: AudioContext;

export function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);
  return analyser;
}

function CircularIndicator({
  audioTrack,
  color = 'white',
  children,
  minSize = 100,
}: {
  audioTrack?: AudioStream;
  color?: string;
  children?: ReactNode;
  minSize?: number;
}) {
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const [size, setSize] = useState(minSize);

  const isTrackEnabled = useIsTrackEnabled(
    audioTrack as LocalAudioStream | RemoteAudioStream
  );
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);
  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
      const stopAllMediaStreamTracks = () =>
        newMediaStream.getTracks().forEach((track) => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    if (isTrackEnabled && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = setInterval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(
          14,
          Math.max(0, Math.log10(values / length / 3) * 7)
        );
        const newSize = (minSize * ((volume / 3) * 10 + 100)) / 100;
        setSize(newSize);
      }, 100);

      return () => {
        setSize(minSize);
        clearInterval(timer);
      };
    }
  }, [isTrackEnabled, analyser, minSize]);

  return (
    <Box>
      <Box
        position="absolute"
        d="flex"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        width="100%"
        height="100%"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        <Icon color={color} as={Membrana} height={size} width={size} />
      </Box>
      <Box
        position="absolute"
        d="flex"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        width="100%"
        height="100%"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        {children}
      </Box>
    </Box>
  );
}

export default React.memo(CircularIndicator);
