import React from 'react';
import NavLink, { NavLinkProps } from '@components/atoms/NavLink';

const SidebarLink = (props: NavLinkProps) => {
  return (
    <NavLink
      d="flex"
      alignItems="center"
      w="full"
      py={2}
      color="gray.500"
      isActiveStyle={{
        color: 'black',
        borderRight: '4px',
        borderColor: 'primary',
      }}
      transition="all"
      transitionTimingFunction="ease-in-out"
      transitionDuration="150ms"
      _focus={{ color: 'black' }}
      _hover={{ color: 'black' }}
      {...props}
    />
  );
};

export default SidebarLink;
