import React, { useMemo, useState, useCallback } from 'react';
import {
  isSameMonth,
  isThisMonth,
  subMonths,
  isWithinInterval,
  endOfDay,
} from 'date-fns';
import { Box, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { ReactComponent as CalendarIcon } from '@src/icons/calendar.svg';
import { useTranslation } from 'react-i18next';
import {
  MeetingFilter,
  FilterOptions,
} from '@src/components/molecules/MeetingHistory/MeetingFilter';
import MeetingHistoryTable from '@src/components/molecules/MeetingHistory';
import { useMyMeetings } from '@src/hooks/useMyMeetings';
import { useDateFormat } from '@src/hooks/useDateFormat';

const MeetingHistory = () => {
  const format = useDateFormat();
  const { allMeetings, pastMeetings } = useMyMeetings();
  const [filter, setFilter] = useState<FilterOptions>();
  const [range, setRange] = useState<[Date, Date] | null>(null);
  const { t } = useTranslation();

  const displayMeetings = useMemo(() => {
    switch (filter) {
      case 'currentMonth':
        return pastMeetings.filter((item) => isThisMonth(item.times.startsAt));
      case 'pastMonth':
        return pastMeetings.filter((item) =>
          isSameMonth(item.times.startsAt, subMonths(new Date(), 1))
        );
      case 'withinInterval':
        if (range) {
          const [start, end] = range;
          return pastMeetings.filter((item) =>
            isWithinInterval(item.times.startsAt, { start, end: endOfDay(end) })
          );
        }
        return pastMeetings;
      default:
        return pastMeetings;
    }
  }, [filter, range, pastMeetings]);

  const subtitle = useMemo(() => {
    const currentDate = new Date();
    switch (filter) {
      case 'currentMonth':
        return format(currentDate, 'MMMM yyyy');
      case 'pastMonth':
        return format(subMonths(currentDate, 1), 'MMMM yyyy');
      case 'withinInterval':
        if (range) {
          const [start, end] = range;
          return `${format(start, 'd MMMM yyyy')} - ${format(
            end,
            'd MMMM yyyy'
          )}`;
        }
        return null;
      default:
        return null;
    }
  }, [filter, range, format]);

  const onChange = useCallback((value, range: [Date, Date] | undefined) => {
    setFilter(value);
    if (value === 'withinInterval' && range) {
      setRange(range);
    }
  }, []);

  return (
    <Box maxW="5xl" mx="auto" py={{ base: 6, md: 8 }} px={10}>
      <Box d={{ md: 'flex' }} justifyContent={{ md: 'space-between' }}>
        <Box>
          <Text
            fontSize="lg"
            as="strong"
            color="black"
            textTransform="capitalize"
          >
            <Icon color="black" as={CalendarIcon} w={6} h={6} mr={2} />
            {subtitle}
          </Text>
        </Box>
        <Box alignSelf="flex-end" mt={3}>
          <MeetingFilter onChange={onChange} selected={filter} />
        </Box>
      </Box>
      {allMeetings?.loading && (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
      {!allMeetings?.loading && allMeetings.meetings && (
        <Box
          mt={{ md: 6 }}
          mb={4}
          verticalAlign="middle"
          display="inline-block"
          minWidth="full"
        >
          <Box overflow="hidden">
            <MeetingHistoryTable meetings={displayMeetings ?? []} />
          </Box>
        </Box>
      )}
      {!allMeetings?.loading && displayMeetings.length === 0 && (
        <Box p={5} d="flex" alignItems="center" flexDir="column" mt={8}>
          <Text>{t('my_meetings.no_meetings')}</Text>
        </Box>
      )}
    </Box>
  );
};

export default MeetingHistory;
