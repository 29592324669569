import React, { useState, useEffect } from 'react';
import { Participant, Meeting } from '@src/video';

export interface ContextProviderProps {
  meeting: Meeting;
  children: React.ReactNode;
}

export interface ContextProps {
  selectedParticipant: Participant | null;
  setSelectedParticipant: (participant: Participant | null) => void;
}

const SelectedParticipantContext = React.createContext<ContextProps>({
  selectedParticipant: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedParticipant: () => {},
});

function SelectedParticipantContextProvider({
  children,
  meeting,
}: ContextProviderProps) {
  const [
    activeParticipant,
    setActiveParticipant,
  ] = useState<Participant | null>(null);

  const setSelectedParticipant = (participant: Participant | null) => {
    setActiveParticipant(participant);
  };

  useEffect(() => {
    const onDisconnect = () => setActiveParticipant(null);
    const handleParticipantDisconnected = (participant: Participant) => {
      if (activeParticipant !== participant) {
        setActiveParticipant(null);
      }
    };

    meeting.on('disconnected', onDisconnect);
    meeting.on('participantDisconnected', handleParticipantDisconnected);
    return () => {
      meeting.off('disconnected', onDisconnect);
      meeting.off('participantDisconnected', handleParticipantDisconnected);
    };
  }, [meeting, activeParticipant]);

  return (
    <SelectedParticipantContext.Provider
      value={{
        selectedParticipant: activeParticipant,
        setSelectedParticipant: setSelectedParticipant,
      }}
    >
      {children}
    </SelectedParticipantContext.Provider>
  );
}
export { SelectedParticipantContext, SelectedParticipantContextProvider };
