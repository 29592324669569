import { useEffect, useRef } from 'react';
import { AudioStream } from '@src/video';

interface AudioTrackProps {
  track: AudioStream;
}

const MeetingRoomAudioTrack = ({ track }: AudioTrackProps) => {
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return () => track.detach().forEach((el) => el.remove());
  }, [track]);
  return null;
};
export default MeetingRoomAudioTrack;
