import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FormLabelProps } from '@src/components/molecules/CardSetupForm';
import { useField, UseFieldConfig } from 'react-final-form';
import {
  SelectSingleWrapper,
  SelectSingleWrapperProps,
  Option,
} from '../../Select/SelectSingleWrapper';
import { ShowErrorFunc, showErrorOnChange } from '@src/utils';

export interface SelectSingleFieldProps<Item>
  extends SelectSingleWrapperProps<Item> {
  name: string;
  label?: string;
  showError?: ShowErrorFunc;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  fieldProps?: UseFieldConfig<Item>;
  isDisabled?: boolean;
  hideError?: boolean;
}

export function SelectSingle<Item = Option>({
  name,
  formControlProps,
  fieldProps,
  label,
  isDisabled,
  hideError = false,
  showError = showErrorOnChange,
  ...props
}: SelectSingleFieldProps<Item>) {
  const { input, meta } = useField<Item>(name, fieldProps);
  const { error } = meta;
  const isInvalid = showError({ meta });
  return (
    <FormControl
      name={name}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      {...formControlProps}
    >
      <SelectSingleWrapper<Item> {...props} {...input} label={label} />
      {!hideError && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
