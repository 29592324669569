import React, { useContext } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import Participant from './Participant';
import useMainParticipant from '@src/hooks/meeting/useMainParticipant';
import useParticipants from '@src/hooks/meeting/useParticipants';
import { useVideoContext } from '@src/context/VideoContext';
import { SelectedParticipantContext } from '@src/context';

export default function ParticipantList() {
  const {
    meeting: { localParticipant },
  } = useVideoContext();

  const participants = useParticipants();

  const { selectedParticipant, setSelectedParticipant } = useContext(
    SelectedParticipantContext
  );

  const mainParticipant = useMainParticipant();

  if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <Box
      mt={{ base: -16, sm: 12, lg: 0 }}
      p={{ base: 2, sm: '2em' }}
      display={{ base: 'flex', sm: 'block' }}
      overflowY={{ base: 'initial', sm: 'auto' }}
      overflowX={{ base: 'auto', sm: 'initial' }}
      backgroundColor="transparent"
      gridArea={{ base: '2 / 1 / 3 / 3', sm: '1 / 2 / 1 / 3' }}
      zIndex={5}
    >
      <Stack
        direction={{ base: 'row', sm: 'column' }}
        spacing={{ base: 2, sm: 6 }}
      >
        <Participant participant={localParticipant} isLocalParticipant={true} />

        {participants.map((participant) => {
          const isSelected = participant === selectedParticipant;
          const hideParticipant =
            participant === mainParticipant && !isSelected;
          return (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={participant === selectedParticipant}
              onClick={() => setSelectedParticipant(participant)}
              hideParticipant={hideParticipant}
              videoOnly={false}
            />
          );
        })}
      </Stack>
    </Box>
  );
}
