import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { ReactComponent as PhoneIcon } from '@src/icons/phone-call.svg';
import { useVideoContext } from '@src/context/VideoContext';
import { useViewerQuery } from '@src/apollo/hooks';
import { redirectRatingOrHome } from '@src/utils';

const HangUpCallButton: FC = () => {
  const { meeting } = useVideoContext();
  const { meeting_id, locale } = useParams<RoomParamTypes & BaseRouteParams>();
  const { data } = useViewerQuery();
  const viewer = data?.viewer;
  const size = useBreakpointValue({ base: 'md', md: 'lg' });
  const hangUpCall = useCallback(() => {
    meeting.disconnect();
    // https://github.com/twilio/video-quickstart-js/issues/40
    // localStreams.forEach((stream) => stream.stop());
    setTimeout(() => {
      redirectRatingOrHome({
        viewer: viewer,
        homePath: `/${locale}`,
        ratingPath: `/${locale}/meeting/${meeting_id}/done`,
      });
    }, 100);
  }, [meeting, viewer, locale, meeting_id]);
  return (
    <IconButton
      isRound
      aria-label="camera-btn"
      colorScheme="white"
      variant="solid"
      size={size}
      onClick={hangUpCall}
      boxShadow="0px 0px 18px 2px rgba(0, 0, 0, 0.12)"
      _focus={{ boxShadow: '0px 0px 18px 2px rgba(0, 0, 0, 0.25)' }}
    >
      <Icon as={PhoneIcon} w={{ base: 5, sm: 6 }} h={{ base: 5, sm: 6 }} />
    </IconButton>
  );
};

export default HangUpCallButton;
