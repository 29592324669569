import { useFormValidation } from '@src/hooks';
import React, { useMemo } from 'react';
import { TextField, TextFieldProps } from '../TextField';

export type EmailFieldProps<FieldValue> = Omit<
  TextFieldProps<FieldValue>,
  'name'
> & {
  name?: string;
};

export const EmailField = ({
  name = 'email',
  label = 'Email',
  placeholder = 'Enter your email',
  fieldProps,
  ...props
}: // eslint-disable-next-line
EmailFieldProps<any>) => {
  const { validEmail } = useFormValidation();
  const _fieldProps = useMemo(
    () => ({
      validate: validEmail(),
      ...fieldProps,
    }),
    [fieldProps, validEmail]
  );
  return (
    <TextField
      type="email"
      name={name}
      label={label}
      placeholder={placeholder}
      fieldProps={_fieldProps}
      {...props}
    />
  );
};
