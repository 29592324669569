export const lawyerReasons = [
  {
    translationKey: 1,
    action: {
      label: 'Sign Up',
      href: 'signup',
    },
    transform: { lg: 'translateX(-30%)' },
  },
  {
    translationKey: 2,
    action: {
      label: 'Join Now',
      href: 'signup',
    },
  },
  {
    translationKey: 3,
    action: {
      label: 'Contact us',
      href: 'contact',
    },
    transform: { lg: 'translateX(-35%)' },
  },
];
