import { IVideoTrack } from '@src/@types/twilio';
import Video, {
  AudioTrack,
  VideoTrack,
  LocalVideoTrack,
  LocalAudioTrack,
  LocalDataTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
  CreateLocalTrackOptions,
  LocalTrackPublication,
  RemoteTrackPublication,
} from 'twilio-video';

export type AudioStream = LocalAudioTrack | AudioTrack;

export type LocalVideoStream = LocalVideoTrack;
export type LocalAudioStream = LocalAudioTrack;
export type LocalDataStream = LocalDataTrack;
export type LocalMediaStream =
  | LocalAudioStream
  | LocalVideoStream
  | LocalDataStream;

export type RemoteVideoStream = RemoteVideoTrack;
export type RemoteAudioStream = RemoteAudioTrack;

export type LocalStream = LocalAudioTrack | LocalVideoTrack | LocalDataTrack;
export type Stream = AudioTrack | VideoTrack | undefined;
export type VideoStream = IVideoTrack;
export type CreateStreamOptions = CreateLocalTrackOptions;
export type Publication = RemoteTrackPublication | LocalTrackPublication;

const createVideoStream = (
  options: CreateStreamOptions
): Promise<LocalVideoStream> => {
  return new Promise<LocalVideoTrack>((done) => {
    return Video.createLocalVideoTrack(options).then((track) => {
      return done(track);
    });
  });
};

const createAudioStream = (
  options: CreateStreamOptions
): Promise<LocalAudioStream> => {
  return new Promise<LocalAudioStream>((done) => {
    return Video.createLocalAudioTrack(options).then((track) => {
      return done(track);
    });
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createLocalStreams = (options: any): Promise<LocalMediaStream[]> => {
  return new Promise<LocalMediaStream[]>((done) => {
    return Video.createLocalTracks(options).then((streams) => {
      return done(streams);
    });
  });
};

export { createAudioStream, createVideoStream, createLocalStreams };
