import React from 'react';
import useTrack from '@src/hooks/meeting/useTrack';
import MeetingRoomAudioTrack from '@molecules/MeetingRoom/Audio/Track';
import MeetingRoomVideoTrack from '@molecules/MeetingRoom/Video/Track';

import {
  AudioStream,
  VideoStream,
  Publication as IPublication,
  Participant,
  Track,
} from '@src/video';

interface PublicationProps {
  publication: IPublication;
  participant: Participant;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
}

export default function Publication({
  publication,
  isLocalParticipant,
  videoOnly,
  videoPriority,
}: PublicationProps) {
  const track = useTrack(publication);

  if (!track) return null;
  switch (track.kind) {
    case 'video':
      return (
        <MeetingRoomVideoTrack
          track={track as VideoStream}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocalParticipant}
        />
      );
    case 'audio':
      return videoOnly ? null : (
        <MeetingRoomAudioTrack track={track as AudioStream} />
      );
    default:
      return null;
  }
}
