import { mode } from '@chakra-ui/theme-tools';

const parts = ['popper', 'content', 'header', 'body', 'footer', 'arrow'];

const baseStylePopper = {
  w: '100%',
  maxW: 'xs',
  zIndex: 10,
};

function baseStyleContent(props: Dict) {
  return {
    bg: mode('white', 'gray.700')(props),
    border: '1px solid',
    borderColor: 'inherit',
    borderRadius: 'xl',
    boxShadow: 'sm',
    zIndex: 'inherit',
    _focus: {
      outline: 0,
      boxShadow: 'lg',
    },
  };
}

function baseStyleArrow(props: Dict) {
  return {
    bg: mode('white', 'gray.700')(props),
  };
}

const baseStyleHeader = {
  px: 3,
  py: 2,
  borderBottomWidth: '1px',
};

const baseStyleBody = {
  px: 3,
  py: 2,
};

const baseStyleFooter = {
  px: 3,
  py: 2,
  borderTopWidth: '1px',
};

const baseStyle = (props: Dict) => {
  return {
    popper: baseStylePopper,
    content: baseStyleContent(props),
    header: baseStyleHeader,
    body: baseStyleBody,
    footer: baseStyleFooter,
    arrow: baseStyleArrow(props),
  };
};

function getSize(value: string) {
  return {
    popper: { maxW: value },
  };
}

const sizes = {
  '3xs': getSize('3xs'),
  '2xs': getSize('2xs'),
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
  xl: getSize('xl'),
  '2xl': getSize('2xl'),
  '3xl': getSize('3xl'),
  '4xl': getSize('4xl'),
  '5xl': getSize('5xl'),
  '6xl': getSize('6xl'),
};

export default {
  parts,
  baseStyle,
  sizes,
};
