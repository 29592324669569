import React, { ReactNode } from 'react';
import Loader from '@atoms/Loader';
import { useViewerQuery } from '@src/apollo/hooks';
import Layout from './Layout';
import SidebarLayout from './SidebarLayout';

export interface LayoutProps {
  children: ReactNode;
  preview?: boolean;
  theme?: string;
}

const RooLayout = ({ theme = 'light', children }: LayoutProps) => {
  const { data, loading } = useViewerQuery();

  if (loading) return <Loader />;
  return (
    <>
      {!data?.viewer && <Layout theme={theme}>{children}</Layout>}
      {data?.viewer && <SidebarLayout>{children}</SidebarLayout>}
    </>
  );
};

export default RooLayout;
