import React, { useMemo } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react';
import { ShowErrorFunc, showErrorOnChange } from '@src/utils';

export interface SelectFieldProps<FieldValue> extends ChakraSelectProps {
  name: string;
  label: string;
  showError?: ShowErrorFunc;
  fieldProps?: UseFieldConfig<FieldValue>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
}

export const Select = ({
  name,
  label,
  placeholder,
  fieldProps,
  formControlProps,
  formLabelProps,
  children,
  variant = 'roo',
  showError = showErrorOnChange,
  isDisabled,
  ...props
}: // eslint-disable-next-line
SelectFieldProps<any>) => {
  const { input, meta } = useField(name, { type: 'select', ...fieldProps });

  const { error } = meta;

  const isError = useMemo(() => showError({ meta }), [meta, showError]);
  return (
    <FormControl
      isInvalid={isError}
      isDisabled={isDisabled}
      outline="none"
      appearance="none"
      d="inline-flex"
      verticalAlign="top"
      flexDirection="column"
      {...formControlProps}
    >
      <FormLabel htmlFor={input.name} {...formLabelProps}>
        {label}
      </FormLabel>
      <ChakraSelect
        {...props}
        {...input}
        id={input.name}
        placeholder={placeholder}
        size="xl"
        variant={variant}
      >
        {children}
      </ChakraSelect>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
