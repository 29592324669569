import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { useCallback, useState } from 'react';
import {
  PaymentMethod,
  PaymentMethodCreateParams,
  StripeError,
} from '@stripe/stripe-js';

export function useStripeCreatePaymentMethod() {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<StripeError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethod | null | undefined
  >(null);

  const onCreatePaymentMethod = useCallback(
    async (billingDetails?: PaymentMethodCreateParams.BillingDetails) => {
      if (!stripe || !elements) {
        return;
      }

      setLoading(true);
      const cardElement = elements.getElement(CardNumberElement);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!, // eslint-disable-line
        billing_details: billingDetails,
      });

      if (result.error) {
        setError(result.error);
        setLoading(false);
        setPaymentMethod(null);
      } else {
        setLoading(false);
        setPaymentMethod(result.paymentMethod);
      }
      return result;
    },
    [stripe, elements, setError]
  );

  return {
    loading: loading || !stripe,
    error: error,
    onCreatePaymentMethod,
    paymentMethod,
  };
}
