import React from 'react';
import { Box, Avatar, Text } from '@chakra-ui/react';

const MeetingRoomPillProfileCard = ({ name }: { name: string }) => {
  return (
    <Box
      w="full"
      d="flex"
      flexDir="row"
      alignItems="center"
      h="50px"
      rounded="25px"
      background="linear-gradient(81.12deg, rgba(35, 6, 215, 0.47) 19.37%, rgba(12, 11, 11, 0) 90.87%);"
    >
      <Avatar mr="10px" w={'50px'} h={'50px'} name={name} />
      <Text color="white">{name}</Text>
    </Box>
  );
};
export default MeetingRoomPillProfileCard;
