import React from 'react';
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  Placement,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as chevronUp } from '@src/icons/chevronUp.svg';
import MeetingWith from '@organisms/PreJoinScreens/MeetingWith';

const MeetingInfo = ({ viewer }: { viewer: User }) => {
  const size = useBreakpointValue({ base: 'md', md: 'lg' });
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const menuPlacement = useBreakpointValue<Placement>({
    base: 'bottom',
    md: 'right',
  });

  return (
    <Menu placement={menuPlacement}>
      <MenuButton
        isRound
        as={IconButton}
        colorScheme={isOpen ? 'primary' : 'white'}
        variant={isOpen ? 'solid' : 'outline'}
        size={size}
        onClick={onOpen}
        d={{ base: 'unset', lg: 'none' }}
        _hover={{ bg: isOpen ? 'blue.500' : 'rgba(255,255,255,0.4)' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Icon
          aria-label="settings-btn"
          as={chevronUp}
          w={{ base: 5, sm: 6 }}
          h={{ base: 5, sm: 6 }}
        />
      </MenuButton>
      {isOpen && (
        <Box
          alignItems="center"
          px={{ base: 2, md: 0 }}
          d={{ base: 'flex', lg: 'none' }}
        >
          <MeetingWith onClose={onClose} viewer={viewer} />
        </Box>
      )}
    </Menu>
  );
};

export default MeetingInfo;
