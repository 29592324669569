import React, { useCallback } from 'react';
import {
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Icon,
  Heading,
  VStack,
  Text,
  HStack,
  Link,
  chakra,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as TextureAbst } from '@src/icons/abst-invert.svg';
import { ReactComponent as LegarooIcon } from '@src/icons/white-logo.svg';
import SigninForm, { SigninFormValues } from '@components/molecules/SigninForm';
import useAuth0 from '@src/hooks/useAuth0';
import { FORM_ERROR } from 'final-form';
import AuthSocialButtons from '@components/molecules/AuthSocialButtons';
import { Alert } from '@components/atoms/Alert';
import { formatError, setCookie } from '@src/utils';
import { ErrorState } from '@src/hooks';
import { Auth0Error } from 'auth0-js';

export interface SigninModalProps {
  isOpen: boolean;
  onClose(): void;
  onSignupClick(): void;
}

const SigninModal = ({ isOpen, onClose, onSignupClick }: SigninModalProps) => {
  const { t } = useTranslation();
  const { signin } = useAuth0();
  const history = useHistory();
  const location = useLocation<{
    signinError?: ErrorState | null;
  }>();
  const modalSize = useBreakpointValue({ lg: '3xl' });
  const classNameVariant = useBreakpointValue({
    base: 'clip-image-top-left',
    lg: '',
  });
  const pathname = location.pathname;
  const error = location.state?.signinError;

  const onSubmit = useCallback(
    async ({ email, password }: SigninFormValues) => {
      try {
        setCookie('path', pathname);
        await signin({
          email,
          password,
        });
        onClose();
      } catch (error) {
        history.replace({ ...history.location, state: {} });
        return { [FORM_ERROR]: error.description };
      }
    },
    [onClose, signin, pathname, history]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay>
        <ModalContent rounded="3xl" mx={{ base: 4, lg: 0 }}>
          <ModalCloseButton zIndex={50} color="black" top={4} right={6} />
          <ModalBody p={0}>
            <Box
              position="relative"
              bg="white"
              overflow="hidden"
              rounded={{ base: 'lg', lg: '3xl' }}
              top={0}
              d="flex"
              flexDir={{ base: 'column-reverse', lg: 'row' }}
            >
              <Box
                position="relative"
                bg="primary"
                insetY={0}
                left={0}
                color="white"
                p={6}
                w={{ base: '100%', lg: '40%' }}
                className={classNameVariant}
              >
                <Icon
                  viewBox="0 0 100 100"
                  fill="currentColor"
                  preserveAspectRatio="none"
                  position="absolute"
                  right={0}
                  insetY={0}
                  h="full"
                  w={12}
                  color="white"
                  d={{ base: 'none', lg: 'flex' }}
                  transform="translateX(50%)"
                >
                  <polygon points="0,0 50,0 100,100 50,100" />
                </Icon>
                <Icon
                  as={TextureAbst}
                  position="absolute"
                  right={0}
                  bottom={0}
                  transform="translateX(50%) translateY(-2.5rem)"
                  w={56}
                  h="auto"
                />
                <Box
                  py={{ base: 4, lg: 16 }}
                  px={{ base: 4, lg: 6 }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={LegarooIcon} w={48} h="auto" />

                  <Box
                    pt={{ base: 4, lg: 32 }}
                    d="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Text fontSize="sm" mt={4}>
                      {t('signin.not_member_yet')}
                    </Text>

                    <Button
                      variant="outline"
                      size="md"
                      px={10}
                      mt={{ base: 2, lg: 4 }}
                      colorScheme="white"
                      onClick={onSignupClick}
                    >
                      {t('signin.signup_here')}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                bg="white"
                p={{ base: 6, lg: 10 }}
                w={{ base: 'full', lg: '60%' }}
              >
                <Heading
                  as="h4"
                  fontWeight="bold"
                  fontSize="sm"
                  lineHeight={6}
                  color="black"
                >
                  {t('signin.title')}
                </Heading>
                <Heading
                  fontSize="2xl"
                  fontWeight="light"
                  lineHeight={6}
                  color="gray.600"
                  mt={1}
                  as="h2"
                >
                  {t('common.brand')}
                </Heading>

                {error &&
                  error.length > 0 &&
                  error.map((err, index) => (
                    <chakra.div key={index}>
                      {(err as Auth0Error)?.errorDescription ===
                      'needs_verification_email' ? (
                        <Alert
                          mt={6}
                          status="error"
                          title={t('auth.verify_email.title')}
                          description={t('auth.verify_email.description')}
                        />
                      ) : (
                        <Alert
                          mt={6}
                          status="error"
                          description={
                            formatError(err) ||
                            'something went wrong please try again'
                          }
                        />
                      )}
                    </chakra.div>
                  ))}
                <SigninForm onSubmit={onSubmit} />
                <VStack mt={5} spacing={4} align="flex-start">
                  <Text fontSize="sm" color="gray.600">
                    {t('signin.signin_options_text')}
                  </Text>
                  <AuthSocialButtons />
                </VStack>
                <HStack mt={12} spacing={4} justifyContent="flex-end" w="full">
                  <Link color="primary">{t('common.termsOfUse')}</Link>
                  <Link color="primary" ml={4}>
                    {t('common.privacy_policy')}
                  </Link>
                </HStack>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default SigninModal;
