import React from 'react';
import { chakra, Icon, Text } from '@chakra-ui/react';
import { formatMoney } from '@src/utils/function';
import { ReactComponent as CreditCardSvg } from '@src/icons/credit-card.svg';
import DateFormat from '@src/components/atoms/DateFormat';
import { Payment } from './PaymentLineItem';

export default function PaymentItemMobile({
  date,
  details,
  bankStatement,
  id,
}: Payment) {
  return (
    <chakra.div rounded="xl" shadow="lg" bg="white" w="full" p={5}>
      <chakra.div>
        <chakra.div d="flex" justifyContent="space-between" alignItems="center">
          <Text fontSize="sm" fontWeight="light">
            {bankStatement}
          </Text>
          <Text fontWeight="semibold" fontSize="xs" ml={1}>
            {formatMoney(details.total)}
          </Text>
        </chakra.div>
        <chakra.div
          d="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Text fontWeight="light" fontSize="sm">
            <DateFormat date={date} />
          </Text>
        </chakra.div>
        <chakra.div
          d="flex"
          justifyContent="space-between"
          mt={4}
          alignItems="center"
        >
          <Text fontWeight="light" fontSize="sm">
            <Icon as={CreditCardSvg} mr={1} w={6} h={6} color="gray.600" />
            {details.paymentSource.cardLast4}
          </Text>
          <Text fontSize="sm" fontWeight="light">
            ID: {id}
          </Text>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
}
