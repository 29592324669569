import React, { useContext, useEffect } from 'react';
import LogRocket from 'logrocket';
import CustomRoute from '@src/Router';
import { useViewerQuery } from './apollo/hooks';
import useHeapAnalytics from '@src/hooks/useHeapAnalitycs';
import { AbilityContext } from '@src/context';
import { defineAbilityFor } from '@src/config/defineAbility';
import AuthCallback from '@src/pages/Auth/Callback';
import LawyerApplication from '@src/pages/LawyerApplication';
import Lawyer from '@src/pages/Lawyer';
import Directory from '@src/pages/Directory';
import Bookings from '@src/pages/Booking';
import MeetingRoom from '@src/pages/MeetingRoom';
import MyMeetings from '@src/pages/MyMeetings';
import MyPayments from '@src/pages/MyPayments';
import IndexPage from '@src/pages';
import Profile from '@src/pages/Profile';
import PrivateRoute from './PrivateRoute';
import WorkingHours from './pages/WorkingHours';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/StaticPages/privacyPolicy';
import Terms from './pages/StaticPages/termsAndConditions';
import FreshChat from './components/atoms/FreshChat';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('qbqxfn/legaroo-meets');
}

const App: React.FC = () => {
  const { data, loading } = useViewerQuery();
  const ability = useContext(AbilityContext);
  useHeapAnalytics(process.env.REACT_APP_HEAP_PROJECT_ID || 0);

  const viewer = data?.viewer;

  useEffect(() => {
    if (viewer && process.env.NODE_ENV === 'production') {
      LogRocket.identify(viewer?.email, {
        name: `${viewer?.profile.firstName} ${viewer?.profile.lastName}`,
        email: viewer?.email,
        roles: viewer?.roles?.join(',') || '',
      });
    }
  }, [viewer]);

  useEffect(() => {
    if (!loading) {
      defineAbilityFor(ability, viewer);
    }
  }, [ability, viewer, loading]);

  return (
    <>
      {process.env.NODE_ENV === 'production' && <FreshChat />}
      <CustomRoute path="/" exact Component={IndexPage} />
      <CustomRoute path="/auth/callback" Component={AuthCallback} />
      <CustomRoute path="/lawyers" Component={Lawyer} />
      <CustomRoute path="/directory" Component={Directory} />
      <CustomRoute path="/application" Component={LawyerApplication} />
      <CustomRoute path="/booking" Component={Bookings} />
      <CustomRoute path="/contact" Component={ContactUs} />
      <CustomRoute path="/privacy-policy" Component={PrivacyPolicy} />
      <CustomRoute path="/terms" Component={Terms} />
      <PrivateRoute path="/meeting/:meeting_id">
        <MeetingRoom />
      </PrivateRoute>
      <PrivateRoute path="/my-meetings">
        <MyMeetings />
      </PrivateRoute>
      <PrivateRoute path="/my-payments">
        <MyPayments />
      </PrivateRoute>
      <PrivateRoute path="/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute
        path="/availability"
        authorize={(ability) => ability.can('create', 'time_slots')}
      >
        <WorkingHours />
      </PrivateRoute>
    </>
  );
};

export default App;
