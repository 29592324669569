import React from 'react';
import { Box, Link, HStack, Button } from '@chakra-ui/react';
import RRLink from '@src/components/atoms/Link';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '@components/atoms/LanguageSelect';
import AccountPopover from '@src/components/molecules/AccountPopover';
import { useSigninModal, useSignupModal } from '@src/context';
import { useLawyerApplicationStatus } from '@src/hooks/useLawyerApplicationStatus';
export interface UserNavigationProps {
  viewer?: Maybe<Viewer>;
  theme: { [key: string]: string };
}

const UserNavigation = ({ viewer, theme }: UserNavigationProps) => {
  const { t } = useTranslation();
  const signupModal = useSignupModal();
  const signinModal = useSigninModal();
  const { canViewApplyAsLawyer } = useLawyerApplicationStatus();

  return (
    <Box
      h={16}
      display={{ base: 'none', lg: 'flex' }}
      w="100%"
      alignContent="center"
      justifyContent="flex-end"
      alignItems="center"
    >
      <HStack>
        {canViewApplyAsLawyer && (
          <Link
            as={RRLink}
            mr={2}
            to="/lawyers"
            _hover={{ color: 'gray.500' }}
            _focus={{ color: 'primary' }}
          >
            {t('common.join_as_lawyer')}
          </Link>
        )}
      </HStack>
      {viewer && <AccountPopover {...(viewer as User)} />}
      <Box ml={[1, 2]}>
        <LanguageSelect />
      </Box>
      {!viewer && (
        <Box ml={2}>
          <Button
            mr={3}
            variant="unstyled"
            p={4}
            py={0}
            onClick={signinModal.open}
          >
            {t('common.labels.sign_in')}
          </Button>
          <Button
            variant="outline"
            colorScheme={theme.colorScheme}
            onClick={signupModal.open}
          >
            {t('common.labels.sign_up')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UserNavigation;
