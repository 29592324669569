import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSignupModal } from '@src/context';

export const RLMSection: React.FC = () => {
  const { t } = useTranslation();
  const modal = useSignupModal();
  return (
    <Box
      position="relative"
      overflow={{ sm: 'hidden' }}
      mt={-4}
      zIndex={1}
      className="clip-image-top-bottom-left"
    >
      <Box
        pos="absolute"
        insetY={0}
        left={0}
        w={{ base: 'full', lg: '70%' }}
        overflow="hidden"
      >
        <Box
          as="img"
          src="/images/remote-legaroo-meeting.png"
          objectFit="cover"
          h="full"
          w="full"
        />
        <Box
          pos="absolute"
          inset={0}
          backgroundColor="primary"
          __css={{ mixBlendMode: 'multiply' }}
        />
      </Box>
      <Box
        pos="absolute"
        overflow="hidden"
        insetY={0}
        right={0}
        width={{ lg: '70%' }}
        backgroundImage="linear-gradient(283.1deg,#0747a6 58.7%, rgba(7, 71, 166, 0) 77.67%)"
      />
      <Box
        pos="relative"
        d={{ base: 'flex', lg: 'grid' }}
        gridTemplateColumns={{ lg: '50% 50%' }}
        justifyContent="center"
        px={{ base: 4 }}
        mx="auto"
      >
        <Box
          gridColumn="2 / -1"
          h="full"
          d="flex"
          color="white"
          justifyContent="center"
          alignItems="center"
          maxW={{ base: 'xl', md: 'lg', lg: 'sm' }}
          py={{ base: 16, sm: 24, lg: 32 }}
        >
          <Box
            d="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ base: 'center', lg: 'baseline' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Heading
              as="h2"
              color="white"
              fontSize={{ base: '3xl', md: '4xl' }}
            >
              {t('landing.rlm')}
            </Heading>
            <Text fontSize="md" lineHeight={6} mt={6}>
              {t('landing.rlm_section.paragraph1')}
            </Text>
            <Text fontSize="md" lineHeight={6} mt={6}>
              {t('landing.rlm_section.paragraph2')}
            </Text>
            <Button
              colorScheme="orange"
              variant="rooLarge"
              size="lg"
              fontSize="sm"
              mt={6}
              textTransform="uppercase"
              onClick={modal.open}
            >
              {t('common.labels.sign_up')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RLMSection;
