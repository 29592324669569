import React from 'react';
import {
  ReasonsSection,
  HeroSection,
  HowItWorkSection,
} from '@components/molecules/LawyerLandingSection';
import Layout from '@src/components/organisms/RooLayout/Layout';

const LawyerLanding: React.FC = () => {
  return (
    <Layout theme="light">
      <div className="landing-page ">
        <HeroSection />
        <HowItWorkSection />
        <ReasonsSection />
      </div>
    </Layout>
  );
};

export default LawyerLanding;
