import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

export interface LayoutProps {
  children: ReactNode;
  preview?: boolean;
  theme?: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LayoutTheme: { [key: string]: any } = {
  light: {
    name: 'light',
    backgroundColor: 'white',
    color: 'gray.900',
    colorHover: 'gray.500',
    colorScheme: 'blue',
  },
  blue: {
    name: 'blue',
    backgroundColor: 'blue.600',
    color: 'white',
    colorHover: 'white.500',
    colorScheme: 'white',
  },
  skyBlue: {
    name: 'skyBlue',
    backgroundColor: 'teal.50',
    color: 'gray.900',
    colorHover: 'gray.500',
    colorScheme: 'blue',
  },
};

const Layout = ({ theme = 'light', children }: LayoutProps) => {
  return (
    <Box backgroundColor={LayoutTheme[theme].backgroundColor}>
      <Header theme={LayoutTheme[theme]} />
      <Box minH="100vh">{children}</Box>
      <Footer />
    </Box>
  );
};

export default Layout;
