/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useCallback, useContext } from 'react';
import uniqBy from 'lodash/fp/uniqBy';
import sortBy from 'lodash/fp/sortBy';
import { lawyers } from '@src/content';
import { Lawyer, LawyerLocation } from '@src/content/lawyers';
import {
  lawyerSortOptions,
  areasOfPractice,
  PracticeArea,
  SortOption,
} from '@src/content/config';
export interface ContextProps {
  lawyers: Lawyer[];
  filteredLawyers: Lawyer[];
  practiceArea: PracticeArea[];
  countries: LawyerLocation[];
  sortOptions: SortOption[];
  setActiveCountryFilter: (countryCode: string) => void;
  activeCountry?: LawyerLocation;
  loading: boolean;
  setPracticeAreasFilter: (areas: string[]) => void;
  activePracticeAreaFilter: string[];
}

export const LawyerContext = React.createContext<ContextProps>({
  lawyers: lawyers,
  practiceArea: [],
  filteredLawyers: [],
  countries: [],
  sortOptions: lawyerSortOptions,
  setActiveCountryFilter: (countryCode: string) => {},
  activeCountry: undefined,
  loading: true,
  setPracticeAreasFilter: (areas: string[]) => {},
  activePracticeAreaFilter: [],
});

export function LawyerContextProvider(props: { children: React.ReactNode }) {
  const [locations, setLocations] = React.useState<LawyerLocation[]>([]);
  const [activeLocation, setActiveLocations] = React.useState<LawyerLocation>(
    locations[0]
  );
  const [filteredLawyers, setFilteredLawyers] = React.useState<Lawyer[]>([]);

  const [
    activePracticeAreaFilter,
    setActivePracticeAreaFilter,
  ] = React.useState<string[]>([]);

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const mappedLocations = lawyers.map((lawyer: Lawyer) => {
      const { nat, location } = lawyer;
      return { code: nat, country: location.country, city: location.city };
    });
    const uniq = uniqBy('country', sortBy('country', mappedLocations));
    setLocations(uniq);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!activeLocation) return;
    const filtered = lawyers.filter((lawyer: Lawyer) => {
      return lawyer.nat === activeLocation.code;
    });
    if (filtered) setFilteredLawyers(filtered);
  }, [activeLocation]);

  const setActiveFilter = useCallback(
    (countryCode: string) => {
      const filteredCountry = locations.find((location: LawyerLocation) => {
        return location.code === countryCode;
      });
      if (filteredCountry) setActiveLocations(filteredCountry);
    },
    [locations]
  );

  return (
    <LawyerContext.Provider
      value={{
        practiceArea: areasOfPractice,
        lawyers,
        filteredLawyers,
        countries: locations,
        activeCountry: activeLocation,
        setPracticeAreasFilter: setActivePracticeAreaFilter,
        activePracticeAreaFilter: activePracticeAreaFilter,
        setActiveCountryFilter: setActiveFilter,
        loading: loading,
        sortOptions: lawyerSortOptions,
      }}
    >
      {props.children}
    </LawyerContext.Provider>
  );
}

export function useLawyers() {
  const context = useContext(LawyerContext);
  if (!context) {
    throw new Error('useLawyers must be used within the LawyerContext');
  }
  return context as ContextProps;
}

export function useLawyerQuery(id: number) {
  const { lawyers } = useLawyers();
  const lawyer = lawyers.find((lawyer) => lawyer.id === id);
  return lawyer;
}
