import { ReactComponent as Support } from '@src/icons/support.svg';
import { ReactComponent as Worldwide } from '@src/icons/worldwide.svg';
import { ReactComponent as Help } from '@src/icons/help.svg';

export const meetFeatures = [
  {
    translationKey: 1,
    action: {
      label: 'Search',
      href: 'search',
    },
    transform: { lg: 'translateX(-30%)' },
    icon: Worldwide,
  },
  {
    translationKey: 2,
    action: {
      label: 'Sign Up',
      href: 'signup',
    },
    icon: Support,
  },
  {
    translationKey: 3,
    action: {
      label: 'Contact us',
      href: 'contact',
    },
    transform: { lg: 'translateX(-35%)' },
    icon: Help,
  },
];
