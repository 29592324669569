import { useState, useEffect } from 'react';
import {
  LocalVideoStream,
  LocalAudioStream,
  RemoteAudioStream,
  RemoteVideoStream,
} from '@src/video';

type TrackType =
  | LocalAudioStream
  | RemoteAudioStream
  | LocalVideoStream
  | RemoteVideoStream
  | undefined;

export default function useIsTrackEnabled(track: TrackType) {
  const [isEnabled, setIsEnabled] = useState(track ? track.isEnabled : false);

  useEffect(() => {
    setIsEnabled(track ? track.isEnabled : false);

    if (track) {
      const setEnabled = () => setIsEnabled(true);
      const setDisabled = () => setIsEnabled(false);
      track.on('enabled', setEnabled);
      track.on('disabled', setDisabled);
      return () => {
        track.off('enabled', setEnabled);
        track.off('disabled', setDisabled);
      };
    }
  }, [track]);

  return isEnabled;
}
