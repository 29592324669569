import React, { cloneElement, ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useViewerQuery } from './apollo/hooks';
import Loader from '@atoms/Loader';
import { useAbility } from '@casl/react';
import { AbilityContext } from './context';
import { Ability } from '@casl/ability';

export type PrivateRouteProps = RouteProps & {
  children: ReactElement;
  prefixLocale?: boolean;
  authorize?(ability: Ability): boolean;
};

function PrivateRoute({
  children,
  prefixLocale = true,
  authorize,
  ...props
}: PrivateRouteProps) {
  const { data, loading } = useViewerQuery();
  const ability = useAbility(AbilityContext);

  if (loading) {
    return <Loader />;
  }
  const path = prefixLocale ? `/:locale${props.path}` : props.path;
  const viewer = data?.viewer;
  const isAuthorize = authorize?.(ability) ?? !!viewer;
  return (
    <Route
      {...props}
      path={path}
      render={({ location }) =>
        isAuthorize ? (
          cloneElement(children, { viewer })
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
