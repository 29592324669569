import { useCallback } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { es, enUS } from 'date-fns/locale';

export function useDateFormat() {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const formatLocale = useCallback(
    (date: Date, formatStr: string) => {
      return format(date, formatStr, {
        locale: currentLang === 'en' ? enUS : es,
      });
    },
    [currentLang]
  );

  return formatLocale;
}
