import React from 'react';
import { Box } from '@chakra-ui/react';
import MeetingItem from './MeetingItem';
export interface MeetingHistoryTableProps {
  meetings: Meeting[];
}

const MeetingHistoryTable = ({ meetings }: MeetingHistoryTableProps) => {
  return (
    <Box mx="auto" maxW="3xl" w="full">
      {meetings.map?.((meeting) => (
        <MeetingItem key={meeting.meeting.roomId} {...meeting} />
      ))}
    </Box>
  );
};

export default MeetingHistoryTable;
