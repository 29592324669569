import React from 'react';
import { Box, Icon, Text } from '@chakra-ui/react';
import DateFormat from '@src/components/atoms/DateFormat';
import { formatMoney } from '@src/utils';
import { ReactComponent as CreditCardSvg } from '@src/icons/credit-card.svg';

export type Payment = Pick<
  PurchaseType,
  'bankStatement' | 'description' | 'id'
> & { date: PurchaseType['insertedAt'] } & {
  details: { __typename?: 'PurchaseDetailsType' } & Pick<
    PurchaseDetailsType,
    'total'
  > & {
      paymentSource: { __typename?: 'PaymentMethodType' } & Pick<
        PaymentMethodType,
        'cardLast4'
      >;
    };
};

const PaymentLineItem = ({ bankStatement, date, details, id }: Payment) => {
  const displayAmount = formatMoney(details.total);
  return (
    <Box as="tr">
      <Box
        as="td"
        textAlign="left"
        px={3}
        py={4}
        whiteSpace="nowrap"
        fontSize="sm"
      >
        {id}
      </Box>

      <Box as="td" px={2} py={4}>
        <Box d="flex" alignItems="left">
          <Box>
            <Text fontSize="sm" color="gray.900">
              {bankStatement}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        <DateFormat date={date} fontSize="sm" />
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap" fontSize="sm">
        <Icon as={CreditCardSvg} mr={1} w={4} h={4} color="gray.600" />{' '}
        {details.paymentSource.cardLast4}
      </Box>

      <Box as="td" px={2} py={4} whiteSpace="nowrap">
        <Box d="flex" alignItems="center" flexDirection="column">
          <Text fontSize="sm" fontWeight="medium">
            {displayAmount}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentLineItem;
