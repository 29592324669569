import React, { useCallback } from 'react';
import {
  chakra,
  Heading,
  Stack,
  VStack,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { ReactComponent as PlusIcon } from '@src/icons/plus.svg';
import { ReactComponent as CloseIcon } from '@src/icons/close.svg';
import { Select, TextField } from '@src/components/atoms/Fields';

const currentYear = new Date().getFullYear();

const years = [...Array(20)].map((_, i) => {
  return currentYear - i;
});

export interface LaboralExperienceSectionProps {
  onAdd(): void;
}

export default function LaboralExperienceSection({
  onAdd,
}: LaboralExperienceSectionProps) {
  const { t } = useTranslation();
  const validate = useCallback(
    (values: LawyerWorkExperienceInput[]) => {
      if (!values.length) return;
      return values.map((value) => {
        // eslint-disable-next-line
        const errors: any = {};
        if (
          value?.endYear &&
          value?.startYear &&
          value.endYear < value.startYear
        ) {
          errors.endYear = t('validate.greater_or_equal_than', {
            name: 'start year',
          });
        }
        return errors;
      });
    },
    [t]
  );
  return (
    <chakra.div pos="relative" mx="auto" maxW="3xl" py={12}>
      <chakra.div px={{ base: 3, sm: 6 }}>
        <Heading as="h3" fontSize="22px" fontWeight="bold" mb={10}>
          {t('profile.laboral_experience')}
        </Heading>
        <VStack w="full" spacing={{ base: 8, md: 12 }}>
          <FieldArray name="workExperience" validate={validate}>
            {({ fields }) =>
              fields.map((name, index) => (
                <VStack key={name} spacing={3} w="full" alignItems="initial">
                  <Stack direction={['column', 'row']} spacing={3}>
                    <TextField
                      name={`${name}.companyName`}
                      label={t('fields.company.label')}
                      placeholder={t('fields.company.placeholder')}
                    />
                    <TextField
                      name={`${name}.title`}
                      label={t('fields.company_role.label')}
                      placeholder={t('fields.company_role.placeholder')}
                    />
                  </Stack>
                  <Stack direction={['column', 'row']} spacing={3}>
                    <Select
                      name={`${name}.startYear`}
                      label={t('fields.from.label')}
                      placeholder={t('fields.year.placeholder')}
                      formControlProps={{
                        w: { base: '80%', sm: 56 },
                      }}
                    >
                      <chakra.div
                        as="option"
                        disabled
                        hidden
                        d="none"
                        value=""
                      />
                      {years.map((year) => (
                        <chakra.div as="option" value={year} key={year}>
                          {year}
                        </chakra.div>
                      ))}
                    </Select>

                    <chakra.div>
                      <Select
                        name={`${name}.endYear`}
                        label={t('fields.to.label')}
                        placeholder={t('fields.year.placeholder')}
                        formControlProps={{
                          w: { base: '80%', sm: 56 },
                        }}
                      >
                        <chakra.div
                          as="option"
                          disabled
                          hidden
                          d="none"
                          value=""
                        />
                        <chakra.div as="option" value="current">
                          {t('common.current')}
                        </chakra.div>
                        {years.map((year) => (
                          <chakra.div as="option" value={year} key={year}>
                            {year}
                          </chakra.div>
                        ))}
                      </Select>
                      <chakra.div
                        d="inline-flex"
                        ml={3}
                        flexDir="column"
                        alignItems="center"
                        justifyContent="center"
                        h="full"
                      >
                        <IconButton
                          size="xs"
                          isRound
                          colorScheme="primary"
                          aria-label="Add title"
                          onClick={onAdd}
                        >
                          <Icon as={PlusIcon} h={3} w={3} />
                        </IconButton>
                        {index !== 0 && (
                          <IconButton
                            mt={1}
                            size="xs"
                            isRound
                            colorScheme="red"
                            aria-label="trash"
                            onClick={() => fields.remove(index)}
                          >
                            <Icon as={CloseIcon} h={2} w={2} />
                          </IconButton>
                        )}
                      </chakra.div>
                    </chakra.div>
                  </Stack>
                </VStack>
              ))
            }
          </FieldArray>
        </VStack>
      </chakra.div>
    </chakra.div>
  );
}
