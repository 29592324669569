import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export const ProfileFragmentDoc = gql`
  fragment profile on UserProfile {
    firstName
    lastName
    avatar
    dob
    phone
  }
`;
export const LawyerTimeSlotFragmentDoc = gql`
  fragment lawyerTimeSlot on LawyerTimeSlotsType {
    name
    from
    to
  }
`;
export const CountryFragmentDoc = gql`
  fragment country on CountryType {
    id
    name
    slug
    code
  }
`;
export const LegalPracticeFragmentDoc = gql`
  fragment legalPractice on LegalPracticeType {
    description
    name
    slug
  }
`;
export const LawyerFragmentDoc = gql`
  fragment lawyer on LawyerType {
    id
    firstName
    lastName
    bio
    headshot
    hourlyRate
    title
    location {
      ...country
    }
    legalPractices {
      ...legalPractice
    }
    spokenLanguages {
      name
      code
    }
    workingHours {
      to
      from
      name
    }
  }
  ${CountryFragmentDoc}
  ${LegalPracticeFragmentDoc}
`;
export const MeetingFragmentDoc = gql`
  fragment meeting on AppointmentMeetingType {
    duration
    roomId
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    email
    permissions
    roles
  }
`;
export const AppointmentFragmentDoc = gql`
  fragment appointment on AppointmentType {
    times {
      startsAt
      endsAt
    }
    purchaseInfo {
      description
      invoiceUrl
      paid
      details {
        total
      }
    }
    title
  }
`;
export const ValidationMessageFragmentDoc = gql`
  fragment validationMessage on ValidationMessage {
    code
    message
    field
    template
    options {
      key
      value
    }
  }
`;
export const PaymentMethodFragmentDoc = gql`
  fragment paymentMethod on PaymentMethodType {
    id
    cardBrand
    cardLast4
    isDefault
    expMonth
    expYear
  }
`;
export const LanguageFragmentDoc = gql`
  fragment language on LanguageType {
    code
    name
    slug
  }
`;
export const LawyerApplicationFragmentDoc = gql`
  fragment lawyerApplication on LawyerApplicationType {
    id
    email
    firstName
    lastName
    location
    status
    insertedAt
    title
    updatedAt
    phone
    bio
    hourlyRate
    legalPractices
    spokenLanguages
    workingHours
    feedback {
      feedback
      completedAt
    }
  }
`;
export const LawyerWorkExperienceFragmentDoc = gql`
  fragment lawyerWorkExperience on LawyerWorkExperienceType {
    companyName
    endYear
    startYear
    title
  }
`;
export const CreateAppointmentDocument = gql`
  mutation CreateAppointment(
    $input: AppointmentInput
    $paymentMethod: String!
  ) {
    createAppointment(input: $input, paymentMethod: $paymentMethod) {
      successful
      messages {
        ...validationMessage
      }
      result {
        ...appointment
        meeting {
          ...meeting
        }
      }
    }
  }
  ${ValidationMessageFragmentDoc}
  ${AppointmentFragmentDoc}
  ${MeetingFragmentDoc}
`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useCreateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >(CreateAppointmentDocument, baseOptions);
}
export type CreateAppointmentMutationHookResult = ReturnType<
  typeof useCreateAppointmentMutation
>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;
export const GetMyAppointmentsDocument = gql`
  query GetMyAppointments {
    getMyAppointments {
      ...appointment
      host {
        ...lawyer
      }
      meeting {
        ...meeting
      }
      user {
        email
        profile {
          ...profile
        }
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${LawyerFragmentDoc}
  ${MeetingFragmentDoc}
  ${ProfileFragmentDoc}
`;

/**
 * __useGetMyAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetMyAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyAppointmentsQuery,
    GetMyAppointmentsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMyAppointmentsQuery,
    GetMyAppointmentsQueryVariables
  >(GetMyAppointmentsDocument, baseOptions);
}
export function useGetMyAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyAppointmentsQuery,
    GetMyAppointmentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMyAppointmentsQuery,
    GetMyAppointmentsQueryVariables
  >(GetMyAppointmentsDocument, baseOptions);
}
export type GetMyAppointmentsQueryHookResult = ReturnType<
  typeof useGetMyAppointmentsQuery
>;
export type GetMyAppointmentsLazyQueryHookResult = ReturnType<
  typeof useGetMyAppointmentsLazyQuery
>;
export type GetMyAppointmentsQueryResult = Apollo.QueryResult<
  GetMyAppointmentsQuery,
  GetMyAppointmentsQueryVariables
>;
export const GetAppointmentByRoomDocument = gql`
  query GetAppointmentByRoom($roomId: String!) {
    getAppointmentByRoom(roomId: $roomId) {
      ...appointment
      user {
        email
        profile {
          ...profile
        }
      }
      host {
        ...lawyer
      }
      meeting {
        ...meeting
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${ProfileFragmentDoc}
  ${LawyerFragmentDoc}
  ${MeetingFragmentDoc}
`;

/**
 * __useGetAppointmentByRoomQuery__
 *
 * To run a query within a React component, call `useGetAppointmentByRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentByRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentByRoomQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetAppointmentByRoomQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentByRoomQuery,
    GetAppointmentByRoomQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAppointmentByRoomQuery,
    GetAppointmentByRoomQueryVariables
  >(GetAppointmentByRoomDocument, baseOptions);
}
export function useGetAppointmentByRoomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentByRoomQuery,
    GetAppointmentByRoomQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAppointmentByRoomQuery,
    GetAppointmentByRoomQueryVariables
  >(GetAppointmentByRoomDocument, baseOptions);
}
export type GetAppointmentByRoomQueryHookResult = ReturnType<
  typeof useGetAppointmentByRoomQuery
>;
export type GetAppointmentByRoomLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentByRoomLazyQuery
>;
export type GetAppointmentByRoomQueryResult = Apollo.QueryResult<
  GetAppointmentByRoomQuery,
  GetAppointmentByRoomQueryVariables
>;
export const CountriesDocument = gql`
  query countries {
    countries {
      id
      name
      slug
      flag
      code
    }
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>
) {
  return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    baseOptions
  );
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    baseOptions
  );
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<
  typeof useCountriesLazyQuery
>;
export type CountriesQueryResult = Apollo.QueryResult<
  CountriesQuery,
  CountriesQueryVariables
>;
export const ActiveLanguagesDocument = gql`
  query activeLanguages {
    getActiveLanguages {
      ...language
    }
  }
  ${LanguageFragmentDoc}
`;

/**
 * __useActiveLanguagesQuery__
 *
 * To run a query within a React component, call `useActiveLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActiveLanguagesQuery,
    ActiveLanguagesQueryVariables
  >
) {
  return Apollo.useQuery<ActiveLanguagesQuery, ActiveLanguagesQueryVariables>(
    ActiveLanguagesDocument,
    baseOptions
  );
}
export function useActiveLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveLanguagesQuery,
    ActiveLanguagesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ActiveLanguagesQuery,
    ActiveLanguagesQueryVariables
  >(ActiveLanguagesDocument, baseOptions);
}
export type ActiveLanguagesQueryHookResult = ReturnType<
  typeof useActiveLanguagesQuery
>;
export type ActiveLanguagesLazyQueryHookResult = ReturnType<
  typeof useActiveLanguagesLazyQuery
>;
export type ActiveLanguagesQueryResult = Apollo.QueryResult<
  ActiveLanguagesQuery,
  ActiveLanguagesQueryVariables
>;
export const GetCountryDocument = gql`
  query getCountry($countryCode: String!) {
    getCountryByCode(countryCode: $countryCode) {
      ...country
    }
  }
  ${CountryFragmentDoc}
`;

/**
 * __useGetCountryQuery__
 *
 * To run a query within a React component, call `useGetCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetCountryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCountryQuery,
    GetCountryQueryVariables
  >
) {
  return Apollo.useQuery<GetCountryQuery, GetCountryQueryVariables>(
    GetCountryDocument,
    baseOptions
  );
}
export function useGetCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryQuery,
    GetCountryQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetCountryQuery, GetCountryQueryVariables>(
    GetCountryDocument,
    baseOptions
  );
}
export type GetCountryQueryHookResult = ReturnType<typeof useGetCountryQuery>;
export type GetCountryLazyQueryHookResult = ReturnType<
  typeof useGetCountryLazyQuery
>;
export type GetCountryQueryResult = Apollo.QueryResult<
  GetCountryQuery,
  GetCountryQueryVariables
>;
export const GetAllActiveLegalPracticesDocument = gql`
  query getAllActiveLegalPractices {
    getAllActiveLegalPractices {
      description
      name
      slug
    }
  }
`;

/**
 * __useGetAllActiveLegalPracticesQuery__
 *
 * To run a query within a React component, call `useGetAllActiveLegalPracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActiveLegalPracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActiveLegalPracticesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllActiveLegalPracticesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllActiveLegalPracticesQuery,
    GetAllActiveLegalPracticesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAllActiveLegalPracticesQuery,
    GetAllActiveLegalPracticesQueryVariables
  >(GetAllActiveLegalPracticesDocument, baseOptions);
}
export function useGetAllActiveLegalPracticesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllActiveLegalPracticesQuery,
    GetAllActiveLegalPracticesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllActiveLegalPracticesQuery,
    GetAllActiveLegalPracticesQueryVariables
  >(GetAllActiveLegalPracticesDocument, baseOptions);
}
export type GetAllActiveLegalPracticesQueryHookResult = ReturnType<
  typeof useGetAllActiveLegalPracticesQuery
>;
export type GetAllActiveLegalPracticesLazyQueryHookResult = ReturnType<
  typeof useGetAllActiveLegalPracticesLazyQuery
>;
export type GetAllActiveLegalPracticesQueryResult = Apollo.QueryResult<
  GetAllActiveLegalPracticesQuery,
  GetAllActiveLegalPracticesQueryVariables
>;
export const GetAllLanguagesDocument = gql`
  query GetAllLanguages {
    getAllLanguages {
      ...language
    }
  }
  ${LanguageFragmentDoc}
`;

/**
 * __useGetAllLanguagesQuery__
 *
 * To run a query within a React component, call `useGetAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllLanguagesQuery,
    GetAllLanguagesQueryVariables
  >
) {
  return Apollo.useQuery<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>(
    GetAllLanguagesDocument,
    baseOptions
  );
}
export function useGetAllLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllLanguagesQuery,
    GetAllLanguagesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllLanguagesQuery,
    GetAllLanguagesQueryVariables
  >(GetAllLanguagesDocument, baseOptions);
}
export type GetAllLanguagesQueryHookResult = ReturnType<
  typeof useGetAllLanguagesQuery
>;
export type GetAllLanguagesLazyQueryHookResult = ReturnType<
  typeof useGetAllLanguagesLazyQuery
>;
export type GetAllLanguagesQueryResult = Apollo.QueryResult<
  GetAllLanguagesQuery,
  GetAllLanguagesQueryVariables
>;
export const GetAllMeetsCountriesDocument = gql`
  query GetAllMeetsCountries {
    getAllMeetsCountries {
      ...country
    }
  }
  ${CountryFragmentDoc}
`;

/**
 * __useGetAllMeetsCountriesQuery__
 *
 * To run a query within a React component, call `useGetAllMeetsCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMeetsCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMeetsCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMeetsCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllMeetsCountriesQuery,
    GetAllMeetsCountriesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAllMeetsCountriesQuery,
    GetAllMeetsCountriesQueryVariables
  >(GetAllMeetsCountriesDocument, baseOptions);
}
export function useGetAllMeetsCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllMeetsCountriesQuery,
    GetAllMeetsCountriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllMeetsCountriesQuery,
    GetAllMeetsCountriesQueryVariables
  >(GetAllMeetsCountriesDocument, baseOptions);
}
export type GetAllMeetsCountriesQueryHookResult = ReturnType<
  typeof useGetAllMeetsCountriesQuery
>;
export type GetAllMeetsCountriesLazyQueryHookResult = ReturnType<
  typeof useGetAllMeetsCountriesLazyQuery
>;
export type GetAllMeetsCountriesQueryResult = Apollo.QueryResult<
  GetAllMeetsCountriesQuery,
  GetAllMeetsCountriesQueryVariables
>;
export const LawyersDirectoryDocument = gql`
  query lawyersDirectory($filters: LawyerDirectoryFilters) {
    getLawyersDirectory(filters: $filters) {
      ...lawyer
      legalPractices {
        ...legalPractice
      }
      spokenLanguages {
        name
        code
      }
      workingHours {
        to
        from
        name
      }
      user {
        profile {
          ...profile
        }
      }
    }
  }
  ${LawyerFragmentDoc}
  ${LegalPracticeFragmentDoc}
  ${ProfileFragmentDoc}
`;

/**
 * __useLawyersDirectoryQuery__
 *
 * To run a query within a React component, call `useLawyersDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawyersDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawyersDirectoryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useLawyersDirectoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LawyersDirectoryQuery,
    LawyersDirectoryQueryVariables
  >
) {
  return Apollo.useQuery<LawyersDirectoryQuery, LawyersDirectoryQueryVariables>(
    LawyersDirectoryDocument,
    baseOptions
  );
}
export function useLawyersDirectoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LawyersDirectoryQuery,
    LawyersDirectoryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    LawyersDirectoryQuery,
    LawyersDirectoryQueryVariables
  >(LawyersDirectoryDocument, baseOptions);
}
export type LawyersDirectoryQueryHookResult = ReturnType<
  typeof useLawyersDirectoryQuery
>;
export type LawyersDirectoryLazyQueryHookResult = ReturnType<
  typeof useLawyersDirectoryLazyQuery
>;
export type LawyersDirectoryQueryResult = Apollo.QueryResult<
  LawyersDirectoryQuery,
  LawyersDirectoryQueryVariables
>;
export const GetLawyerDocument = gql`
  query getLawyer($lawyerId: Int!) {
    getLawyer(lawyerId: $lawyerId) {
      ...lawyer
      legalPractices {
        ...legalPractice
      }
      spokenLanguages {
        ...language
      }
      workExperience {
        ...lawyerWorkExperience
      }
      educationalBackground {
        graduationYear
        institution
        title
      }
      workingHours {
        ...lawyerTimeSlot
      }
      user {
        email
      }
    }
  }
  ${LawyerFragmentDoc}
  ${LegalPracticeFragmentDoc}
  ${LanguageFragmentDoc}
  ${LawyerWorkExperienceFragmentDoc}
  ${LawyerTimeSlotFragmentDoc}
`;

/**
 * __useGetLawyerQuery__
 *
 * To run a query within a React component, call `useGetLawyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLawyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLawyerQuery({
 *   variables: {
 *      lawyerId: // value for 'lawyerId'
 *   },
 * });
 */
export function useGetLawyerQuery(
  baseOptions: Apollo.QueryHookOptions<GetLawyerQuery, GetLawyerQueryVariables>
) {
  return Apollo.useQuery<GetLawyerQuery, GetLawyerQueryVariables>(
    GetLawyerDocument,
    baseOptions
  );
}
export function useGetLawyerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLawyerQuery,
    GetLawyerQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetLawyerQuery, GetLawyerQueryVariables>(
    GetLawyerDocument,
    baseOptions
  );
}
export type GetLawyerQueryHookResult = ReturnType<typeof useGetLawyerQuery>;
export type GetLawyerLazyQueryHookResult = ReturnType<
  typeof useGetLawyerLazyQuery
>;
export type GetLawyerQueryResult = Apollo.QueryResult<
  GetLawyerQuery,
  GetLawyerQueryVariables
>;
export const CheckLawyerAvailabilityDocument = gql`
  query CheckLawyerAvailability($input: AvailabilityInput!) {
    checkAvailability(input: $input) {
      date
      slots {
        from
        to
      }
    }
  }
`;

/**
 * __useCheckLawyerAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckLawyerAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLawyerAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLawyerAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckLawyerAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckLawyerAvailabilityQuery,
    CheckLawyerAvailabilityQueryVariables
  >
) {
  return Apollo.useQuery<
    CheckLawyerAvailabilityQuery,
    CheckLawyerAvailabilityQueryVariables
  >(CheckLawyerAvailabilityDocument, baseOptions);
}
export function useCheckLawyerAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckLawyerAvailabilityQuery,
    CheckLawyerAvailabilityQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CheckLawyerAvailabilityQuery,
    CheckLawyerAvailabilityQueryVariables
  >(CheckLawyerAvailabilityDocument, baseOptions);
}
export type CheckLawyerAvailabilityQueryHookResult = ReturnType<
  typeof useCheckLawyerAvailabilityQuery
>;
export type CheckLawyerAvailabilityLazyQueryHookResult = ReturnType<
  typeof useCheckLawyerAvailabilityLazyQuery
>;
export type CheckLawyerAvailabilityQueryResult = Apollo.QueryResult<
  CheckLawyerAvailabilityQuery,
  CheckLawyerAvailabilityQueryVariables
>;
export const CreateLawyerApplicationDocument = gql`
  mutation CreateLawyerApplication(
    $input: LawyerApplicationInput
    $paymentMethod: String!
  ) {
    createLawyerApplication(input: $input, paymentMethod: $paymentMethod) {
      successful
      result {
        confirmationRequired
      }
      messages {
        message
        field
        code
        template
      }
    }
  }
`;
export type CreateLawyerApplicationMutationFn = Apollo.MutationFunction<
  CreateLawyerApplicationMutation,
  CreateLawyerApplicationMutationVariables
>;

/**
 * __useCreateLawyerApplicationMutation__
 *
 * To run a mutation, you first call `useCreateLawyerApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLawyerApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLawyerApplicationMutation, { data, loading, error }] = useCreateLawyerApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useCreateLawyerApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLawyerApplicationMutation,
    CreateLawyerApplicationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateLawyerApplicationMutation,
    CreateLawyerApplicationMutationVariables
  >(CreateLawyerApplicationDocument, baseOptions);
}
export type CreateLawyerApplicationMutationHookResult = ReturnType<
  typeof useCreateLawyerApplicationMutation
>;
export type CreateLawyerApplicationMutationResult = Apollo.MutationResult<CreateLawyerApplicationMutation>;
export type CreateLawyerApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateLawyerApplicationMutation,
  CreateLawyerApplicationMutationVariables
>;
export const SubmitMyLawyerApplicationForReviewDocument = gql`
  mutation submitMyLawyerApplicationForReview {
    submitMyLawyerApplicationForReview {
      successful
      result {
        ...lawyerApplication
      }
      messages {
        message
        field
        code
        template
      }
    }
  }
  ${LawyerApplicationFragmentDoc}
`;
export type SubmitMyLawyerApplicationForReviewMutationFn = Apollo.MutationFunction<
  SubmitMyLawyerApplicationForReviewMutation,
  SubmitMyLawyerApplicationForReviewMutationVariables
>;

/**
 * __useSubmitMyLawyerApplicationForReviewMutation__
 *
 * To run a mutation, you first call `useSubmitMyLawyerApplicationForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMyLawyerApplicationForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMyLawyerApplicationForReviewMutation, { data, loading, error }] = useSubmitMyLawyerApplicationForReviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitMyLawyerApplicationForReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitMyLawyerApplicationForReviewMutation,
    SubmitMyLawyerApplicationForReviewMutationVariables
  >
) {
  return Apollo.useMutation<
    SubmitMyLawyerApplicationForReviewMutation,
    SubmitMyLawyerApplicationForReviewMutationVariables
  >(SubmitMyLawyerApplicationForReviewDocument, baseOptions);
}
export type SubmitMyLawyerApplicationForReviewMutationHookResult = ReturnType<
  typeof useSubmitMyLawyerApplicationForReviewMutation
>;
export type SubmitMyLawyerApplicationForReviewMutationResult = Apollo.MutationResult<SubmitMyLawyerApplicationForReviewMutation>;
export type SubmitMyLawyerApplicationForReviewMutationOptions = Apollo.BaseMutationOptions<
  SubmitMyLawyerApplicationForReviewMutation,
  SubmitMyLawyerApplicationForReviewMutationVariables
>;
export const UpdateMyLawyerApplicationDocument = gql`
  mutation UpdateMyLawyerApplication($input: LawyerApplicationUpdateInput) {
    updateMyLawyerApplication(input: $input) {
      successful
      result {
        ...lawyerApplication
      }
      messages {
        message
        field
        code
        template
      }
    }
  }
  ${LawyerApplicationFragmentDoc}
`;
export type UpdateMyLawyerApplicationMutationFn = Apollo.MutationFunction<
  UpdateMyLawyerApplicationMutation,
  UpdateMyLawyerApplicationMutationVariables
>;

/**
 * __useUpdateMyLawyerApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateMyLawyerApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyLawyerApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyLawyerApplicationMutation, { data, loading, error }] = useUpdateMyLawyerApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyLawyerApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyLawyerApplicationMutation,
    UpdateMyLawyerApplicationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateMyLawyerApplicationMutation,
    UpdateMyLawyerApplicationMutationVariables
  >(UpdateMyLawyerApplicationDocument, baseOptions);
}
export type UpdateMyLawyerApplicationMutationHookResult = ReturnType<
  typeof useUpdateMyLawyerApplicationMutation
>;
export type UpdateMyLawyerApplicationMutationResult = Apollo.MutationResult<UpdateMyLawyerApplicationMutation>;
export type UpdateMyLawyerApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyLawyerApplicationMutation,
  UpdateMyLawyerApplicationMutationVariables
>;
export const GetMyLawyerApplicationDocument = gql`
  query getMyLawyerApplication {
    getMyLawyerApplication {
      ...lawyerApplication
    }
  }
  ${LawyerApplicationFragmentDoc}
`;

/**
 * __useGetMyLawyerApplicationQuery__
 *
 * To run a query within a React component, call `useGetMyLawyerApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLawyerApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLawyerApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyLawyerApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyLawyerApplicationQuery,
    GetMyLawyerApplicationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMyLawyerApplicationQuery,
    GetMyLawyerApplicationQueryVariables
  >(GetMyLawyerApplicationDocument, baseOptions);
}
export function useGetMyLawyerApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLawyerApplicationQuery,
    GetMyLawyerApplicationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMyLawyerApplicationQuery,
    GetMyLawyerApplicationQueryVariables
  >(GetMyLawyerApplicationDocument, baseOptions);
}
export type GetMyLawyerApplicationQueryHookResult = ReturnType<
  typeof useGetMyLawyerApplicationQuery
>;
export type GetMyLawyerApplicationLazyQueryHookResult = ReturnType<
  typeof useGetMyLawyerApplicationLazyQuery
>;
export type GetMyLawyerApplicationQueryResult = Apollo.QueryResult<
  GetMyLawyerApplicationQuery,
  GetMyLawyerApplicationQueryVariables
>;
export const CreatePaymentSourceDocument = gql`
  mutation CreatePaymentSource(
    $paymentMethodId: String!
    $isDefault: Boolean = false
  ) {
    createPaymentSource(
      paymentMethodId: $paymentMethodId
      isDefault: $isDefault
    ) {
      successful
      messages {
        ...validationMessage
      }
      result {
        ...paymentMethod
      }
    }
  }
  ${ValidationMessageFragmentDoc}
  ${PaymentMethodFragmentDoc}
`;
export type CreatePaymentSourceMutationFn = Apollo.MutationFunction<
  CreatePaymentSourceMutation,
  CreatePaymentSourceMutationVariables
>;

/**
 * __useCreatePaymentSourceMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSourceMutation, { data, loading, error }] = useCreatePaymentSourceMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useCreatePaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentSourceMutation,
    CreatePaymentSourceMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePaymentSourceMutation,
    CreatePaymentSourceMutationVariables
  >(CreatePaymentSourceDocument, baseOptions);
}
export type CreatePaymentSourceMutationHookResult = ReturnType<
  typeof useCreatePaymentSourceMutation
>;
export type CreatePaymentSourceMutationResult = Apollo.MutationResult<CreatePaymentSourceMutation>;
export type CreatePaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentSourceMutation,
  CreatePaymentSourceMutationVariables
>;
export const GetMyPaymentSourcesDocument = gql`
  query GetMyPaymentSources {
    getMyPaymentSources {
      ...paymentMethod
    }
  }
  ${PaymentMethodFragmentDoc}
`;

/**
 * __useGetMyPaymentSourcesQuery__
 *
 * To run a query within a React component, call `useGetMyPaymentSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPaymentSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPaymentSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPaymentSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPaymentSourcesQuery,
    GetMyPaymentSourcesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMyPaymentSourcesQuery,
    GetMyPaymentSourcesQueryVariables
  >(GetMyPaymentSourcesDocument, baseOptions);
}
export function useGetMyPaymentSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPaymentSourcesQuery,
    GetMyPaymentSourcesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMyPaymentSourcesQuery,
    GetMyPaymentSourcesQueryVariables
  >(GetMyPaymentSourcesDocument, baseOptions);
}
export type GetMyPaymentSourcesQueryHookResult = ReturnType<
  typeof useGetMyPaymentSourcesQuery
>;
export type GetMyPaymentSourcesLazyQueryHookResult = ReturnType<
  typeof useGetMyPaymentSourcesLazyQuery
>;
export type GetMyPaymentSourcesQueryResult = Apollo.QueryResult<
  GetMyPaymentSourcesQuery,
  GetMyPaymentSourcesQueryVariables
>;
export const DeletePaymentSourceDocument = gql`
  mutation DeletePaymentSource($paymentMethodId: String!) {
    deletePaymentSource(paymentMethodId: $paymentMethodId) {
      messages {
        ...validationMessage
      }
      result
      successful
    }
  }
  ${ValidationMessageFragmentDoc}
`;
export type DeletePaymentSourceMutationFn = Apollo.MutationFunction<
  DeletePaymentSourceMutation,
  DeletePaymentSourceMutationVariables
>;

/**
 * __useDeletePaymentSourceMutation__
 *
 * To run a mutation, you first call `useDeletePaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentSourceMutation, { data, loading, error }] = useDeletePaymentSourceMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useDeletePaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentSourceMutation,
    DeletePaymentSourceMutationVariables
  >
) {
  return Apollo.useMutation<
    DeletePaymentSourceMutation,
    DeletePaymentSourceMutationVariables
  >(DeletePaymentSourceDocument, baseOptions);
}
export type DeletePaymentSourceMutationHookResult = ReturnType<
  typeof useDeletePaymentSourceMutation
>;
export type DeletePaymentSourceMutationResult = Apollo.MutationResult<DeletePaymentSourceMutation>;
export type DeletePaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentSourceMutation,
  DeletePaymentSourceMutationVariables
>;
export const SetDefaultPaymentSourceDocument = gql`
  mutation SetDefaultPaymentSource($paymentMethodId: String!) {
    setDefaultPaymentSource(paymentMethodId: $paymentMethodId) {
      messages {
        ...validationMessage
      }
      result {
        ...paymentMethod
      }
      successful
    }
  }
  ${ValidationMessageFragmentDoc}
  ${PaymentMethodFragmentDoc}
`;
export type SetDefaultPaymentSourceMutationFn = Apollo.MutationFunction<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;

/**
 * __useSetDefaultPaymentSourceMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentSourceMutation, { data, loading, error }] = useSetDefaultPaymentSourceMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useSetDefaultPaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDefaultPaymentSourceMutation,
    SetDefaultPaymentSourceMutationVariables
  >
) {
  return Apollo.useMutation<
    SetDefaultPaymentSourceMutation,
    SetDefaultPaymentSourceMutationVariables
  >(SetDefaultPaymentSourceDocument, baseOptions);
}
export type SetDefaultPaymentSourceMutationHookResult = ReturnType<
  typeof useSetDefaultPaymentSourceMutation
>;
export type SetDefaultPaymentSourceMutationResult = Apollo.MutationResult<SetDefaultPaymentSourceMutation>;
export type SetDefaultPaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;
export const MyPurchasesDocument = gql`
  query myPurchases {
    getMyPurchases {
      id
      date: insertedAt
      bankStatement
      description
      details {
        paymentSource {
          cardLast4
        }
        total
      }
    }
  }
`;

/**
 * __useMyPurchasesQuery__
 *
 * To run a query within a React component, call `useMyPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPurchasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPurchasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyPurchasesQuery,
    MyPurchasesQueryVariables
  >
) {
  return Apollo.useQuery<MyPurchasesQuery, MyPurchasesQueryVariables>(
    MyPurchasesDocument,
    baseOptions
  );
}
export function useMyPurchasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyPurchasesQuery,
    MyPurchasesQueryVariables
  >
) {
  return Apollo.useLazyQuery<MyPurchasesQuery, MyPurchasesQueryVariables>(
    MyPurchasesDocument,
    baseOptions
  );
}
export type MyPurchasesQueryHookResult = ReturnType<typeof useMyPurchasesQuery>;
export type MyPurchasesLazyQueryHookResult = ReturnType<
  typeof useMyPurchasesLazyQuery
>;
export type MyPurchasesQueryResult = Apollo.QueryResult<
  MyPurchasesQuery,
  MyPurchasesQueryVariables
>;
export const SigninDocument = gql`
  mutation signin($accessToken: String!) {
    authenticate(accessToken: $accessToken) {
      successful
      messages {
        ...validationMessage
      }
    }
  }
  ${ValidationMessageFragmentDoc}
`;
export type SigninMutationFn = Apollo.MutationFunction<
  SigninMutation,
  SigninMutationVariables
>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSigninMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninMutation,
    SigninMutationVariables
  >
) {
  return Apollo.useMutation<SigninMutation, SigninMutationVariables>(
    SigninDocument,
    baseOptions
  );
}
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<
  SigninMutation,
  SigninMutationVariables
>;
export const ViewerDocument = gql`
  query viewer {
    viewer {
      ...user
      profile {
        ...profile
      }
    }
  }
  ${UserFragmentDoc}
  ${ProfileFragmentDoc}
`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    baseOptions
  );
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    baseOptions
  );
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>;
export const SignoutDocument = gql`
  mutation signout {
    signOut
  }
`;
export type SignoutMutationFn = Apollo.MutationFunction<
  SignoutMutation,
  SignoutMutationVariables
>;

/**
 * __useSignoutMutation__
 *
 * To run a mutation, you first call `useSignoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signoutMutation, { data, loading, error }] = useSignoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignoutMutation,
    SignoutMutationVariables
  >
) {
  return Apollo.useMutation<SignoutMutation, SignoutMutationVariables>(
    SignoutDocument,
    baseOptions
  );
}
export type SignoutMutationHookResult = ReturnType<typeof useSignoutMutation>;
export type SignoutMutationResult = Apollo.MutationResult<SignoutMutation>;
export type SignoutMutationOptions = Apollo.BaseMutationOptions<
  SignoutMutation,
  SignoutMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateProfileInput) {
    updateProfile(input: $input) {
      messages {
        ...validationMessage
      }
      result {
        profile {
          ...profile
        }
      }
      successful
    }
  }
  ${ValidationMessageFragmentDoc}
  ${ProfileFragmentDoc}
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, baseOptions);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const UpdateLawyerProfileDocument = gql`
  mutation UpdateLawyerProfile($input: UpdateLawyerInput) {
    updateLawyer(input: $input) {
      messages {
        ...validationMessage
      }
      successful
      result {
        ...lawyer
      }
    }
  }
  ${ValidationMessageFragmentDoc}
  ${LawyerFragmentDoc}
`;
export type UpdateLawyerProfileMutationFn = Apollo.MutationFunction<
  UpdateLawyerProfileMutation,
  UpdateLawyerProfileMutationVariables
>;

/**
 * __useUpdateLawyerProfileMutation__
 *
 * To run a mutation, you first call `useUpdateLawyerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLawyerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLawyerProfileMutation, { data, loading, error }] = useUpdateLawyerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLawyerProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLawyerProfileMutation,
    UpdateLawyerProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateLawyerProfileMutation,
    UpdateLawyerProfileMutationVariables
  >(UpdateLawyerProfileDocument, baseOptions);
}
export type UpdateLawyerProfileMutationHookResult = ReturnType<
  typeof useUpdateLawyerProfileMutation
>;
export type UpdateLawyerProfileMutationResult = Apollo.MutationResult<UpdateLawyerProfileMutation>;
export type UpdateLawyerProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateLawyerProfileMutation,
  UpdateLawyerProfileMutationVariables
>;
export const GetMyLawyerProfileDocument = gql`
  query GetMyLawyerProfile {
    getMyLawyer {
      ...lawyer
      legalPractices {
        ...legalPractice
      }
      spokenLanguages {
        ...language
      }
      workExperience {
        ...lawyerWorkExperience
      }
      educationalBackground {
        graduationYear
        institution
        title
      }
      workingHours {
        ...lawyerTimeSlot
      }
    }
  }
  ${LawyerFragmentDoc}
  ${LegalPracticeFragmentDoc}
  ${LanguageFragmentDoc}
  ${LawyerWorkExperienceFragmentDoc}
  ${LawyerTimeSlotFragmentDoc}
`;

/**
 * __useGetMyLawyerProfileQuery__
 *
 * To run a query within a React component, call `useGetMyLawyerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLawyerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLawyerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyLawyerProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyLawyerProfileQuery,
    GetMyLawyerProfileQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMyLawyerProfileQuery,
    GetMyLawyerProfileQueryVariables
  >(GetMyLawyerProfileDocument, baseOptions);
}
export function useGetMyLawyerProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLawyerProfileQuery,
    GetMyLawyerProfileQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMyLawyerProfileQuery,
    GetMyLawyerProfileQueryVariables
  >(GetMyLawyerProfileDocument, baseOptions);
}
export type GetMyLawyerProfileQueryHookResult = ReturnType<
  typeof useGetMyLawyerProfileQuery
>;
export type GetMyLawyerProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyLawyerProfileLazyQuery
>;
export type GetMyLawyerProfileQueryResult = Apollo.QueryResult<
  GetMyLawyerProfileQuery,
  GetMyLawyerProfileQueryVariables
>;
export const GenerateAvatarPresignedUrlDocument = gql`
  mutation GenerateAvatarPresignedUrl($name: String!) {
    generateAvatarPresignedUrl(name: $name)
  }
`;
export type GenerateAvatarPresignedUrlMutationFn = Apollo.MutationFunction<
  GenerateAvatarPresignedUrlMutation,
  GenerateAvatarPresignedUrlMutationVariables
>;

/**
 * __useGenerateAvatarPresignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateAvatarPresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAvatarPresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAvatarPresignedUrlMutation, { data, loading, error }] = useGenerateAvatarPresignedUrlMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGenerateAvatarPresignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAvatarPresignedUrlMutation,
    GenerateAvatarPresignedUrlMutationVariables
  >
) {
  return Apollo.useMutation<
    GenerateAvatarPresignedUrlMutation,
    GenerateAvatarPresignedUrlMutationVariables
  >(GenerateAvatarPresignedUrlDocument, baseOptions);
}
export type GenerateAvatarPresignedUrlMutationHookResult = ReturnType<
  typeof useGenerateAvatarPresignedUrlMutation
>;
export type GenerateAvatarPresignedUrlMutationResult = Apollo.MutationResult<GenerateAvatarPresignedUrlMutation>;
export type GenerateAvatarPresignedUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateAvatarPresignedUrlMutation,
  GenerateAvatarPresignedUrlMutationVariables
>;
