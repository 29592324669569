import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  chakra,
  Checkbox,
  IconButton,
  Icon,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  StackDivider,
} from '@chakra-ui/react';
import { useFieldArray, UseFieldArrayConfig } from 'react-final-form-arrays';
import { ReactComponent as PlusIcon } from '@src/icons/plus.svg';
import { ReactComponent as CopyIcon } from '@src/icons/copy.svg';
import { WorkingHourSlot } from './WorkingHourSlot';
import { numberOfSlotHours } from '@src/utils';
import { useTranslation } from 'react-i18next';

export type WorkingHourDayProps<FieldValue> = {
  name: string;
  onAddSlot(): void;
  onCopySlot(to: string): void;
  onClear(): void;
  config?: UseFieldArrayConfig<FieldValue>;
};
const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export default function WorkingHourDay({
  name,
  onAddSlot,
  onCopySlot,
  onClear,
  config,
}: WorkingHourDayProps<Slot>) {
  const { t } = useTranslation();
  const { fields, meta } = useFieldArray<Slot>(name, config);
  const { error } = meta;
  const { value } = fields;
  const valueLength = value.length;
  const [isChecked, setIsChecked] = useState(!!valueLength);
  const hours = useMemo(() => numberOfSlotHours(value ?? []), [value]);
  useEffect(() => {
    const newValue = !!valueLength;
    if (isChecked !== newValue) setIsChecked(newValue);
    // eslint-disable-next-line
  }, [valueLength]);

  const onCheckboxChange = useCallback(
    (e) => {
      setIsChecked(e.target.checked);
      onClear();
    },
    [onClear]
  );

  return (
    <chakra.div
      px={{ base: 4, sm: 6, lg: 10 }}
      py={{ base: 4, sm: 6, lg: 8 }}
      d="flex"
      flexDir="column"
      justifyContent="center"
    >
      <chakra.div d="flex" alignItems="center">
        <chakra.div
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          minW="42%"
        >
          <Checkbox
            borderColor="gray.600"
            colorScheme="blue"
            isChecked={isChecked}
            onChange={onCheckboxChange}
          >
            <chakra.span
              fontWeight="semibold"
              fontSize="sm"
              textTransform="capitalize"
            >
              {t(`dates.day_names.${name}`)}
            </chakra.span>
            {!isChecked ? (
              <chakra.span
                ml={2}
                fontWeight="semibold"
                fontSize="sm"
                color="gray.600"
              >
                {t('common.unavailable')}
              </chakra.span>
            ) : (
              <chakra.span
                ml={2}
                color="gray.600"
                fontWeight="semibold"
                fontSize="sm"
              >
                {hours}h
              </chakra.span>
            )}
          </Checkbox>
          {isChecked && (
            <chakra.div d="flex" alignItems="center">
              <Popover size="3xs">
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <chakra.button
                        type="button"
                        ml={5}
                        aria-label="Copy slot"
                        outline="none"
                      >
                        <Icon as={CopyIcon} h={5} w={5} />
                      </chakra.button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader fontSize="sm" py={3} fontWeight="semibold">
                        {t('availability.copy_hours_to')}
                      </PopoverHeader>
                      <PopoverBody p={0} maxH="200px" overflowY="auto">
                        <VStack
                          overflow="hidden"
                          pb={2}
                          align="initial"
                          spacing={0}
                          divider={<StackDivider />}
                        >
                          {days
                            .filter((i) => i !== name)
                            .map((day) => (
                              <chakra.button
                                onClick={() => {
                                  onCopySlot(day);
                                  onClose();
                                }}
                                type="button"
                                key={day}
                                d="block"
                                p={3}
                                textAlign="left"
                                textTransform="capitalize"
                                fontSize="sm"
                                fontWeight={300}
                                outline="none"
                                _hover={{ bg: 'gray.50' }}
                                _focus={{
                                  bg: 'gray.100',
                                }}
                                _active={{ bg: 'gray.100' }}
                              >
                                {t(`dates.day_names.${day}`)}
                              </chakra.button>
                            ))}
                        </VStack>
                      </PopoverBody>
                    </PopoverContent>
                  </>
                )}
              </Popover>
              <IconButton
                ml={2}
                size="xs"
                isRound
                colorScheme="primary"
                aria-label="Add slot"
                onClick={onAddSlot}
              >
                <Icon as={PlusIcon} h={3} w={3} />
              </IconButton>
            </chakra.div>
          )}
        </chakra.div>
      </chakra.div>
      {valueLength > 0 && (
        <VStack
          align="initial"
          spacing={{ base: 2, sm: 3 }}
          pt={4}
          px={{ sm: 8 }}
        >
          {fields.map((name, index) => {
            const errorMessage = error?.[index]?.from ?? error?.[index]?.to;
            return (
              <chakra.div key={index}>
                <WorkingHourSlot
                  name={name}
                  onRemove={() => fields.remove(index)}
                />
                {errorMessage && (
                  <chakra.div
                    d="flex"
                    alignItems="center"
                    color="red.500"
                    fontSize="sm"
                    mt={2}
                  >
                    {errorMessage}
                  </chakra.div>
                )}
              </chakra.div>
            );
          })}
        </VStack>
      )}
    </chakra.div>
  );
}
