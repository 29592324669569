import { mode, transparentize } from '@chakra-ui/theme-tools';

const baseStyle = {
  lineHeight: '1.2',
  borderRadius: 'md',
  fontWeight: 'medium',
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
};

function variantGhost(props: Dict) {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: { bg: mode(`gray.100`, `whiteAlpha.200`)(props) },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    };
  }

  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);

  return {
    color: c === 'white' ? c : mode(`${c}.600`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: 'none',
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  };
}

function variantOutline(props: Dict) {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props);
  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(props),
  };
}

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
  focusBoxShadow?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  white: {
    bg: 'white',
    color: 'dove-gray.500',
    hoverBg: 'white',
    activeBg: 'white',
    focusBoxShadow: 'outline',
  },
  blue: {
    bg: 'blue.600',
    color: 'white',
    hoverBg: 'blue.500',
    activeBg: 'blue.700',
    focusBoxShadow: 'outline',
  },
  primary: {
    bg: 'blue.600',
    color: 'white',
    hoverBg: 'blue.500',
    activeBg: 'blue.600',
    focusBoxShadow: 'outline',
  },
  orange: {
    bg: 'orange.500',
    color: 'white',
    hoverBg: 'orange.600',
    activeBg: 'orange.700',
    focusBoxShadow: '0 0 0 3px rgba(251, 211, 141, 0.45)',
  },
};

function variantSolid(props: Dict) {
  const { colorScheme: c } = props;

  if (c === 'gray')
    return {
      bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      _hover: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
      _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) },
    };

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
    focusBoxShadow,
  } = accessibleColorMap[c] || {};
  return {
    bg: mode(bg, `${c}.200`)(props),
    color: mode(color, `gray.800`)(props),
    _hover: { bg: mode(hoverBg, `${c}.300`)(props) },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
    _focus: { boxShadow: focusBoxShadow },
  };
}

function variantLink(props: Dict) {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    color: mode(`${c}.500`, `${c}.200`)(props),
    _hover: { textDecoration: 'underline' },
    _active: {
      color: mode(`${c}.700`, `${c}.500`)(props),
    },
  };
}

function variantRooLarge(props: Dict) {
  const { colorScheme: c } = props;
  return {
    height: '54px',
    lineHeight: 'normal',
    color: mode(`${c}.500`, `${c}.200`)(props),
    minWidth: '195px',
    ...variantSolid(props),
  };
}
const variantUnstyled = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
};

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  unstyled: variantUnstyled,
  rooLarge: variantRooLarge,
};

const sizes = {
  long: {
    h: '54px',
    minW: '195px',
    fontSize: 'md',
    px: 6,
  },
  lg: {
    h: 12,
    minW: 12,
    fontSize: 'lg',
    px: 6,
  },
  md: {
    h: 10,
    minW: 10,
    fontSize: 'md',
    px: 4,
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: 'sm',
    px: 3,
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: 'xs',
    px: 2,
  },
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'gray',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
