import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
  AlertTitle,
} from '@chakra-ui/react';

import enhanceMessage from './enhanceMessage';
import { MeetingError } from '@src/video';

interface ErrorDialogProps {
  dismissError: () => void;
  error: MeetingError | null | undefined;
}

const ErrorDialog = ({ dismissError, error }: ErrorDialogProps) => {
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);
  if (!error) return null;
  return (
    <Alert status="error" padding="30px" zIndex="100">
      <AlertIcon />
      <AlertTitle mr={2}>ERROR</AlertTitle>
      <AlertDescription>{enhancedMessage}</AlertDescription>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={dismissError}
      />
    </Alert>
  );
};

export default ErrorDialog;
