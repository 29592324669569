import React from 'react';
import {
  Box,
  Avatar,
  Button,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import useSignout from '@src/hooks/useSignout';
import { BackIcon } from './icons';
import { useTranslation } from 'react-i18next';
import Link from '@components/atoms/Link';
export interface UserMenuProps {
  viewer: Viewer;
  onChangeTab: (tab: number) => void;
}

const TabTwo = ({ viewer, onChangeTab }: UserMenuProps) => {
  const { t } = useTranslation();
  const doSignOut = useSignout();
  const { profile, email } = viewer;

  return (
    <Box py={6} px={6}>
      <Box py={4} borderBottom={'1px solid'} borderBottomColor={'blue.200'}>
        <Box
          d="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Avatar
            size="lg"
            name={`${profile?.firstName} ${profile?.lastName}`}
            src={profile?.avatar as string}
          />
          <Box
            isTruncated
            w="full"
            textAlign="center"
            as="span"
            fontSize="lg"
            lineHeight="normal"
            fontWeight="bold"
            mt={4}
            color="gray.900"
          >
            {profile?.firstName}
          </Box>
          <Box
            isTruncated
            as="span"
            textStyle="secondary"
            textAlign="center"
            w="full"
            color="gray.600"
            fontSize="xs"
          >
            {email}
          </Box>
        </Box>
      </Box>

      <Box
        px={8}
        py={4}
        borderBottom={'1px solid'}
        borderBottomColor={'blue.200'}
      >
        <VStack align="flex-start">
          <Link
            to="/profile/edit"
            color="gray.900"
            _hover={{ color: 'primary' }}
            _focus={{ color: 'primary' }}
          >
            {t('common.edit_profile')}
          </Link>
          <Link
            color="gray.900"
            to="/profile"
            _hover={{ color: 'primary' }}
            _focus={{ color: 'primary' }}
          >
            {t('common.view_profile')}
          </Link>
          {/* <Link
            to="/preferences"
            color="gray.900"
            _hover={{ color: 'primary' }}
            _focus={{ color: 'primary' }}
          >
            {t('common.preferences')}
          </Link> */}
        </VStack>
      </Box>

      <Box>
        <Box
          py={4}
          px={6}
          textAlign="end"
          d="flex"
          justifyContent="space-between"
        >
          <Button variant="unstyled" onClick={() => onChangeTab(0)}>
            <BackIcon color="blue.700" />
          </Button>
          <ChakraLink
            as="button"
            color="primary"
            onClick={doSignOut}
            fontSize="xs"
            fontWeight="800"
            _hover={{ color: 'blue.700' }}
            _focus={{ color: 'blue.700' }}
          >
            {t('common.sign_out')}
          </ChakraLink>
        </Box>
      </Box>
    </Box>
  );
};
export default TabTwo;
