import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Icon,
  Heading,
  VStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactComponent as LegarooIcon } from '@src/icons/white-logo.svg';
import { ReactComponent as TextureAbst } from '@src/icons/abst-invert.svg';
import SignupForm, { SignupFormValues } from '@components/molecules/SignupForm';
import useAuth0 from '@src/hooks/useAuth0';
import AuthSocialButtons from '@components/molecules/AuthSocialButtons';
import { useToast } from '@src/hooks';

export interface SignupModalProps {
  isOpen: boolean;
  onClose(): void;
  onSigninClick(): void;
}

const SignupModal = ({ isOpen, onClose, onSigninClick }: SignupModalProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { signup } = useAuth0();
  const modalSize = useBreakpointValue({ lg: '3xl' });
  const classNameVariant = useBreakpointValue({
    base: 'clip-image-top-right',
    lg: '',
  });
  const onSubmit = useCallback(
    async ({ email, password, firstName, lastName }: SignupFormValues) => {
      try {
        await signup({
          email,
          password,
          userMetadata: { first_name: firstName, last_name: lastName },
        });
        onClose();
        toast.success({
          title: t('signup.notifications.success.title'),
          description: t('signup.notifications.success.description'),
        });
      } catch (error) {
        toast.warning({
          title: t('signup.notifications.error.title'),
          description:
            error.code === 'user_exists'
              ? error.description
              : t('signup.notifications.error.description'),
        });
      }
    },
    [onClose, t, toast, signup]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay>
        <ModalContent rounded="3xl" mx={{ base: 4, lg: 0 }}>
          <ModalCloseButton
            zIndex={10}
            color={{ base: 'black', lg: 'white' }}
            top={{ base: 2, lg: 4 }}
            right={{ base: 3, lg: 6 }}
          />
          <ModalBody p={0}>
            <Box
              position="relative"
              bg="white"
              overflow={{ base: 'auto', lg: 'hidden' }}
              rounded="3xl"
            >
              <Box
                position={{ lg: 'relative' }}
                zIndex={{ lg: 10 }}
                bg="white"
                p={{ base: 6, lg: 10 }}
                w={{ base: '100%', lg: '60%' }}
              >
                <Icon
                  viewBox="0 0 100 100"
                  fill="currentColor"
                  preserveAspectRatio="none"
                  display="block"
                  position="absolute"
                  right={0}
                  insetY={0}
                  h="full"
                  w={12}
                  color="white"
                  transform="translateX(50%)"
                  d={{ base: 'none', lg: 'flex' }}
                >
                  <polygon points="50,0 100,0 50,100 0,100" />
                </Icon>
                <Heading
                  as="h4"
                  fontWeight="bold"
                  fontSize="sm"
                  color="black"
                  lineHeight={6}
                >
                  {t('signup.title')}
                </Heading>
                <Heading
                  fontSize="2xl"
                  fontWeight="light"
                  lineHeight={6}
                  color="gray.600"
                  mt={1}
                  as="h2"
                >
                  {t('common.brand')}
                </Heading>
                <SignupForm onSubmit={onSubmit} />
                <VStack mt={5} spacing={4} align="flex-start">
                  <Text fontSize="sm" color="gray.600">
                    {t('signup.signupOptionsText')}
                  </Text>
                  <AuthSocialButtons />

                  <Box
                    width="100%"
                    d="flex"
                    justifyContent={{ base: 'flex-end', lg: 'flex-start' }}
                  >
                    <Text color="gray.600" fontSize="xs">
                      {t('signup.footer')} <br />{' '}
                      <Text color="primary" fontWeight="semibold" as="span">
                        {t('common.termsOfUse')}
                      </Text>{' '}
                      &amp;{' '}
                      <Text as="span" color="primary" fontWeight="semibold">
                        {t('common.privacy_policy')}
                      </Text>
                    </Text>
                  </Box>
                </VStack>
              </Box>
              <Box
                display="block"
                bg="primary"
                position={{ lg: 'absolute' }}
                insetY={{ lg: 0 }}
                right={{ lg: 0 }}
                color="white"
                w={{ base: '100%', lg: '40%' }}
                className={classNameVariant}
              >
                <Icon
                  as={TextureAbst}
                  position="absolute"
                  right={0}
                  bottom={0}
                  transform="translateX(50%) translateY(-2.5rem)"
                  w={56}
                  h="auto"
                />
                <Box
                  pt={16}
                  px={6}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={LegarooIcon} w={48} h="auto" />

                  <Box
                    pt={{ base: 6, lg: 48 }}
                    d="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Text fontSize="sm" mt={4}>
                      {t('signup.alreadyHaveAccount')}
                    </Text>

                    <Button
                      variant="outline"
                      size="md"
                      px={10}
                      mt={4}
                      mb={6}
                      onClick={onSigninClick}
                      colorScheme="white"
                    >
                      {t('signup.signinHere')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default SignupModal;
