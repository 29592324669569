import React, { ReactNode } from 'react';
import { AudioStream, LocalAudioStream } from '@src/video';
import { useVideoContext } from '@src/context/VideoContext';
import CircularIndicator from '../CircularIndicator';

export interface LocalAudioIndicatorProps {
  children: ReactNode;
  sourceAudioTrack?: AudioStream | LocalAudioStream;
  size?: number;
}

const MeetingRoomAudioIndicatorLocal = ({
  children,
  size = 100,
  sourceAudioTrack,
}: LocalAudioIndicatorProps) => {
  const { localStreams } = useVideoContext();

  const audioT = localStreams.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioStream;

  return (
    <CircularIndicator
      minSize={size}
      audioTrack={sourceAudioTrack ? sourceAudioTrack : audioT}
    >
      {children}
    </CircularIndicator>
  );
};

export default MeetingRoomAudioIndicatorLocal;
