import React from 'react';
import { Form } from 'react-final-form';
import { Button, Box, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { EmailField, PasswordField } from '@src/components/atoms/Fields';
import { useForgotPasswordModal } from '@src/context';
import { Alert } from '@components/atoms/Alert';

export interface SigninFormValues {
  email: string;
  password: string;
}

export interface SigninFormProps {
  onSubmit: (v: SigninFormValues) => void;
}

const SigninForm = ({ onSubmit }: SigninFormProps) => {
  const { t } = useTranslation();
  const forgotPasswordModal = useForgotPasswordModal();
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <Box as="form" onSubmit={handleSubmit} pt={6}>
          {submitError && (
            <Alert mb={6} status="error" description={submitError} />
          )}
          <VStack spacing={4}>
            <EmailField
              placeholder={t('fields.email.placeholder')}
              label={t('fields.email.label')}
            />
            <PasswordField
              placeholder={t('fields.password.placeholder')}
              label={t('fields.password.label')}
            />
          </VStack>
          <Box
            d="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <Button
              colorScheme="orange"
              px={10}
              mt={2}
              isLoading={submitting}
              type="submit"
            >
              {t('common.signin')}
            </Button>
            <Button
              variant="link"
              mt={2}
              color="gray.600"
              _hover={{
                color: 'gray.800',
              }}
              fontSize={{ base: 'sm', lg: 'md' }}
              onClick={forgotPasswordModal.open}
            >
              {t('common.forgotPassword')}
            </Button>
          </Box>
        </Box>
      )}
    />
  );
};

export default SigninForm;
