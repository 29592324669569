import React from 'react';
import { Avatar, AvatarBadge, Box, Button, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '@src/icons/calendar.svg';
import DateFormat, { LARGE_FORMAT } from '@components/atoms/DateFormat';
import Link from '@src/components/atoms/Link';

const NextMeeting = ({ participant, times, meeting }: Meeting) => {
  const { t } = useTranslation();

  return (
    <Box position="relative" backgroundColor="blue.100" rounded="2xl">
      <Avatar
        name={`${participant?.firstName} ${participant?.lastName}`}
        src={participant.avatar as string}
        position="absolute"
        left={0}
        top={0}
        transform="translateX(-30%) translateY(-10%)"
        size="lg"
      >
        <AvatarBadge borderColor="transparent" boxSize="0.9em" bg="secondary" />
      </Avatar>
      <Box px={{ base: 6, lg: 8 }} py={{ base: 6, lg: 8 }}>
        <Box pl={{ base: 12, lg: 8 }} ml={1}>
          <Box
            d="flex"
            flexDir={{ base: 'column', lg: 'row' }}
            justifyContent={{ lg: 'space-between' }}
          >
            <Text as="h4" fontSize="base" fontWeight="bold">
              {t('home.meeting_with')}{' '}
              <Box as="span" color="primary">
                {`${participant.firstName} ${participant.lastName}`}
              </Box>
            </Text>
            <Text fontSize="sm">{t('home.next_meeting')}</Text>
          </Box>
        </Box>
        <Box
          d="flex"
          flexDir={{ base: 'column', lg: 'row' }}
          justifyContent={{ lg: 'space-between' }}
          alignItems={{ base: 'center' }}
          mt={4}
          pl={{ base: 5, lg: 12 }}
        >
          <Box d="flex" alignItems="center" pr={8}>
            <Icon color="gray.600" as={CalendarIcon} mr={2} w={6} h={6} />
            <DateFormat
              date={times.startsAt}
              format={LARGE_FORMAT}
              fontWeight="semibold"
              fontSize="sm"
            />
          </Box>
          <Button
            as={Link}
            to={`/meeting/${meeting.roomId}`}
            colorScheme="blue"
            fontSize="sm"
            fontWeight="bold"
            mt={{ base: 3, lg: 0 }}
          >
            {t('home.join_meeting')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NextMeeting;
