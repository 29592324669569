import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';

export interface SiteNotificationProps {
  type: 'info' | 'warning' | 'success' | 'error' | undefined;
  title: string;
  description: string;
  onDismiss?: () => void;
}

const SiteNotification = ({
  type,
  title,
  description,
  onDismiss,
}: SiteNotificationProps) => {
  return (
    <Alert status={type} zIndex="100" p="30px" mb={6}>
      <AlertIcon />
      <AlertTitle mr={2}>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
      <CloseButton
        onClick={onDismiss}
        position="absolute"
        right="8px"
        top="8px"
      />
    </Alert>
  );
};

export default SiteNotification;
