import React, { useCallback, useMemo, useState } from 'react';
import { Box, Heading, Skeleton, Stack, Text } from '@chakra-ui/react';
import sortBy from 'lodash/fp/sortBy';
import {
  isThisWeek,
  addWeeks,
  isSameWeek,
  isWithinInterval,
  endOfDay,
  isAfter,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import MeetingList from '@src/components/molecules/MeetingList';

import {
  MeetingFilter,
  FilterOptions,
} from '@src/components/molecules/MeetingList/MeetingFilter';
import { useGetMyAppointmentsQuery, useViewerQuery } from '@src/apollo/hooks';
import { parseMeeting } from '@src/utils';

const ScheduledMeetings = () => {
  const { data: viewerData } = useViewerQuery();
  const viewer = viewerData?.viewer;
  const { t } = useTranslation();
  const { data, loading } = useGetMyAppointmentsQuery();
  const [filter, setFilter] = useState<FilterOptions>();
  const [range, setRange] = useState<[Date, Date] | null>(null);
  const getMyAppointments = data?.getMyAppointments;
  const displayMeetings = useMemo(() => {
    const mapped = getMyAppointments?.map((item) => {
      const participantType =
        viewer?.email === item?.user.email ? 'lawyer' : 'user';
      return parseMeeting(item, participantType);
    });
    const futureMeetings = sortBy(
      (m) => m.times.startsAt,
      (mapped ?? []).filter((item) => isAfter(item.times.endsAt, new Date()))
    ) as Meeting[];
    switch (filter) {
      case 'currentWeek':
        return futureMeetings.filter((item) =>
          isThisWeek(item.times.startsAt, { weekStartsOn: 1 })
        ) as Meeting[];
      case 'nextWeek':
        return futureMeetings.filter((item) =>
          isSameWeek(item.times.startsAt, addWeeks(new Date(), 1), {
            weekStartsOn: 1,
          })
        ) as Meeting[];
      case 'withinInterval':
        if (range) {
          const [start, end] = range;
          return futureMeetings.filter((item) =>
            isWithinInterval(item.times.startsAt, { start, end: endOfDay(end) })
          );
        }
        return futureMeetings;
      default:
        return futureMeetings;
    }
  }, [filter, range, getMyAppointments, viewer]);

  const onChange = useCallback((value, range: [Date, Date] | undefined) => {
    setFilter(value);
    if (value === 'withinInterval' && range) {
      setRange(range);
    }
  }, []);
  return (
    <Box maxW="5xl" mx="auto" py={8} px={3}>
      <Heading fontSize="xl" textAlign={{ base: 'center', md: 'left' }}>
        {t(`my_meetings.list_title.${filter ? filter : 'all'}`)}
      </Heading>
      <Box maxW="4xl" mx="auto" py={8} px={{ lg: 3 }}>
        {loading && (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        )}
        {!loading && data?.getMyAppointments && (
          <>
            <Box d="flex" justifyContent="flex-end">
              <MeetingFilter onChange={onChange} selected={filter} />
            </Box>
            <MeetingList meetings={displayMeetings} />
          </>
        )}
        {!loading && displayMeetings.length === 0 && (
          <Box p={5} d="flex" alignItems="center" flexDir="column" mt={8}>
            <Text>{t('my_meetings.no_upcoming_meeting')}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScheduledMeetings;
