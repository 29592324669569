import React from 'react';
import {
  FormControlProps,
  FormLabel,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  FormLabelProps,
} from '@chakra-ui/react';
import { useField, UseFieldConfig, FieldRenderProps } from 'react-final-form';
import { ShowErrorFunc, showErrorOnChange } from '@src/utils';
import { Control } from '../Control';
import { Error } from '../Error';

export interface TextareaFieldProps extends ChakraTextareaProps {
  name: string;
}

export const Textarea = ({ name, ...rest }: TextareaFieldProps) => {
  const { input, meta } = useField(name);
  return (
    <ChakraTextarea
      isInvalid={meta.error && meta.touched}
      {...input}
      {...rest}
    />
  );
};

// eslint-disable-next-line
export type AdaptedTextareaProps<FieldValue = any> = ChakraTextareaProps &
  FieldRenderProps<FieldValue>;

export const AdaptedTextarea = ({
  input,
  meta,
  ...props
}: AdaptedTextareaProps) => {
  return (
    <ChakraTextarea
      isInvalid={meta.error && meta.touched}
      {...props}
      {...input}
    />
  );
};

// eslint-disable-next-line
export interface TextareaProps<FieldValue = any> extends ChakraTextareaProps {
  name: string;
  label?: string;
  showError?: ShowErrorFunc;
  fieldProps?: UseFieldConfig<FieldValue>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
}

export const TextareaField = ({
  variant = 'roo',
  name,
  label,
  placeholder,
  fieldProps,
  formControlProps,
  formLabelProps,
  isDisabled,
  showError = showErrorOnChange,
  ...props
}: TextareaProps) => {
  const { input, meta } = useField(name, { type: 'text', ...fieldProps });

  const isError = showError({ meta });

  return (
    <Control
      name={name}
      isInvalid={isError}
      isDisabled={isDisabled}
      {...formControlProps}
    >
      <FormLabel
        htmlFor={name}
        rounded="xl"
        ml="1px"
        mt="1px"
        w="98%"
        background="white"
        {...formLabelProps}
      >
        {label}
      </FormLabel>
      <ChakraTextarea
        {...input}
        {...props}
        id={input.name}
        placeholder={placeholder}
        variant={variant}
      />
      <Error name={name} />
    </Control>
  );
};
