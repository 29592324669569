import React from 'react';
import { Icon, IconProps } from '@chakra-ui/icon';
import { ReactComponent as CheckedIcon } from '@src/icons/success-notification.svg';
import { ReactComponent as WarningSvg } from '@src/icons/warning.svg';
import { ReactComponent as InfoSvg } from '@src/icons/info.svg';

export const CheckIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props} as={CheckedIcon} />
);

export const InfoIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props} as={InfoSvg} />
);

export const WarningIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props} as={WarningSvg} />
);
