import React, { useState } from 'react';
import { Box, HStack, Icon, SlideFade } from '@chakra-ui/react';
import TabTwo from './TabTwo';
import TabOne from './TabOne';
import { ReactComponent as FBIcon } from '@src/icons/facebook-icon.svg';
import { ReactComponent as InIcon } from '@src/icons/in-icon.svg';
import { ReactComponent as TwitterIcon } from '@src/icons/twitter-icon.svg';

export interface UserMenuProps {
  viewer: Viewer;
}

const UserMenu = ({ viewer }: UserMenuProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <Box zIndex={1400} background="blue.100" borderRadius="lg" m="10px">
      {currentTab === 0 && (
        <SlideFade in={currentTab === 0} offsetX="50px">
          <TabOne viewer={viewer} onChangeTab={setCurrentTab} />
        </SlideFade>
      )}
      {currentTab === 1 && (
        <SlideFade in={currentTab === 1} offsetX="50px">
          <TabTwo viewer={viewer} onChangeTab={setCurrentTab} />
        </SlideFade>
      )}
      <HStack
        spacing={4}
        color="blue.600"
        padding={5}
        justifyContent="flex-end"
      >
        <Icon as={FBIcon} w={{ base: 6, md: 8 }} h={{ base: 6, md: 8 }} />
        <Icon as={InIcon} w={{ base: 6, md: 8 }} h={{ base: 6, md: 8 }} />
        <Icon as={TwitterIcon} w={{ base: 6, md: 8 }} h={{ base: 6, md: 8 }} />
      </HStack>
    </Box>
  );
};

export default UserMenu;
