import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Icon, Button } from '@chakra-ui/react';
import { ReactComponent as CheckIcon } from '@src/icons/circle-check.svg';
import { SortOption } from '@src/content/config';

export interface SortPopOverProps {
  setSort: (sort: string) => void;
  activeSort: string;
  sortOptions: SortOption[];
}

export const SortPopOver: React.FC<SortPopOverProps> = ({
  setSort,
  activeSort,
  sortOptions,
}: SortPopOverProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box d="flex" flexDir="column" marginLeft="5" alignItems="flex-start">
        {sortOptions.map((option, key) => {
          const isActive = activeSort === option.name;
          return (
            <Button
              variant="unstyled"
              pt={5}
              key={key}
              onClick={() => setSort(option.name)}
            >
              <Text
                color={isActive ? 'orange.500' : 'gray.600'}
                _hover={{
                  color: 'orange.500',
                }}
              >
                {isActive && <Icon mr={2} as={CheckIcon} />}
                {t(`directory.sort_option.${option.name}`)}
              </Text>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default SortPopOver;
