import React from 'react';
import { Box, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PaymentLineItem, { Payment } from './PaymentLineItem';

const thStyle = {
  px: 2,
  py: 3,
  bg: 'gray.50',
  textAlign: 'left',
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'gray.500',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
};

export interface PaymentHistoryTableProps {
  payments: Payment[];
}

const PaymentHistoryTable = ({ payments }: PaymentHistoryTableProps) => {
  const { t } = useTranslation();
  return (
    <Box className="divide-y" d="table" as="table" minW="full">
      <thead>
        <tr>
          <Box sx={thStyle} px={2} as="th">
            {t('common.transaction_id')}
          </Box>
          <Box sx={thStyle} as="th">
            {t('common.concept')}
          </Box>
          <Box sx={thStyle} as="th">
            {t('common.date')}
          </Box>
          <Box sx={thStyle} as="th">
            {t('common.credit_card')}
          </Box>
          <Box sx={thStyle} as="th">
            {t('common.amount_payed')}
          </Box>
        </tr>
      </thead>
      <Box className="divide-y" bg="white" d="table-row-group" as="tbody">
        {payments.length <= 0 && (
          <tr>
            <chakra.td colSpan={5} alignItems="center" p={6}>
              <chakra.div
                d="flex"
                alignItems="center"
                justifyContent="center"
                color="gray.800"
              >
                {t('my_payments.no_payments')}
              </chakra.div>
            </chakra.td>
          </tr>
        )}
        {payments.map?.((payment) => (
          <PaymentLineItem key={payment.id} {...payment} />
        ))}
      </Box>
    </Box>
  );
};

export default PaymentHistoryTable;
