import React, { useState } from 'react';
import { Input, InputProps, useTheme } from '@chakra-ui/react';
import { CardElementProps } from '@stripe/react-stripe-js';

export default function StripeInput(props: InputProps & CardElementProps) {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { id, placeholder, as, ...rest } = props; // eslint-disable-line

  return (
    <Input
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      id={id}
      variant="roo"
      boxShadow={isFocused ? `0 0 0 1px ${theme.colors.gray[400]}` : 'base'}
      as={as}
      {...rest}
    />
  );
}
