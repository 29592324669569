import lawyerData from './lawyerData';
export interface LawyerLocation {
  country: string;
  code: string;
  city: string;
}

export interface Lawyer {
  gender: string;
  name: Name;
  location: Location;
  law_firm?: string;
  email: string;
  id: number;
  picture: Picture;
  nat: string;
  areas_of_practice: number[];
}

export interface Location {
  street: Street;
  city: string;
  state: string;
  country: string;
  postcode: number | string;
  coordinates: Coordinates;
  timezone: Timezone;
}

export interface Coordinates {
  latitude: string;
  longitude: string;
}

export interface Street {
  number: number;
  name: string;
}

export interface Timezone {
  offset: string;
  description: string;
}

export interface Name {
  title: string;
  first: string;
  last: string;
}

export interface Picture {
  large?: string;
  medium: string;
  thumbnail?: string;
}

const lawyers: Lawyer[] = lawyerData;

export function mockLawyer(): Lawyer {
  return lawyers[Math.floor(Math.random() * lawyers.length)];
}

export { lawyers };
