import React from 'react';
import { Switch, useRouteMatch, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Route from '@src/PrivateRoute';
import MeetingRating from './MeetingRating';
import VideoApp from './VideoApp';
import { useGetAppointmentByRoomQuery } from '@src/apollo/hooks';
import Loader from '@atoms/Loader';

const MeetingRoom: React.FC = () => {
  const { path } = useRouteMatch();
  const { meeting_id } = useParams<RoomParamTypes>();
  const { loading, error } = useGetAppointmentByRoomQuery({
    variables: {
      roomId: meeting_id,
    },
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <Box
      background={`url('/images/meeting-background.svg')`}
      backgroundSize="cover"
      backgroundColor="#E5F0FF"
    >
      <Switch>
        <Route exact path={path} authorize={() => !error} prefixLocale={false}>
          <VideoApp />
        </Route>
        <Route
          path={`${path}/done`}
          authorize={() => !error}
          prefixLocale={false}
        >
          <MeetingRating />
        </Route>
      </Switch>
    </Box>
  );
};
export default MeetingRoom;
