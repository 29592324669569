import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  chakra,
  Fade,
  Icon,
  IconButton,
  SlideFade,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as CloseSvg } from '@src/icons/close.svg';
import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';
import style from './style';

export interface CreditCardProps
  extends Omit<PaymentMethodType, 'id' | 'isDefault'> {
  onSetAsDefault?(): Promise<void>;
  onDelete?(): Promise<void>;
}

const CardNumber = ({ last4 }: { last4: string | number }) => {
  return (
    <chakra.div
      pos="absolute"
      top="45%"
      left="10%"
      lineHeight="24px"
      letterSpacing="0.2em"
    >
      <chakra.span fontSize="3xl" verticalAlign="middle" opacity={0.8}>
        •••• ••••
      </chakra.span>{' '}
      <chakra.span fontSize="16px" fontWeight="light" verticalAlign="middle">
        {last4}
      </chakra.span>
    </chakra.div>
  );
};

export const CreditCard = ({
  cardBrand,
  cardLast4,
  expMonth,
  expYear,
  onDelete,
  onSetAsDefault,
}: CreditCardProps) => {
  const { t } = useTranslation();
  const confirmDelete = useDisclosure();
  const { icon: BaseIcon, cardBg, iconBtnStyle } = style[
    cardBrand as keyof typeof style
  ];
  const cardExpiry = useMemo(() => {
    const _expYear = String(expYear);
    return `${expMonth} / ${_expYear.substr(_expYear.length - 2)}`;
  }, [expMonth, expYear]);
  return (
    <chakra.div
      position="relative"
      width="235px"
      height="154px"
      onMouseLeave={confirmDelete.onClose}
    >
      <chakra.div
        background="linear-gradient(25deg, #939393, #717171)"
        rounded="2xl"
        position="absolute"
        overflow="hidden"
        top={0}
        left={0}
        w="full"
        h="full"
        shadow="lg"
        role="group"
        color="white"
      >
        <chakra.div
          bg={cardBg}
          height="200%"
          position="absolute"
          top="-60%"
          transform="rotate(25deg)"
          width="150%"
          left="-22%"
        />
        <BaseIcon
          position="absolute"
          height={10}
          width="auto"
          top="10%"
          left="10%"
        />

        <CardNumber last4={cardLast4 as string} />

        <chakra.div
          opacity={{ base: 0, lg: 1 }}
          visibility={{ base: 'hidden', lg: 'visible' }}
          pos="absolute"
          bottom="12%"
          right="10%"
          fontWeight="light"
          fontSize="sm"
          transition="visibility 0s, opacity 0.2s ease-out"
          _groupHover={{
            opacity: { lg: 0 },
            visibility: { lg: 'hidden' },
          }}
        >
          {cardExpiry}
        </chakra.div>

        <chakra.div
          opacity={{ base: 1, lg: 0 }}
          visibility={{ base: 'visible', lg: 'hidden' }}
          pos="absolute"
          bottom="12%"
          left="10%"
          width="80%"
          transition="visibility 0s, opacity 0.2s ease-in"
          _groupHover={{
            opacity: { lg: 1 },
            visibility: { lg: 'visible' },
          }}
        >
          <chakra.div d="flex" pos="relative">
            <chakra.div d="flex">
              <IconButton
                isRound
                aria-label="delete-btn"
                variant="unstyled"
                size="xs"
                onClick={confirmDelete.onToggle}
                {...iconBtnStyle}
              >
                <Icon as={CloseSvg} w="10px" h="10px" />
              </IconButton>

              <SlideFade
                in={confirmDelete.isOpen}
                offsetX="20px"
                offsetY={0}
                unmountOnExit
              >
                <chakra.button
                  fontSize="sm"
                  ml={2}
                  onClick={onDelete}
                  pos="absolute"
                  top="15%"
                  whiteSpace="nowrap"
                  outline="none"
                  fontWeight="semibold"
                >
                  {t('common.delete_confirm')}{' '}
                  <Icon w={4} h={4} as={ArrowForward} />
                </chakra.button>
              </SlideFade>
            </chakra.div>
            <Fade in={!confirmDelete.isOpen} unmountOnExit>
              <chakra.button
                fontSize="sm"
                pos="absolute"
                right={0}
                top="15%"
                outline="none"
                fontWeight="semibold"
                onClick={onSetAsDefault}
              >
                {t('my_payments.set_as_default')}{' '}
                <Icon w={4} h={4} as={ArrowForward} />
              </chakra.button>
            </Fade>
          </chakra.div>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  );
};
