import React from 'react';
import { Box, Button, Text, Icon, BoxProps, Link } from '@chakra-ui/react';
import { ReactComponent as SVGReactComponent } from '*.svg';
import { useTranslation } from 'react-i18next';

export interface FeatureCardProps {
  translationKey: number;
  icon?: typeof SVGReactComponent;
  transform?: BoxProps['transform'];
  onAction: (action: string) => void;
  action: {
    label: string;
    href: string;
  };
}

const FeatureCardLawyer = ({
  translationKey,
  icon: SvgIcon,
  transform,
  action,
  onAction,
}: FeatureCardProps) => {
  const { t } = useTranslation();

  return (
    <Box
      d="flex"
      backgroundColor="white"
      rounded="xl"
      color="primary"
      p={6}
      shadow="0px 0px 20px rgba(0, 0, 0, 0.15)"
      transform={transform}
    >
      <Box flexShrink={0}>
        {SvgIcon && (
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            h={12}
            w={12}
          >
            <Icon as={SvgIcon} w={12} h={12} />
          </Box>
        )}
      </Box>
      <Box ml={4}>
        <Box fontSize="lg" fontWeight="bold">
          {t(`lawyer_meet_features.feature${translationKey}.title`)}
        </Box>
        <Text
          fontSize="sm"
          mt={3}
          fontWeight="medium"
          lineHeight={5}
          color="gray.600"
        >
          {t(`lawyer_meet_features.feature${translationKey}.text`)}
        </Text>
        <Button
          as={Link}
          variant="solid"
          onClick={() => onAction(action.href)}
          mt={4}
          colorScheme="orange"
          fontWeight="medium"
          fontSize="sm"
          px={35}
        >
          {t(`lawyer_meet_features.feature${translationKey}.action.label`)}
        </Button>
      </Box>
    </Box>
  );
};

export default FeatureCardLawyer;
