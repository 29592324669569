import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '@src/icons/calendar.svg';
// import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';
import {
  Avatar,
  Box,
  Text,
  Button,
  Icon,
  Tag,
  TagLabel,
  Stack,
  // useBreakpointValue,
} from '@chakra-ui/react';
import {
  isWithinInterval,
  formatDistanceStrict,
  differenceInMinutes,
  subMinutes,
} from 'date-fns';
import DateFormat from '@src/components/atoms/DateFormat';
import Link from '@src/components/atoms/Link';

const MeetingItem = ({ times, participant, meeting }: Meeting) => {
  const { endsAt, startsAt } = times;
  const { t } = useTranslation();
  const currentDate = useMemo(() => new Date(), []);
  const isWithinRange = useMemo(() => {
    return isWithinInterval(currentDate, {
      start: subMinutes(startsAt, 15),
      end: endsAt,
    });
  }, [startsAt, endsAt, currentDate]);

  const duration = differenceInMinutes(endsAt, startsAt);
  // const rescheduleBtnVariant = useBreakpointValue({
  //   base: isWithinRange ? 'link' : 'solid',
  //   md: 'link',
  // });
  // const rescheduleBtnColor = useBreakpointValue({
  //   base: 'orange',
  //   md: isWithinRange ? 'orange' : 'blue',
  // });

  return (
    <Box
      d="flex"
      backgroundColor={isWithinRange ? 'white' : 'blue.100'}
      rounded="xl"
      p={{ base: 2, md: 4 }}
      shadow={isWithinRange ? 'lg' : 'none'}
    >
      <Stack
        d="flex"
        spacing={{ base: 4, md: 2 }}
        direction={{ base: 'column', md: 'row' }}
        w="full"
        p={4}
        borderRadius="2xl"
      >
        <Box d="flex" alignItems="center" w={{ md: '35%' }} flexShrink={0}>
          <Avatar
            name={`${participant.firstName} ${participant.lastName}` as string}
            src={participant.avatar as string}
            size="lg"
          />
          <Box pl={4}>
            <Text color="blue.600" fontWeight="300" fontSize="lg">
              {participant.firstName} {participant.lastName}
            </Text>
            {participant.title && (
              <Text fontSize="sm" color="gray.600">
                {participant.title}
              </Text>
            )}
          </Box>
        </Box>
        <Box
          d="flex"
          flexDirection="row"
          justifyContent={{ base: 'center', md: 'flex-start' }}
          w={{ md: '45%' }}
        >
          <Box pt={1}>
            <Icon color="gray.600" as={CalendarIcon} w={6} h={6} mr={2} />
          </Box>
          <Box>
            <Text fontSize="sm" as="strong" color="blue.600">
              <DateFormat date={startsAt} format="EEEE, LLLL do. yyyy" />
            </Text>

            <Stack
              direction={{ base: 'row', md: 'column' }}
              alignItems="flex-start"
              spacing={{ base: 3, md: 1 }}
            >
              <Text textAlign="left" color="gray.600">
                <DateFormat date={startsAt} format="p" /> -{' '}
                <DateFormat as="span" date={endsAt} format="p" />
              </Text>
              <Tag
                size="md"
                borderRadius="full"
                variant="outline"
                colorScheme="blue"
              >
                <TagLabel>{duration} min</TagLabel>
              </Tag>
            </Stack>
          </Box>
        </Box>
        <Box d="flex" justifyContent={{ base: 'center', md: 'flex-end' }}>
          <Box
            d="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {isWithinRange ? (
              <Button
                as={Link}
                to={`/meeting/${meeting.roomId}`}
                colorScheme="blue"
                size="sm"
                px={6}
              >
                {t('home.join_meeting')}
              </Button>
            ) : (
              <Text fontSize="sm" as="strong" textAlign="center">
                {t('my_meetings.meeting_will_start')}{' '}
                {formatDistanceStrict(startsAt, currentDate, {
                  addSuffix: true,
                })}
              </Text>
            )}
            {/* {participant.type === 'lawyer' && (
              <Button
                as={Link}
                variant={rescheduleBtnVariant}
                colorScheme={rescheduleBtnColor}
                size="sm"
                mt={4}
                to={`/booking/new/${participant.id}`}
              >
                <Text as="strong">
                  {t('common.reschedule')}
                  <Icon ml={1} w={4} h={4} as={ArrowForward} />
                </Text>
              </Button>
            )} */}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default MeetingItem;
