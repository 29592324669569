import React from 'react';
import {
  Box,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactComponent as Microphone } from '@src/icons/microphone.svg';
import { ReactComponent as MicrophoneOff } from '@src/icons/microphone-off.svg';

const ParticipantInfoName = ({
  name,
  isAudioEnabled,
}: {
  name: string;
  isAudioEnabled?: boolean;
}) => {
  const btnSize = useBreakpointValue({ base: 'xs', sm: 'sm' });
  return (
    <Box
      w="full"
      h={8}
      borderRadius="50px"
      position="relative"
      d="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={1}
      _before={{
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        borderRadius: '50px',
        opacity: 0.8,
        background:
          'linear-gradient(81.12deg, rgba(35, 6, 215, 0.47) 19.37%, rgba(12, 11, 11, 0) 90.87%);',
      }}
    >
      <Text
        width="76%"
        textAlign="center"
        pr={3}
        fontWeight="medium"
        color="white"
        isTruncated
        fontSize={{ base: 'sm', lg: 'md' }}
      >
        {name}
      </Text>
      <IconButton
        position="absolute"
        top={0}
        right={0}
        isRound
        aria-label="microphone-btn"
        colorScheme={isAudioEnabled ? 'primary' : 'red'}
        variant="solid"
        color="white"
        size={btnSize}
      >
        <Icon
          as={isAudioEnabled ? Microphone : MicrophoneOff}
          w={{ base: 4, sm: 5 }}
          h={{ base: 4, sm: 5 }}
          transition="ease-in-out"
        />
      </IconButton>
    </Box>
  );
};

export default ParticipantInfoName;
