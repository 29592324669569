import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { languages } from '@src/config/i18n';
import Link, { LinkProps } from '../Link';

export type NavLinkProps = LinkProps & {
  isActiveStyle?: Dict;
  exact?: boolean;
};

const localePath = `/:locale(${languages.join('|')})`;

const NavLink = ({ to, exact, isActiveStyle, ...props }: NavLinkProps) => {
  const match = useRouteMatch({
    path: `${localePath}${to as string}`,
    exact,
  });

  const linkProps = {
    ...props,
    ...(match && isActiveStyle),
  };

  return <Link to={to} {...linkProps} />;
};

export default NavLink;
