import { Styles } from '@chakra-ui/theme-tools';
import 'react-datepicker/dist/react-datepicker.css';
import 'cropperjs/dist/cropper.css';
import './datepicker.css';
import './datepicker-field.css';
import './cropper.css';

const styles: Styles = {
  global: {
    '.divide-y': {
      '> * + *': {
        borderTopWidth: '1px',
        borderColor: '#eff2f6',
      },
    },
    '.truncate': {
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      whiteSpace: 'nowrap !important',
    },
    '.pull-up': {
      '@media screen and (min-width: 48em)': {
        marginTop: '-4rem !important',
        paddingTop: '6rem !important',
        paddingBottom: '6rem !important',
      },
    },
    '.clip-image-bottom-right': {
      clipPath: 'polygon(0 0, 100% 0, 100% 95%, 0% 100%)',
      '@media screen and (max-width: 62em)': {
        clipPath: 'polygon(0 0, 100% 0, 100% 99%, 0% 100%)',
      },
    },
    '.clip-image-bottom-right-lawyers': {
      clipPath: 'polygon(0 0, 100% 0, 100% 95%, 0% 100%)',
    },
    '.clip-image-bottom-left': {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 95%)',
      '@media screen and (max-width: 62em)': {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 98%)',
      },
    },
    '.clip-image-top-left': {
      clipPath: 'polygon(0 5%, 100% 0, 100% 100%, 0 100%)',
      '@media screen and (max-width: 62em)': {
        clipPath: 'polygon(0 2%, 100% 0, 100% 100%, 0 100%)',
      },
    },
    '.clip-image-top-right': {
      clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 100%)',
    },
    '.clip-image-top-bottom-left': {
      '@media screen and (min-width: 32em)': {
        clipPath: 'polygon(0 5%, 100% 0, 100% 100%, 0 95%)',
      },
      '@media screen and (min-width: 48em)': {
        clipPath: 'polygon(0 2%, 100% 0, 100% 100%, 0 98%)',
      },
    },
    '.clip-image-bottom-left-extra': {
      clipPath: 'polygon(0 0%, 100% 0, 100% 100%, 0 85%)',
    },
    '.clip-image-top-bottom-right': {
      clipPath: 'polygon(0 0, 100% 5%, 100% 95%, 0 100%)',
      '@media screen and (max-width: 62em)': {
        clipPath: 'polygon(0 0, 100% 2%, 100% 98%, 0 100%)',
      },
    },
    '.clip-color-bottom-right': {
      '&::before': {
        position: 'absolute',
        pointerEvents: 'none',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 200' fill='rgb(234,50,23)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,0 0,200'%3E%3C/polygon%3E%3C/svg%3E")`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        content: '""',
        top: '0px',
        right: 0,
        left: 0,
        width: '100%',
      },
    },
    '.clip-color-bottom-left': {
      '&::before': {
        position: 'absolute',
        pointerEvents: 'none',
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 120' fill='rgb(234,50,23)' fill-opacity='1'%3E%3Cpolygon points='0,0 100,120 100,0'%3E%3C/polygon%3E%3C/svg%3E")`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        content: '""',
        height: '120px',
        top: '0px',
        right: 0,
        left: 0,
        width: '100%',
      },
    },
    '.text-decoration-skip-ink': {
      textDecoration: '0.3rem underline #FF991F',
      textDecorationSkipInk: 'none',
    },
    '.clip-background-top-right-bottom-right': {
      '@media screen and (max-width: 32em)': {
        clipPath: 'polygon(0% 0%, 100% 4%, 100% 96%, 0 100%)',
      },
    },
  },
};

export default styles;
