import React, { useRef, useEffect } from 'react';
import { chakra } from '@chakra-ui/react';
import { IVideoTrack } from '@src/@types/twilio';
import { Track } from '@src/video';
import useMediaStreamTrack from '@src/hooks/meeting/useMediaStreamTrack';
import useVideoTrackDimensions from '@src/hooks/meeting/useVideoTrackDimensions';

const VideoBox = chakra('video');

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

const MeetingRoomVideoTrack = ({
  track,
  isLocal,
  priority,
}: VideoTrackProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        track.setPriority(null);
      }
    };
  }, [track, priority]);
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
    objectFit:
      isPortrait || track.name.includes('screen')
        ? ('contain' as const)
        : ('cover' as const),
  };
  return <VideoBox ref={ref} w="100%" h="100%" {...style} />;
};

export default MeetingRoomVideoTrack;
