import React from 'react';
import RRLink from '@src/components/atoms/Link';
import { Box, HStack, Icon, Link, Stack, VStack, Text } from '@chakra-ui/react';
import { ReactComponent as FBIcon } from '@src/icons/facebook-icon.svg';
import { ReactComponent as InIcon } from '@src/icons/in-icon.svg';
import { ReactComponent as LegarooIcon } from '@src/icons/white-logo.svg';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      position="relative"
      zIndex={0}
      color="white"
      backgroundColor="blue.600"
      _after={{
        background: 'inherit',
        content: '""',
        display: 'block',
        height: '50%',
        left: 0,
        position: 'absolute',
        right: 0,
        zIndex: -1,
        top: 0,
        transform: 'skewY(1.2deg)',
        transformOrigin: '100% 0',
      }}
    >
      <Box py={16} px={{ base: 4, sm: 6, lg: 8 }} overflow="hidden">
        <VStack spacing={4}>
          <Icon as={LegarooIcon} h={12} w="auto" data-testid="footer-logo" />
          <HStack spacing={8}>
            <Link href="https://facebook.com/legaroo" target="_blank">
              <Icon as={FBIcon} w={{ base: 6, md: 8 }} h={{ base: 6, md: 8 }} />
            </Link>
            <Link
              href="https://cr.linkedin.com/company/legaroo"
              target="_blank"
            >
              <Icon as={InIcon} w={{ base: 6, md: 8 }} h={{ base: 6, md: 8 }} />
            </Link>
          </HStack>
        </VStack>
      </Box>

      <Box backgroundColor={{ base: 'blue.600', lg: 'blue.700' }} py={8}>
        <Stack
          maxW="6xl"
          mx="auto"
          direction={{ base: 'column', lg: 'row' }}
          align="center"
          justifyContent={{ lg: 'space-between' }}
          px={{ base: 0, lg: 8 }}
          spacing={{ base: 4, sm: 6, lg: 8 }}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 4, sm: 6, lg: 8 }}
            order={{ lg: 2 }}
            align="center"
            w={{ base: '100%', lg: '50%' }}
          >
            <Link
              as={RRLink}
              color="gray.300"
              borderBottom={{ base: '1px solid #0656CD', lg: 'none' }}
              width={{ base: '100%', lg: 'auto' }}
              textAlign="center"
              paddingBottom={{ base: '14px', lg: '0px' }}
              _hover={{ color: 'white' }}
              to="/terms"
            >
              {t('common.terms_and_conditions')}
            </Link>

            <Link
              as={RRLink}
              color="gray.300"
              borderBottom={{ base: '1px solid #0656CD', lg: 'none' }}
              width={{ base: '100%', lg: 'auto' }}
              textAlign="center"
              paddingBottom={{ base: '14px', lg: '0px' }}
              _hover={{ color: 'white' }}
              to="/privacy-policy"
            >
              {t('common.privacy_policy')}
            </Link>
            <Link
              as={RRLink}
              color="gray.300"
              _hover={{ color: 'white' }}
              to="/contact"
            >
              {t('common.contact_us')}
            </Link>
          </Stack>
          <Text fontSize="sm" mb={0} pt={{ xs: 6, md: 0 }} order={{ md: 1 }}>
            <strong>
              <span role="img" aria-label="rights">
                ©️
              </span>
              {t('common.legaroo')} 2020.
            </strong>{' '}
            {t('common.rights_reserved')}
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
