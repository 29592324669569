import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { chakra, Heading } from '@chakra-ui/react';
import CardSetupForm from '@src/components/molecules/CardSetupForm';
import { useStripeCreatePaymentMethod, useToast } from '@src/hooks';
import {
  GetMyPaymentSourcesDocument,
  useCreatePaymentSourceMutation,
  useGetMyPaymentSourcesQuery,
} from '@src/apollo/hooks';
import { Alert } from '@src/components/atoms/Alert';
import { formatError } from '@src/utils';

export default function AddPaymentMethod() {
  const { t } = useTranslation();
  const [
    createPaymentSource,
    { error, loading },
  ] = useCreatePaymentSourceMutation({
    refetchQueries: [{ query: GetMyPaymentSourcesDocument }],
  });
  const { data } = useGetMyPaymentSourcesQuery();
  const {
    loading: stripeLoading,
    onCreatePaymentMethod,
    error: stripeError,
  } = useStripeCreatePaymentMethod();
  const { locale } = useParams<BaseRouteParams>();
  const history = useHistory();
  const toast = useToast();
  const finalError = stripeError ?? error;

  const showSetAsDefaultField = data?.getMyPaymentSources?.some(
    (pm) => pm?.isDefault
  );

  const onSubmit = useCallback(
    async (values) => {
      try {
        const payload = await onCreatePaymentMethod({
          name: values.cardholderName,
        });
        if (payload?.paymentMethod) {
          const { data } = await createPaymentSource({
            variables: {
              paymentMethodId: payload.paymentMethod.id,
              isDefault: values.setAsDefault,
            },
          });
          if (!data?.createPaymentSource?.successful) {
            data?.createPaymentSource?.messages?.forEach((error) => {
              toast.warning({
                title: t('common.error'),
                description: error?.message,
                isClosable: true,
              });
            });
            return;
          }
          history.push(`/${locale}/my-payments/payment-methods`);
          toast.success({
            title: t('common.success'),
            description: t('my_payments.payment_methods.create_success'),
            isClosable: true,
          });
        }
      } catch (err) {
        toast.warning({
          title: 'Error',
          description: formatError(err),
          isClosable: true,
        });
      }
    },
    [history, toast, t, locale, createPaymentSource, onCreatePaymentMethod]
  );
  return (
    <chakra.div maxW="sm" mx="auto" mt={4}>
      <chakra.div>
        <Heading as="h3" fontSize="lg" mb={3}>
          {t('my_payments.add_new_card_title')}
        </Heading>
        {finalError && (
          <chakra.div pb={6}>
            <Alert
              variant="roo"
              status="error"
              title="Error"
              description={formatError(finalError)}
            />
          </chakra.div>
        )}
        <CardSetupForm
          onSubmit={onSubmit}
          labels={{
            cardNumber: t('fields.card_number.label'),
            cardExpiry: t('fields.card_expiry.label'),
            cardCVC: t('fields.card_cvc.label'),
          }}
          showSetAsDefaultField={showSetAsDefaultField}
          isLoading={stripeLoading ?? loading}
        />
      </chakra.div>
    </chakra.div>
  );
}
