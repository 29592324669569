/* eslint-disable react/display-name */

import React, { useCallback, useRef } from 'react';
import { chakra, StylesProvider, useMultiStyleConfig } from '@chakra-ui/system';
import { runIfFn } from '@chakra-ui/utils';
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftProps,
  PropGetters,
} from 'downshift';
import { FormControlOptions } from '@chakra-ui/form-control';
import { SelectProvider } from './useDownshift';

// eslint-disable-next-line
export type SelectSingleProps<Item = any> = FormControlOptions &
  Pick<
    DownshiftProps<Item>,
    | 'itemToString'
    | 'defaultIsOpen'
    | 'isOpen'
    | 'defaultHighlightedIndex'
    | 'stateReducer'
    | 'onInputValueChange'
  > & {
    id?: string;
    value?: Item | null;
    defaultValue?: Item;
    onChange?(
      selectedItem: Item | null | undefined,
      stateAndHelpers?: ControllerStateAndHelpers<Item>
    ): void;
    children: MaybeRenderProp<{
      isOpen: boolean;
      highlightedIndex: number | null;
      selectedItem: Item | null;
      onClose?(): void;
      inputValue: string | null;
      getLabelProps: PropGetters<Item>['getLabelProps'];
    }>;
  };

// eslint-disable-next-line
export function SelectSingle<Item = any>({
  id: idProp,
  children,
  isOpen,
  onInputValueChange,
  defaultValue,
  defaultIsOpen,
  defaultHighlightedIndex,
  onChange,
  itemToString,
  value,
  stateReducer,
  ...props
}: SelectSingleProps<Item>) {
  const styles = useMultiStyleConfig('SelectSingle', {});
  const inputRef = useRef<HTMLInputElement>(null);

  const getStateAndHelpers = useCallback(
    (downshift) => {
      return {
        ...downshift,
        ...props,
        inputRef,
        controlProps: downshift.getRootProps({}, { suppressRefError: true }),
      };
    },
    [props]
  );

  return (
    <Downshift
      id={idProp}
      selectedItem={value}
      onChange={onChange}
      onInputValueChange={onInputValueChange}
      initialSelectedItem={defaultValue}
      initialIsOpen={defaultIsOpen}
      isOpen={isOpen}
      itemToString={itemToString}
      initialHighlightedIndex={defaultHighlightedIndex}
      stateReducer={stateReducer}
    >
      {(downshift) => (
        <chakra.div pos="relative">
          <StylesProvider value={styles}>
            <SelectProvider value={getStateAndHelpers(downshift)}>
              {runIfFn(children, {
                inputValue: downshift.inputValue,
                isOpen: downshift.isOpen,
                highlightedIndex: downshift.highlightedIndex,
                selectedItem: downshift.selectedItem,
                getLabelProps: downshift.getLabelProps,
              })}
            </SelectProvider>
          </StylesProvider>
        </chakra.div>
      )}
    </Downshift>
  );
}
