import React from 'react';
import SiteNotification from '@src/components/atoms/SiteNotification';
import {
  useHasAudioInputDevices,
  useHasVideoInputDevices,
} from '@src/hooks/meeting/useDevice';
interface ErrorDialogProps {
  dismissError: () => void;
  error: Error | null | undefined;
}

export function getNotificationContent(
  hasAudio: boolean,
  hasVideo: boolean,
  error?: Error | null
) {
  let headline = '';
  let message = '';

  switch (true) {
    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = 'Unable to Access Media:';

      if (error && error.message === 'Permission denied by system') {
        // Chrome only
        message =
          'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.';
      } else {
        message =
          'The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera.';
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = 'Cannot Find Microphone or Camera:';
      message =
        'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.';
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = 'Error Acquiring Media:';
      message = error
        ? `${error.name} ${error.message}`
        : 'something went wrong';
      break;

    case !hasAudio && !hasVideo:
      headline = 'No Camera or Microphone Detected:';
      message =
        'Other participants in the room will be unable to see and hear you.';
      break;

    case !hasVideo:
      headline = 'No Camera Detected:';
      message = 'Other participants in the room will be unable to see you.';
      break;

    case !hasAudio:
      headline = 'No Microphone Detected:';
      message = 'Other participants in the room will be unable to hear you.';
  }

  return {
    headline,
    message,
  };
}

const MediaErrorNotification = ({ dismissError, error }: ErrorDialogProps) => {
  const hasAudio = useHasAudioInputDevices();
  const hasVideo = useHasVideoInputDevices();

  const { headline, message } = getNotificationContent(
    hasAudio,
    hasVideo,
    error
  );

  if (!error) return null;
  return (
    <SiteNotification
      type="warning"
      title={headline}
      description={message}
      onDismiss={dismissError}
    />
  );
};

export default MediaErrorNotification;
