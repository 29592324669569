import React, { FC } from 'react';
import { useViewerQuery } from '@src/apollo/hooks';
import { isLawyer } from '@src/utils';
import CustomRoute from '@src/Router';
import Home from '@src/pages/Home';
import LawyerHome from '@src/pages/LawyerHome';
import Landing from '@src/pages/Landing';
import Loader from '@atoms/Loader';

const Index: FC = () => {
  const { data, loading } = useViewerQuery();
  if (loading) {
    return <Loader />;
  }
  if (data?.viewer) {
    return (
      <CustomRoute
        path="/"
        exact
        Component={isLawyer(data?.viewer) ? LawyerHome : Home}
      />
    );
  }
  return <CustomRoute path="/" exact Component={Landing} />;
};

export default Index;
