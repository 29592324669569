import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import I18nBackend from './i18n-backend';

const backend = new I18nBackend();

// In future have to  fetch apollo languages
export const languages = ['en', 'es'];

i18n
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .use(backend as any)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['labels', 'index'],
    fallbackLng: 'en',
    detection: {
      order: [
        'path',
        'localStorage',
        'cookie',
        'languageChecker',
        'navigator',
        'querystring',
        'htmlTag',
        'subdomain',
      ],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    whitelist: languages,
  });

export default i18n;
