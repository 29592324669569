import { defineAbility, AbilityBuilder, Ability } from '@casl/ability';

const defaultRules = [{ action: 'create', resource: 'account' }];
const ability = defineAbility((can) => {
  defaultRules.forEach((permission) => {
    return can(permission.action, permission.resource);
  });
});

const defineAbilityFor = (
  ability: Ability,
  user: Viewer | undefined | null
) => {
  const { can, rules } = new AbilityBuilder(Ability);

  if (user?.permissions) {
    user?.permissions.forEach((permission: Permission) => {
      return can(permission.action, permission.resource);
    });
  } else {
    defaultRules.forEach((permission) => {
      return can(permission.action, permission.resource);
    });
  }
  ability.update(rules);
};
export { ability, defineAbilityFor };
