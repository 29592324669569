import { useCallback, useRef, useState } from 'react';
import { useVideoContext } from '@src/context/VideoContext';
import { LocalVideoStream, LocalStream } from '@src/video';

export default function useLocalVideoToggle() {
  const {
    meeting: { localParticipant },
    localStreams,
    getLocalVideoStream,
    removeLocalVideoStream,
    onError,
  } = useVideoContext();

  const videoStream = localStreams.find((track) =>
    track.name.includes('camera')
  ) as LocalVideoStream;

  const [isPublishing, setIspublishing] = useState(false);
  const previousDeviceIdRef = useRef<string>();

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoStream) {
        previousDeviceIdRef.current = videoStream.mediaStreamTrack.getSettings().deviceId;
        const localStreamPublication = localParticipant?.unpublishTrack(
          videoStream
        );
        localParticipant?.emit('trackUnpublished', localStreamPublication);
        removeLocalVideoStream();
      } else {
        setIspublishing(true);

        getLocalVideoStream({
          deviceId: { exact: previousDeviceIdRef.current },
        })
          .then((track: LocalStream) =>
            localParticipant?.publishTrack(track, { priority: 'low' })
          )
          .catch(onError)
          .finally(() => setIspublishing(false));
      }
    }
  }, [
    videoStream,
    localParticipant,
    getLocalVideoStream,
    isPublishing,
    onError,
    removeLocalVideoStream,
  ]);

  return [!!videoStream, toggleVideoEnabled] as const;
}
