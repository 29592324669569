const usa = [
  {
    gender: 'female',
    name: {
      title: 'Ms',
      first: 'Ann',
      last: 'Grahambell Long',
    },
    areas_of_practice: [1, 3],
    location: {
      street: {
        number: 8718,
        name: 'Samaritan Dr',
      },
      city: 'Wilmington',
      state: 'Tennessee',
      country: 'United States',
      postcode: 62651,
      coordinates: {
        latitude: '-21.2715',
        longitude: '164.6314',
      },
      timezone: {
        offset: '+3:00',
        description: 'Baghdad, Riyadh, Moscow, St. Petersburg',
      },
    },
    email: 'ann.bell@example.com',
    id: 1,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/26.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/26.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/26.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'female',
    name: {
      title: 'Mrs',
      first: 'Denise',
      last: 'Hunt',
    },
    areas_of_practice: [1, 3],
    location: {
      street: {
        number: 9847,
        name: 'Paddock Way',
      },
      city: 'Joliet',
      state: 'Louisiana',
      country: 'United States',
      postcode: 19525,
      coordinates: {
        latitude: '-39.2311',
        longitude: '51.9481',
      },
      timezone: {
        offset: '+5:30',
        description: 'Bombay, Calcutta, Madras, New Delhi',
      },
    },
    email: 'denise.hunt@example.com',
    id: 2,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/64.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/64.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/64.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Benjamin',
      last: 'Powell',
    },
    areas_of_practice: [3, 5],
    location: {
      street: {
        number: 1835,
        name: 'Samaritan Dr',
      },
      city: 'Denver',
      state: 'Alaska',
      country: 'United States',
      postcode: 72630,
      coordinates: {
        latitude: '15.4331',
        longitude: '15.4075',
      },
      timezone: {
        offset: '0:00',
        description: 'Western Europe Time, London, Lisbon, Casablanca',
      },
    },
    email: 'benjamin.powell@example.com',
    id: 3,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/89.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/89.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/89.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'female',
    name: {
      title: 'Ms',
      first: 'Nicole Marie',
      last: 'Phillips Jameson',
    },
    areas_of_practice: [3, 5],
    location: {
      street: {
        number: 7444,
        name: 'Wheeler Ridge Dr',
      },
      city: 'Modesto',
      state: 'Illinois',
      country: 'United States',
      postcode: 46824,
      coordinates: {
        latitude: '83.9871',
        longitude: '67.0106',
      },
      timezone: {
        offset: '-9:00',
        description: 'Alaska',
      },
    },
    email: 'nicole.castro@example.com',
    id: 4,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/53.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/53.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/53.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Ronnie',
      last: 'Martin',
    },
    areas_of_practice: [3, 7],
    location: {
      street: {
        number: 2712,
        name: 'Lone Wolf Trail',
      },
      city: 'Tempe',
      state: 'Massachusetts',
      country: 'United States',
      postcode: 41167,
      coordinates: {
        latitude: '1.1038',
        longitude: '-149.5806',
      },
      timezone: {
        offset: '+2:00',
        description: 'Kaliningrad, South Africa',
      },
    },
    email: 'ronnie.martin@example.com',
    id: 5,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/46.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/46.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/46.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Stephen',
      last: 'Bowman',
    },
    areas_of_practice: [3, 7, 6],
    location: {
      street: {
        number: 8774,
        name: 'Northaven Rd',
      },
      city: 'North Charleston',
      state: 'Illinois',
      country: 'United States',
      postcode: 50372,
      coordinates: {
        latitude: '81.2464',
        longitude: '58.7727',
      },
      timezone: {
        offset: '+3:00',
        description: 'Baghdad, Riyadh, Moscow, St. Petersburg',
      },
    },
    email: 'stephen.bowman@example.com',
    id: 6,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/50.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/50.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/50.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Chris',
      last: 'Reid',
    },
    areas_of_practice: [1, 7],
    location: {
      street: {
        number: 4141,
        name: 'E North St',
      },
      city: 'Aubrey',
      state: 'New Jersey',
      country: 'United States',
      postcode: 60753,
      coordinates: {
        latitude: '31.5449',
        longitude: '4.5817',
      },
      timezone: {
        offset: '-5:00',
        description: 'Eastern Time (US & Canada), Bogota, Lima',
      },
    },
    email: 'chris.reid@example.com',
    id: 7,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/27.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/27.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/27.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'female',
    name: {
      title: 'Miss',
      first: 'Pearl',
      last: 'Jenkins',
    },
    areas_of_practice: [4, 6],
    location: {
      street: {
        number: 4745,
        name: 'Samaritan Dr',
      },
      city: 'Victorville',
      state: 'Montana',
      country: 'United States',
      postcode: 75947,
      coordinates: {
        latitude: '-79.5383',
        longitude: '-80.4276',
      },
      timezone: {
        offset: '+11:00',
        description: 'Magadan, Solomon Islands, New Caledonia',
      },
    },
    email: 'pearl.jenkins@example.com',
    id: 8,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/47.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/47.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/47.jpg',
    },
    nat: 'US',
  },
  {
    gender: 'female',
    name: {
      title: 'Mrs',
      first: 'Loretta',
      last: 'Boyd',
    },
    areas_of_practice: [9, 2],
    location: {
      street: {
        number: 4972,
        name: 'Hogan St',
      },
      city: 'Virginia Beach',
      state: 'Connecticut',
      country: 'United States',
      postcode: 28104,
      coordinates: {
        latitude: '-15.8827',
        longitude: '-12.8900',
      },
      timezone: {
        offset: '-10:00',
        description: 'Hawaii',
      },
    },
    email: 'loretta.boyd@example.com',
    id: 9,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/13.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/13.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/13.jpg',
    },
    nat: 'US',
  },
];
const brazil = [
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Rubim',
      last: 'Nunes',
    },
    areas_of_practice: [9, 2],
    location: {
      street: {
        number: 1046,
        name: 'Rua Mato Grosso ',
      },
      city: 'São Bernardo do Campo',
      state: 'Pará',
      country: 'Brazil',
      postcode: 25392,
      coordinates: {
        latitude: '-81.3390',
        longitude: '-56.1665',
      },
      timezone: {
        offset: '+5:45',
        description: 'Kathmandu',
      },
    },
    email: 'rubim.nunes@example.com',
    id: 10,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/41.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/41.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/41.jpg',
    },
    nat: 'BR',
  },
  {
    gender: 'female',
    name: {
      title: 'Mrs',
      first: 'Rosemary',
      last: 'Gonçalves',
    },
    areas_of_practice: [10, 3, 4],
    location: {
      street: {
        number: 8829,
        name: 'Beco dos Namorados',
      },
      city: 'Francisco Morato',
      state: 'Rio Grande do Sul',
      country: 'Brazil',
      postcode: 36468,
      coordinates: {
        latitude: '8.3588',
        longitude: '-23.9493',
      },
      timezone: {
        offset: '+11:00',
        description: 'Magadan, Solomon Islands, New Caledonia',
      },
    },
    email: 'rosemary.goncalves@example.com',
    id: 11,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/74.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/74.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/74.jpg',
    },
    nat: 'BR',
  },
  {
    gender: 'female',
    name: {
      title: 'Miss',
      first: 'Josini',
      last: 'Gonçalves',
    },
    areas_of_practice: [10, 3, 4],
    location: {
      street: {
        number: 6740,
        name: 'Rua Vinte E Dois ',
      },
      city: 'Tatuí',
      state: 'Roraima',
      country: 'Brazil',
      postcode: 84108,
      coordinates: {
        latitude: '-73.3751',
        longitude: '-135.6322',
      },
      timezone: {
        offset: '+6:00',
        description: 'Almaty, Dhaka, Colombo',
      },
    },
    email: 'josini.goncalves@example.com',
    id: 12,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/56.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/56.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/56.jpg',
    },
    nat: 'BR',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Marcial Jose',
      last: 'Campos Herrera',
    },
    areas_of_practice: [2, 5, 7],
    location: {
      street: {
        number: 4132,
        name: 'Rua Castro Alves ',
      },
      city: 'Araguaína',
      state: 'Tocantins',
      country: 'Brazil',
      postcode: 13248,
      coordinates: {
        latitude: '71.9443',
        longitude: '118.3690',
      },
      timezone: {
        offset: '+7:00',
        description: 'Bangkok, Hanoi, Jakarta',
      },
    },
    email: 'marcial.campos@example.com',
    id: 13,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/63.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/63.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/63.jpg',
    },
    nat: 'BR',
  },
  {
    gender: 'female',
    name: {
      title: 'Ms',
      first: 'Laroê',
      last: 'Pinto',
    },
    areas_of_practice: [1, 2, 6],
    location: {
      street: {
        number: 1742,
        name: 'Rua Amazonas ',
      },
      city: 'Divinópolis',
      state: 'Paraíba',
      country: 'Brazil',
      postcode: 67135,
      coordinates: {
        latitude: '86.0854',
        longitude: '-33.9398',
      },
      timezone: {
        offset: '+6:00',
        description: 'Almaty, Dhaka, Colombo',
      },
    },
    email: 'laroe.pinto@example.com',
    id: 14,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/85.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/85.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/85.jpg',
    },
    nat: 'BR',
  },
  {
    gender: 'female',
    name: {
      title: 'Mrs',
      first: 'Catiúcia',
      last: 'Moraes',
    },
    areas_of_practice: [1, 2, 6],
    location: {
      street: {
        number: 2641,
        name: 'Rua Rui Barbosa ',
      },
      city: 'Rio Branco',
      state: 'Acre',
      country: 'Brazil',
      postcode: 71414,
      coordinates: {
        latitude: '43.5309',
        longitude: '48.8759',
      },
      timezone: {
        offset: '-3:30',
        description: 'Newfoundland',
      },
    },
    email: 'catiucia.moraes@example.com',
    id: 15,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/21.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/21.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/21.jpg',
    },
    nat: 'BR',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Caíco',
      last: 'Martins',
    },
    areas_of_practice: [1, 2, 6],
    location: {
      street: {
        number: 5909,
        name: 'Travessa dos Martírios',
      },
      city: 'Itapetininga',
      state: 'São Paulo',
      country: 'Brazil',
      postcode: 79937,
      coordinates: {
        latitude: '14.6387',
        longitude: '-140.1815',
      },
      timezone: {
        offset: '+9:00',
        description: 'Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
      },
    },
    email: 'caico.martins@example.com',
    id: 16,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/42.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/42.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/42.jpg',
    },
    nat: 'BR',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Rudolfo',
      last: 'Lopes',
    },
    areas_of_practice: [9, 4, 5],
    location: {
      street: {
        number: 2679,
        name: 'Rua Santos Dumont ',
      },
      city: 'Coronel Fabriciano',
      state: 'Ceará',
      country: 'Brazil',
      postcode: 39318,
      coordinates: {
        latitude: '20.4537',
        longitude: '16.5518',
      },
      timezone: {
        offset: '0:00',
        description: 'Western Europe Time, London, Lisbon, Casablanca',
      },
    },
    email: 'rudolfo.lopes@example.com',
    id: 17,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/89.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/89.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/89.jpg',
    },
    nat: 'BR',
  },
];
const spain = [
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Ivan',
      last: 'Garrido',
    },
    areas_of_practice: [10, 4, 9],
    location: {
      street: {
        number: 2212,
        name: 'Paseo de Extremadura',
      },
      city: 'Valladolid',
      state: 'Navarra',
      country: 'Spain',
      postcode: 37921,
      coordinates: {
        latitude: '-62.9241',
        longitude: '-101.1535',
      },
      timezone: {
        offset: '-2:00',
        description: 'Mid-Atlantic',
      },
    },
    email: 'ivan.garrido@example.com',
    id: 18,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/67.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/67.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/67.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Salvador',
      last: 'Santiago',
    },
    areas_of_practice: [10, 4, 9],
    location: {
      street: {
        number: 1188,
        name: 'Calle de Pedro Bosch',
      },
      city: 'Orense',
      state: 'Ceuta',
      country: 'Spain',
      postcode: 64023,
      coordinates: {
        latitude: '-46.9766',
        longitude: '167.7556',
      },
      timezone: {
        offset: '-12:00',
        description: 'Eniwetok, Kwajalein',
      },
    },
    email: 'salvador.santiago@example.com',
    id: 19,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/85.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/85.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/85.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'female',
    name: {
      title: 'Mrs',
      first: 'Noelia',
      last: 'Hidalgo',
    },
    areas_of_practice: [10, 4, 9],
    location: {
      street: {
        number: 2677,
        name: 'Calle del Prado',
      },
      city: 'Ciudad Real',
      state: 'Comunidad de Madrid',
      country: 'Spain',
      postcode: 58758,
      coordinates: {
        latitude: '-33.5481',
        longitude: '-13.2060',
      },
      timezone: {
        offset: '-9:00',
        description: 'Alaska',
      },
    },
    email: 'noelia.hidalgo@example.com',
    id: 20,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/44.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/44.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/44.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Joaquin',
      last: 'Ferrer',
    },
    areas_of_practice: [10, 4, 9],
    location: {
      street: {
        number: 191,
        name: 'Calle de Alberto Aguilera',
      },
      city: 'Lugo',
      state: 'Navarra',
      country: 'Spain',
      postcode: 43106,
      coordinates: {
        latitude: '-19.8818',
        longitude: '-111.8102',
      },
      timezone: {
        offset: '-12:00',
        description: 'Eniwetok, Kwajalein',
      },
    },
    email: 'joaquin.ferrer@example.com',
    id: 21,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/41.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/41.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/41.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'female',
    name: {
      title: 'Ms',
      first: 'Irene',
      last: 'Marin',
    },
    areas_of_practice: [9, 4, 2],
    location: {
      street: {
        number: 5055,
        name: 'Calle de La Almudena',
      },
      city: 'Valencia',
      state: 'Cantabria',
      country: 'Spain',
      postcode: 69949,
      coordinates: {
        latitude: '36.4661',
        longitude: '148.4373',
      },
      timezone: {
        offset: '-5:00',
        description: 'Eastern Time (US & Canada), Bogota, Lima',
      },
    },
    email: 'irene.marin@example.com',
    id: 22,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/89.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/89.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/89.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Eduardo',
      last: 'Gonzalez',
    },
    areas_of_practice: [1, 4, 6],
    location: {
      street: {
        number: 2886,
        name: 'Calle de Atocha',
      },
      city: 'Jerez de la Frontera',
      state: 'Aragón',
      country: 'Spain',
      postcode: 73330,
      coordinates: {
        latitude: '-25.4184',
        longitude: '-76.2954',
      },
      timezone: {
        offset: '-3:00',
        description: 'Brazil, Buenos Aires, Georgetown',
      },
    },
    email: 'eduardo.gonzalez@example.com',
    id: 23,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/18.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/18.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/18.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Esteban',
      last: 'Rodriguez',
    },
    areas_of_practice: [2, 5, 3],
    location: {
      street: {
        number: 4926,
        name: 'Calle de Ferraz',
      },
      city: 'Ferrol',
      state: 'Región de Murcia',
      country: 'Spain',
      postcode: 92870,
      coordinates: {
        latitude: '66.2362',
        longitude: '-126.4859',
      },
      timezone: {
        offset: '-8:00',
        description: 'Pacific Time (US & Canada)',
      },
    },
    email: 'esteban.rodriguez@example.com',
    id: 24,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/72.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/72.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/72.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'female',
    name: {
      title: 'Miss',
      first: 'Gloria',
      last: 'Diez',
    },
    areas_of_practice: [7, 3, 6],
    location: {
      street: {
        number: 2292,
        name: 'Calle de Arganzuela',
      },
      city: 'Hospitalet de Llobregat',
      state: 'Región de Murcia',
      country: 'Spain',
      postcode: 21418,
      coordinates: {
        latitude: '-0.6772',
        longitude: '-23.1511',
      },
      timezone: {
        offset: '+3:30',
        description: 'Tehran',
      },
    },
    email: 'gloria.diez@example.com',
    id: 25,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/59.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/59.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/59.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'female',
    name: {
      title: 'Ms',
      first: 'Margarita',
      last: 'Vargas',
    },
    areas_of_practice: [7, 3, 6],
    location: {
      street: {
        number: 9123,
        name: 'Calle de Bravo Murillo',
      },
      city: 'Mérida',
      state: 'Comunidad de Madrid',
      country: 'Spain',
      postcode: 25925,
      coordinates: {
        latitude: '73.2663',
        longitude: '-147.8180',
      },
      timezone: {
        offset: '-11:00',
        description: 'Midway Island, Samoa',
      },
    },
    email: 'margarita.vargas@example.com',
    id: 26,
    picture: {
      large: 'https://randomuser.me/api/portraits/women/21.jpg',
      medium: 'https://randomuser.me/api/portraits/med/women/21.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/women/21.jpg',
    },
    nat: 'ES',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Gonzalo',
      last: 'Romero',
    },
    areas_of_practice: [4],
    location: {
      street: {
        number: 9038,
        name: 'Avenida de Andalucía',
      },
      city: 'Zaragoza',
      state: 'País Vasco',
      country: 'Spain',
      postcode: 54145,
      coordinates: {
        latitude: '69.8876',
        longitude: '-88.1788',
      },
      timezone: {
        offset: '+5:30',
        description: 'Bombay, Calcutta, Madras, New Delhi',
      },
    },
    email: 'gonzalo.romero@example.com',
    id: 27,
    picture: {
      large: 'https://randomuser.me/api/portraits/men/79.jpg',
      medium: 'https://randomuser.me/api/portraits/med/men/79.jpg',
      thumbnail: 'https://randomuser.me/api/portraits/thumb/men/79.jpg',
    },
    nat: 'ES',
  },
];
const chile = [
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Pablo',
      last: 'del Campo',
    },
    lawfirm: 'Vis Abogados',
    areas_of_practice: [7, 8, 9],
    location: {
      street: {
        number: 2687,
        name: 'Av. Andrés Bello, piso 23,',
      },
      city: 'Las Condes',
      state: 'Santiago',
      country: 'Chile',
      postcode: 93236,
      coordinates: {
        latitude: '59.0912',
        longitude: '-132.9654',
      },
      timezone: {
        offset: '-3:00',
        description: 'Brazil, Buenos Aires, Georgetown',
      },
    },
    email: 'pdelcampo@siv.cl',
    id: 28,
    picture: {
      large: 'https://siv.cl/wp-content/uploads/2019/10/pablo-del-campo.jpg',
      medium: 'https://siv.cl/wp-content/uploads/2019/10/pablo-del-campo.jpg',
      thumbnail:
        'https://siv.cl/wp-content/uploads/2019/10/pablo-del-campo.jpg',
    },
    nat: 'CL',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Rodrigo',
      last: 'Novoa',
    },
    lawfirm: 'Vis Abogados',
    areas_of_practice: [6, 8, 9],
    location: {
      street: {
        number: 2687,
        name: 'Av. Andrés Bello, piso 23,',
      },
      city: 'Las Condes',
      state: 'Santiago',
      country: 'Chile',
      postcode: 93236,
      coordinates: {
        latitude: '59.0912',
        longitude: '-132.9654',
      },
      timezone: {
        offset: '-3:00',
        description: 'Brazil, Buenos Aires, Georgetown',
      },
    },
    email: 'pdelcampo@siv.cl',
    id: 29,
    picture: {
      large: 'https://siv.cl/wp-content/uploads/2019/10/rodrigo-novoa.jpg',
      medium: 'https://siv.cl/wp-content/uploads/2019/10/rodrigo-novoa.jpg',
      thumbnail: 'https://siv.cl/wp-content/uploads/2019/10/rodrigo-novoa.jpg',
    },
    nat: 'CL',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Juan Pablo',
      last: 'Prieto',
    },
    lawfirm: 'Vis Abogados',
    areas_of_practice: [6, 3, 9],
    location: {
      street: {
        number: 2687,
        name: 'Av. Andrés Bello, piso 23,',
      },
      city: 'Las Condes',
      state: 'Santiago',
      country: 'Chile',
      postcode: 93236,
      coordinates: {
        latitude: '59.0912',
        longitude: '-132.9654',
      },
      timezone: {
        offset: '-3:00',
        description: 'Brazil, Buenos Aires, Georgetown',
      },
    },
    email: 'pdelcampo@siv.cl',
    id: 30,
    picture: {
      large: 'https://siv.cl/wp-content/uploads/2019/10/juan-pablo.jpg',
      medium: 'https://siv.cl/wp-content/uploads/2019/10/juan-pablo.jpg',
      thumbnail: 'https://siv.cl/wp-content/uploads/2019/10/juan-pablo.jpg',
    },
    nat: 'CL',
  },
];
const costa_rica = [
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Alex',
      last: 'Thompson',
    },
    lawfirm: 'Cresco Legal',
    areas_of_practice: [6, 9, 2],
    location: {
      street: {
        number: 2687,
        name: 'Av. Andrés Bello, piso 23,',
      },
      city: 'Santa Ana',
      state: 'San Jose',
      country: 'Costa Rica',
      postcode: 10109,
      coordinates: {
        latitude: '59.0912',
        longitude: '-132.9654',
      },
      timezone: {
        offset: '-3:00',
        description: 'Brazil, Buenos Aires, Georgetown',
      },
    },
    email: 'pdelcampo@siv.cl',
    id: 31,
    picture: {
      medium:
        'https://crescolegal.com/wp-content/uploads/2017/08/alex-thompson-2.jpg',
    },
    nat: 'CR',
  },
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Adrian',
      last: 'Obando',
    },
    lawfirm: 'Cresco Legal',
    areas_of_practice: [6, 9, 2],
    location: {
      street: {
        number: 2687,
        name: 'Av. Andrés Bello, piso 23,',
      },
      city: 'Santa Ana',
      state: 'San Jose',
      country: 'Costa Rica',
      postcode: 10109,
      coordinates: {
        latitude: '59.0912',
        longitude: '-132.9654',
      },
      timezone: {
        offset: '-3:00',
        description: 'Brazil, Buenos Aires, Georgetown',
      },
    },
    email: 'pdelcampo@siv.cl',
    id: 32,
    picture: {
      medium:
        'https://crescolegal.com/wp-content/uploads/2017/08/Adrian-Obando.jpg',
    },
    nat: 'CR',
  },
];
const peru = [
  {
    gender: 'male',
    name: {
      title: 'Mr',
      first: 'Luis Fernando',
      last: 'Martinot',
    },
    lawfirm: 'Martinot Abogados',
    areas_of_practice: [6, 9, 2],
    location: {
      street: {
        number: 123,
        name: 'Av. Canaval y Moreyra 480, Piso 10-B',
      },
      city: 'San Isidro',
      state: 'Lima',
      country: 'Peru',
      postcode: 10109,
      coordinates: {
        latitude: '59.0912',
        longitude: '-132.9654',
      },
      timezone: {
        offset: '-3:00',
        description: 'Brazil, Buenos Aires, Georgetown',
      },
    },
    email: 'pdelcampo@siv.cl',
    id: 33,
    picture: {
      medium:
        'http://martinotabogados.pe/public/img/el-equipo/Fernando-MartinotByN.png',
    },
    nat: 'PE',
  },
];
const lawyerData = [
  ...usa,
  ...spain,
  ...costa_rica,
  ...chile,
  ...brazil,
  ...peru,
];
export default lawyerData;
