import React from 'react';
import { Box, Text, VStack, Stack, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCreateLawyerApplicationMutation } from '@src/apollo/hooks';
import { LawyerApplicationConfirmationProps } from '@molecules/ApplicationForm/LawyerApplicationType';
import { useToast } from '@src/hooks';
import { formatMoney } from '@src/utils';
export enum ApplicationTypes {
  Affiliate = 'AFFILIATE',
  Lawyer = 'LAWYER',
}

const ConfirmationPage = ({
  applicationData,
  onApplicationCreated,
}: LawyerApplicationConfirmationProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [
    createLawyerApplication,
    { loading },
  ] = useCreateLawyerApplicationMutation();

  const createNewLawyerApplication = async () => {
    const {
      firstName,
      lastName,
      title,
      email,
      phone,
      password,
      countryId,
    } = applicationData.input;

    if (!applicationData.paymentMethod.id) {
      return null;
    }
    const { data } = await createLawyerApplication({
      variables: {
        input: {
          firstName,
          lastName,
          title,
          email,
          phone,
          password: password || '',
          countryCode: countryId,
        },
        paymentMethod: applicationData.paymentMethod.id,
      },
    });

    if (data?.createLawyerApplication?.successful) {
      const { createLawyerApplication } = data;
      onApplicationCreated(
        createLawyerApplication as ApplicationCreationPayload
      );
    } else {
      data?.createLawyerApplication?.messages?.map((message) => {
        return toast.warning({
          title: 'Error',
          description: message?.message,
          isClosable: true,
        });
      });
    }
    window.scrollTo(0, 0);
  };

  return (
    <Box>
      <VStack spacing={4} alignItems="flex-start" w="90%" my={8} mx="auto">
        <Text>{t('lawyer_application.confirmation_description')}</Text>
        <Stack direction={['column', 'row']} w="full">
          <Box d="flex" flexDir="column" w="50%">
            <Text textStyle="secondary" fontSize="sm">
              {t('fields.firstName.label')}:{' '}
            </Text>
            <Text textStyle="strongBlue" fontSize="lg">
              {applicationData.input.firstName}
            </Text>
          </Box>
          <Box>
            <Text textStyle="secondary" fontSize="sm">
              {t('fields.lastName.label')}:{' '}
            </Text>
            <Text textStyle="strongBlue" fontSize="lg">
              {applicationData.input.lastName}
            </Text>
          </Box>
        </Stack>

        <Stack direction={['column', 'row']} w="full">
          <Box d="flex" flexDir="column" w="50%">
            <Text textStyle="secondary" fontSize="sm">
              {t('fields.email.label')}:
            </Text>
            <Text textStyle="strongBlue" fontSize="lg">
              {applicationData.input.email}
            </Text>
          </Box>
          <Box>
            <Text textStyle="secondary" fontSize="sm">
              {t('fields.phone.label')}:{' '}
            </Text>
            <Text textStyle="strongBlue" fontSize="lg">
              {applicationData.input.phone}
            </Text>
          </Box>
        </Stack>

        <Stack direction={['column', 'row']} w="full">
          <Box d="flex" flexDir="column" w="50%">
            <Text textStyle="secondary" fontSize="sm">
              {t('fields.title.label')}:{' '}
            </Text>
            <Text textStyle="strongBlue" fontSize="lg">
              {t(`fields.title_options.${applicationData.input.title}`)}
            </Text>
          </Box>
          <Box>
            <Text textStyle="secondary" fontSize="sm">
              {t('fields.country.label')}:{' '}
            </Text>
            <Text textStyle="strongBlue" fontSize="lg">
              {applicationData.input.countryId}
            </Text>
          </Box>
        </Stack>

        <Stack alignItems="flex-end" width="100%" py={8}>
          <Text>{t('lawyer_application.terms_agreed')}</Text>
          <Text textStyle="strongYellow">
            {t('lawyer_application.you_will_be_charged')}
          </Text>
          <Text as="strong" fontSize="3xl">
            USD {formatMoney(10000)}
          </Text>
          <Box d="flex" flexDir="row">
            <Text mr="1">{t('meetings.from_card_ending_with')}</Text>
            <Text as="strong"> {applicationData.paymentMethod.cardLast4}</Text>
          </Box>
        </Stack>
        <Stack alignItems="flex-end" width="100%">
          <Button
            variant="solid"
            colorScheme="blue"
            onClick={createNewLawyerApplication}
            isLoading={loading}
          >
            {t('lawyer_application.pay_create_application')}
          </Button>
        </Stack>
      </VStack>
    </Box>
  );
};

export default ConfirmationPage;
