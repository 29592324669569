import React from 'react';
import { HStack, Stack } from '@chakra-ui/layout';
import { FormControl, InputGroup, Text } from '@chakra-ui/react';
import { SelectSingle, CheckboxArray } from '@src/components/atoms/Fields';
import { generateTimeSlots } from '@src/utils';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
export declare type StringOrNumber = string | number;

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
const timeSlots = generateTimeSlots({
  start: 0,
  end: 24,
  interval: 30,
});

const SimpleAvailability = ({ isDisabled }: { isDisabled: boolean }) => {
  const { t } = useTranslation();
  return (
    <FormControl rounded="xl" ml="1px" mt="1px" w="100%">
      <InputGroup w="100%">
        <Stack w="100%">
          <Text fontSize="sm" fontWeight="800" ml={2} color={'blue.600'}>
            {t('availability.lawyer_availability')}
          </Text>

          <HStack mt={12} align="center" spacing={{ base: 3, sm: 6 }}>
            <SelectSingle<Date>
              name="workingHours.from"
              label={t('fields.from.label')}
              options={timeSlots}
              isDisabled={isDisabled}
              getOptionKey={(item) => (item ? format(item, 'HH:mm') : '')}
              getOptionLabel={(item) => (item ? format(item, 'HH:mm') : '')}
              hideError={true}
              isRequired
            />
            <SelectSingle<Date>
              isDisabled={isDisabled}
              name="workingHours.to"
              label={t('fields.to.label')}
              options={timeSlots}
              getOptionKey={(item) => (item ? format(item, 'HH:mm') : '')}
              getOptionLabel={(item) => (item ? format(item, 'HH:mm') : '')}
              hideError={false}
              isRequired
            />
          </HStack>
          <HStack>
            <Stack direction={{ base: 'column', lg: 'row' }} spacing={5} mt={2}>
              {days.map((day) => (
                <CheckboxArray
                  isDisabled={isDisabled}
                  key={day}
                  name="workingHours.days"
                  value={day}
                >
                  {t(`dates.day_names.${day}`)}
                </CheckboxArray>
              ))}
            </Stack>
          </HStack>
        </Stack>
      </InputGroup>
    </FormControl>
  );
};

export default SimpleAvailability;
