import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useViewerQuery } from '@src/apollo/hooks';
import MobileMenu from '@src/components/molecules/MobileMenu';
import UserNavigation from '@src/components/molecules/UserNavigation';

const Header: FC = () => {
  const { data } = useViewerQuery();

  return (
    <Box backgroundColor="transparent" pt={4}>
      <Box
        maxW="6xl"
        mx="auto"
        px={{ base: 4, sm: 6, md: 8 }}
        d="flex"
        justifyContent="space-between"
      >
        <MobileMenu
          theme={{
            name: 'skyBlue',
            backgroundColor: 'teal.50',
            color: 'gray.900',
            colorHover: 'gray.500',
            colorScheme: 'blue',
            buttonColor: 'blue.500',
          }}
          viewer={data?.viewer}
          showLogo={true}
        />
        <UserNavigation
          viewer={data?.viewer}
          theme={{ colorScheme: 'white' }}
        />
      </Box>
    </Box>
  );
};

export default Header;
