import React from 'react';
import { Box, Button, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TextureAbst } from '@src/icons/abst.svg';
import { ReactComponent as TextureGroup3 } from '@src/icons/texture-group3.svg';

export const HowItWorkSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      pos="relative"
      backgroundColor="teal.50"
      d="flex"
      justifyContent="center"
      alignItems="center"
      h="full"
      zIndex={1}
      className="clip-image-top-bottom-left"
    >
      <Icon
        as={TextureAbst}
        position="absolute"
        left={0}
        transform="translateX(-50%)"
        h={{ base: 24, md: 40, lg: 48 }}
        w={{ base: 24, md: 40, lg: 48 }}
      />
      <Box
        w={{ base: 'full', md: '60%', lg: '50%', xl: '36%' }}
        py={{ base: 16, sm: 24, lg: 32 }}
        pl={{ base: 16, md: 0 }}
      >
        <Heading
          as="h2"
          fontSize={{ base: '2xl', md: '4xl' }}
          fontWeight="bold"
          color="black"
        >
          {t('landing.how_it_works_section.how_does')}{' '}
          <Box as="strong" color="secondary">
            {t('landing.how_it_works_section.it_work')}
          </Box>
          <Icon
            as={TextureGroup3}
            ml={4}
            display={{ base: 'none', sm: 'inline-block' }}
            h={{ md: 16, lg: 'auto' }}
            w={{ md: 16, lg: 'auto' }}
          />
        </Heading>
        <Box pr={12} mt={6}>
          <VStack spacing={4}>
            <Text color="gray.600" fontSize="base" lineHeight={6}>
              {t('landing.how_it_works_section.p1')}
            </Text>
            <Text color="gray.600" fontSize="base" lineHeight={6}>
              {t('landing.how_it_works_section.p2')}
            </Text>
            <Text w="full" color="gray.600" fontSize="base" lineHeight={6}>
              {t('landing.how_it_works_section.p3')}
            </Text>
          </VStack>
        </Box>
        <Button
          colorScheme="blue"
          variant="rooLarge"
          size="lg"
          mt={8}
          fontSize="sm"
          fontWeight="bold"
          px={12}
          textTransform="uppercase"
          onClick={() => window.scrollTo(0, 10)}
        >
          {t('common.try_it')}
        </Button>
      </Box>
    </Box>
  );
};

export default HowItWorkSection;
