import React from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react';
import { useField, UseFieldConfig } from 'react-final-form';
import { ShowErrorFunc, showErrorOnChange } from '@src/utils';

// eslint-disable-next-line
export interface CheckboxArrayProps<FieldValue = any>
  extends ChakraCheckboxProps {
  name: string;
  showError?: ShowErrorFunc;
  fieldProps?: UseFieldConfig<FieldValue>;
}

export const CheckboxArray = ({
  name,
  value,
  fieldProps,
  children,
  showError = showErrorOnChange,
  ...props
}: CheckboxArrayProps) => {
  const {
    input: { checked, ...input },
    meta,
  } = useField(name, { type: 'checkbox', value, ...fieldProps });
  const isError = showError({ meta });
  return (
    <ChakraCheckbox
      {...props}
      {...input}
      isChecked={checked}
      isInvalid={isError}
    >
      {children}
    </ChakraCheckbox>
  );
};
