import React from 'react';
import {
  chakra,
  ChakraProps,
  useStyles,
  HTMLChakraProps,
} from '@chakra-ui/react';
import { GetItemPropsOptions } from 'downshift';
import { dataAttr, runIfFn } from '@chakra-ui/utils';
import { useSelect } from './useDownshift';

// eslint-disable-next-line
export type SelectOptionProps<Item = any> = Omit<
  GetItemPropsOptions<Item>,
  'item' | 'disabled' | 'value'
> &
  Omit<ChakraProps, 'value'> & {
    value: GetItemPropsOptions<Item>['item'];
    isDisabled?: boolean;
    children: MaybeRenderProp<{
      isSelected?: boolean;
      isActive?: boolean;
    }>;
  };

export function SelectOption({
  children,
  value,
  index,
  isDisabled,
  ...props
}: SelectOptionProps) {
  const { getItemProps, selectedItem, highlightedIndex } = useSelect();
  const styles = useStyles();
  const isSelected = selectedItem === value;
  const isActive = highlightedIndex === index;
  return (
    <chakra.li
      bg={isActive ? 'gray.50' : 'white'}
      data-disabled={dataAttr(isDisabled)}
      {...getItemProps({
        item: value,
        index,
      })}
      aria-selected={props.isSelected ? 'true' : `${isSelected}`}
      __css={styles.option}
      {...props}
    >
      {runIfFn(children, {
        isSelected,
        isActive,
      })}
    </chakra.li>
  );
}

export type SelectMenuListProps = HTMLChakraProps<'ul'>;
export function SelectMenuList(props: SelectMenuListProps) {
  const { isOpen } = useSelect();
  const styles = useStyles();
  if (!isOpen) return null;
  return <chakra.ul __css={styles.list} {...props} />;
}

export type SelectMenuProps = HTMLChakraProps<'div'>;
export function SelectMenu(props: SelectMenuProps) {
  const { getMenuProps } = useSelect();
  const styles = useStyles();
  return <chakra.div __css={styles.menu} {...getMenuProps()} {...props} />;
}
