import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Avatar, Text, useBreakpointValue } from '@chakra-ui/react';

export interface LawyerNameCardProps {
  lawyer: LawyerFragment;
}

const LawyerNameCard = ({ lawyer }: LawyerNameCardProps) => {
  const { t } = useTranslation();
  const size = useBreakpointValue({ base: 'xl', lg: 'lg' });
  return (
    <Box d="flex" alignItems="center">
      <Avatar
        size={size}
        mr={4}
        name={`${lawyer.firstName} ${lawyer.lastName}`}
        src={lawyer.headshot as string}
      />
      <Box>
        <Text fontSize="xl" fontWeight="300" color="black">
          {`${lawyer.firstName} ${lawyer.lastName}`}
        </Text>
        <Text fontSize="md" color="gray.700" fontWeight="300">
          {t(`fields.title_options.${lawyer.title}`)}
        </Text>
      </Box>
    </Box>
  );
};

export default LawyerNameCard;
