import React, { FC } from 'react';
import {
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useViewerQuery } from '@src/apollo/hooks';
import { Can } from '@src/context';
import SearchForm from '@src/components/molecules/SearchForm';
import NextMeeting from '@src/components/molecules/NextMeeting';
import SidebarLayout from '@src/components/organisms/RooLayout/SidebarLayout';
import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';
import { ReactComponent as Lamp } from '@src/icons/lamp.svg';
import { ReactComponent as Shadow } from '@src/icons/shadow.svg';
import HomeCard from '@components/molecules/HomeCard';
import Link from '@components/atoms/Link';
import { useNextMeeting } from '@src/hooks';

const HomePage: FC = () => {
  const { t } = useTranslation('home');
  const { data } = useViewerQuery();
  const nextMeeting = useNextMeeting();
  return (
    <SidebarLayout>
      <Box maxW="5xl" mx="auto" py={16} px={6}>
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Heading textAlign="center">
            {t('home.welcome')},{' '}
            <Box as="span" fontWeight="light">
              {data?.viewer?.profile?.firstName}
            </Box>
          </Heading>
          <Text fontWeight="bold" fontSize="lg" pt={1}>
            {t('home.subtitle')}
          </Text>
          <SearchForm />
        </Box>
      </Box>
      {nextMeeting && (
        <Box position="relative">
          <Box
            pos="absolute"
            w="full"
            h={{ base: 20, lg: 56 }}
            background="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
          />
          <Box maxW="5xl" py={{ base: 10, lg: 14 }} mx="auto">
            <Box
              maxW="lg"
              w="full"
              mx="auto"
              position="relative"
              px={{ base: 6, lg: 0 }}
            >
              <NextMeeting {...nextMeeting} />
              <Box px={6} pt={6}>
                <Link to="/my-meetings" color="orange.600" fontWeight="bold">
                  <Box as="span">
                    {t('home.view_scheduled_meetings')}
                    <Icon w={5} h={5} as={ArrowForward} />
                  </Box>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        maxW="5xl"
        mx="auto"
        spacing={12}
        px={{ base: 4, md: 0 }}
        pb={4}
      >
        <HomeCard
          title={t('home.joining_meet.title')}
          image="/images/joining-meeting.png"
        >
          <Box mt={6} fontSize={{ base: 'sm', lg: 'md' }}>
            <Text color="black" fontWeight="light">
              {t('home.joining_meet.paragraph1')}
            </Text>
            <Text color="black" fontWeight="light" mt={6}>
              {t('home.joining_meet.paragraph2')}
            </Text>
          </Box>
        </HomeCard>

        <HomeCard
          title={t('home.my_scheduled_meetings.title')}
          image="/images/my-scheduled-meetings.png"
        >
          <VStack mt={6} spacing={6} fontSize={{ base: 'sm', lg: 'md' }}>
            <Text color="black" fontWeight="light">
              {t('home.my_scheduled_meetings.paragraph1')}
            </Text>
            <Text color="black" fontWeight="light">
              {t('home.my_scheduled_meetings.paragraph2')}
            </Text>

            <Button
              as={Link}
              to="/my-meetings"
              variant="outline"
              colorScheme="blue"
              ml="auto"
            >
              {t('home.go_to_my_meeting')}
            </Button>
          </VStack>
        </HomeCard>
      </Stack>
      <Box
        background="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
        pb={6}
      >
        <Box maxW="5xl" mx="auto">
          <Stack direction={{ base: 'column', lg: 'row' }} px={6}>
            <Box
              py={1}
              flexShrink={0}
              position="relative"
              d="flex"
              alignItems={{ lg: 'flex-end' }}
              justifyContent={{ base: 'center' }}
              px={6}
            >
              <Icon
                color="white"
                as={Lamp}
                position="absolute"
                top={0}
                left={0}
                right={0}
                mx="auto"
                w={{ base: 14, lg: 16 }}
                h="auto"
                transform={{ base: 'translateX(-50%)', lg: 'translateX(0)' }}
              />
              <Icon
                color="white"
                as={Lamp}
                position="absolute"
                top={0}
                left={0}
                w={{ base: 8, lg: 10 }}
                h="auto"
                right={0}
                mx="auto"
                transform={{ base: 'translateX(50%)', lg: 'translateX(100%)' }}
              />
              <Image
                src="/images/are-you-a-lawyer.svg"
                alt="Are you a lawyer?"
                pt={{ base: 20, lg: 0 }}
                transform={{ lg: 'translateY(-20%)' }}
                zIndex={1}
              />
              <Icon
                as={Shadow}
                position="absolute"
                w={64}
                h="auto"
                d={{ base: 'none', lg: 'block' }}
                bottom={0}
                zIndex={0}
                transform="translateY(-200%)"
              />
            </Box>
            <Can I="read" a="application">
              <Box py={{ base: 4, lg: 12 }} pl={{ lg: 8 }} textAlign="center">
                <Heading as="h4" fontSize={{ base: 'lg', lg: 'xl' }}>
                  {t('home.are_you_lawyer.title')}
                </Heading>
                <Text fontSize={{ base: 'sm', lg: 'md' }} mt={4}>
                  {t('home.are_you_lawyer.paragraph1')}
                </Text>
                <Text fontSize={{ base: 'sm', lg: 'md' }} mt={4}>
                  {t('home.are_you_lawyer.paragraph2')}
                </Text>

                <Button
                  as={Link}
                  to="/lawyers"
                  mt={4}
                  variant="outline"
                  colorScheme="blue"
                >
                  {t('home.are_you_lawyer.action')}
                </Button>
              </Box>
            </Can>
            //TODO change text when ready.
            <Can not I="read" a="application">
              <Box py={{ base: 4, lg: 12 }} pl={{ lg: 8 }} textAlign="center">
                <Heading as="h4" fontSize={{ base: 'lg', lg: 'xl' }}>
                  {t('home.are_you_lawyer.title')}
                </Heading>
                <Text fontSize={{ base: 'sm', lg: 'md' }} mt={4}>
                  {t('home.are_you_lawyer.paragraph1')}
                </Text>
                <Text fontSize={{ base: 'sm', lg: 'md' }} mt={4}>
                  {t('home.are_you_lawyer.paragraph2')}
                </Text>

                <Button
                  as={Link}
                  to="/lawyers"
                  mt={4}
                  variant="outline"
                  colorScheme="blue"
                >
                  {t('home.are_you_lawyer.action')}
                </Button>
              </Box>
            </Can>
          </Stack>
        </Box>
      </Box>
    </SidebarLayout>
  );
};

export default HomePage;
