import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import PaymentStep from '@atoms/PaymentStep';
export interface PaymentStepperProps {
  step: number;
}

const steps = [1, 2, 3];

const PaymentStepper = ({ step }: PaymentStepperProps) => {
  const { t } = useTranslation();
  return (
    <Flex d={{ base: 'none', lg: 'flex' }}>
      {steps.map((stepNumber: number) => {
        return (
          <PaymentStep
            key={`step_${stepNumber}`}
            stepNumberText={t(`meetings.step_info.step_${stepNumber}`)}
            stepHeaderText={t(
              `meetings.step_info.step_${stepNumber}_description`
            )}
            stepNumber={stepNumber}
            currentStep={step}
          />
        );
      })}
    </Flex>
  );
};

export default PaymentStepper;
