import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import SidebarHeader from './SidebarHeader';
import SidebarNav from './SidebarNav';

const Sidebar: FC = () => {
  return (
    <Box d="flex" flexDirection="column" w={64}>
      <Box d="flex" flexDirection="column" h={0} flex="1 1 0%" bg="white">
        <Box
          d="flex"
          flex="1 1 0%"
          flexDirection="column"
          pt={8}
          pb={6}
          overflowY="auto"
        >
          <SidebarHeader />
          <Box pt={12} mt={100} pl={12}>
            <SidebarNav />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
