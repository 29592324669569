import React from 'react';
import { LocalVideoStream } from '@src/video';
import { useVideoContext } from '@src/context/VideoContext';
import { Avatar, Box, useBreakpointValue } from '@chakra-ui/react';
import MeetingRoomVideoTrack from '@src/components/molecules/MeetingRoom/Video/Track';

const LocalVideoPreview = ({
  avatarUrl,
}: {
  identity: string;
  avatarUrl: string;
}) => {
  const { localStreams } = useVideoContext();
  const avatarSize = useBreakpointValue({ base: 'lg', md: '2xl' });

  const videoTrack = localStreams.find((track) =>
    track.name.includes('camera')
  ) as LocalVideoStream;

  return (
    <Box
      position="relative"
      height={{ base: '100vh', lg: 0 }}
      overflow="hidden"
      pt={{ lg: `${(9 / 16) * 100}%` }}
      background="url('/images/lobby-background.svg') no-repeat"
      backgroundSize="cover"
      rounded={{ lg: 'xl' }}
    >
      <Box position="absolute" top={0} left={0} w="full" h="full">
        {videoTrack ? (
          <MeetingRoomVideoTrack track={videoTrack} isLocal />
        ) : (
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            zIndex={1}
            transform="translateY(-1.5rem)"
          >
            <Avatar size={avatarSize} src={avatarUrl} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LocalVideoPreview;
