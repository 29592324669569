import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Switch, useRouteMatch } from 'react-router-dom';
import Route from '@src/PrivateRoute';
import ListPaymentMethods from './ListPaymentMethods';
import AddPaymentMethod from './AddPaymentMethod';
import { stripePromise } from '@src/utils';

export default function PaymentMethodPage() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} prefixLocale={false}>
        <ListPaymentMethods />
      </Route>
      <Route path={`${path}/setup`} prefixLocale={false}>
        <Elements stripe={stripePromise}>
          <AddPaymentMethod />
        </Elements>
      </Route>
    </Switch>
  );
}
