const baseStyle = {
  pointerEvents: 'none',
  pt: 3,
  px: { base: 4, md: 6 },
  fontSize: 'xs',
  fontWeight: 'semibold',
  position: 'absolute',
  display: 'block',
  w: 'full',
  top: 0,
  left: 0,
  zIndex: 2,
  color: 'black',
  transition: 'all 0.2s',
  opacity: 1,
  _disabled: {
    opacity: 0.4,
  },
  _invalid: {
    color: 'red.500',
  },
};

export default {
  baseStyle,
};
