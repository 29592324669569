import React from 'react';
import {
  Box,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  VStack,
  PopoverFooter,
  Button,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Link from '@components/atoms/Link';
import useSignout from '@src/hooks/useSignout';

export type AccountPopoverProps = Pick<User, 'email' | 'profile'>;

const AccountPopover = ({ profile, email }: AccountPopoverProps) => {
  const { t } = useTranslation();
  const onSignout = useSignout();
  return (
    <Popover closeOnBlur size="2xs">
      <PopoverTrigger>
        <Button
          variant="unstyled"
          d="flex"
          h="auto"
          border="0"
          borderRadius="full"
          aria-label={profile?.firstName as string}
          _focus={{
            boxShadow: 'outline',
          }}
        >
          <Avatar
            w={10}
            h={10}
            src={profile?.avatar as string}
            name={`${profile?.firstName} ${profile?.lastName}`}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex={1400}>
        <PopoverArrow />
        <PopoverHeader py={6}>
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Avatar
              size="lg"
              name={`${profile?.firstName} ${profile?.lastName}`}
              src={profile?.avatar as string}
            />
            <Text
              isTruncated
              w="full"
              textAlign="center"
              fontSize="lg"
              lineHeight="normal"
              fontWeight="bold"
              mt={4}
              color="gray.900"
            >
              {profile?.firstName}
            </Text>
            <Text
              isTruncated
              w="full"
              textAlign="center"
              textStyle="secondary"
              className="truncate"
              color="gray.600"
            >
              {email}
            </Text>
          </Box>
        </PopoverHeader>
        <PopoverBody px={8} py={4}>
          <VStack align="flex-start">
            <Link
              color="gray.900"
              to="/profile"
              _hover={{ color: 'primary' }}
              _focus={{ color: 'primary' }}
            >
              {t('common.my_profile')}
            </Link>
            {/* <Link
              to="/preferences"
              color="gray.900"
              _hover={{ color: 'primary' }}
              _focus={{ color: 'primary' }}
            >
              {t('common.preferences')}
            </Link> */}
          </VStack>
        </PopoverBody>
        <PopoverFooter>
          <Box py={4} px={6} textAlign="end">
            <ChakraLink
              as="button"
              color="primary"
              onClick={onSignout}
              _hover={{ color: 'blue.700' }}
              _focus={{ color: 'blue.700' }}
            >
              {t('common.sign_out')}
            </ChakraLink>
          </Box>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default AccountPopover;
