import React, { ReactNode } from 'react';
import { Box, Icon, Select } from '@chakra-ui/react';
import { ReactComponent as ChevronDownIcon } from '@src/icons/arrow-down.svg';

export interface SelectProps {
  placeholder?: string;
  defaultValue?: string;
  handleChange: (val: string) => void;
  children: ReactNode;
}

const SelectBox = ({
  placeholder,
  handleChange,
  defaultValue,
  children,
}: SelectProps) => {
  return (
    <Box position="relative">
      <Box as="span" display="inline-block" w="full">
        <Box
          as="button"
          type="button"
          cursor="default"
          position="relative"
          w="full"
          backgroundColor="white"
          textAlign="left"
          transition="all"
          lineHeight="base"
          fontSize={{ base: 'sm', md: 'md' }}
          _focus={{ outline: 'none' }}
        >
          <Box
            as="span"
            display="block"
            color="black"
            fontWeight="medium"
            className="truncate"
            pl={3}
          >
            <Select
              value={defaultValue}
              onChange={(e) => handleChange(e.target.value)}
              icon={<Icon as={ChevronDownIcon} fontSize="xs" />}
              variant="unstyle"
              placeholder={placeholder}
              backgroundColor="white"
            >
              {children}
            </Select>
          </Box>
          <Box
            as="span"
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            display="flex"
            alignItems="center"
            pr={2}
            pointerEvents="none"
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectBox;
