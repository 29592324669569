import { subMonths, startOfMonth, addMinutes } from 'date-fns';
import { mockLawyer, Lawyer } from './lawyers';
import { genId, randomDate } from '@src/utils/mock';
import { slotOptions } from './config';

export interface Meeting {
  id: number;
  date: Date;
  startTime: Date;
  endTime: Date;
  lawyer: Lawyer;
  duration: number;
}

// eslint-disable-next-line
export function mockMeeting(override?: any): Meeting {
  const start = subMonths(new Date(), 1);
  const end = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20);
  const slots = Object.keys(slotOptions);
  const duration = parseInt(slots[Math.floor(Math.random() * slots.length)]);
  const date = randomDate(start, end);
  return {
    id: genId(),
    date: date,
    startTime: date,
    endTime: addMinutes(date, duration),
    lawyer: mockLawyer(),
    duration: duration,
    ...override,
  };
}

const nextMeetingDate = new Date(new Date().getTime() + 1000 * 60 * 15);

export const meetings = [...Array(20)]
  .map(mockMeeting)
  .concat(mockMeeting({ date: nextMeetingDate }))
  .concat(mockMeeting({ date: startOfMonth(new Date()) }))
  .concat(mockMeeting({ date: startOfMonth(new Date()) }));
