import React, { useContext } from 'react';

import {
  Meeting,
  CreateStreamOptions,
  LocalStream,
  ConnectMeetingOptions,
  MeetingError,
} from '@src/video';

import useLocalStreams from '@src/hooks/meeting/useLocalStreams';
import useMeeting from '@src/hooks/meeting/useMeeting';
import { ErrorCallback, Callback } from '@src/@types/twilio';
import { SelectedParticipantContextProvider } from '@src/context';
import useHandleStreamPublicationFailed from '@src/hooks/meeting/useHandleStreamPublicationFailed';
import useHandleRoomDisconnectionErrors from '@src/hooks/meeting/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from '@src/hooks/meeting/useHandleOnDisconnect';

export interface GetTokenResponse {
  meeting_token: string;
}

export interface VideoContextProps {
  children: React.ReactNode;
  options?: ConnectMeetingOptions;
  onError: ErrorCallback;
  onDisconnect?: Callback;
}

export interface VideoContextType {
  meeting: Meeting;
  localStreams: LocalStream[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect?: Callback;
  getLocalVideoStream: (
    newOptions?: CreateStreamOptions
  ) => Promise<LocalStream>;
  getLocalAudioStream: (deviceId?: string) => Promise<LocalStream>;
  isAcquiringLocalStreams: boolean;
  removeLocalVideoStream: () => void;
  getAudioAndVideoStreams: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const VideoContext = React.createContext<VideoContextType>(null!);

export default function VideoContextProvider({
  children,
  // eslint-disable-next-line
  onDisconnect = () => {},
  options,
  ...props
}: VideoContextProps) {
  const onErrorCallback = (error: MeetingError) => {
    props.onError?.(error);
  };

  const {
    localStreams,
    getLocalVideoStream,
    getLocalAudioStream,
    isAcquiringLocalStreams,
    removeLocalVideoStream,
    getAudioAndVideoStreams,
  } = useLocalStreams();

  const { meeting, isConnecting, connect } = useMeeting(
    localStreams,
    onErrorCallback,
    options
  );

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(meeting, props.onError);
  useHandleStreamPublicationFailed(meeting, props.onError);
  useHandleOnDisconnect(meeting, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        meeting,
        localStreams,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoStream,
        getLocalAudioStream,
        connect,
        isAcquiringLocalStreams,
        removeLocalVideoStream,
        getAudioAndVideoStreams,
      }}
    >
      <SelectedParticipantContextProvider meeting={meeting}>
        {children}
      </SelectedParticipantContextProvider>
    </VideoContext.Provider>
  );
}
export function useVideoContext() {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error(
      'useVideoContext must be used within the VideoStateProvider'
    );
  }
  return context;
}
