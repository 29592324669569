import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const BasicInformation = ({
  applicationData,
}: {
  applicationData: LawyerApplicationType;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction={['column', 'row']} w="full">
        <Box d="flex" flexDir="column" w="50%">
          <Text textStyle="secondary" fontSize="sm">
            {t('fields.firstName.label')}:{' '}
          </Text>
          <Text textStyle="strongBlue" fontSize="lg">
            {applicationData?.firstName}
          </Text>
        </Box>
        <Box>
          <Text textStyle="secondary" fontSize="sm">
            {t('fields.lastName.label')}:{' '}
          </Text>
          <Text textStyle="strongBlue" fontSize="lg">
            {applicationData?.lastName}
          </Text>
        </Box>
      </Stack>
      <Stack direction={['column', 'row']} w="full">
        <Box d="flex" flexDir="column" w="50%">
          <Text textStyle="secondary" fontSize="sm">
            {t('fields.email.label')}:
          </Text>
          <Text textStyle="strongBlue" fontSize="lg">
            {applicationData?.email}
          </Text>
        </Box>
        <Box>
          <Text textStyle="secondary" fontSize="sm">
            {t('fields.phone.label')}:{' '}
          </Text>
          <Text textStyle="strongBlue" fontSize="lg">
            {applicationData?.phone}
          </Text>
        </Box>
      </Stack>
      <Stack direction={['column', 'row']} w="full">
        <Box d="flex" flexDir="column" w="50%">
          <Text textStyle="secondary" fontSize="sm">
            {t('fields.title.label')}:{' '}
          </Text>
          <Text textStyle="strongBlue" fontSize="lg">
            {t(`fields.title_options.${applicationData?.title}`)}
          </Text>
        </Box>
        <Box>
          <Text textStyle="secondary" fontSize="sm">
            {t('fields.country.label')}:{' '}
          </Text>
          <Text textStyle="strongBlue" fontSize="lg">
            {applicationData?.location.name}
          </Text>
        </Box>
      </Stack>
    </>
  );
};

export default BasicInformation;
