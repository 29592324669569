import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

export interface UseMeetingListFilterOptions {
  onChange(value: string, range?: [Date, Date]): void;
}

export function useMeetingListFilter({
  onChange,
}: UseMeetingListFilterOptions) {
  const menu = useDisclosure();
  const rangePicker = useDisclosure();
  const [range, setRange] = useState<[Date, Date] | null>();
  const [startDate, endDate] = range ?? [];

  const onMenuClose = useCallback(() => {
    rangePicker.onClose();
    menu.onClose();
  }, [rangePicker, menu]);

  const onApplyInterval = useCallback(() => {
    if (startDate && endDate) {
      onChange('withinInterval', [startDate as Date, endDate as Date]);
      rangePicker.onClose();
      menu.onClose();
    }
  }, [menu, onChange, rangePicker, startDate, endDate]);

  const handleChange = useCallback(
    (value) => {
      if (rangePicker.isOpen) rangePicker.onClose();
      if (value !== 'withinInterval') {
        onChange(value);
        menu.onClose();
      }
    },
    [menu, onChange, rangePicker]
  );

  const onDateChange = (dates: [Date, Date]) => {
    setRange(dates);
  };

  return {
    menu: {
      onClose: onMenuClose,
      onOpen: menu.onOpen,
      isOpen: menu.isOpen,
      onChange: handleChange,
    },
    rangePicker: {
      isOpen: rangePicker.isOpen,
      onClose: rangePicker.onClose,
      onOpen: rangePicker.onOpen,
      onChange: onDateChange,
      startDate,
      endDate,
      onApply: onApplyInterval,
    },
  };
}
