import { useEffect, useState } from 'react';
import { Publication, Participant } from '@src/video';

export default function usePublications(participant: Participant) {
  const [publications, setPublications] = useState<Publication[]>([]);

  useEffect(() => {
    // Reset the publications when the 'participant' variable changes.
    setPublications(Array.from(participant.tracks.values()) as Publication[]);

    const publicationAdded = (publication: Publication) =>
      setPublications((prevPublications) => [...prevPublications, publication]);
    const publicationRemoved = (publication: Publication) =>
      setPublications((prevPublications) =>
        prevPublications.filter((p) => p !== publication)
      );

    participant.on('trackPublished', publicationAdded);
    participant.on('trackUnpublished', publicationRemoved);
    return () => {
      participant.off('trackPublished', publicationAdded);
      participant.off('trackUnpublished', publicationRemoved);
    };
  }, [participant]);

  return publications;
}
