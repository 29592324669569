import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  chakra,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { ShowErrorFunc, showErrorOnChange } from '@src/utils';

const ChakraDatePicker = chakra(ReactDatePicker);

export interface DatePickerProps<FieldValue>
  extends Pick<
    ReactDatePickerProps,
    | 'showYearDropdown'
    | 'showMonthDropdown'
    | 'dropdownMode'
    | 'maxDate'
    | 'minDate'
    | 'yearDropdownItemNumber'
    | 'scrollableYearDropdown'
    | 'dateFormatCalendar'
    | 'showTimeSelect'
    | 'showTimeSelectOnly'
    | 'timeIntervals'
    | 'timeCaption'
    | 'dateFormat'
    | 'maxTime'
    | 'minTime'
  > {
  name: string;
  label?: string;
  placeholder?: string;
  showError?: ShowErrorFunc;
  fieldProps?: UseFieldConfig<FieldValue>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  variant?: string;
  isDisabled?: boolean;
}

const DatePickerInput = ({ value, onChange, variant }: InputProps) => {
  return (
    <Input
      value={value}
      // eslint-disable-next-line
      // @ts-ignore
      onChange={(e) => onChange(e.target.value)}
      variant={variant}
    />
  );
};
DatePickerInput.displayName = 'DatePickerInput';

export const DatePicker = ({
  name,
  label,
  placeholder,
  fieldProps,
  formControlProps,
  formLabelProps,
  variant = 'roo',
  showError = showErrorOnChange,
  isDisabled,
  ...props
}: DatePickerProps<Date>) => {
  const { input, meta } = useField(name, fieldProps);
  const { error } = meta;
  const isError = showError({ meta });

  return (
    <FormControl
      isInvalid={isError}
      isDisabled={isDisabled}
      {...formControlProps}
      outline="none"
      appearance="none"
      d="inline-flex"
      verticalAlign="top"
      flexDirection="column"
    >
      {!!label && (
        <FormLabel htmlFor={input.name} {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      <ChakraDatePicker
        className="roo-datepicker"
        onChange={input.onChange}
        selected={input.value}
        disabled={isDisabled}
        customTimeInput={
          <DatePickerInput variant={variant} placeholder={placeholder} />
        }
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
