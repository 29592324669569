import { isWithinInterval, parseISO, subMinutes } from 'date-fns';
import { useGetMyAppointmentsQuery, useViewerQuery } from '@src/apollo/hooks';
import { parseMeeting } from '@src/utils';

export function useNextMeeting() {
  const { data } = useGetMyAppointmentsQuery();
  const { data: userData } = useViewerQuery();

  const nextMeeting = data?.getMyAppointments?.find((appointment) =>
    isWithinInterval(new Date(), {
      start: subMinutes(parseISO(appointment?.times?.startsAt), 30),
      end: parseISO(appointment?.times?.endsAt),
    })
  ) as AppointmentType | undefined;
  const participantType =
    userData?.viewer?.email === nextMeeting?.user.email ? 'lawyer' : 'user';
  return nextMeeting && parseMeeting(nextMeeting, participantType);
}
