import Video, { ConnectOptions, Room, TwilioError } from 'twilio-video';

export interface MeetingConnectionProps {
  token: string;
}
export interface Meeting extends Room {
  meetingId?: string;
}
export type ConnectMeetingOptions = ConnectOptions;
export type MeetingError = TwilioError;

const connectMeeting = (
  token: string,
  options?: ConnectMeetingOptions
): Promise<Meeting> => {
  return new Promise<Meeting>((done) => {
    return Video.connect(token, options).then((room) => {
      return done(room);
    });
  });
};

export { connectMeeting };
