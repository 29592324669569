import { parseISO } from 'date-fns';

let id = 0;
export const genId = () => ++id;

export function randomDate(
  start: Date,
  end: Date,
  startHour = 8,
  endHour = 18
) {
  const date = new Date(
    +start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
  const hour = (startHour + Math.random() * (endHour - startHour)) | 0;
  date.setHours(hour);
  date.setMinutes(0);
  return date;
}

export function generateID() {
  return Math.random().toString(36).substr(2, 9);
}

// TODO: replace when schema change
export function parseMeeting(
  meeting: Maybe<
    { __typename?: 'AppointmentType' } & {
      host: { __typename?: 'LawyerType' } & LawyerFragment;
      meeting: { __typename?: 'AppointmentMeetingType' } & MeetingFragment;
      user: { __typename?: 'User' } & {
        profile: { __typename?: 'UserProfile' } & ProfileFragment;
      };
    } & AppointmentFragment
  >,
  participantType = 'lawyer'
): Meeting {
  const participant = {
    ...meeting,
    times: {
      startsAt: parseISO(meeting?.times?.startsAt as string),
      endsAt: parseISO(meeting?.times?.endsAt as string),
    },
    participant: {
      type: participantType,
      ...(participantType === 'lawyer'
        ? {
            id: meeting?.host.id,
            firstName: meeting?.host.firstName,
            lastName: meeting?.host.lastName,
            avatar: meeting?.host.headshot,
            title: meeting?.host.title,
            location: { ...meeting?.host.location },
          }
        : {
            firstName: meeting?.user.profile.firstName,
            lastName: meeting?.user.profile.lastName,
            avatar: meeting?.user.profile.avatar,
            ...meeting?.user,
          }),
    },
  };

  return participant as Meeting;
}
