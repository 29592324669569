const colors = {
  primary: '#0747a6',
  secondary: '#ff991f',
  tealAlpha: {
    50: 'rgb(229, 240, 255, 0.96)',
  },
  teal: {
    50: '#e5f0ff',
  },
  skyBlue: {
    50: '#E5F0FF',
    100: '#A0C4F9',
  },
  red: {
    '50': '#ffebee',
    '100': '#ffcdd2',
    '200': '#ef9a9a',
    '300': '#e57373',
    '400': '#ef5350',
    '500': '#f44336',
    '600': '#e53935',
    '700': '#d32f2f',
    '800': '#c62828',
    '900': '#b71c1c',
  },
  blue: {
    50: '#C3DDFD',
    100: '#D2E3FB',
    200: '#80B1FA',
    300: '#4F92F8',
    400: '#1E74F6',
    500: '#095CD7',
    600: '#0747A6',
    700: '#053276',
    800: '#031D45',
    900: '#010814',
  },
  orange: {
    50: '#FFFAF4',
    100: '#FFF6EB',
    200: '#FFDFB8',
    300: '#FFC885',
    400: '#FFB152',
    500: '#FF9A1F',
    600: '#D17E1B',
    700: '#B86500',
    800: '#854900',
    900: '#522D00',
  },
  gray: {
    100: '#f7f9fb',
    200: '#eff2f6',
    300: '#d8e0ea',
    400: '#c1cddd',
    500: '#92a7c3',
    600: '#6381a9',
    700: '#597498',
    800: '#3b4d65',
    900: '#2d3a4c',
  },
  'dove-gray': {
    50: '#f8f8f8',
    100: '#f0f1f1',
    200: '#dadbdb',
    300: '#c4c5c6',
    400: '#989a9b',
    500: '#6c6e70',
    600: '#616365',
    700: '#515354',
    800: '#414243',
    900: '#353637',
  },
};

export default colors;
