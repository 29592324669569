import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Text,
  Icon,
  Flex,
  Checkbox,
  Button,
  Link,
} from '@chakra-ui/react';
import { ReactComponent as Visa } from '@src/icons/visa.svg';
import { ReactComponent as MasterCard } from '@src/icons/mastercard.svg';
import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';

import { useSignupModal, useSigninModal, Can } from '@src/context';
import { formatMoney } from '@src/utils';

export interface PaymentDetailsProps {
  amount: number;
  handleGoBack: () => void;
  onCreateAppointment(): Promise<void>;
  isLoading?: boolean;
  onAddCreditCard(): void;
  paymentMethod?: Maybe<
    { __typename?: 'PaymentMethodType' } & Pick<
      PaymentMethodType,
      'id' | 'cardBrand' | 'cardLast4'
    >
  >;
}

const PaymentDetails = ({
  amount,
  handleGoBack,
  onCreateAppointment,
  isLoading,
  paymentMethod,
  onAddCreditCard,
}: PaymentDetailsProps) => {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const signupModal = useSignupModal();
  const signinModal = useSigninModal();
  const { t } = useTranslation();

  return (
    <Box p="36px" d="flex" flexDir="column">
      <Flex
        p={4}
        justifyContent="space-between"
        width="100%"
        borderBottomWidth={'1px'}
        borderBottomColor={'blue.200'}
        borderStyle={'dashed'}
      >
        <Text color="gray.600">{t('meetings.will_be_charged')}</Text>
        <Text as="strong"> USD {formatMoney(amount)}</Text>
      </Flex>
      <Flex
        p={4}
        flexDir="column"
        alignItems="flex-start"
        width="100%"
        borderBottomWidth={'1px'}
        borderBottomColor={'blue.200'}
        borderStyle={'dashed'}
      >
        <Can I="create" a="appointment">
          {paymentMethod ? (
            <>
              <Box d="flow" textStyle="secondary">
                {t('meetings.from_card_ending_with')}
                <Text as="strong">{paymentMethod.cardLast4}</Text>
              </Box>
              <Box d="flow" alignItems="center">
                <Text fontSize="sm" textStyle="strongYellow">
                  {t('meetings.change_payment_method')}{' '}
                  <Icon as={ArrowForward} ml="5px" textStyle="strongYellow" />
                </Text>
              </Box>
            </>
          ) : (
            <>
              <Text fontSize="sm" textStyle="secondary">
                {t('meetings.need_add_credit_card')}
              </Text>
              <Link
                as="button"
                onClick={onAddCreditCard}
                justifyContent="initial"
                fontSize="sm"
                pt={1}
                textStyle="strongYellow"
              >
                {t('meetings.add_credit_card')}
                <Icon as={ArrowForward} ml="5px" textStyle="strongYellow" />
              </Link>
            </>
          )}
        </Can>

        <Can not I="create" a="appointment">
          <Box d="flow" textStyle="secondary">
            {t('meetings.must_be_logged_in')}
          </Box>
        </Can>
      </Flex>
      <Flex p={3} justifyContent="flex-end">
        <Icon as={Visa} mr="5px" w={'30px'} h={'30px'} color="gray.600" />
        <Icon as={MasterCard} mr="5px" h={'30px'} color="gray.600" />
      </Flex>
      <Can I="create" a="appointment">
        {paymentMethod && (
          <Flex>
            <Checkbox
              color="gray.600"
              onChange={(e) => setAgreeToTerms(e.target.checked)}
              checked={agreeToTerms}
              borderColor={'gray.600'}
              size="sm"
            >
              {t('meetings.agree_to')}{' '}
              <Text fontSize="sm" as="strong" textDecor="underline">
                {t('meetings.terms_and_conditions')}
              </Text>
            </Checkbox>
          </Flex>
        )}
      </Can>
      <Flex w="100%" d="flex" justifyContent="center" mt={6}>
        <Button
          variant="outline"
          onClick={handleGoBack}
          colorScheme="blue"
          mr={4}
          fontWeight="medium"
          fontSize="sm"
          px={35}
          w="122px"
          isDisabled={isLoading}
        >
          {t('meetings.go_back')}
        </Button>
        <Can I="create" a="appointment">
          {paymentMethod && (
            <Button
              colorScheme="blue"
              fontWeight="medium"
              fontSize="sm"
              px={35}
              w="122px"
              disabled={!agreeToTerms}
              onClick={onCreateAppointment}
              isLoading={isLoading}
            >
              {t('meetings.pay_and_book')}
            </Button>
          )}
        </Can>
        <Can not I="create" a="appointment">
          <Button
            onClick={signinModal.open}
            colorScheme="blue"
            fontWeight="medium"
            fontSize="sm"
            px={35}
            w="122px"
          >
            {t('common.labels.sign_in')}
          </Button>
        </Can>
      </Flex>
      <Can not I="create" a="appointment">
        <Flex w="100%" d="flex" justifyContent="center" mt={6}>
          <Text fontStyle="secondary">{t('signin.not_member_yet')} </Text>
          <Link
            as="button"
            onClick={signupModal.open}
            ml="5px"
            color="orange.500"
          >
            <Text as="strong"> {t('common.labels.sign_up')}</Text>
          </Link>
        </Flex>
      </Can>
    </Box>
  );
};

export default PaymentDetails;
