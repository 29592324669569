const languages = [
  {
    code: 'en',
    name: 'English',
    slug: 'en',
  },
  {
    code: 'es',
    name: 'Spanish',
    slug: 'es',
  },
];

export { languages };
