import React from 'react';
import { Box, Icon, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import FeatureCard from '@components/molecules/FeatureCard';
import { meetFeatures } from '@src/content';
import { ReactComponent as TexturePoint } from '@src/icons/point.svg';
import { useSignupModal } from '@src/context';

export const FeaturesSection: React.FC = () => {
  const history = useHistory();
  const signupModal = useSignupModal();
  const handleAction = (action: string) => {
    switch (action) {
      case 'search':
        return window.scrollTo(0, 0);
      case 'signup':
        return signupModal.open();
      case 'contact':
        return history.push('/contact');
      default:
        return;
    }
  };

  return (
    <Box position="relative" backgroundColor="teal.50" mt={-8}>
      <Box
        d="flex"
        position={{ md: 'absolute' }}
        left={{ md: 0 }}
        height={{ md: 'full' }}
        width={{ md: '50%' }}
        alignItems="center"
        pt={{ base: 12, md: 0 }}
      >
        <Box position="relative">
          <Icon
            as={TexturePoint}
            position="absolute"
            right={0}
            top={0}
            transform={{
              base: 'translateX(-80%) translateY(-28%)',
              md: 'translateX(-10%) translateY(-28%)',
              lg: 'translateX(-30%) translateY(-28%)',
              xl: 'translateX(-10%) translateY(-28%)',
            }}
            w={{ base: 20, md: 32, xl: 48 }}
            h={{ base: 20, md: 32, xl: 48 }}
            zIndex={0}
          />
          <Box
            as="img"
            position="relative"
            w={{ base: '80%', md: '90%' }}
            height="auto"
            display={{ base: 'none', md: 'unset' }}
            alt="laptop-during-call"
            src="/images/laptop.png"
          />
        </Box>
      </Box>
      <Box
        position="relative"
        maxW="7xl"
        mx="auto"
        py={{ base: 16, sm: 24, lg: 32 }}
        px={{ base: 4, sm: 6, lg: 8 }}
      >
        <VStack
          spacing={12}
          ml={{ md: 'auto' }}
          pl={{ md: 10 }}
          w={{ md: '50%', lg: '40%' }}
        >
          {meetFeatures.map((feature) => (
            <FeatureCard
              key={feature.translationKey}
              {...feature}
              onAction={handleAction}
            />
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default FeaturesSection;
