import React, { createContext, ReactNode, useContext } from 'react';
import { AuthOptions } from 'auth0-js';

export const Auth0Context = createContext<AuthOptions | undefined>(undefined);

export function useAuth0Context() {
  const context = useContext(Auth0Context);
  return context as AuthOptions;
}
export interface Auth0ProviderProps extends AuthOptions {
  children: ReactNode;
}

export const Auth0Provider = ({ children, ...props }: Auth0ProviderProps) => {
  const overrides = {
    __tenant: process.env.REACT_APP_AUTH0_TENANT,
    __token_issuer: process.env.REACT_APP_AUTH0_TOKEN_ISSUER,
    __jwks_uri: process.env.REACT_APP_AUTH0_JWKS_URI,
  };
  return (
    <Auth0Context.Provider value={{ ...props, overrides }}>
      {children}
    </Auth0Context.Provider>
  );
};
