export const slotOptions: Dict = {
  '30m': [
    '7 AM',
    '7:30 AM',
    '8 AM',
    '8:30 AM',
    '9 AM',
    '9:30 AM',
    '10:30 AM',
    '11 AM',
    '11:30 AM',
    '12 AM',
    '12:30 PM',
    '1 PM',
  ],
  '60m': ['7 AM', '9 AM', '10 AM', '1 PM', '3 PM'],
  '90m': ['9 AM', '10:30 AM'],
};
