/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, Redirect } from 'react-router';

export type TRoute = {
  name?: string;
  exact?: boolean;
  path?: string;
  Component?: any;
  hideAuth?: boolean;
  redirectTo?: string;
  hardRedirect?: string;
};

const CustomRoute = (props: TRoute) => {
  const { Component, name, exact, path, hardRedirect } = props;
  return (
    <Route
      exact={exact}
      path={`/:locale${path}`}
      render={(props) => {
        if (hardRedirect) {
          return (
            <Redirect
              to={{
                pathname: hardRedirect,
              }}
            />
          );
        }

        return <Component {...props} pageName={name} />;
      }}
    />
  );
};

export default CustomRoute;
