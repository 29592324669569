import React from 'react';
import CustomRoute from '@src/Router';
import LawyerApplication from './Application';
import LawyerApplicationStatus from './Status';

import Layout from '@src/components/organisms/RooLayout/Layout';
import { useGetMyLawyerApplicationQuery } from '@src/apollo/hooks';
import Loader from '@atoms/Loader';

const Directory: React.FC = () => {
  const { data, loading } = useGetMyLawyerApplicationQuery();
  if (loading) return <Loader />;
  return (
    <Layout theme="blue">
      <CustomRoute
        exact
        path="/application"
        Component={
          data?.getMyLawyerApplication
            ? LawyerApplicationStatus
            : LawyerApplication
        }
      />
    </Layout>
  );
};

export default Directory;
