import React, { useCallback, useRef } from 'react';
import {
  BoxProps,
  chakra,
  StylesProvider,
  useMultiStyleConfig,
  FormControlOptions,
  Tag,
  TagCloseButton,
  TagLabel,
  TagProps,
} from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/theme-tools';
import Downshift, {
  DownshiftProps,
  PropGetters,
  useMultipleSelection,
  UseMultipleSelectionStateChange,
} from 'downshift';
import { UseMultipleSelectionGetSelectedItemPropsOptions } from 'downshift';
import { SelectProvider, useSelect } from './useDownshift';

export type SelectedItemTagProps<
  // eslint-disable-next-line
  Item = any
> = UseMultipleSelectionGetSelectedItemPropsOptions<Item> & TagProps;

export function SelectedItemTag({
  children,
  selectedItem,
  index,
  ...props
}: SelectedItemTagProps) {
  const { removeSelectedItem, getSelectedItemProps, inputRef } = useSelect();
  return (
    <Tag
      size="sm"
      m="2px"
      zIndex={1}
      {...getSelectedItemProps?.({ selectedItem, index })}
      {...props}
    >
      <TagLabel color="primary" fontWeight="semibold">
        {children}
      </TagLabel>
      <TagCloseButton
        tabIndex={-1}
        _focus={{ outline: 'none' }}
        cursor="default"
        onClick={(e) => {
          e.stopPropagation();
          removeSelectedItem?.(selectedItem);
          inputRef.current?.focus();
        }}
      />
    </Tag>
  );
}

// eslint-disable-next-line
export type SelectProps<Item = any> = Omit<BoxProps, 'onChange'> &
  FormControlOptions &
  Pick<DownshiftProps<Item>, 'itemToString'> & {
    initialSelectedItems?: Array<Item>;
    defaultSelectedItems?: Array<Item>;
    value?: Item[] | undefined;
    onChange?: (changes: UseMultipleSelectionStateChange<Item>) => void;
    children: MaybeRenderProp<{
      isOpen: boolean;
      highlightedIndex: number | null;
      onClose?(): void;
      inputValue: string | null;
      selectedItems: Array<Item>;
      getLabelProps: PropGetters<Item>['getLabelProps'];
    }>;
  };

// eslint-disable-next-line
export function SelectMulti<Item = any>({
  children,
  onChange,
  initialSelectedItems = [],
  defaultSelectedItems = [],
  itemToString,
  value,
  isDisabled,
  ...props
}: SelectProps<Item>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const styles = useMultiStyleConfig('SelectMultiple', {});

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    defaultSelectedItems,
    initialSelectedItems,
    onSelectedItemsChange: onChange,
    ...(value && {
      selectedItems: value,
    }),
  });

  const getStateAndHelpers = useCallback(
    (downshift) => {
      return {
        ...downshift,
        selectedItems,
        getDropdownProps,
        getSelectedItemProps,
        removeSelectedItem,
      };
    },
    [selectedItems, getDropdownProps, getSelectedItemProps, removeSelectedItem]
  );

  const stateReducer = useCallback((state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownSpaceButton:
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: '',
        };
      default:
        return changes;
    }
  }, []);

  const onStateChange = useCallback(
    ({ type, selectedItem }) => {
      switch (type) {
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.keyDownSpaceButton:
        case Downshift.stateChangeTypes.clickItem:
          if (selectedItem) {
            addSelectedItem(selectedItem as never);
          }
          break;
        default:
          break;
      }
    },
    [addSelectedItem]
  );

  return (
    <Downshift
      stateReducer={stateReducer}
      onStateChange={onStateChange}
      selectedItem={null}
      itemToString={itemToString}
    >
      {(downshift) => {
        const ctx = {
          ...getStateAndHelpers(downshift),
          isDisabled,
          inputRef,
        };
        return (
          <chakra.div
            position="relative"
            {...props}
            {...downshift.getRootProps()}
          >
            <StylesProvider value={styles}>
              <SelectProvider value={ctx}>
                {runIfFn(children, {
                  inputValue: downshift.inputValue,
                  isOpen: downshift.isOpen,
                  highlightedIndex: downshift.highlightedIndex,
                  selectedItems,
                  getLabelProps: downshift.getLabelProps,
                })}
              </SelectProvider>
            </StylesProvider>
          </chakra.div>
        );
      }}
    </Downshift>
  );
}
