import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { chakra, Icon, Spinner } from '@chakra-ui/react';
import { CreditCard } from '@src/components/molecules/CreditCard';
import { ReactComponent as PlusSvg } from '@src/icons/plus.svg';
import { useToast } from '@src/hooks';
import DefaultCreditCard from '@src/components/molecules/CreditCard/DefaultCreditCard';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import {
  GetMyPaymentSourcesDocument,
  useDeletePaymentSourceMutation,
  useGetMyPaymentSourcesQuery,
  useSetDefaultPaymentSourceMutation,
} from '@src/apollo/hooks';

const ListItem = chakra(motion.li);
const List = chakra(motion.ul);
const Button = chakra(motion.button);
const MotionDiv = chakra(motion.div);

export default function ListPaymentMethods() {
  const toast = useToast();
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { data, loading } = useGetMyPaymentSourcesQuery();
  const [deletePaymentMethod] = useDeletePaymentSourceMutation();
  const [setAsDefault] = useSetDefaultPaymentSourceMutation();
  const handleAddCard = useCallback(() => history.push(`${url}/setup`), [
    history,
    url,
  ]);

  const allCards = data?.getMyPaymentSources?.map((pm) => ({
    ...pm,
    cardExpiry: '11/22',
  })) as PaymentMethodFragment[] | undefined | null;

  const onSetAsDefault = useCallback(
    async (paymentMethod: PaymentMethodType) => {
      const { data } = await setAsDefault({
        variables: { paymentMethodId: paymentMethod.id as string },
        optimisticResponse: {
          __typename: 'RootMutationType',
          setDefaultPaymentSource: {
            __typename: 'PaymentMethodPayload',
            successful: true,
            result: {
              id: paymentMethod.id,
              cardBrand: paymentMethod.cardBrand,
              cardLast4: paymentMethod.cardLast4,
              expMonth: paymentMethod.expMonth,
              expYear: paymentMethod.expYear,
              isDefault: true,
            },
            messages: null,
          },
        },
        update: (proxy, { data }) => {
          const prevData = proxy.readQuery<GetMyPaymentSourcesQuery>({
            query: GetMyPaymentSourcesDocument,
          });
          proxy.writeQuery({
            query: GetMyPaymentSourcesDocument,
            data: {
              ...prevData,
              getMyPaymentSources: prevData?.getMyPaymentSources?.map((pm) => {
                if (pm?.id === data?.setDefaultPaymentSource?.result?.id) {
                  return {
                    ...pm,
                    isDefault: true,
                  };
                }
                return { ...pm, isDefault: false };
              }),
            },
          });
        },
      });
      if (!data?.setDefaultPaymentSource?.successful) {
        data?.setDefaultPaymentSource?.messages?.forEach((error) => {
          toast.warning({
            title: t('common.error'),
            description: error?.message,
          });
        });
        return;
      }

      toast.success({
        isClosable: true,
        title: t('common.success'),
        description: t('my_payments.payment_methods.set_as_default_success'),
      });
    },
    [setAsDefault, toast, t]
  );

  const onDeleteCard = useCallback(
    async (paymentMethodId) => {
      try {
        const { data } = await deletePaymentMethod({
          variables: { paymentMethodId },
          optimisticResponse: {
            __typename: 'RootMutationType',
            deletePaymentSource: {
              __typename: 'BooleanPayload',
              successful: true,
              result: true,
              messages: null,
            },
          },
          update: (proxy) => {
            const data = proxy.readQuery<GetMyPaymentSourcesQuery>({
              query: GetMyPaymentSourcesDocument,
            });
            proxy.writeQuery({
              query: GetMyPaymentSourcesDocument,
              data: {
                ...data,
                getMyPaymentSources: data?.getMyPaymentSources?.filter(
                  (pm) => pm?.id !== paymentMethodId
                ),
              },
            });
          },
        });
        if (!data?.deletePaymentSource?.successful) {
          data?.deletePaymentSource?.messages?.forEach((error) => {
            toast.warning({
              title: t('common.error'),
              description: error?.message,
            });
          });
          return;
        }
        toast.success({
          isClosable: true,
          title: t('common.success'),
          description: t('my_payments.payment_methods.delete_success'),
        });
      } catch (err) {
        //
      }
    },
    [deletePaymentMethod, toast, t]
  );

  if (loading) {
    return (
      <chakra.div d="flex" alignItems="center" justifyContent="center" h="full">
        <Spinner />
      </chakra.div>
    );
  }

  const defaultCard = allCards?.find((card) => card.isDefault);
  const cards = allCards?.filter((card) => !card.isDefault);

  return (
    <chakra.div
      maxW="4xl"
      mx="auto"
      pos="relative"
      px={{ base: 4, sm: 6, lg: 8 }}
      py={8}
    >
      {defaultCard && (
        <AnimatePresence initial={false} exitBeforeEnter>
          <MotionDiv
            key={defaultCard.id}
            d="flex"
            alignItems="center"
            justifyContent="center"
            mb={{ base: 12, lg: 6 }}
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 0, opacity: 0 }}
          >
            <DefaultCreditCard
              cardBrand={defaultCard.cardBrand}
              cardLast4={defaultCard.cardLast4}
              expMonth={defaultCard.expMonth}
              expYear={defaultCard.expYear}
            />
          </MotionDiv>
        </AnimatePresence>
      )}
      <AnimateSharedLayout>
        <List
          layout
          initial={false}
          d="grid"
          gridTemplateColumns={{
            base: 'repeat(1, minmax(0, 1fr))',
            lg: 'repeat(3, minmax(0, 1fr))',
          }}
          gridGap={8}
        >
          {cards?.map((card) => (
            <ListItem
              layout
              initial={false}
              key={card.id}
              d="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CreditCard
                cardBrand={card.cardBrand}
                cardLast4={card.cardLast4}
                expMonth={card.expMonth}
                expYear={card.expYear}
                onDelete={async () => onDeleteCard(card.id)}
                onSetAsDefault={async () =>
                  onSetAsDefault(card as PaymentMethodType)
                }
              />
            </ListItem>
          ))}

          <Button
            layout
            aria-label="Add"
            width="235px"
            height="154px"
            border="1.3px dashed #0D5ED7"
            rounded="2xl"
            outline="none"
            d="flex"
            pt={4}
            role="group"
            onClick={handleAddCard}
            _focus={{ boxShadow: 'outline' }}
            mx="auto"
          >
            <chakra.div p={4} color="secondary">
              <chakra.span
                px={4}
                py={3}
                background="secondary"
                rounded="2xl"
                mr={2}
                _groupHover={{ background: 'orange.400' }}
              >
                <Icon as={PlusSvg} h={4} w={4} color="white" />
              </chakra.span>
              {t('my_payments.add_new_card')}
            </chakra.div>
          </Button>
        </List>
      </AnimateSharedLayout>
    </chakra.div>
  );
}
