import React from 'react';
import {
  AreYouLawyer,
  FeaturesSection,
  HeroSection,
  HowItWorkSection,
  RLMSection,
  WhyWeDoSection,
} from '@components/molecules/LandingSection';
import Layout from '@src/components/organisms/RooLayout/Layout';

const Landing: React.FC = () => {
  return (
    <Layout theme="light">
      <div className="landing-page ">
        <HeroSection />

        <RLMSection />

        <WhyWeDoSection />

        <HowItWorkSection />

        <AreYouLawyer />

        <FeaturesSection />
      </div>
    </Layout>
  );
};

export default Landing;
