import React from 'react';
import { format } from 'date-fns';
import { Box, BoxProps } from '@chakra-ui/react';

export interface DateFormatProps extends BoxProps {
  date: Date;
}

/**
 * Returns the ordinal of a given number
 * @param {number} num
 * @returns {string}  number ordinal
 */

const getOrdinal = (num: number): string => {
  if (num > 3 && num < 21) return 'th';
  switch (num % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const DaySuffix = ({ date, ...props }: DateFormatProps) => {
  const dayNumber = parseInt(format(date, 'd'));
  return <Box {...props}>{getOrdinal(dayNumber)}</Box>;
};

export default DaySuffix;
