import React from 'react';
import useLocalAudioToggle from '@src/hooks/meeting/useLocalAudioToggle';
import {
  ButtonProps,
  Icon,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactComponent as Microphone } from '@src/icons/microphone.svg';
import { ReactComponent as MicrophoneOff } from '@src/icons/microphone-off.svg';
import { useVideoContext } from '@src/context/VideoContext';

export type ToggleAudioProps = ButtonProps;

const CallControlsButtonToggleAudio = ({
  isDisabled,
  variant = 'outline',
  color = 'currentcolor',
  ...props
}: ToggleAudioProps) => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localStreams } = useVideoContext();
  const size = useBreakpointValue({ base: 'md', md: 'lg' });
  const hasAudioTrack = localStreams.some((track) => track.kind === 'audio');

  return (
    <IconButton
      isRound
      aria-label="microphone-btn"
      colorScheme={isAudioEnabled ? 'white' : 'red'}
      variant={isAudioEnabled ? variant : 'solid'}
      color={isAudioEnabled ? color : 'white'}
      size={size}
      onClick={toggleAudioEnabled}
      isDisabled={!hasAudioTrack || isDisabled}
      _hover={{
        bg: isAudioEnabled
          ? variant === 'outline'
            ? 'rgba(255,255,255,0.4)'
            : 'white'
          : 'red.500',
      }}
      _focus={{ boxShadow: 'none' }}
      {...props}
    >
      <Icon
        as={isAudioEnabled ? Microphone : MicrophoneOff}
        w={{ base: 5, sm: 6 }}
        h={{ base: 5, sm: 6 }}
        transition="ease-in-out"
      />
    </IconButton>
  );
};

export default CallControlsButtonToggleAudio;
