/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from './constants';
import { useCallback, useState } from 'react';
import {
  LocalMediaStream,
  CreateStreamOptions,
  createVideoStream,
  createAudioStream,
  createLocalStreams,
  LocalVideoStream,
  LocalAudioStream,
  LocalStream,
} from '@src/video';

import { useAudioInputDevices, useVideoInputDevices } from './useDevice';

export default function useLocalStreams() {
  const [audioStream, setAudioStream] = useState<LocalAudioStream>();
  const [videoStream, setVideoStream] = useState<LocalVideoStream>();
  const [isAcquiringLocalStreams, setIsAcquiringLocalStreams] = useState(false);

  const localAudioDevices = useAudioInputDevices();
  const localVideoDevices = useVideoInputDevices();

  const hasAudio = localAudioDevices.length > 0;
  const hasVideo = localVideoDevices.length > 0;

  const getLocalAudioStream = useCallback((deviceId?: string) => {
    const options: CreateStreamOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return createAudioStream(options).then((newStream: LocalAudioStream) => {
      setAudioStream(newStream);
      return newStream;
    });
  }, []);

  const getLocalVideoStream = useCallback(
    (newOptions?: CreateStreamOptions) => {
      const options: CreateStreamOptions = {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...newOptions,
      };

      return createVideoStream(options).then((newStream: LocalVideoStream) => {
        setVideoStream(newStream);
        return newStream;
      });
    },
    []
  );

  const removeLocalVideoStream = useCallback(() => {
    if (videoStream) {
      videoStream.stop();
      setVideoStream(undefined);
    }
  }, [videoStream]);

  const getAudioAndVideoStreams = useCallback(() => {
    if (!hasAudio && !hasVideo) return Promise.resolve();
    if (isAcquiringLocalStreams || audioStream || videoStream)
      return Promise.resolve();

    setIsAcquiringLocalStreams(true);

    const selectedAudioDeviceId = window.localStorage.getItem(
      SELECTED_AUDIO_INPUT_KEY
    );
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    );

    const hasSelectedAudioDevice = localAudioDevices.some(
      (device) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = localVideoDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const localStreamConstraints = {
      video: hasVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: { exact: selectedVideoDeviceId! },
        }),
      },
      audio: hasSelectedAudioDevice
        ? { deviceId: { exact: selectedAudioDeviceId! } }
        : hasAudio,
    };

    return createLocalStreams(localStreamConstraints)
      .then((tracks: LocalMediaStream[]) => {
        const videoStream = tracks.find(
          (track) => track.kind === 'video'
        ) as LocalVideoStream;

        const audioStream = tracks.find(
          (track) => track.kind === 'audio'
        ) as LocalAudioStream;

        if (videoStream) {
          setVideoStream(videoStream);
        }
        if (audioStream) {
          setAudioStream(audioStream);
        }
      })
      .finally(() => setIsAcquiringLocalStreams(false));
  }, [
    hasAudio,
    hasVideo,
    audioStream,
    videoStream,
    localAudioDevices,
    localVideoDevices,
    isAcquiringLocalStreams,
  ]);

  const localStreams = [audioStream, videoStream].filter(
    (track) => track !== undefined
  ) as LocalStream[];

  return {
    localStreams,
    getLocalVideoStream,
    getLocalAudioStream,
    isAcquiringLocalStreams,
    removeLocalVideoStream,
    getAudioAndVideoStreams,
  };
}
