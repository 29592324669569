import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Link from '@src/components/atoms/Link';

export const AreYouLawyer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      position="relative"
      backgroundImage={{
        base: "url('/images/are-you-bg-base.png')",
        md: "url('/images/are-you-bg.png')",
      }}
      backgroundColor="white"
      backgroundPosition={{ base: '100% 100%', md: '50% 0', xxl: '12% 42%' }}
      backgroundRepeat="no-repeat"
      backgroundSize={{ base: 'contain', md: 'cover' }}
      w="full"
      d="flex"
      flexDirection="column"
      justifyContent={{ base: 'flex-start', md: 'center' }}
      zIndex={1}
      mt={{ sm: -8 }}
      className="clip-image-top-bottom-right"
    >
      <Box
        maxW="5xl"
        position="relative"
        mx="auto"
        pl={{ base: 0, md: 8, lg: 12, xl: 0 }}
        py={{ base: 16, sm: 24, lg: 32 }}
      >
        <Box
          w={{ base: '75%', md: '48%', lg: '40%', xl: '36%' }}
          textAlign={{ base: 'center', md: 'left' }}
          display={{ base: 'flex', md: 'block' }}
          alignItems={{ base: 'center', md: 'left' }}
          flexDir="column"
          margin={{ base: 'auto', md: '0' }}
          pb={{ base: 32, sm: 0 }}
        >
          <Heading as="h2" color="black" fontSize={{ base: '2xl', md: '4xl' }}>
            {t('landing.are_you_lawyer_section.are_you_a_lawyer')}
          </Heading>
          <Text fontSize="base" color="gray.600" lineHeight={6} mt={8}>
            {t('landing.are_you_lawyer_section.paragraph1')}
          </Text>
          <Text fontSize="base" color="gray.600" lineHeight={6} mt={6}>
            {t('landing.are_you_lawyer_section.paragraph2')}
          </Text>
          <Button
            as={Link}
            colorScheme="orange"
            mt={8}
            fontSize="sm"
            fontWeight="semibold"
            variant="rooLarge"
            size="lg"
            textTransform="uppercase"
            to="/lawyers"
          >
            {t('common.join_as_lawyer')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AreYouLawyer;
