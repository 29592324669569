import React from 'react';
import { Icon, IconProps } from '@chakra-ui/icon';
import { ReactComponent as VisaSvg } from '@src/icons/card-issuer/visa.svg';
import { ReactComponent as MastercardSvg } from '@src/icons/card-issuer/mastercard.svg';
import { ReactComponent as MastercardShadowSvg } from '@src/icons/card-issuer/mastercard-card-shadow.svg';
import { ReactComponent as VisaShadowSvg } from '@src/icons/card-issuer/visa-card-shadow.svg';
import { ReactComponent as VisaBackgroundSvg } from '@src/icons/card-issuer/visa-background.svg';
import { ReactComponent as MastercardBackgroundSvg } from '@src/icons/card-issuer/mastercard-background.svg';

export const VisaIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} as={VisaSvg} viewBox="0 0 24 24" />
);

export const MastercardIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} as={MastercardSvg} viewBox="0 0 24 24" />
);

export const VisaShadowIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} as={VisaShadowSvg} />
);

export const MastercardShadowIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} as={MastercardShadowSvg} />
);

export const VisaBackgroundIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} as={VisaBackgroundSvg} />
);

export const MastercardBackgroundIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} as={MastercardBackgroundSvg} />
);
