import React from 'react';
import { FormControl, FormControlProps } from '@chakra-ui/react';
import { useField } from 'react-final-form';

export type ControlProps = FormControlProps & {
  name: string;
};

export const Control = ({ name, ...rest }: ControlProps) => {
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { touched: true, error: true } });
  return <FormControl isInvalid={error && touched} {...rest} />;
};
