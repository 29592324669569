import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Box, Text, Button } from '@chakra-ui/react';
import { CheckboxArray } from '@atoms/Fields';

export interface PracticeAreaFilterProps {
  activeFilter: { practiceArea: string[] };
  onFilterChange: (areas: string[]) => void;
  allLegalPractices: LegalPracticeType[];
}
export const PracticeAreaFilter: React.FC<PracticeAreaFilterProps> = ({
  onFilterChange,
  activeFilter,
  allLegalPractices,
}: PracticeAreaFilterProps) => {
  const { t } = useTranslation();

  return (
    <Box d="flex" flexDir="column" m="5" alignItems="flex-start">
      <Text as="strong" fontSize="lg">
        {t('directory.refine_your_search')}
      </Text>
      <Form onSubmit={onFilterChange} initialValues={activeFilter}>
        {({ handleSubmit }) => {
          return (
            <Box d="flex" flexDir="row">
              <form onSubmit={handleSubmit}>
                {allLegalPractices.map((option, key) => {
                  return (
                    <Box key={key}>
                      <CheckboxArray
                        my={2}
                        name="practiceArea"
                        value={option.slug}
                        colorScheme="orange"
                      >
                        {t(`practice_areas.${option.slug}`, option.name)}
                      </CheckboxArray>
                    </Box>
                  );
                })}
                <Box
                  d="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  mt={4}
                >
                  <Button colorScheme="primary" type="submit">
                    {t('common.filter')}
                  </Button>
                </Box>
              </form>
            </Box>
          );
        }}
      </Form>
    </Box>
  );
};

export default PracticeAreaFilter;
