import React, { useCallback, useMemo } from 'react';
import { Box, Text, Button, Stack, VStack, Checkbox } from '@chakra-ui/react';
import Link from '@atoms/Link';
import { useTranslation } from 'react-i18next';
import { FORM_ERROR } from 'final-form';
import { format, parse } from 'date-fns';
import {
  GetMyLawyerApplicationDocument,
  useGetMyLawyerApplicationQuery,
  useUpdateMyLawyerApplicationMutation,
  useSubmitMyLawyerApplicationForReviewMutation,
} from '@src/apollo/hooks';
import ApplicationExtraInformation from '@molecules/ApplicationForm/ApplicationExtraInformation';
import BasicInformation from '@molecules/ApplicationForm/BasicInformation';
import Loader from '@atoms/Loader';
import AlertDialogWrapper from '@atoms/AlertDialog';
import DateFormat from '@src/components/atoms/DateFormat';
import { dollarToCents, centsToDollar } from '@src/utils';
import { useToast } from '@src/hooks';
import { uniq } from 'lodash';

export interface LawyerApplicationFormReturnValues {
  bio: Maybe<string>;
  phone: string;
  hourlyRate: number;
  legalPractices: Option[];
  spokenLanguages: Option[];
  workingHours: { from: Date; to: Date; days: string[] };
}

function LawyerApplicationStatus() {
  const { t } = useTranslation();
  const toast = useToast();
  const { data, loading } = useGetMyLawyerApplicationQuery();
  const [updateMyLawyerApplication] = useUpdateMyLawyerApplicationMutation({
    refetchQueries: [{ query: GetMyLawyerApplicationDocument }],
    awaitRefetchQueries: true,
  });
  const [
    submitMyLawyerApplicationForReview,
  ] = useSubmitMyLawyerApplicationForReviewMutation({
    refetchQueries: [{ query: GetMyLawyerApplicationDocument }],
    awaitRefetchQueries: true,
  });

  const applicationData = data?.getMyLawyerApplication;
  const phone = applicationData?.phone;

  const submitForReview = () => {
    if (data?.getMyLawyerApplication?.id) {
      submitMyLawyerApplicationForReview();
    }
    window.scrollTo(0, 0);
  };

  const updateApplicationExtras = useCallback(
    async (values: LawyerApplicationFormReturnValues) => {
      try {
        const practiceSlugs = values.legalPractices.map(
          (practice) => practice.value
        );
        const languageCodes = values.spokenLanguages.map(
          (language) => language.value
        );
        const parsedWorkingHours = uniq(values.workingHours.days).map((day) => {
          return {
            name: day,
            from: format(values.workingHours.from, 'HH:mm:ss'),
            to: format(values.workingHours.to, 'HH:mm:ss'),
          };
        });
        const updateValues = {
          bio: values.bio,
          phone: phone || '',
          hourlyRate: dollarToCents(values.hourlyRate),
          spokenLanguages: { codes: languageCodes },
          legalPractices: { slugs: practiceSlugs },
          workingHours: {
            hours: parsedWorkingHours,
          },
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const { data } = await updateMyLawyerApplication({
          variables: {
            input: updateValues,
          },
        });

        if (!data?.updateMyLawyerApplication?.successful) {
          data?.updateMyLawyerApplication?.messages?.forEach((error) => {
            toast.warning({
              title: t('common.error'),
              description: error?.message,
              isClosable: true,
            });
          });
          return;
        }
        toast.success({
          title: t('common.success'),
          description: t('profile.toast.updated_profile.title'),
          isClosable: true,
        });
        window.scrollTo(0, 0);
      } catch (err) {
        toast.warning({
          title: err.description,
          isClosable: true,
        });
        return { [FORM_ERROR]: err.description };
      }
    },
    [updateMyLawyerApplication, toast, t, phone]
  );

  const defaultValues = useMemo(() => {
    return {
      bio: applicationData?.bio,
      phone: applicationData?.phone || '',
      hourlyRate: centsToDollar(applicationData?.hourlyRate || 0),
      spokenLanguages: applicationData?.spokenLanguages?.codes,
      legalPractices: applicationData?.legalPractices?.slugs,
      workingHours: {
        from: parse(
          applicationData?.workingHours?.hours[0]?.from || '08:00:00',
          'HH:mm:ss',
          new Date()
        ),
        to: parse(
          applicationData?.workingHours?.hours[0]?.to || '15:00:00',
          'HH:mm:ss',
          new Date()
        ),
        days:
          applicationData?.workingHours?.hours.length > 0
            ? uniq(
                applicationData?.workingHours?.hours.map(
                  (workingOn: LawyerTimeSlotsInput) => workingOn.name
                )
              )
            : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
      },
    };
  }, [applicationData]);

  if (loading) return <Loader />;
  const {
    bio,
    phone: extraPhone,
    hourlyRate,
    spokenLanguages,
    legalPractices,
    workingHours,
  } = defaultValues;

  const canSubmit =
    (bio || extraPhone) !== '' &&
    hourlyRate !== 0 &&
    (spokenLanguages.length && legalPractices.length) !== 0 &&
    workingHours.days;

  return (
    <>
      <Text
        color="white"
        fontSize="3xl"
        fontWeight="200"
        textAlign="center"
        mt={16}
        mb={20}
      >
        {t('lawyer_application.your_application')}
      </Text>

      <Box
        backgroundColor="blue.50"
        backgroundPosition="center"
        w="full"
        h="auto"
        minHeight="100vh"
        zIndex={2}
      >
        <Box maxW="6xl" mx="auto" px={6}>
          <Box
            position="relative"
            top="-50"
            d="flex"
            w="full"
            flexDirection="column"
            justifyContent="top"
            bgColor="white"
            padding={{ base: 6, md: 50 }}
            rounded={15}
            shadow={'0px 0px 40px rgba(0, 0, 0, 0.15)'}
          >
            <VStack
              spacing={4}
              alignItems="flex-start"
              w="90%"
              my={8}
              mx="auto"
            >
              <Text fontSize="2xl">
                <Text as="span" textStyle="strongBlue">
                  {t('lawyer_application.status_title')}{' '}
                </Text>
                <Text as="span" textStyle="strongYellow">
                  {t(`lawyer_application.status.${applicationData?.status}`)}{' '}
                </Text>
              </Text>
              <Text>{t('lawyer_application.submit_prompt')}</Text>
              <Text textStyle="strongBlue">
                {t('lawyer_application.created_at')}
                {'  '}
                <DateFormat date={new Date(applicationData?.insertedAt)} />
              </Text>
              <Stack
                w="100%"
                bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
                rounded="lg"
                p={6}
              >
                <Text as="span" textStyle="strongBlue">
                  {t('lawyer_application.basic_information')}
                </Text>

                {applicationData && (
                  <BasicInformation applicationData={applicationData} />
                )}
              </Stack>

              <Stack
                w="100%"
                bg="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
                rounded="lg"
                p={6}
                mt={4}
              >
                <Text as="span" textStyle="strongBlue">
                  {t('lawyer_application.extra_information')}
                </Text>
                <ApplicationExtraInformation
                  canUpdate={applicationData?.status === 'draft'}
                  initialValues={defaultValues}
                  onSubmit={updateApplicationExtras}
                />
              </Stack>

              {applicationData?.status !== 'draft' && (
                <Stack w="100%" bg="orange.100" rounded="lg" p={6}>
                  <Text as="span" textStyle="strongYellow">
                    {t('lawyer_application.application_feedback')}
                  </Text>
                  <Text fontSize={'sm'}>
                    {t('lawyer_application.application_feedback_description')}
                  </Text>
                  {applicationData?.feedback?.map((feedback, index) => {
                    return (
                      <Box key={index}>
                        <Checkbox
                          colorScheme={'yellow'}
                          isChecked={!!feedback?.completedAt}
                        >
                          <Text
                            whiteSpace="pre-wrap"
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {t(
                              `lawyer_application.feedback.${feedback?.feedback}`
                            )}
                          </Text>
                        </Checkbox>
                      </Box>
                    );
                  })}
                </Stack>
              )}

              {applicationData?.status === 'draft' && canSubmit && (
                <Box d="flex" justifyContent="flex-end" w="100%" pr={'1rem'}>
                  <AlertDialogWrapper
                    buttonText={t('lawyer_application.submit_for_review')}
                    onSubmit={submitForReview}
                    submitText={t('common.continue')}
                    cancelText={t('common.cancel')}
                    dialogDescription={t(
                      'lawyer_application.submit_warning_description'
                    )}
                    dialogHeader={t('lawyer_application.submit_warning_header')}
                  />
                </Box>
              )}
              {applicationData?.status !== 'draft' && (
                <Button
                  as={Link}
                  variant="solid"
                  colorScheme="blue"
                  alignSelf="flex-end"
                  to="/"
                >
                  {t('common.main_page')}
                </Button>
              )}
            </VStack>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LawyerApplicationStatus;
