import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverFooter,
  PopoverBody,
  chakra,
  Button,
  Stack,
  VisuallyHidden,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { ReactComponent as ChevronDownIcon } from '@src/icons/arrow-down.svg';
import { ReactComponent as FilterIcon } from '@src/icons/filter.svg';
import { useMeetingListFilter, UseMeetingListFilterOptions } from '@src/hooks';

const StyledDatePicker = chakra(DatePicker);

export type FilterOptions =
  | 'currentMonth'
  | 'pastMonth'
  | 'pastSixMonths'
  | 'withinInterval';

export interface MeetingFilterProps extends UseMeetingListFilterOptions {
  selected?: FilterOptions;
}

const currentDate = new Date();

export const MeetingFilter = ({ selected, onChange }: MeetingFilterProps) => {
  const { t } = useTranslation();
  const { menu, rangePicker } = useMeetingListFilter({ onChange });
  const [isLargerMobile] = useMediaQuery('(min-width: 48em)');

  return (
    <Menu isOpen={menu.isOpen} onClose={menu.onClose} closeOnSelect={false}>
      {isLargerMobile ? (
        <MenuButton
          as={Button}
          variant="link"
          rightIcon={<ChevronDownIcon />}
          color="black"
          _active={{ color: 'black' }}
          onClick={menu.onOpen}
        >
          {t('my_meetings.select_date_range')}
        </MenuButton>
      ) : (
        <MenuButton
          as={IconButton}
          aria-label="Filters"
          variant="link"
          icon={<FilterIcon />}
          pos="absolute"
          top={-16}
          right={3}
          color="gray.600"
          onClick={menu.onOpen}
        />
      )}
      <MenuList>
        <MenuOptionGroup onChange={menu.onChange} value={selected} type="radio">
          <MenuItemOption value="currentMonth">
            {t('my_meetings.filter.currentMonth')}
          </MenuItemOption>
          <MenuItemOption value="pastMonth">
            {t('my_meetings.filter.pastMonth')}
          </MenuItemOption>
          <MenuItemOption value="pastSixMonths">
            {t('my_meetings.filter.pastSixMonths')}
          </MenuItemOption>
          <MenuItemOption value="withinInterval" onClick={rangePicker.onOpen}>
            {t('my_meetings.filter.withinInterval')}
          </MenuItemOption>
        </MenuOptionGroup>
        {rangePicker.isOpen && (
          <Popover
            isOpen={rangePicker.isOpen}
            onClose={rangePicker.onClose}
            returnFocusOnClose={false}
            closeOnBlur={false}
            placement="bottom"
          >
            <PopoverTrigger>
              <chakra.button position="absolute">
                <VisuallyHidden>
                  {t('my_meetings.select_date_range')}
                </VisuallyHidden>
              </chakra.button>
            </PopoverTrigger>
            <PopoverContent maxW="xs" w="300px" mt={1} rounded="base">
              <PopoverBody>
                <Stack
                  pt={6}
                  direction="row"
                  align="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StyledDatePicker
                    selected={rangePicker.startDate}
                    onChange={rangePicker.onChange}
                    startDate={rangePicker.startDate}
                    endDate={rangePicker.endDate}
                    maxDate={currentDate}
                    selectsRange
                    inline
                  />
                </Stack>
              </PopoverBody>
              <PopoverFooter>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  pr={4}
                  spacing={6}
                >
                  <Button
                    variant="link"
                    size="sm"
                    onClick={rangePicker.onClose}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={rangePicker.onApply}
                  >
                    {t('common.apply')}
                  </Button>
                </Stack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        )}
      </MenuList>
    </Menu>
  );
};

export default MeetingFilter;
