import React, { useState, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Box, Text, Stack, VStack, Button, Checkbox } from '@chakra-ui/react';
import { TextField, Select } from '@src/components/atoms/Fields';
import { useGetAllMeetsCountriesQuery } from '@src/apollo/hooks';
import { stripePromise } from '@src/utils';
import titleOptions from '@src/content/titleOptions';
import PaymentMethod from './ApplicationPaymentMethod';
import {
  LawyerApplicationProps,
  LawyerProfileFormValues,
} from './LawyerApplicationType';
import { useFormValidation } from '@src/hooks';

const LawyerApplicationForm = ({
  viewer,
  onSaveData,
}: LawyerApplicationProps) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { data } = useGetAllMeetsCountriesQuery();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childRef = useRef<any>(null);
  const { t } = useTranslation();
  const { validPhone } = useFormValidation();

  const onSubmit = (values: LawyerProfileFormValues) => {
    checkPaymentMethod().then((method) => {
      const withDefaultValues = {
        ...values,
        spokenLanguages: '{"codes":["es"]}',
        workingHours:
          '[{name: "monday", from: 8, to: 15},{name: "tuesday", from: 8, to: 15}, {name: "wednesday", from: 8, to: 15},{name: "thursday", from: 8, to: 15},{name: "friday", from: 8, to: 15}]',
        legalPractices: '{"slugs": ["administrative-law"]}',
      };
      const applicationValues = {
        input: {
          ...withDefaultValues,
        },
        paymentMethod: method,
      };
      window.scrollTo(0, 0);
      onSaveData(applicationValues);
    });
  };

  let initialValues = {};

  if (viewer && viewer.profile) {
    initialValues = {
      firstName: viewer.profile.firstName,
      lastName: viewer.profile.lastName,
      email: viewer.email,
    };
  }

  const checkPaymentMethod = async () => {
    if (childRef && childRef.current) {
      return await childRef.current.processMethod();
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" p={{ base: 4, md: 10 }} onSubmit={handleSubmit}>
          <Box mb={4}>
            <Text whiteSpace="pre-wrap">
              {t('lawyer_application.description')}
            </Text>
          </Box>

          <VStack spacing={4} alignItems="flex-start">
            <Stack direction={['column', 'row']} w="full">
              <TextField
                isRequired
                isDisabled={viewer ? true : false}
                name="firstName"
                label={t('fields.firstName.label')}
              />
              <TextField
                isRequired
                isDisabled={viewer ? true : false}
                name="lastName"
                label={t('fields.lastName.label')}
              />
            </Stack>
            <Stack direction={['column', 'row']} w="full">
              <TextField
                isRequired
                isDisabled={viewer ? true : false}
                type="email"
                name="email"
                label={t('fields.email.label')}
              />
              <TextField
                isRequired
                name="phone"
                label={t('fields.phone.label')}
                fieldProps={{ validate: validPhone() }}
              />
            </Stack>
            {!viewer && (
              <TextField
                isRequired
                type="password"
                name="password"
                label={t('fields.password.label')}
              />
            )}
            <Stack direction={['column', 'row']} w="full">
              <Select
                name="title"
                label={t('fields.title.label')}
                placeholder={t('fields.title.placeholder')}
                isRequired
              >
                {titleOptions.titleOptions.map((option: string) => {
                  return (
                    <Box key={option} as="option" value={option}>
                      {t(`fields.title_options.${option}`)}
                    </Box>
                  );
                })}
              </Select>

              <Select
                name="countryId"
                label={t('fields.country.label')}
                fieldProps={{ parse: (value) => value }}
                placeholder={t('fields.country.placeholder')}
                isRequired
              >
                <Box as="option" disabled hidden d="none" value=""></Box>
                {data?.getAllMeetsCountries?.map((country) => {
                  if (!country) return null;
                  return (
                    <Box as="option" key={country.id} value={country.code}>
                      {country.name}
                    </Box>
                  );
                })}
              </Select>
            </Stack>

            <Box width="100%" d="flex" justifyContent="center" flexDir="column">
              <Text color="black" fontSize="2xl" mb={6}>
                {t('lawyer_application.application_fee')}
              </Text>
              <Text>
                {' '}
                {t('lawyer_application.application_fee_description')}
              </Text>

              <Elements stripe={stripePromise}>
                <Box d="flex" flexDir="column">
                  <PaymentMethod ref={childRef} />

                  <Box
                    d="flex"
                    justifyContent="center"
                    padding={2}
                    my={2}
                    borderRadius={16}
                  >
                    <Checkbox
                      checked={acceptedTerms}
                      mr={2}
                      borderColor={'blue.500'}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                    >
                      <Text color="black" fontSize="xs">
                        {t('lawyer_application.by_applying')}{' '}
                        <Text color="black" fontWeight="semibold" as="span">
                          {t('common.termsOfUse')}
                        </Text>{' '}
                        &amp;{' '}
                        <Text as="span" color="black" fontWeight="semibold">
                          {t('common.privacy_policy')}
                        </Text>
                      </Text>
                    </Checkbox>
                  </Box>
                  <Button
                    alignSelf="center"
                    colorScheme="orange"
                    isLoading={submitting}
                    px={10}
                    disabled={!acceptedTerms}
                    type="submit"
                  >
                    {t('common.confirm_data')}
                  </Button>
                </Box>
              </Elements>
            </Box>
          </VStack>
        </Box>
      )}
    />
  );
};

export default LawyerApplicationForm;
