export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'LegarooMeet-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'LegarooMeet-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'LegarooMeet-selectedVideoInput';
