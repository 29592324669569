export interface FormLabelProps {
  cardCVC?: string;
  cardNumber?: string;
  cardExpiry?: string;
}

export interface CardSetupFormProps {
  labels?: FormLabelProps;
  onSubmit(e?: React.FormEvent<HTMLFormElement>): Promise<void>;
  isLoading?: boolean;
  showSetAsDefaultField?: boolean;
}

export interface LawyerApplicationType {
  input: LawyerProfileFormValues;
  paymentMethod: PaymentMethodType;
}
export interface LawyerApplicationProps {
  viewer?: Viewer | null;
  onSaveData: (data: LawyerApplicationType) => void;
}

export interface LawyerApplicationConfirmationProps {
  applicationData: LawyerApplicationType;
  onApplicationCreated(application: ApplicationCreationPayload): void;
}
export interface LawyerApplicationResultProps {
  application: ApplicationCreationPayload;
}
export interface LawyerProfileFormValues {
  firstName: string;
  lastName: string;
  countryId: string;
  title: string;
  phone: string;
  email: string;
  password?: string | undefined | null;
}

const DEFAULT_ELEMENT_STYLE = {
  base: {
    fontWeight: '600',
    color: '#0747a6',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    lineHeight: '1.5',
    '::placeholder': {
      color: '#c1cddd',
      fontWeight: '400',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const CARD_NUMBER_ELEMENT_OPTIONS = {
  style: DEFAULT_ELEMENT_STYLE,
  showIcon: true,
};

const CARD_ELEMENT_OPTIONS = {
  style: DEFAULT_ELEMENT_STYLE,
};

export {
  CARD_ELEMENT_OPTIONS,
  CARD_NUMBER_ELEMENT_OPTIONS,
  DEFAULT_ELEMENT_STYLE,
};
