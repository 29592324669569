import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/system';
import React from 'react';
import {
  ArrowIndicator,
  SelectButton,
  SelectControl,
  SelectSearchInput,
  SelectValueContainer,
} from './SelectControl';
import { ReactComponent as ChevronDownIcon } from '@src/icons/chevron-down-icon.svg';
import { SelectedItemTag, SelectMulti, SelectProps } from './SelectMulti';
import { SelectMenu, SelectMenuList, SelectOption } from './SelectMenu';
import { DownshiftProps } from 'downshift';
import { FormControlOptions } from '@chakra-ui/form-control';

export interface Option {
  value: string;
  label: string;
}

interface FilterOptionArgs<Item> {
  items: Item[];
  selectedItems: Item[];
  getOptionLabel: (item: Item | null) => string;
  inputValue: string | null;
}

function defaultFilterOption<Item>({
  items,
  inputValue,
  selectedItems,
  getOptionLabel,
}: FilterOptionArgs<Item>): Item[] {
  return items.filter((item) => {
    if (inputValue) {
      return (
        selectedItems.indexOf(item) < 0 &&
        getOptionLabel(item).toLowerCase().startsWith(inputValue.toLowerCase())
      );
    }
    return selectedItems.indexOf(item) < 0;
  });
}

export type SelectWrapperProps<Item> = FormControlOptions &
  Pick<SelectProps<Item>, 'value' | 'onChange' | 'defaultValue'> & {
    options: Item[];
    placeholder?: string;
    isSearchable?: boolean;
    noOptionsMessage?(inputValue: string | null): string | null;
    getOptionLabel?: DownshiftProps<Item>['itemToString'];
    getOptionKey?: (item: Item) => string;
    filterOption?(args: FilterOptionArgs<Item>): Item[];
  };

export default function SelectWrapper<Item = Option>({
  options,
  getOptionLabel = (i) => (i === null ? '' : ((i as unknown) as Option).label),
  getOptionKey = (i) => (i === null ? '' : ((i as unknown) as Option).value),
  filterOption = defaultFilterOption,
  placeholder,
  isSearchable,
  value,
  onChange,
  isDisabled,
  noOptionsMessage = () => 'No options',
}: SelectWrapperProps<Item>) {
  return (
    <SelectMulti
      isDisabled={isDisabled}
      itemToString={getOptionLabel}
      value={value}
      onChange={onChange}
    >
      {({ selectedItems, inputValue }) => {
        const showPlaceholder =
          selectedItems.length <= 0 && !isSearchable && !!placeholder;
        const items = filterOption({
          items: options,
          selectedItems,
          inputValue,
          getOptionLabel,
        });
        const noOptionsMsg = noOptionsMessage(inputValue);
        const showNoOptionsMsg = items.length <= 0 && !!noOptionsMsg;
        return (
          <>
            <SelectControl>
              <SelectValueContainer>
                {selectedItems?.map((selectedItem, index) => (
                  <SelectedItemTag
                    key={`selected-item-${index}`}
                    index={index}
                    selectedItem={selectedItem}
                  >
                    {getOptionLabel(selectedItem)}
                  </SelectedItemTag>
                ))}
                {isSearchable && (
                  <SelectSearchInput placeholder={placeholder} />
                )}
                {showPlaceholder && (
                  <chakra.span color="gray.400" fontWeight="normal">
                    {placeholder}
                  </chakra.span>
                )}
              </SelectValueContainer>
              <SelectButton aria-label="toggle menu">
                <ArrowIndicator>
                  <Icon as={ChevronDownIcon} h={3} w={3} color="black" />
                </ArrowIndicator>
              </SelectButton>
            </SelectControl>
            <SelectMenu>
              <SelectMenuList>
                {items.map((option, index) => {
                  const key = getOptionKey(option);
                  const label = getOptionLabel(option);
                  return (
                    <SelectOption
                      key={JSON.stringify(key)}
                      value={option}
                      index={index}
                    >
                      {label}
                    </SelectOption>
                  );
                })}
                {showNoOptionsMsg && (
                  <chakra.div py={2} pl={3} pr={9} color="gray.900">
                    {noOptionsMsg}
                  </chakra.div>
                )}
              </SelectMenuList>
            </SelectMenu>
          </>
        );
      }}
    </SelectMulti>
  );
}
