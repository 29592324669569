import { useContext } from 'react';
import { SelectedParticipantContext } from '@src/context';
import { useVideoContext } from '@src/context/VideoContext';

import useDominantSpeaker from './useDominantSpeaker';
import useParticipants from './useParticipants';

export default function useMainParticipant() {
  const { selectedParticipant } = useContext(SelectedParticipantContext);
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();

  const {
    meeting: { localParticipant },
  } = useVideoContext();

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    selectedParticipant ||
    dominantSpeaker ||
    participants[0] ||
    localParticipant
  );
}
