import { mode } from '@chakra-ui/theme-tools';

const parts = ['errorText', 'errorIcon', 'requiredIndicator', 'helperText'];

function baseStyleErrorText(props: Dict) {
  return {
    display: 'block',
    color: mode('red.500', 'red.300')(props),
    mt: 2,
    mx: { base: 3, md: 6 },
    fontSize: 'xs',
  };
}

function baseStyleRequiredIndicator(props: Dict) {
  return {
    ml: 1,
    color: mode('red.500', 'red.300')(props),
  };
}

function baseStyleHelperText(props: Dict) {
  return {
    mt: 2,
    color: mode('gray.500', 'whiteAlpha.600')(props),
    lineHeight: 'normal',
    fontSize: 'sm',
  };
}

function baseStyleErrorIcon(props: Dict) {
  return {
    mr: '0.5em',
    color: mode('red.500', 'red.300')(props),
  };
}

const baseStyle = (props: Dict) => ({
  errorText: baseStyleErrorText(props),
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props),
  errorIcon: baseStyleErrorIcon(props),
});

export default {
  parts,
  baseStyle,
};
