import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Icon,
  IconButton,
  Button,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
} from '@chakra-ui/react';
import { ReactComponent as FilterIcon } from '@src/icons/filter.svg';
import { ReactComponent as CloseIcon } from '@src/icons/close.svg';
import { ReactComponent as ChevronDownIcon } from '@src/icons/chevron-down-icon.svg';
import { useTranslation } from 'react-i18next';
import { SortPopOver } from '@molecules/Directory';
import PracticeAreaFilter from './PracticeAreaFilter';
import { lawyerSortOptions } from '@src/content/config';

export interface SearchHeaderProps {
  onSortByChange: (sort: string) => void;
  selectedSortBy: string;
  filterOpen: boolean;
  setFilterOpen: (open: boolean) => void;
  showingAmount: number;
  totalItems: number;
  activeCountry?: string;
  onPracticeAreaChange(value: string[]): void;
  selectedPracticeArea: { practiceArea: string[] };
  allLegalPractices: LegalPracticeType[];
}
export const SearchHeader: React.FC<SearchHeaderProps> = ({
  onSortByChange,
  selectedSortBy,
  filterOpen,
  setFilterOpen,
  showingAmount,
  activeCountry,
  onPracticeAreaChange,
  selectedPracticeArea,
  totalItems,
  allLegalPractices,
}: SearchHeaderProps) => {
  const { t } = useTranslation();
  const [activeFilterTab, setActiveFilterTab] = useState(0);

  const setActiveFilterTo = (tab: number) => {
    setActiveFilterTab(tab);
    if (!filterOpen) return setFilterOpen(true);
  };

  return (
    <Box>
      <Box d="flex" zIndex={1}>
        <Box w={{ base: '80%', lg: '60%' }}>
          {activeCountry && (
            <Heading as="h3" fontSize={{ base: 'xl', md: '3xl' }}>
              <Box as="span" marginRight="5px" color="black">
                {t('directory.lawyers_in')}
              </Box>
              <Box as="span" color="orange.500">
                {activeCountry}
              </Box>
            </Heading>
          )}
          <Box
            fontSize={{ base: 'sm', md: 'base' }}
            color="gray.600"
            lineHeight={6}
          >
            {t('directory.showing')}{' '}
            <Box as="span" fontWeight="700">
              {showingAmount}
            </Box>{' '}
            {t('directory.results_of')}{' '}
            <Box as="span" fontWeight="700">
              {totalItems}
            </Box>
          </Box>
        </Box>
        <Box
          w={{ base: '20%', lg: '40%' }}
          bgColor="white"
          position="absolute"
          top="0"
          right="0"
          pt={{ base: 8, lg: 50 }}
          borderTopRightRadius={14}
        >
          <IconButton
            aria-label="close-btn"
            position="absolute"
            right={5}
            top={5}
            color={filterOpen ? 'gray.600' : 'white'}
            as={CloseIcon}
            variant="unstyled"
            cursor="pointer"
            size="xxs"
            onClick={() => {
              setFilterOpen(false);
            }}
          />
          <Box
            d="flex"
            flexDir="row"
            justifyContent={{ base: 'flex-end', lg: 'space-around' }}
            marginRight={{ base: '20px', lg: '40px' }}
          >
            <Box
              d="flex"
              flexBasis="row"
              as="button"
              alignItems="center"
              onClick={() => setActiveFilterTo(0)}
            >
              <Icon color={'gray.500'} as={FilterIcon} marginRight={4} />
              <Text color={'gray.500'} d={{ base: 'none', lg: 'block' }}>
                {t('directory.filters')}
              </Text>
            </Box>
            <Button
              h="auto"
              variant="unstyled"
              onClick={() => setActiveFilterTo(1)}
              d={{ base: 'none', lg: 'block' }}
            >
              <Box flexBasis="row" alignItems="center" d="flex" minW={200}>
                <Text color={'gray.500'} display="flow">
                  {t('directory.sort_by')}:
                </Text>
                <Text marginLeft={2} color={'black'}>
                  {t(`directory.sort_option.${selectedSortBy}`)}
                </Text>
                <Icon
                  marginLeft={2}
                  marginBottom={'4px'}
                  color={'black'}
                  h={3}
                  w={3}
                  as={ChevronDownIcon}
                />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
      <Drawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        placement="right"
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton
              size="sm"
              color="white"
              position="absolute"
              top="20px"
              right="20px"
            />
            <DrawerHeader width="100%" background="blue.600">
              <Box
                d="flex"
                flexDir="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Button
                  variant="outlined"
                  color="white"
                  border={activeFilterTab === 0 ? 'thin solid white' : 'none'}
                  onClick={() => setActiveFilterTo(0)}
                >
                  <Icon as={FilterIcon} marginRight={4} />
                  <Text>{t('directory.filters')}</Text>
                </Button>
                <Box
                  d="flex"
                  minW={200}
                  w="80%"
                  alignItems="center"
                  mt={4}
                  fontSize="md"
                  fontWeight="thin"
                >
                  <Button
                    variant="outlined"
                    onClick={() => setActiveFilterTo(1)}
                    color="white"
                    border={activeFilterTab === 1 ? 'thin solid white' : 'none'}
                  >
                    <Box d="flex">
                      <Text color="orange.600" display="flex">
                        {t('directory.sort_by')}:
                      </Text>
                      <Text marginLeft={2} color="white">
                        {t(`directory.sort_option.${selectedSortBy}`)}
                      </Text>
                      <Icon
                        marginLeft={2}
                        marginBottom={'4px'}
                        color="white"
                        h={3}
                        w={3}
                        as={ChevronDownIcon}
                      />
                    </Box>
                  </Button>
                </Box>
              </Box>
            </DrawerHeader>
            <DrawerBody>
              {activeFilterTab === 0 && (
                <PracticeAreaFilter
                  allLegalPractices={allLegalPractices}
                  activeFilter={selectedPracticeArea}
                  onFilterChange={onPracticeAreaChange}
                />
              )}
              {activeFilterTab === 1 && (
                <SortPopOver
                  sortOptions={lawyerSortOptions}
                  activeSort={selectedSortBy}
                  setSort={onSortByChange}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default SearchHeader;
