import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Route } from 'react-router-dom';
import Layout from '@components/organisms/RooLayout/SidebarLayout';
import {
  chakra,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { usePathIndex } from '@src/hooks';
import ListView from './ListView';

export default function WorkingHoursPage() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { index, onChange } = usePathIndex({
    paths: [{ path, exact: true }, { path: `${path}/calendar` }],
    prefixLocale: false,
  });
  return (
    <Layout>
      <chakra.div maxW="5xl" mx="auto" py="5">
        <Heading ml={3} color="blue.100" textAlign="left">
          {t('availability.lawyer_availability')}
        </Heading>
      </chakra.div>

      <Tabs isManual isLazy index={index} onChange={onChange}>
        <TabList
          maxW="5xl"
          mx="auto"
          flexDir={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'normal' }}
        >
          <Tab>{t('availability.list_view')}</Tab>
        </TabList>
        <TabPanels
          pos="relative"
          _before={{
            content: '""',
            pos: 'absolute',
            insetX: 0,
            h: { base: 36, lg: 56 },
            background:
              'linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)',
          }}
        >
          <TabPanel pos="relative">
            <Route path={path} exact prefixLocale={false}>
              <ListView />
            </Route>
          </TabPanel>
          <TabPanel pos="relative">
            <Route path={`${path}/calendar`} exact prefixLocale={false}>
              <chakra.div>WIP</chakra.div>
            </Route>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}
