import { subMonths, startOfMonth, addMinutes } from 'date-fns';
import { mockLawyer, Lawyer } from './lawyers';
import { genId, randomDate } from '@src/utils/mock';
import { slotOptions } from './config';

export interface Payment {
  id: number;
  date: Date;
  endTime: Date;
  lawyer: Lawyer;
  duration: number;
  amount: number;
}

// eslint-disable-next-line
export function mockPayments(override?: any): Payment {
  const start = subMonths(new Date(), 6);
  const end = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 20);
  const slots = Object.keys(slotOptions);
  const duration = parseInt(slots[Math.floor(Math.random() * slots.length)]);
  const date = randomDate(start, end);
  return {
    id: genId(),
    date: date,
    endTime: addMinutes(date, duration),
    lawyer: mockLawyer(),
    duration: duration,
    amount: duration * 30,
    ...override,
  };
}

export const payments = [...Array(20)]
  .map(mockPayments)
  .concat(mockPayments({ date: startOfMonth(new Date()) }))
  .concat(mockPayments({ date: startOfMonth(new Date()) }));
