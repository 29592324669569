/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { languages } from '@src/i18n/Languages';

const findLanguage = (lang: string) => {
  return languages.find(({ code }: { code: string }) => {
    return code === lang;
  });
};

const LanguageContext = React.createContext({
  language: languages[0],
  changeLanguage: (lang: string) => {},
});

function LanguageContextProvider(props: { children: React.ReactNode }) {
  const [language, setLang] = React.useState(languages[0]);
  const { i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const current = localStorage.getItem('i18nextLng') || i18n.language;
    const foundLanguage = findLanguage(current);
    if (foundLanguage) setLang(foundLanguage);
  }, [i18n]);

  useEffect(() => {
    const url = history.location.pathname.split('/');
    url[1] = language.code;
    history.push(url.join('/'));
  }, [language, history]);

  const changeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language, (err: string) => {
        const foundLanguage = findLanguage(language);
        if (foundLanguage) setLang(foundLanguage);
        if (err) return console.log('something went wrong loading', err);
      });
    },
    [i18n]
  );

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
}
export { LanguageContext, LanguageContextProvider };
