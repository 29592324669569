import { useGetMyAppointmentsQuery, useViewerQuery } from '@src/apollo/hooks';
import sortBy from 'lodash/fp/sortBy';
import { isBefore } from 'date-fns';
import { parseMeeting } from '@src/utils';

export function useMyMeetings() {
  const { data: viewerData } = useViewerQuery();
  const { data, loading } = useGetMyAppointmentsQuery();
  const viewer = viewerData?.viewer;

  const allMeetings = {
    meetings: data?.getMyAppointments,
    loading: loading,
  };

  const myParsedMeetings = () => {
    return data?.getMyAppointments?.map((item) => {
      const participantType =
        viewer?.email === item?.user.email ? 'lawyer' : 'user';
      return parseMeeting(item, participantType);
    });
  };
  const futureMeetings = sortBy(
    (m) => m.times.startsAt,
    (myParsedMeetings() ?? []).filter(
      (item) => !isBefore(item.times.endsAt, new Date())
    )
  );
  const pastMeetings = sortBy(
    (m) => m.times.startsAt,
    (myParsedMeetings() ?? []).filter((item) =>
      isBefore(item.times.endsAt, new Date())
    )
  );

  return {
    allMeetings: allMeetings,
    myParsedMeetings: myParsedMeetings(),
    pastMeetings: pastMeetings,
    futureMeetings: futureMeetings,
  };
}
