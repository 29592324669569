import React from 'react';
import { useField, UseFieldConfig, FieldRenderProps } from 'react-final-form';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { ShowErrorFunc } from '@src/utils';

export type TextFieldVariant =
  | 'outline'
  | 'filled'
  | 'flushed'
  | 'unstyled'
  | 'roo';

export interface TextFieldProps<FieldValue> extends InputProps {
  name: string;
  label?: string;
  showError?: ShowErrorFunc;
  fieldProps?: UseFieldConfig<FieldValue>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  variant?: TextFieldVariant;
}

// eslint-disable-next-line
export type AdaptedInputProps<FieldValue = any> = InputProps &
  FieldRenderProps<FieldValue>;

export const AdaptedInput = ({ input, meta, ...props }: AdaptedInputProps) => {
  return <Input {...props} {...input} isInvalid={meta.error && meta.touched} />;
};

export const TextField = ({
  name,
  label,
  placeholder,
  fieldProps,
  formControlProps,
  formLabelProps,
  showError = ({ meta: { touched, invalid } }) => touched && invalid,
  type,
  variant = 'roo',
  isDisabled,
  ...props
}: // eslint-disable-next-line
TextFieldProps<any>) => {
  const { input, meta } = useField(name, { type, ...fieldProps });

  const { error } = meta;

  const isError = showError({ meta });
  return (
    <FormControl
      isInvalid={isError}
      isDisabled={isDisabled}
      {...formControlProps}
      outline="none"
      appearance="none"
      d="inline-flex"
      verticalAlign="top"
      flexDirection="column"
    >
      {!!label && (
        <FormLabel htmlFor={input.name} {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      <Input
        {...props}
        {...input}
        id={input.name}
        placeholder={placeholder}
        variant={variant}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
