import React from 'react';
import { Box, Text, VStack, Stack, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface LawyerApplicationResultProps {
  application: ApplicationCreationPayload;
}
const ApplicationConfirmation = ({
  application,
}: LawyerApplicationResultProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <VStack spacing={4} alignItems="flex-start" w="90%" my={8} mx="auto">
        <Text fontSize="2xl" textStyle="strongBlue">
          {t('lawyer_application.result_title')}
        </Text>
        <Stack width="100%" py={8}>
          <Text>{t('lawyer_application.please_fulfill')}</Text>
          <Text fontSize="2xl" textStyle="strongBlue">
            {t('lawyer_application.next_steps')}
          </Text>
          <Text>{t('lawyer_application.next_steps_description')}</Text>
          <Text as="strong">{t('lawyer_application.you_can_review')}</Text>
          <Text fontSize="2xl" textStyle="strongBlue">
            {t('lawyer_application.disclaimer')}
          </Text>
          <Text>{t('lawyer_application.disclaimer_description')}</Text>
        </Stack>
        {application.result?.confirmationRequired && (
          <Text textStyle="strongYellow">
            {t('lawyer_application.confirmation_required')}
          </Text>
        )}
        {!application.result?.confirmationRequired && (
          <Button
            as={'a'}
            variant="solid"
            colorScheme="blue"
            alignSelf="flex-end"
            href="/application"
          >
            {application.result?.confirmationRequired
              ? t('labels.sign_in')
              : t('lawyer_application.next_step')}
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default ApplicationConfirmation;
