import React, { useCallback } from 'react';
import { chakra, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CardSetupForm from '@src/components/molecules/CardSetupForm';
import {
  GetMyPaymentSourcesDocument,
  useCreatePaymentSourceMutation,
} from '@src/apollo/hooks';
import { useStripeCreatePaymentMethod, useToast } from '@src/hooks';
import { Alert } from '@src/components/atoms/Alert';
import { formatError } from '@src/utils';

export interface AddPaymentMethodProps {
  onSuccess(paymentMethod: PaymentMethodType): void;
}

export default function AddPaymentMethod({ onSuccess }: AddPaymentMethodProps) {
  const { t } = useTranslation();
  const [
    createPaymentSource,
    { error, loading },
  ] = useCreatePaymentSourceMutation({
    refetchQueries: [{ query: GetMyPaymentSourcesDocument }],
  });
  const {
    loading: stripeLoading,
    onCreatePaymentMethod,
    error: stripeError,
  } = useStripeCreatePaymentMethod();

  const toast = useToast();
  const finalError = stripeError ?? error;

  const onSubmit = useCallback(
    async (values) => {
      try {
        const payload = await onCreatePaymentMethod({
          name: values.cardholderName,
        });
        if (payload?.paymentMethod) {
          const { data } = await createPaymentSource({
            variables: {
              paymentMethodId: payload.paymentMethod.id,
            },
          });

          if (!data?.createPaymentSource?.successful) {
            data?.createPaymentSource?.messages?.forEach((error) => {
              toast.warning({
                title: 'Error',
                description: error?.message,
                isClosable: true,
              });
            });
            return;
          }
          onSuccess(data.createPaymentSource.result as PaymentMethodType);
          toast.success({
            title: t('my_payments.payment_methods.create_success'),
            isClosable: true,
          });
        }
      } catch (err) {
        toast.warning({
          title: err.message,
          isClosable: true,
        });
      }
    },
    [toast, t, createPaymentSource, onCreatePaymentMethod, onSuccess]
  );
  return (
    <chakra.div maxW="md" mt={4}>
      <Heading as="h3" fontSize="lg" mb={3}>
        {t('my_payments.add_new_card_title')}
      </Heading>
      {finalError && (
        <chakra.div pb={6}>
          <Alert
            variant="roo"
            status="error"
            title="Error"
            description={formatError(finalError)}
          />
        </chakra.div>
      )}
      <CardSetupForm
        onSubmit={onSubmit}
        labels={{
          cardNumber: t('fields.card_number.label'),
          cardExpiry: t('fields.card_expiry.label'),
          cardCVC: t('fields.card_cvc.label'),
        }}
        isLoading={stripeLoading ?? loading}
      />
    </chakra.div>
  );
}
