import React from 'react';
import { Form } from 'react-final-form';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  Stack,
  VStack,
  Button,
  FormControl,
  FormLabel,
  chakra,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import StripeInput from './StripeInput';
import { Checkbox, TextField } from '@src/components/atoms/Fields';

export interface FormLabelProps {
  cardCVC?: string;
  cardNumber?: string;
  cardExpiry?: string;
}

export interface CardSetupFormProps {
  labels?: FormLabelProps;
  onSubmit(e?: React.FormEvent<HTMLFormElement>): Promise<void>;
  isLoading?: boolean;
  showSetAsDefaultField?: boolean;
}

const DEFAULT_ELEMENT_STYLE = {
  base: {
    fontWeight: '600',
    color: '#0747a6',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    lineHeight: '1.5',
    '::placeholder': {
      color: '#c1cddd',
      fontWeight: '400',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const CARD_NUMBER_ELEMENT_OPTIONS = {
  style: DEFAULT_ELEMENT_STYLE,
  showIcon: true,
};

const CARD_ELEMENT_OPTIONS = {
  style: DEFAULT_ELEMENT_STYLE,
};

export default function CardSetupForm({
  labels = {
    cardNumber: 'Card Number',
    cardCVC: 'Security Code',
    cardExpiry: 'Expiration Date',
  },
  showSetAsDefaultField = false,
  onSubmit,
  isLoading,
}: CardSetupFormProps) {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <VStack alignItems="initial" spacing={4}>
            <TextField
              isRequired
              name="cardholderName"
              label={t('fields.cardholder_name.label')}
              placeholder={t('fields.cardholder_name.placeholder')}
              fontSize="md"
              _placeholder={{
                fontWeight: '400',
              }}
            />
            <FormControl>
              <FormLabel>{labels.cardNumber}</FormLabel>
              <StripeInput
                as={CardNumberElement}
                options={CARD_NUMBER_ELEMENT_OPTIONS}
              />
            </FormControl>
            <Stack alignItems="initial" direction={['column', 'row']}>
              <FormControl>
                <FormLabel>{labels.cardExpiry}</FormLabel>
                <StripeInput
                  as={CardExpiryElement}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{labels.cardCVC}</FormLabel>
                <StripeInput
                  as={CardCvcElement}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </FormControl>
            </Stack>
            {showSetAsDefaultField && (
              <Checkbox name="setAsDefault" borderColor="gray.600" my={0}>
                <chakra.span fontSize="sm" color="gray.600">
                  {t('my_payments.set_as_default')}
                </chakra.span>
              </Checkbox>
            )}
            <Button
              type="submit"
              colorScheme="blue"
              px={8}
              isLoading={submitting ?? isLoading}
            >
              {t('common.save')}
            </Button>
          </VStack>
        </form>
      )}
    />
  );
}
