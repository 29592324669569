import React from 'react';
import { formatDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { chakra, SimpleGrid, Text, Icon } from '@chakra-ui/react';
import DateFormat from '@src/components/atoms/DateFormat';
import { ReactComponent as TimeIcon } from '@src/icons/clock.svg';

export interface MeetingTimeBoxProps {
  date: Date;
  duration: number;
}

export default function MeetingTimeBox({
  date,
  duration,
}: MeetingTimeBoxProps) {
  const { t } = useTranslation();
  return (
    <SimpleGrid
      columns={{ base: 1, lg: 2 }}
      gap={{ base: 4, lg: 2 }}
      w="full"
      background="blue.100"
      borderRadius="xl"
      p={{ base: 6, lg: 8 }}
    >
      <chakra.div>
        <Text textStyle="strongBlue" fontSize="lg">
          {' '}
          {t('meetings.date')}
        </Text>
        <DateFormat fontSize="md" fontWeight="light" date={date} format="PPP" />
      </chakra.div>
      <chakra.div>
        <Text textStyle="strongBlue" fontSize="lg">
          {t('meetings.time')}
        </Text>
        <DateFormat fontSize="md" fontWeight="light" date={date} format="p" />
        <Text
          mt={{ base: 1, lg: 0 }}
          textStyle="strongBlue"
          d="flex"
          alignContent="center"
        >
          <Icon as={TimeIcon} mr="5px" h={'22px'} />
          {formatDuration({
            hours: Math.floor(duration / 60),
            minutes: duration % 60,
          })}
        </Text>
      </chakra.div>
    </SimpleGrid>
  );
}
