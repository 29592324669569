import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import MainPage from './MainPage';
import Layout from '@src/components/organisms/RooLayout/Layout';
import { DirectoryHero } from '@molecules/Directory';

const Directory: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Layout theme="blue">
      <Route path={`${path}/:country/:area?`} component={MainPage} />
      <Route exact path={path}>
        <div className="internal-page">
          <DirectoryHero />
        </div>
      </Route>
    </Layout>
  );
};

export default Directory;
