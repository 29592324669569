import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  VStack,
  Button,
  HStack,
  chakra,
  InputGroup,
  InputRightElement,
  FormLabel,
  InputLeftElement,
  Box,
} from '@chakra-ui/react';
import { isBefore } from 'date-fns';
import {
  Control,
  AdaptedInput,
  TextareaField,
  SelectMulti as SelectMultipleField,
  Error,
} from '@src/components/atoms/Fields';
import {
  useGetAllActiveLegalPracticesQuery,
  useGetAllLanguagesQuery,
} from '@src/apollo/hooks';
import Loader from '@atoms/Loader';
import SimpleAvailability from './SimpleAvailability';
import { useFormValidation } from '@src/hooks';
export interface LawyerApplicationFormValues {
  bio: Maybe<string>;
  phone: string;
  hourlyRate: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  legalPractices: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  spokenLanguages: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workingHours: any;
}
export interface LawyerProfileFormProps {
  onSubmit: (v: LawyerApplicationFormValues) => void;
  initialValues?: Partial<LawyerApplicationFormValues>;
  canUpdate: boolean;
}

const ApplicationExtraInformation = ({
  onSubmit,
  initialValues,
  canUpdate,
}: LawyerProfileFormProps) => {
  const { data: legalPractices } = useGetAllActiveLegalPracticesQuery();
  const { data: langData, loading } = useGetAllLanguagesQuery();
  const { t } = useTranslation();
  const { validHourlyRate } = useFormValidation();

  const allLang = useMemo(() => {
    return (langData?.getAllLanguages ?? []).map((item) => ({
      label: item?.name,
      value: item?.code,
    })) as Option[];
  }, [langData]);

  const allLegalPractices = useMemo(() => {
    return (legalPractices?.getAllActiveLegalPractices ?? []).map((item) => ({
      label: item?.name,
      value: item?.slug,
    })) as Option[];
  }, [legalPractices]);

  const parsedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      bio: initialValues?.bio || '',
      phone: initialValues?.phone || '',
      hourlyRate: initialValues?.hourlyRate || 0,
      spokenLanguages: allLang.filter((item) => {
        return initialValues?.spokenLanguages?.includes(item.value);
      }),
      legalPractices: allLegalPractices.filter((item) => {
        return initialValues?.legalPractices?.includes(item.value);
      }),
    };
  }, [initialValues, allLang, allLegalPractices]);

  const validateForm = (values: LawyerApplicationFormValues) => {
    const { workingHours, spokenLanguages, legalPractices } = values;
    if (!workingHours) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    errors.workingHours = {};

    if (
      workingHours.to &&
      workingHours.from &&
      isBefore(workingHours.to, workingHours.from)
    ) {
      errors.workingHours.to = t('fields.errors.choose_to_later_than_from');
    }
    if (workingHours.days && workingHours.days.length === 0) {
      errors.workingHours.days = t('fields.errors.choose_one_day');
    }
    if (spokenLanguages.length === 0) {
      errors.spokenLanguages = t('fields.errors.required');
    }

    if (legalPractices.length === 0) {
      errors.legalPractices = t('fields.errors.required');
    }

    return errors;
  };

  if (loading) return <Loader />;
  return (
    <Form
      d={'flex'}
      onSubmit={onSubmit}
      initialValues={parsedInitialValues}
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <chakra.div d={'flex'} pos="relative" w="full">
            <chakra.div
              aria-hidden="true"
              pos="absolute"
              insetX={0}
              top={0}
              h={48}
            />

            <chakra.div py={6} w={'100%'}>
              <VStack pos="relative" w={'100%'} spacing={3}>
                <TextareaField
                  isDisabled={!canUpdate}
                  name="bio"
                  label={t('fields.biography.label')}
                  rows={5}
                  isRequired
                  maxLength={250}
                />

                <Stack direction={['column', 'row']} w="full" spacing={3}>
                  <Control name="hourlyRate">
                    <FormLabel htmlFor="hourlyRate">
                      {t('fields.rate.label')}
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.400"
                        fontSize="1.2em"
                        bottom={-6}
                        pl={{ base: 4, sm: 6 }}
                        d="flex"
                        alignItems="center"
                      >
                        <Box as="span" fontSize="sm">
                          USD$
                        </Box>
                      </InputLeftElement>
                      <Field
                        isDisabled={!canUpdate}
                        name="hourlyRate"
                        component={AdaptedInput}
                        variant="roo"
                        px={undefined}
                        pl={{ base: 14, sm: 16 }}
                        pr={24}
                        type="text"
                        placeholder={t('fields.rate.placeholder')}
                        validate={validHourlyRate()}
                      />
                      <InputRightElement width="6.5rem" bottom={-6} h="auto">
                        <chakra.span
                          bg="primary"
                          color="white"
                          rounded="md"
                          fontSize="xs"
                          fontWeight="bold"
                          w="full"
                          textAlign="center"
                        >
                          {t('common.per_hour')}
                        </chakra.span>
                      </InputRightElement>
                    </InputGroup>
                    <Error name="hourlyRate" />
                  </Control>
                </Stack>

                <SelectMultipleField
                  isDisabled={!canUpdate}
                  isSearchable
                  name="spokenLanguages"
                  options={allLang}
                  label={t('fields.language.label')}
                  placeholder={t('fields.language.placeholder')}
                  getOptionKey={(item) => (item ? item.value : '')}
                  getOptionLabel={(item) =>
                    item ? t(`languages.${item.value}`, item.label) : ''
                  }
                />

                <SelectMultipleField
                  isDisabled={!canUpdate}
                  isSearchable
                  name="legalPractices"
                  options={allLegalPractices}
                  label={t('fields.legal_practice.label')}
                  placeholder={t('fields.legal_practice.placeholder')}
                  getOptionKey={(item) => (item ? item.value : '')}
                  getOptionLabel={(item) =>
                    item ? t(`practice_areas.${item.value}`, item.label) : ''
                  }
                />

                <Stack direction={['column', 'row']} w="full" spacing={3}>
                  <SimpleAvailability isDisabled={!canUpdate} />
                </Stack>
              </VStack>
            </chakra.div>
          </chakra.div>

          <HStack
            pt={3}
            pb={8}
            spacing={4}
            alignItems="left"
            justifyContent="left"
          >
            {canUpdate && (
              <Button
                colorScheme="primary"
                isLoading={submitting}
                type="submit"
                px={10}
              >
                {t('common.update_information')}
              </Button>
            )}
          </HStack>
        </form>
      )}
    />
  );
};

export default ApplicationExtraInformation;
