import React from 'react';
import { Box, Heading, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PointPattern } from '@src/icons/point-pattern.svg';

export const WhyWeDoSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      backgroundColor="white"
      w="full"
      zIndex={0}
      className="clip-color-bottom-right"
    >
      <Box
        position="relative"
        display="grid"
        gridTemplateColumns={{ base: '1fr', md: '40% 60%' }}
        gridTemplateRows={{ base: '240px 1fr', md: '1fr' }}
        backgroundColor="white"
        mx="auto"
        maxW="5xl"
        py={{ base: 16, sm: 24, lg: 32 }}
        pl={6}
        pr={{ base: 6, md: 0 }}
      >
        <Box
          position="relative"
          display={{ base: 'flex', md: 'unset' }}
          alignContent="center"
        >
          <Icon as={PointPattern} w={{ base: 32, lg: 64 }} h="auto" />
          <Heading
            as="h2"
            position="absolute"
            color="black"
            top={0}
            fontWeight="bold"
            transform={{
              base: 'translateX(20%) translateY(175%)',
              md: 'translateX(38%) translateY(40%)',
              lg: 'translateX(44%) translateY(30%)',
            }}
            textAlign="center"
            fontSize={{ base: '2xl', lg: '4xl' }}
            lineHeight={{ base: 6, md: 8, lg: 10 }}
          >
            {t('landing.why_we_do_section.why_we_do')}{' '}
            <Box
              as="span"
              ml={{ base: 6, md: 2 }}
              py="px"
              px={2}
              display={{ base: 'block', md: 'flex' }}
            >
              {t('landing.why_we_do_section.we_do')}
            </Box>
          </Heading>
        </Box>
        <Box
          w={{ base: 'full', md: '76%' }}
          d="flex"
          alignItems={{ base: 'center', md: 'end' }}
          flexDirection="column"
        >
          <Text color="gray.600" fontSize="base" lineHeight={6} mt={{ md: 32 }}>
            {t('landing.why_we_do_section.paragraph1')}
            <br />
            <br />
            {t('landing.why_we_do_section.paragraph2')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default WhyWeDoSection;
