import React from 'react';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  Placement,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactComponent as SettingsIcon } from '@src/icons/settings.svg';
import DeviceSelectionMenu from '../../DeviceSelectionMenu';

const CallSettings = () => {
  const size = useBreakpointValue({ base: 'md', md: 'lg' });
  const menuPlacement = useBreakpointValue<Placement>({
    base: 'bottom',
    md: 'right',
  });
  return (
    <Menu placement={menuPlacement}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isRound
            as={IconButton}
            colorScheme={isOpen ? 'primary' : 'white'}
            variant={isOpen ? 'solid' : 'outline'}
            size={size}
            _hover={{ bg: isOpen ? 'blue.500' : 'rgba(255,255,255,0.4)' }}
            _focus={{ boxShadow: 'none' }}
          >
            <Icon
              aria-label="settings-btn"
              as={SettingsIcon}
              w={{ base: 5, sm: 6 }}
              h={{ base: 5, sm: 6 }}
            />
          </MenuButton>
          <DeviceSelectionMenu />
        </>
      )}
    </Menu>
  );
};

export default CallSettings;
