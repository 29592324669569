import { useGetMyLawyerApplicationQuery } from '@src/apollo/hooks';

export function useLawyerApplicationStatus() {
  const { data } = useGetMyLawyerApplicationQuery();

  const applicationStatus = () => {
    const status = data?.getMyLawyerApplication?.status;
    if (!status) {
      return null;
    }
    if (['draft', 'submitted_for_review', 'under_review'].includes(status)) {
      return 'inProgress';
    }
    return status;
  };

  return {
    applicationStatus: applicationStatus(),
    canViewApplyAsLawyer: !applicationStatus(),
  };
}
