import React from 'react';
import { Box } from '@chakra-ui/react';
import MainParticipant from '@src/components/molecules/MeetingRoom/Participant/MainParticipant';
import ParticipantList from '@src/components/molecules/MeetingRoom/Participant/ParticipantList';
import VideoHeader from '@src/components/molecules/MeetingRoom/VideoHeader';

const totalMobileSidebarHeight = `${90 + 8 * 2 + 2}px`;

const Room = ({ startTime, endTime }: { startTime: Date; endTime: Date }) => {
  return (
    <Box
      position="relative"
      height="full"
      display="grid"
      gridTemplateColumns={{ base: '100%', sm: '1fr 320px' }}
      gridTemplateRows={{
        base: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
        sm: '100%',
      }}
    >
      <VideoHeader startTime={startTime} endTime={endTime} />
      <MainParticipant />
      <ParticipantList />
    </Box>
  );
};

export default Room;
