import React, { useRef } from 'react';
import {
  AlertDialog,
  Button,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogHeader,
  Box,
} from '@chakra-ui/react';

export interface AlertDialogProps {
  buttonText: string;
  dialogHeader: string;
  dialogDescription: string;
  cancelText: string;
  submitText: string;
  onSubmit: () => void;
}

const AlertDialogWrapper = ({
  buttonText,
  dialogHeader,
  dialogDescription,
  cancelText,
  submitText,
  onSubmit,
}: AlertDialogProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const onDialogSubmit = () => {
    onClose();
    return onSubmit();
  };
  return (
    <>
      <Button colorScheme="green" onClick={() => setIsOpen(true)}>
        {buttonText}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <Box className="side" pr={4} bg={'blue'}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {dialogHeader}
              </AlertDialogHeader>

              <AlertDialogBody>{dialogDescription}</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  {cancelText}
                </Button>
                <Button colorScheme="green" onClick={onDialogSubmit} ml={3}>
                  {submitText}
                </Button>
              </AlertDialogFooter>
            </Box>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AlertDialogWrapper;
