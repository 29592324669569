import SelectSingle from './select-single';

const parts = ['control', 'menu', 'list', 'option', 'label', 'button'];

const baseStyle = SelectSingle.baseStyle;

export default {
  parts,
  baseStyle,
};
