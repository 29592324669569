import Input from './input';

const baseStyle = {
  ...Input.baseStyle.field,
  paddingY: '8px',
  minHeight: '80px',
  lineHeight: 'short',
};

const variants = {
  outline: (props: Dict) => Input.variants.outline(props).field,
  flushed: (props: Dict) => Input.variants.flushed(props).field,
  filled: (props: Dict) => Input.variants.filled(props).field,
  roo: (props: Dict) => Input.variants.roo(props).field,
  unstyled: Input.variants.unstyled.field,
};

const sizes = {
  sm: Input.sizes.sm.field,
  md: Input.sizes.md.field,
  lg: Input.sizes.lg.field,
};

const defaultProps = {
  size: 'md',
  variant: 'roo',
};

export default {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
