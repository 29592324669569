/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';
import { Text } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { getDay, getMonth, getYear } from 'date-fns';

export interface DayPickerProps {
  startDate: Date;
  setStartDate: (date: Date) => void;
  availableDates: Date[];
  className?: string;
  onMonthChange?: (date: Date) => void;
}

const DayPicker = ({
  startDate,
  setStartDate,
  availableDates,
  className,
  onMonthChange,
}: DayPickerProps) => {
  const { t } = useTranslation();
  const isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };
  return (
    <DatePicker
      onMonthChange={onMonthChange}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
        <div className="datepicker-header">
          <Text>
            {t(`dates.months.month${getMonth(date)}`)} {getYear(date)}
          </Text>
          <div className="btn-holder">
            <button onClick={decreaseMonth}>{'<'}</button>
            <button onClick={increaseMonth}>{'>'}</button>
          </div>
        </div>
      )}
      includeDates={availableDates}
      highlightDates={[new Date()]}
      selected={startDate}
      onChange={setStartDate}
      filterDate={isWeekday}
      inline
      calendarClassName={`roo-calendar ${className ? className : ''}`}
      className={className}
    />
  );
};

export default DayPicker;
