import { LocalAudioStream } from '@src/video';
import { useCallback } from 'react';
import useIsTrackEnabled from './useIsTrackEnabled';
import { useVideoContext } from '../../context/VideoContext';

export default function useLocalAudioToggle() {
  const { localStreams } = useVideoContext();

  const audioTrack = localStreams.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioStream;

  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}
