import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import {
  Box,
  Text,
  Spinner,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  Button,
  Icon,
  SimpleGrid,
  chakra,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { ReactComponent as CalendarIcon } from '@src/icons/calendar-icon.svg';
import { ReactComponent as TimeIcon } from '@src/icons/clock.svg';
import { ReactComponent as ChevronDownIcon } from '@src/icons/arrow-down.svg';
import DayPicker from '@molecules/Meetings/DayPicker';
import LawyerNameCard from '@molecules/LawyerNameCard';
import {
  endOfMonth,
  lightFormat,
  startOfMonth,
  addMonths,
  parse,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { formatTimeZone } from '@src/utils';
import MeetingStepper from '@src/components/molecules/Meetings/PaymentStepper';
import DateFormat from '@src/components/atoms/DateFormat';
import {
  useGetLawyerQuery,
  useCheckLawyerAvailabilityQuery,
} from '@src/apollo/hooks';
export interface ParamTypes extends BaseRouteParams {
  lawyer_id: string;
}

export interface TimeOption {
  value: 'HALF_HOUR' | 'AN_HOUR';
  label: string;
}
export interface FormValues {
  date: string;
  time: string;
  duration?: 'HALF_HOUR' | 'AN_HOUR';
}

const currentDate = new Date();
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const NewBooking: React.FC = () => {
  const history = useHistory<FormValues>();
  const initialState = history.location.state;

  const [selectedDate, setSelectedDate] = useState<Date | null>(() =>
    initialState?.date ? new Date(initialState?.date) : null
  );

  const [rangeDates, setRangeDates] = useState<{
    startDate: string;
    endDate: string;
  }>(() => {
    const start = initialState?.date
      ? new Date(initialState.date)
      : currentDate;
    return {
      startDate: formatTimeZone(start),
      endDate: formatTimeZone(endOfMonth(start)),
    };
  });

  const [duration, setDuration] = useState<TimeRange>(() => {
    if (initialState?.duration) {
      return (initialState.duration as string) === '30'
        ? 'HALF_HOUR'
        : 'AN_HOUR';
    }
    return 'HALF_HOUR';
  });

  const [selectedTime, setSelectedTime] = useState(
    () => initialState?.time ?? ''
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  const { lawyer_id, locale } = useParams<ParamTypes>();

  const { data, loading } = useGetLawyerQuery({
    variables: { lawyerId: Number(lawyer_id) },
  });

  const {
    data: availabilityData,
    loading: availabilityLoading,
  } = useCheckLawyerAvailabilityQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        lawyerId: Number(lawyer_id),
        startDate: rangeDates.startDate,
        endDate: rangeDates.endDate,
        timeRange: duration || 'HALF_HOUR',
        tmz: timeZone,
      },
    },
  });

  useEffect(() => {
    if (
      availabilityData?.checkAvailability &&
      availabilityData.checkAvailability.length <= 0
    ) {
      const start = addMonths(startOfMonth(currentDate), 1);
      setRangeDates({
        startDate: formatTimeZone(start),
        endDate: formatTimeZone(endOfMonth(start)),
      });
    }
  }, [availabilityData, duration]);

  useEffect(() => {
    if (
      selectedDate === null &&
      availabilityData?.checkAvailability &&
      availabilityData.checkAvailability.length > 0
    ) {
      const stringDate = availabilityData.checkAvailability[0]?.date;
      const newDate = stringDate
        ? parse(stringDate, 'yyyy-MM-dd', currentDate)
        : currentDate;
      setSelectedDate(newDate);
    }
  }, [availabilityData, selectedDate]);

  const availableDates = useMemo(() => {
    return availabilityData?.checkAvailability?.map((date) => {
      return utcToZonedTime(date?.date, timeZone);
    });
  }, [availabilityData]);

  const availableTimeOptions = useMemo(() => {
    return [
      { label: '30', value: 'HALF_HOUR' },
      {
        label: '60',
        value: 'AN_HOUR',
      },
    ] as TimeOption[];
  }, []);

  const selectedSlotsDay = useMemo(() => {
    return availabilityData?.checkAvailability?.find((data) => {
      const zonedDate = utcToZonedTime(data?.date, timeZone);
      return zonedDate.getDate() === selectedDate?.getDate();
    });
  }, [availabilityData, selectedDate]);
  const availableSlots = selectedSlotsDay?.slots;

  const lawyer = data?.getLawyer;

  const durationInTime = useMemo(() => {
    return Number(
      availableTimeOptions.find((option) => option.value === duration)?.label
    );
  }, [duration, availableTimeOptions]);

  const goToPayment = useCallback(() => {
    history.push(
      `/${locale}/booking/new/${lawyer_id}/payment/${selectedDate?.toISOString()}/${selectedTime}/${durationInTime}`
    );
  }, [history, locale, lawyer_id, selectedDate, selectedTime, durationInTime]);

  const goBack = useCallback(() => {
    history.push(`/${locale}/directory/${lawyer?.location.code}`);
  }, [history, lawyer, locale]);

  const onMonthChange = async (date: Date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    setRangeDates({
      startDate: formatTimeZone(start),
      endDate: formatTimeZone(end),
    });
  };

  if (!lawyer && !loading) return <Redirect to="/" />;

  return (
    <Box
      borderColor="blue.50"
      backgroundPosition="center"
      w="full"
      h="auto"
      minHeight="100vh"
      marginBottom={20}
    >
      <Box maxW="6xl" mx="auto" px={6} marginTop="70px">
        <Box
          d="flex"
          px={{ lg: 6, xl: 8 }}
          justifyContent={{ base: 'center', lg: 'space-between' }}
          alignItems="center"
        >
          <Text fontWeight="800">{t('meetings.schedule_with')}</Text>
          <MeetingStepper step={1} />
        </Box>
        <Box
          borderColor="blue.100"
          borderWidth={{ lg: '1px' }}
          borderRadius={4}
          mt={5}
          d="flex"
          w="100%"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Box
            borderColor="blue.100"
            width={{ base: '100%', lg: '36%' }}
            borderWidth={{ base: '1px', lg: 0 }}
            borderRightWidth={{ lg: '1px' }}
          >
            <Box p={{ base: 6, lg: 10 }}>
              {loading && <Spinner />}
              {lawyer && <LawyerNameCard lawyer={lawyer} />}
              <Box>
                <Box
                  mt={21}
                  p={'15px 25px'}
                  bg={'white'}
                  borderRadius={8}
                  boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
                >
                  <Text fontSize="sm" color="black" fontWeight="600">
                    {t('meetings.select_time')}
                  </Text>
                  <Popover isOpen={isOpen}>
                    <PopoverTrigger>
                      <Button
                        size="xl"
                        d="flex"
                        justifyContent="space-between"
                        w="100%"
                        variant="unstyled"
                        onClick={onOpen}
                        _focus={{
                          borderColor: 'transparent',
                        }}
                      >
                        <Text fontWeight="800" color="blue.600">
                          {t(`meetings.duration.${duration}`)}
                        </Text>
                        <Icon as={ChevronDownIcon} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      borderRadius="8px"
                      marginTop="20px"
                      zIndex={4}
                    >
                      <PopoverArrow />
                      <PopoverBody d="flex" flexDir="column" p="15px 0px">
                        {availableTimeOptions.map((option, index) => {
                          return (
                            <Button
                              key={index}
                              p="0px"
                              background="transparent"
                              value={option.value}
                              rounded="0"
                              _hover={{
                                backgroundColor: 'blue.600',
                                color: 'white',
                              }}
                              _focus={{
                                borderColor: 'transparent',
                              }}
                              onClick={() => {
                                setDuration(option.value);
                                onClose();
                              }}
                            >
                              {t(`meetings.duration.${option.label}`)}
                            </Button>
                          );
                        })}
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </Box>
            </Box>
            <Box
              p={{ base: 6, lg: 10 }}
              borderTopWidth="1px"
              borderColor="blue.100"
              borderStyle="dashed"
            >
              <Box d="flex" mb={4} color={'gray.600'}>
                <Icon
                  as={TimeIcon}
                  w={{ base: 4, md: 6 }}
                  h={{ base: 4, md: 6 }}
                  mr={2}
                />

                <Text fontWeight={800} d="inline-block">
                  {t('meetings.duration_meeting', {
                    duration: t(`meetings.duration.${duration}`),
                  })}
                </Text>
              </Box>
              <Box d="flex" mb={2} color="gray.600">
                <Icon
                  as={CalendarIcon}
                  w={{ base: 4, md: 6 }}
                  h={{ base: 4, md: 6 }}
                  mr={2}
                />

                {selectedDate && (
                  <Text fontWeight={800} d="inline-block">
                    <DateFormat date={selectedDate} format="PPP" />
                  </Text>
                )}
                {selectedTime !== '' && (
                  <Text ml={1} fontWeight={800} d="inline">
                    {`${t('meetings.at')}  ${selectedTime}`}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            d="flex"
            flex="1 1 0%"
            flexDir={{ base: 'column', xl: 'row' }}
            borderWidth={{ base: '1px', lg: 0 }}
            borderColor="blue.100"
            mt={{ base: 4, lg: 0 }}
          >
            <Box
              borderColor="blue.100"
              borderBottomWidth={{ base: '1px', lg: 0 }}
              flexShrink={0}
              p={{ lg: 4 }}
              pt={{ base: 6, lg: 10 }}
            >
              <Text
                fontSize="xl"
                marginLeft={{ lg: 5 }}
                color="black"
                fontWeight="800"
                textAlign={{ base: 'center', lg: 'left' }}
              >
                {t('meetings.select_day_and_time')}
              </Text>
              {availabilityLoading && <Spinner />}
              {selectedDate && (
                <chakra.div
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <DayPicker
                    availableDates={availableDates || []}
                    startDate={selectedDate}
                    setStartDate={setSelectedDate}
                    onMonthChange={onMonthChange}
                  />
                </chakra.div>
              )}
            </Box>
            <Box
              mt={{ base: 6, xl: 20 }}
              pt={0}
              px={4}
              pb={4}
              flexShrink={0}
              flex="1 1 0%"
            >
              {selectedDate && (
                <Box textAlign="center" mb={2}>
                  <DateFormat date={selectedDate} format="iiii, MMMM do" />
                </Box>
              )}
              <SimpleGrid
                columns={{ base: 2, xl: 1 }}
                spacing={4}
                maxH={{ xl: 'lg' }}
                overflowY={{ xl: 'auto' }}
                p={4}
              >
                {availableSlots?.map((slot, index) => {
                  const timed = new Date(slot?.from);
                  const time = lightFormat(timed, 'hh:mm aa');
                  return (
                    <Button
                      key={index}
                      w="full"
                      px={8}
                      colorScheme="blue"
                      variant="outline"
                      isActive={time === selectedTime}
                      onClick={() => setSelectedTime(time)}
                      _active={{
                        backgroundColor: 'orange.500',
                        borderColor: 'orange.500',
                        color: 'white',
                      }}
                    >
                      {time}
                    </Button>
                  );
                })}
              </SimpleGrid>
            </Box>
          </Box>
        </Box>
        <Box w="100%" d="flex" justifyContent="flex-end" mt={6}>
          <Button variant="outline" onClick={goBack} colorScheme="blue" mr={4}>
            {t('meetings.go_back')}
          </Button>
          <Button
            isDisabled={selectedTime === ''}
            colorScheme="blue"
            onClick={goToPayment}
          >
            {t('meetings.next_step')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NewBooking;
