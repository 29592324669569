import React, { FC } from 'react';
import { Box, Icon, Link } from '@chakra-ui/react';
import { ReactComponent as LogoColor } from '@src/icons/logo.svg';

const SidebarHeader: FC = () => {
  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
      px={4}
    >
      <Link href="/">
        <Icon as={LogoColor} h={16} w="auto" />
      </Link>
    </Box>
  );
};

export default SidebarHeader;
