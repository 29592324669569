/* eslint-disable */
import client from '@src/apollo/apolloClient';
import translations from '@src/apollo/translations.gql';

class Backend {
  type: string = 'backend';
  services: any = undefined;
  options: any = undefined;

  init(services: any, options: any) {
    this.services = services;
  }

  read(
    language: string,
    namespace: string,
    callback: (namespace: any, translations: any) => void
  ) {
    import(`@src/i18n/${language || 'en'}`).then((data: any) => {
      return callback(null, data.default);
    });
  }

  async loadURL(language: string, callback: any) {
    let res;
    let err;
    try {
      const lang = client.readQuery({
        query: translations.GET,
        variables: {
          lang: language,
        },
      });
    } catch (error) {
      err = `failed fetching url ${language}`;
    }
    if (err) callback(err, false);
    callback(null, res);
  }
}

export default Backend;
