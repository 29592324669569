import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { FORM_ERROR } from 'final-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useToast from '@src/hooks/useToast';
import { Box, Heading, Text } from '@chakra-ui/react';
import PrivateRoute from '@src/PrivateRoute';
import Link from '@src/components/atoms/Link';
import { languages } from '@src/config/i18n';
import UserProfileForm, {
  UserProfileFormValues,
} from '@src/components/molecules/ProfileForm/UserProfileForm';
import Layout from '@components/organisms/RooLayout/SidebarLayout';
import { useUpdateProfileMutation, ViewerDocument } from '@src/apollo/hooks';
import AvatarUpload from '@src/components/molecules/ProfileForm/UserProfileForm/AvatarUpload';

const localePath = `/:locale(${languages.join('|')})`;

interface ProfileHeaderProps {
  avatar: string;
  name: string;
  email: string;
  isEditable?: boolean;
  editUrl?: string;
}

const ProfileHeader = ({
  avatar,
  name,
  email,
  isEditable,
  editUrl,
}: ProfileHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Box
      pt={4}
      px={3}
      d="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box d="flex">
        <AvatarUpload size="xl" src={avatar} name={name} />
        <Box pl={3} d="flex" justifyContent="center" flexDirection="column">
          <Text fontWeight="semibold" fontSize={{ base: 'sm', md: 'md' }}>
            {name}
          </Text>
          <Text fontWeight="semibold" fontSize={{ base: 'xs', md: 'sm' }}>
            {email}
          </Text>
        </Box>
      </Box>
      {isEditable && (
        <Link
          prefixLocale={false}
          to={editUrl as string}
          alignSelf="flex-end"
          textDecoration="underline"
          _hover={{ textDecoration: 'underline' }}
        >
          {t('common.edit_profile')}
        </Link>
      )}
    </Box>
  );
};

const UserProfile = ({ viewer }: { viewer: Viewer }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();
  const { path, url } = useRouteMatch();
  const match = useRouteMatch({
    path: `${localePath}/profile/edit`,
    exact: true,
  });
  const [updateProfile] = useUpdateProfileMutation({
    refetchQueries: [{ query: ViewerDocument }],
    awaitRefetchQueries: true,
  });
  const onSubmit = useCallback(
    async (values: UserProfileFormValues) => {
      const profile = {
        dob: values.dob && format(values.dob, 'yyyy-MM-dd'),
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
      };
      try {
        const { data } = await updateProfile({
          variables: { input: profile },
        });
        if (!data?.updateProfile?.successful) {
          data?.updateProfile?.messages?.forEach((error) => {
            toast.warning({
              title: t('common.error'),
              description: error?.message,
              isClosable: true,
            });
          });
          return;
        }
        history.push(url);
        toast.success({
          title: t('common.success'),
          description: t('profile.toast.updated_profile.title'),
          isClosable: true,
        });
      } catch (error) {
        toast.warning({
          title: error.description,
          isClosable: true,
        });
        return { [FORM_ERROR]: error.description };
      }
    },
    [history, url, toast, t, updateProfile]
  );
  const onCancel = useCallback(() => {
    history.push(url);
  }, [url, history]);
  const formInitialValue = useMemo(
    () => ({
      firstName: viewer.profile?.firstName,
      lastName: viewer.profile?.lastName,
      phone: viewer.profile?.phone,
      dob: viewer.profile?.dob && parseISO(viewer.profile.dob),
    }),
    [viewer]
  );
  const isEditable = !match;
  return (
    <Layout>
      <Box maxW="xl" mx="auto" mt={4}>
        <Heading px={3} fontSize="xl">
          {t('common.profile')}
        </Heading>
        <ProfileHeader
          avatar={viewer.profile?.avatar as string}
          name={viewer.profile?.firstName as string}
          email={viewer.email as string}
          editUrl={`${url}/edit`}
          isEditable={isEditable}
        />
        <Box bg="white" rounded="xl" mt={4} mx={3} mb={12}>
          <PrivateRoute path={path} exact prefixLocale={false}>
            <UserProfileForm
              onSubmit={onSubmit}
              initialValues={formInitialValue}
              onCancel={onCancel}
            />
          </PrivateRoute>
          <PrivateRoute path={`${path}/edit`} exact prefixLocale={false}>
            <UserProfileForm
              onSubmit={onSubmit}
              initialValues={formInitialValue}
              editable={true}
              onCancel={onCancel}
            />
          </PrivateRoute>
        </Box>
      </Box>
    </Layout>
  );
};

export default UserProfile;
