import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { useDateFormat } from '@src/hooks';

//Default date format
export const DEFAULT_FORMAT = 'MM/dd/yyyy';

//Date format used in the lawyer list, Available on Thu, Sept 10th at 12:00 PM to know availability;
export const LARGE_FORMAT = "EEEE. LLLL do 'at' p";
//November 10th, 2020 -> Used in the schedule a meeting
export const PPP_FORMAT = 'PPP';
//Tuesday, November 10th -> Used in schedule a meeting, has day of the week
export const FORMAT_WITH_WEEK_DAY = 'iiii, MMMM do';
// Thursday, November 19th. 2020
export const DAYOFWEEK_MONTH_DAYOFMONTH_YEAR = 'EEEE, LLLL do. yyyy';
// 8:00 AM
export const HOUR_FORMAT = 'p';

export type DATE_FORMAT =
  | typeof DEFAULT_FORMAT
  | typeof LARGE_FORMAT
  | typeof PPP_FORMAT
  | typeof FORMAT_WITH_WEEK_DAY
  | typeof DAYOFWEEK_MONTH_DAYOFMONTH_YEAR
  | typeof HOUR_FORMAT;

export interface DateFormatProps extends BoxProps {
  date: Date;
  format?: DATE_FORMAT | string;
}

const DateFormat = ({
  date,
  format: formatStr = DEFAULT_FORMAT,
  ...props
}: DateFormatProps) => {
  const format = useDateFormat();
  const dateFormatted = format(date, formatStr);
  return (
    <Box as="time" {...props}>
      {dateFormatted}
    </Box>
  );
};

export default DateFormat;
