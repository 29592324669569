import { useEffect, useMemo } from 'react';

const useFreshChat = (freshDeskToken: string) => {
  const freshChat = useMemo(() => {
    const initFreshChat = () => {
      // eslint-disable-next-line
      (window as any).fcWidget.init({
        token: freshDeskToken,
        host: 'https://wchat.freshchat.com',
      });
    };
    return {
      setupFreshChat: () => {
        if (!freshDeskToken) return;
        const scriptFreshChat = document.createElement('script');
        scriptFreshChat.async = true;
        scriptFreshChat.src = 'https://wchat.freshchat.com/js/widget.js';
        scriptFreshChat.addEventListener('load', initFreshChat);
        document.body.append(scriptFreshChat);
        return scriptFreshChat;
      },
      setupFreshWidget: () => {
        if (!freshDeskToken) return;
        const scriptFreshWidget = document.createElement('script');
        scriptFreshWidget.src =
          'https://assets.freshdesk.com/widget/freshwidget.js';
        document.head.append(scriptFreshWidget);
        return scriptFreshWidget;
      },
      setupFreshWidgetStyle: () => {
        if (!freshDeskToken) return;
        const styleCssFreshWidget = document.createElement('style');
        styleCssFreshWidget.media = 'screen, projection';
        styleCssFreshWidget.innerHTML = `
    @import url(https://assets.freshdesk.com/widget/freshwidget.css);
    `;
        document.head.append(styleCssFreshWidget);
        return styleCssFreshWidget;
      },
    };
  }, [freshDeskToken]);

  useEffect(() => {
    const scriptFreshWidget = freshChat.setupFreshWidget();
    const styleCssFreshWidget = freshChat.setupFreshWidgetStyle();
    const scriptFreshChat = freshChat.setupFreshChat();

    return () => {
      if (scriptFreshChat) scriptFreshChat.remove();
      if (scriptFreshWidget) scriptFreshWidget.remove();
      if (styleCssFreshWidget) styleCssFreshWidget.remove();
    };
  }, [freshChat]);
};

export default useFreshChat;
