import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Auth0Error } from 'auth0-js';
import { ApolloError } from '@apollo/client';
import { useSigninMutation, ViewerDocument } from '@src/apollo/hooks';
import useAuth0 from '@src/hooks/useAuth0';
import { useSigninModal } from '@src/context';
import { getCookie } from '@src/utils';

export type ErrorState = Array<Auth0Error | ApolloError | Error>;

export interface AuthenticateOptions {
  hash: string;
  returnTo?: string;
}

export const useAuthenticate = () => {
  const history = useHistory();
  const signinModal = useSigninModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorState | null>(null);
  const [signin] = useSigninMutation({
    refetchQueries: [{ query: ViewerDocument }],
    awaitRefetchQueries: true,
  });
  const { parseHash } = useAuth0();

  const authenticate = useCallback(
    async (options: AuthenticateOptions) => {
      setLoading(true);
      setError(null);
      const path = getCookie('path');
      const returnTo = options.returnTo ?? path ?? '/';
      const returnToPath = returnTo.includes('/auth/callback') ? '/' : returnTo;
      try {
        const { accessToken } = await parseHash(options.hash);
        const { data } = await signin({
          variables: { accessToken },
        });
        if (!data?.authenticate?.successful) {
          const errors = data?.authenticate?.messages?.map(
            (error) => new Error(error?.message as string)
          );
          if (errors?.length) {
            setError(errors);
          }
          return;
        }
        history.push(returnToPath);
      } catch (err) {
        setError([].concat(err));
        setLoading(false);
      }
    },
    [parseHash, signin, history]
  );

  useEffect(() => {
    if (error && !loading) {
      signinModal.open();
    }
  }, [error, loading, signinModal]);

  return { authenticate, loading, error };
};

export type AuthenticateHookResult = ReturnType<typeof useAuthenticate>;
