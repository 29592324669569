import React from 'react';
import { Box, Text, HStack, Stack, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '@src/icons/calendar-icon.svg';
import { ReactComponent as CameraIcon } from '@src/icons/camera.svg';
import { useMyMeetings } from '@src/hooks/useMyMeetings';

const LawyerStats = () => {
  const { t } = useTranslation();
  const { futureMeetings, pastMeetings } = useMyMeetings();

  return (
    <HStack align="center" justifyContent="space-evenly">
      <Stack p={6} backgroundColor="blue.100" rounded="2xl" w="40%" h="150px">
        <Box d="flex" flexDir="row">
          <Icon
            textColor={'white'}
            as={CameraIcon}
            backgroundColor={'blue.500'}
            w={{ base: 8, md: 10 }}
            h={{ base: 8, md: 10 }}
            p={2}
            rounded="md"
          />
          <Text fontSize="2xl" color="black" fontWeight="bold" ml={2}>
            {pastMeetings.length}
          </Text>
        </Box>
        <Text
          fontSize="sm"
          color="black"
          fontWeight="light"
          whiteSpace="break-spaces"
        >
          {t('home.lawyer_stats.meetings_attended')}
        </Text>
      </Stack>
      <Stack p={6} backgroundColor="blue.100" rounded="2xl" w="40%" h="150px">
        <Box d="flex" flexDir="row">
          <Icon
            textColor={'white'}
            as={CalendarIcon}
            backgroundColor={'blue.500'}
            w={{ base: 8, md: 10 }}
            h={{ base: 8, md: 10 }}
            p={2}
            rounded="md"
          />
          <Text fontSize="2xl" color="black" fontWeight="bold" ml={2}>
            {futureMeetings.length}
          </Text>
        </Box>
        <Text
          fontSize="sm"
          color="black"
          fontWeight="light"
          whiteSpace="break-spaces"
        >
          {t('home.lawyer_stats.incoming_meetings')}
        </Text>
      </Stack>
    </HStack>
  );
};

export default LawyerStats;
