import React, { useContext, FC } from 'react';
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react';
import { LanguageContext } from '@src/context/LanguageContext';
import { useActiveLanguagesQuery } from '@src/apollo/hooks';
import { languages as mockLanguages } from '@src/i18n/Languages';
import { ReactComponent as LanguageIcon } from '@src/icons/language.svg';
import { ReactComponent as ChevronDownIcon } from '@src/icons/chevron-down-icon.svg';

const LanguageSelect: FC = () => {
  const currentLanguage = useContext(LanguageContext);
  const { data } = useActiveLanguagesQuery();

  // eslint-disable-next-line
  const languages: any = data?.getActiveLanguages || mockLanguages;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onLanguageChange(e: any) {
    currentLanguage.changeLanguage(e.currentTarget.value);
  }

  if (!currentLanguage.language) return <Spinner />;

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        rounded="full"
        color="currentcolor"
        rightIcon={<ChevronDownIcon />}
        bgColor="transparent"
        _hover={{
          bgColor: 'gray.400',
        }}
      >
        <Box
          color="currentcolor"
          as="span"
          textTransform="capitalize"
          fontWeight="medium"
          _hover={{
            color: 'black.900',
          }}
        >
          <Icon
            color="currentcolor"
            as={LanguageIcon}
            data-testid="footer-logo"
            mr={1}
          />
          {currentLanguage.language.code}
        </Box>
      </MenuButton>
      <MenuList zIndex={3} minW={32} shadow="md">
        {languages?.map((language: LanguageType) => (
          <MenuItem
            key={language.code}
            value={language.code as string}
            onClick={onLanguageChange}
            justifyContent="center"
            color="black"
            fontWeight="medium"
            rounded="md"
            py={1}
            _hover={{
              bg: 'primary',
              color: 'white',
              boxSize: '110%',
              ml: '-5%',
            }}
          >
            {language.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageSelect;
