import React from 'react';
import {
  FormControl,
  FormControlProps,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useField, UseFieldConfig } from 'react-final-form';
import { ShowErrorFunc, showErrorOnChange } from '@src/utils';

// eslint-disable-next-line
export interface CheckboxProps<FieldValue = any> extends ChakraCheckboxProps {
  name: string;
  showError?: ShowErrorFunc;
  fieldProps?: UseFieldConfig<FieldValue>;
  formControlProps?: FormControlProps;
}

export const Checkbox = ({
  name,
  value,
  fieldProps,
  formControlProps,
  showError = showErrorOnChange,
  children,
  ...props
}: CheckboxProps) => {
  const {
    // eslint-disable-next-line
    input: { checked, ...input },
    meta,
  } = useField(name, { type: 'checkbox', value, ...fieldProps });

  const { error } = meta;

  const isError = showError({ meta });
  return (
    <FormControl isInvalid={isError} {...formControlProps}>
      <ChakraCheckbox my={4} {...props} {...input} colorScheme="blue">
        {children}
      </ChakraCheckbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
