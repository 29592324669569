import React from 'react';
import { Participant, Track } from '@src/video';
import Publication from './Publication';
import usePublications from '@src/hooks/meeting/usePublications';

interface ParticipantTracksProps {
  participant: Participant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  isLocalParticipant?: boolean;
}

export default function ParticipantTracks({
  participant,
  videoOnly,
  enableScreenShare,
  videoPriority,
  isLocalParticipant,
}: ParticipantTracksProps) {
  const publications = usePublications(participant);
  let filteredPublications;

  if (
    enableScreenShare &&
    publications.some((p) => p.trackName.includes('screen'))
  ) {
    filteredPublications = publications.filter(
      (p) => !p.trackName.includes('camera')
    );
  } else {
    filteredPublications = publications.filter(
      (p) => !p.trackName.includes('screen')
    );
  }

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.trackName}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
      ))}
    </>
  );
}
