import { Meeting } from '@src/video';
import { useEffect } from 'react';
import { Callback } from '@src/@types/twilio';

export default function useHandleTrackPublicationFailed(
  meeting: Meeting,
  onError: Callback
) {
  const { localParticipant } = meeting;
  useEffect(() => {
    if (localParticipant) {
      localParticipant.on('trackPublicationFailed', onError);
      return () => {
        localParticipant.off('trackPublicationFailed', onError);
      };
    }
  }, [localParticipant, onError]);
}
