import { Callback } from '@src/@types/twilio';
import EventEmitter from 'events';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  connectMeeting,
  Meeting,
  ConnectMeetingOptions,
  LocalStream,
} from '@src/video';

export default function useMeeting(
  localStreams: LocalStream[],
  onError: Callback,
  options?: ConnectMeetingOptions
) {
  const [meeting, setMeeting] = useState<Meeting>(
    new EventEmitter() as Meeting
  );
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    (token) => {
      setIsConnecting(true);
      return connectMeeting(token, {
        ...optionsRef.current,
        tracks: localStreams,
      }).then(
        (newMeeting) => {
          setMeeting(newMeeting);
          const disconnect = () => newMeeting.disconnect();
          newMeeting.setMaxListeners(10);

          newMeeting.once('disconnected', () => {
            setTimeout(() => setMeeting(new EventEmitter() as Meeting));
            window.removeEventListener('beforeunload', disconnect);
          });

          newMeeting.localParticipant.videoTracks.forEach((publication) => {
            publication.setPriority('low');
          });

          setIsConnecting(false);
          window.addEventListener('beforeunload', disconnect);
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [onError, localStreams]
  );

  return { meeting, isConnecting, connect };
}
