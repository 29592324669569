import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import LawyerLanding from './LawyerLanding';
import Lawyer from './Lawyer';

export default function LawyerPage() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} exact>
        <LawyerLanding />
      </Route>
      <Route path={`${path}/:lawyerId`} exact>
        <Lawyer />
      </Route>
    </Switch>
  );
}
