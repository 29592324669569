import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@chakra-ui/react';
import SelectBox from '@components/atoms/SelectBox';
import {
  useGetAllActiveLegalPracticesQuery,
  useGetAllMeetsCountriesQuery,
} from '@src/apollo/hooks';
export interface ParamTypes {
  locale?: string;
  country?: string;
  area?: string;
}
const SearchForm: React.FC = () => {
  const { country, locale, area } = useParams<ParamTypes>();
  const [selectedPractice, setSelectedPractice] = useState(area ?? '');
  const [selectedCountry, setSelectedCountry] = useState(country ?? '');
  const { t } = useTranslation();
  const history = useHistory();
  const { data: countries } = useGetAllMeetsCountriesQuery();
  const { data: legalPractices } = useGetAllActiveLegalPracticesQuery();

  const allLegalPractices = legalPractices?.getAllActiveLegalPractices ?? [];
  const allCountries = countries?.getAllMeetsCountries ?? [];

  const countryOptions = allCountries.map((country) => {
    return (
      <option key={country?.code} value={country?.code}>
        {country?.name}
      </option>
    );
  });

  const practiceAreaOptions = allLegalPractices.map((practice) => {
    return (
      <option key={practice.slug} value={practice.slug}>
        {t(`practice_areas.${practice.slug}`, practice.name)}
      </option>
    );
  });

  return (
    <Box
      as="form"
      mt={10}
      backgroundColor="white"
      shadow="0px 0px 30px rgba(24, 33, 64, 0.2)"
      rounded="md"
    >
      <Stack spacing={0} direction={{ base: 'column', md: 'row' }}>
        <Box
          data-testid="search-box"
          py={1}
          px={3}
          w={{ base: '100%', lg: '35%' }}
          shadow="-5px 0px 22px -7px rgba(0, 0, 0, 0.2)"
        >
          <Box as="label" color="gray.600" pl={3} fontSize="xs">
            {t('search_form.select_country')}
          </Box>
          <SelectBox
            placeholder={t('common.select')}
            defaultValue={selectedCountry}
            handleChange={(code) => setSelectedCountry(code)}
          >
            {countryOptions}
          </SelectBox>
        </Box>
        <Box
          py={1}
          px={3}
          w={{ base: '100%', lg: '35%' }}
          shadow="-5px 0px 22px -7px rgba(0, 0, 0, 0.2)"
        >
          <Box as="label" color="gray.600" pl={3} fontSize="xs">
            {t('search_form.area_of_practice')}
          </Box>
          <SelectBox
            defaultValue={selectedPractice}
            handleChange={setSelectedPractice}
            placeholder={t('common.select')}
          >
            {practiceAreaOptions}
          </SelectBox>
        </Box>
        <Button
          size="xl"
          w={{ base: '100%', lg: '30%' }}
          fontSize="sm"
          rounded="none"
          borderTopRightRadius={{ base: '0px', md: 'md' }}
          borderBottomRightRadius="md"
          borderBottomLeftRadius={{ base: 'md', md: '0px' }}
          colorScheme="orange"
          type="button"
          textTransform="uppercase"
          onClick={() => {
            history.push(
              `/${locale}/directory/${selectedCountry}/${selectedPractice}`
            );
          }}
          isDisabled={!selectedCountry}
          data-testid="search-button"
          minH="60px"
        >
          {t('search_form.find_my_lawyer')}
        </Button>
      </Stack>
    </Box>
  );
};

export default SearchForm;
