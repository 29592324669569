import React from 'react';
import { useViewerQuery } from '@src/apollo/hooks';
import { Box, Button, Icon } from '@chakra-ui/react';
import MobileMenu from '@src/components/molecules/MobileMenu';
import Loader from '@atoms/Loader';
import { ReactComponent as LogoColor } from '@src/icons/logo.svg';
import { ReactComponent as LogoWhite } from '@src/icons/white-logo.svg';
import UserNavigation from '@src/components/molecules/UserNavigation';

export interface HeaderProps {
  theme: { [key: string]: string };
}
const Header = ({ theme }: HeaderProps) => {
  const { data, loading } = useViewerQuery();

  const Logo = theme.colorScheme === 'blue' ? LogoColor : LogoWhite;
  if (loading) {
    return <Loader />;
  }
  return (
    <Box
      backgroundColor={theme.backgroundColor ?? 'transparent'}
      color={theme.color}
    >
      <Box maxW="6xl" mx="auto" px={{ base: 4, sm: 6, md: 8 }}>
        <Box h={16} d="flex" justifyContent="space-between" alignItems="center">
          <Button variant="unstyled" as="a" href="/">
            <Icon
              as={Logo}
              w={{ base: 32, md: 40 }}
              h="auto"
              data-testid="app-logo"
            />
          </Button>

          <MobileMenu
            theme={{
              name: 'skyBlue',
              backgroundColor: 'teal.50',
              color: 'gray.900',
              colorHover: 'gray.500',
              colorScheme: 'blue',
              buttonColor: 'blue.200',
            }}
            viewer={data?.viewer}
            showLogo={false}
          />
          <UserNavigation
            viewer={data?.viewer}
            theme={{ colorScheme: 'black' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
