import { useState, useEffect } from 'react';
import {
  LocalVideoStream,
  LocalAudioStream,
  RemoteAudioStream,
  RemoteVideoStream,
  AudioStream,
} from '@src/video';

type TrackType =
  | LocalAudioStream
  | RemoteAudioStream
  | LocalVideoStream
  | RemoteVideoStream
  | AudioStream
  | undefined;

/*
 * This hook allows components to reliably use the 'mediaStreamTrack' property of
 * an AudioTrack or a VideoTrack. Whenever 'localTrack.restart(...)' is called, it
 * will replace the mediaStreamTrack property of the localTrack, but the localTrack
 * object will stay the same. Therefore this hook is needed in order for components
 * to re render in response to the mediaStreamTrack changing.
 */
export default function useMediaStreamTrack(track?: TrackType) {
  const [mediaStreamTrack, setMediaStreamTrack] = useState(
    track?.mediaStreamTrack
  );

  useEffect(() => {
    setMediaStreamTrack(track?.mediaStreamTrack);

    if (track) {
      const handleStarted = () => setMediaStreamTrack(track.mediaStreamTrack);
      track.on('started', handleStarted);
      return () => {
        track.off('started', handleStarted);
      };
    }
  }, [track]);

  return mediaStreamTrack;
}
