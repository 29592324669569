import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { FormLabelProps } from '@src/components/molecules/CardSetupForm';
import { useField, UseFieldConfig } from 'react-final-form';
import Select, { SelectWrapperProps, Option } from '../../Select/SelectWrapper';

export interface SelectMultiFieldProps<Item> extends SelectWrapperProps<Item> {
  name: string;
  label?: string;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  fieldProps?: UseFieldConfig<Item[]>;
  isDisabled?: boolean;
}

export function SelectMulti<Item = Option>({
  name,
  formLabelProps,
  formControlProps,
  fieldProps,
  label,
  isDisabled,
  ...props
}: SelectMultiFieldProps<Item>) {
  const { input, meta } = useField<Item[]>(name, fieldProps);
  const { error, touched, invalid } = meta;
  const isInvalid = touched && invalid;
  return (
    <FormControl
      name={name}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      {...formControlProps}
    >
      {!!label && (
        <FormLabel htmlFor={name} px={{ base: 4, md: 6 }} {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      <Select<Item>
        {...props}
        value={input.value || []}
        onChange={({ selectedItems = [] }) => {
          input.onChange(selectedItems);
        }}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
