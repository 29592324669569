import React from 'react';
import { Spinner, Flex } from '@chakra-ui/react';

const Loader: React.FC = () => {
  return (
    <Flex w="100vw" h="100vh" alignItems="center" justify="center">
      <Spinner />
    </Flex>
  );
};

export default Loader;
