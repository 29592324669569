import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchForm from '@components/molecules/SearchForm';
import { Box, Heading } from '@chakra-ui/react';

export const DirectoryHero: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      backgroundColor="primary"
      backgroundPosition="center"
      w="full"
      zIndex={1}
      pb={100}
    >
      <Box maxW="5xl" mx="auto" px={6}>
        <Box
          d="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          data-testid="landing-hero"
          pt={{ base: 8, lg: 20 }}
          pb={20}
        >
          <Heading
            as="h2"
            lineHeight="base"
            fontSize={{ base: '3xl', lg: '4xl' }}
            fontWeight="bold"
            color="white"
            w="full"
            textAlign="center"
          >
            {t('landing.header')}
          </Heading>
          <SearchForm />
        </Box>
      </Box>
    </Box>
  );
};

export default DirectoryHero;
