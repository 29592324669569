import { mode } from '@chakra-ui/theme-tools';
import Input from './input';

const { sizes, defaultProps, variants } = Input;

const parts = ['field', 'icon'];

function baseStyleField(props: Dict) {
  return {
    ...Input.baseStyle.field,
    fontWeight: 'normal',
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 6,
    '> option': {
      bg: mode('white', 'gray.700')(props),
    },
  };
}

const baseStyleInput = {
  color: 'currentColor',
  fontSize: '1.25rem',
  _disabled: { opacity: 0.5 },
};

const baseStyle = (props: Dict) => ({
  field: baseStyleField(props),
  icon: baseStyleInput,
});

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
