import { generateStripe, getColor, mode } from '@chakra-ui/theme-tools';

const parts = ['track', 'filledTrack', 'panel'];

// http://jsfiddle.net/viphalongpro/YS3nu/
type AccessibleColor = {
  bg?: string;
};

const accessibleColorMap: { [key: string]: AccessibleColor } = {
  'blue-gradient': {
    bg:
      'linear-gradient(254.8deg, #0D5ED7 -66.78%, #580BB9 78.72%, #08418F 221.37%)',
  },
};

function filledStyle(props: Dict) {
  const { colorScheme: c, theme: t, isIndeterminate, hasStripe } = props;

  const stripeStyle = mode(
    generateStripe(),
    generateStripe('1rem', 'rgba(0,0,0,0.1)')
  )(props);

  const bgColor = mode(`${c}.500`, `${c}.200`)(props);

  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColor(t, bgColor)} 50%,
    transparent 100%
  )`;

  const addStripe = !isIndeterminate && hasStripe;

  return {
    ...(addStripe && stripeStyle),
    ...(isIndeterminate ? { bgImage: gradient } : { bgColor }),
  };
}

const baseStyleLabel = {
  lineHeight: '1',
  fontSize: '0.25em',
  fontWeight: 'bold',
  color: 'white',
};

function baseStyleTrack(props: Dict) {
  return {
    bg: mode(`gray.100`, `whiteAlpha.300`)(props),
  };
}

function baseStyleFilledTrack(props: Dict) {
  return {
    transition: 'all 0.3s',
    ...filledStyle(props),
  };
}

const baseStyle = (props: Dict) => ({
  label: baseStyleLabel,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props),
});

const sizes = {
  xs: {
    track: { h: '0.25rem' },
  },
  sm: {
    track: { h: '0.5rem' },
  },
  md: {
    track: { h: '0.75rem' },
  },
  lg: {
    track: { h: '1rem' },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'blue',
  variant: 'default',
};

function variantSlant(props: Dict) {
  const { colorScheme: c } = props;

  const bg = accessibleColorMap[c]?.bg ?? mode(`${c}.500`, `${c}.200`)(props);
  return {
    track: {
      bg: 'transparent',
      _before: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        transformOrigin: '0 0',
        transform: 'skewX(30deg)',
        width: '100%',
        height: '50%',
        boxSizing: 'border-box',
        zIndex: -2,
        bg: 'rgba(255, 255, 255, 0.25)',
      },
      _after: {
        content: '""',
        pos: 'absolute',
        left: 0,
        bottom: 0,
        transformOrigin: '0 100%',
        transform: 'skewX(-30deg)',
        width: '100%',
        height: '50%',
        boxSizing: 'border-box',
        zIndex: -2,
        bg: 'rgba(255, 255, 255, 0.25)',
      },
    },
    filledTrack: {
      overflow: 'hidden',
      position: 'relative',
      bg: 'transparent',
      _before: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        transformOrigin: '0 0',
        transform: 'skewX(30deg)',
        width: '100%',
        height: '51%',
        boxSizing: 'border-box',
        zIndex: -1,
        bg,
      },
      _after: {
        content: '""',
        pos: 'absolute',
        left: 0,
        bottom: 0,
        transformOrigin: '0 100%',
        transform: 'skewX(-30deg)',
        width: '100%',
        height: '51%',
        boxSizing: 'border-box',
        zIndex: -1,
        bg,
      },
    },
  };
}

const variants = {
  slant: variantSlant,
};

export default {
  parts,
  sizes,
  baseStyle,
  defaultProps,
  variants,
};
