import React, { useMemo } from 'react';
import { Avatar, Box, Text, useBreakpointValue } from '@chakra-ui/react';
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from 'twilio-video';
import useIsTrackSwitchedOff from '@src/hooks/meeting/useIsTrackSwitchedOff';
import usePublications from '@src/hooks/meeting/usePublications';
import useTrack from '@src/hooks/meeting/useTrack';
import useParticipantIsReconnecting from '@src/hooks/meeting/useParticipantIsReconnecting';
import ParticipantInfoName from '../ParticipantInfoName';
import useIsTrackEnabled from '@src/hooks/meeting/useIsTrackEnabled';
import { useTranslation } from 'react-i18next';
import MeetingRoomPillProfileCard from '../../PillProfileCard';
import styles from './styles';

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export type AutoTrak = LocalAudioTrack | RemoteAudioTrack | undefined;

export default function ParticipantInfo({
  participant,
  onClick,
  children,
  hideParticipant,
}: ParticipantInfoProps) {
  const identity = useMemo(() => participant.identity.split('_')[2], [
    participant,
  ]);
  const avatarSize = useBreakpointValue({ base: 'sm', sm: 'lg' });
  const { t } = useTranslation();
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) =>
    p.trackName.includes('camera')
  );

  const audioTrack = useTrack(audioPublication) as AutoTrak;
  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  const isVideoEnabled = Boolean(videoPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  if (!isVideoEnabled || isVideoSwitchedOff) {
    return (
      <Box
        data-cy-participant={participant.identity}
        display={hideParticipant ? 'none' : 'flex'}
        justifyContent="flex-end"
        position="relative"
      >
        <Box w="80%">
          <MeetingRoomPillProfileCard name={identity} />
        </Box>
        {children}
      </Box>
    );
  }

  return (
    <Box
      onClick={onClick}
      data-cy-participant={participant.identity}
      display={hideParticipant ? 'none' : 'flex'}
      cursor={onClick ? 'pointer' : 'default'}
      position="relative"
      alignItems="center"
      height={{ base: '90px', sm: 0 }}
      width={{ base: `${(90 * 16) / 9}px`, sm: 'auto' }}
      overflow="hidden"
      borderRadius="2xl"
      paddingTop={{ base: `${90 - 2}px`, sm: `calc(${(9 / 16) * 100}% - 2px)` }}
      backgroundColor="black"
      css={{
        '& video': {
          filter: 'none',
        },
      }}
    >
      <Box sx={styles.infoContainer}>
        <Box sx={styles.infoRowBottom} w="70%" pr={3} pb={2}>
          <ParticipantInfoName
            name={identity}
            isAudioEnabled={isAudioEnabled}
          />
        </Box>
      </Box>
      <Box sx={styles.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <Box sx={styles.avatarContainer}>
            <Avatar size={avatarSize} name={identity} />
          </Box>
        )}
        {isParticipantReconnecting && (
          <Box sx={styles.reconnectingContainer}>
            <Text variant="secondary" color="white">
              {t('meetings.reconnecting')}
            </Text>
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
}
