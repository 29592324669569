import React from 'react';
import Icon from '@chakra-ui/icon';
import { HStack } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';
import { SelectSingle } from '@src/components/atoms/Fields';
import { generateTimeSlots } from '@src/utils';
import { ReactComponent as DeleteIcon } from '@src/icons/delete.svg';
import { format } from 'date-fns';

const timeSlots = generateTimeSlots({
  start: 0,
  end: 24,
  interval: 30,
});

export interface WorkingHourSlotProps {
  name: string;
  onRemove(): void;
}

export function WorkingHourSlot({ name, onRemove }: WorkingHourSlotProps) {
  return (
    <HStack align="center" spacing={{ base: 3, sm: 6 }}>
      <SelectSingle<Date>
        name={`${name}.from`}
        options={timeSlots}
        getOptionKey={(item) => (item ? format(item, 'HH:mm') : '')}
        getOptionLabel={(item) => (item ? format(item, 'HH:mm') : '')}
        hideError={true}
      />
      <SelectSingle<Date>
        name={`${name}.to`}
        options={timeSlots}
        getOptionKey={(item) => (item ? format(item, 'HH:mm') : '')}
        getOptionLabel={(item) => (item ? format(item, 'HH:mm') : '')}
        hideError={true}
      />
      <chakra.button
        p={0}
        type="button"
        variant="unstyle"
        outline="none"
        aria-label="trash"
        onClick={onRemove}
        color="skyBlue.100"
        _hover={{ color: 'blue.200' }}
        _focus={{ color: 'blue.200' }}
      >
        <Icon as={DeleteIcon} h={5} w={5} />
      </chakra.button>
    </HStack>
  );
}
