import React from 'react';
import { VStack, Box, Text } from '@chakra-ui/react';
import groupBy from 'lodash/fp/groupBy';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MeetingItem from './MeetingItem';
import DateFormat, {
  DEFAULT_FORMAT,
  DAYOFWEEK_MONTH_DAYOFMONTH_YEAR,
} from '@src/components/atoms/DateFormat';
export interface MeetingListProps {
  meetings: Array<Meeting>;
}

const MeetingList = ({ meetings }: MeetingListProps) => {
  const { t } = useTranslation();

  const grouped = groupBy(
    (item) => format(item.times.startsAt, DEFAULT_FORMAT),
    meetings
  );

  return (
    <VStack align="normal" spacing={3}>
      {!grouped && (
        <Box p={5} d="flex" alignItems="center" flexDir="column" mt={8}>
          <Text>{t('meetings.no_past_meeting')}</Text>
        </Box>
      )}
      {Object.keys(grouped).map((key) => {
        const date = parse(key, DEFAULT_FORMAT, new Date());
        return (
          <Box key={key} py={2}>
            <DateFormat
              date={date}
              format={DAYOFWEEK_MONTH_DAYOFMONTH_YEAR}
              fontSize={{ base: 'md', md: 'lg' }}
              fontWeight="semibold"
            />
            <VStack align="normal" mt={2} spacing={6}>
              {grouped[key].map((meeting) => (
                <MeetingItem key={meeting.meeting.roomId} {...meeting} />
              ))}
            </VStack>
          </Box>
        );
      })}
    </VStack>
  );
};

export default MeetingList;
