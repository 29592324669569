import React, { forwardRef, LegacyRef } from 'react';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import {
  useParams,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export type LinkProps = ChakraLinkProps &
  RouterLinkProps & {
    prefixLocale?: boolean;
  };

const Link = forwardRef(
  (
    { prefixLocale = true, ...props }: LinkProps,
    ref: LegacyRef<HTMLAnchorElement>
  ) => {
    const params = useParams<{ locale: string }>();
    const to =
      prefixLocale && params?.locale
        ? `/${params.locale}${props.to}`
        : props.to;
    return <ChakraLink ref={ref} as={RouterLink} {...props} to={to} />;
  }
);

Link.displayName = 'RooLink';

export default Link;
