import { addHours, getMinutes, getHours, getSeconds } from 'date-fns';

export const convertToDuration = (secondsAmount: number) => {
  const normalizeTime = (time: string): string =>
    time.length === 1 ? `0${time}` : time;

  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  const hoursOutput = hours !== '00' ? `${hours}:` : '';

  return `${hoursOutput}${minutes}:${seconds}`;
};

export interface CountdownTimeDeltaOptions {
  now?: () => number;
  precision?: number;
}

export interface CountdownTimeDelta {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  completed: boolean;
}

export interface CountdownTimeDeltaFormatted {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export interface CountdownTimeDeltaFormatOptions {
  daysInHours?: boolean;
  zeroPadTime?: number;
  zeroPadDays?: number;
}

export function zeroPad(value: number | string, length = 2): string {
  const strValue = String(value);
  if (length === 0) return strValue;
  const match = strValue.match(/(.*?)([0-9]+)(.*)/);
  const prefix = match ? match[1] : '';
  const suffix = match ? match[3] : '';
  const strNo = match ? match[2] : strValue;
  const paddedNo =
    strNo.length >= length
      ? strNo
      : ([...Array(length)].map(() => '0').join('') + strNo).slice(length * -1);
  return `${prefix}${paddedNo}${suffix}`;
}

export const timeDeltaFormatOptionsDefaults: CountdownTimeDeltaFormatOptions = {
  daysInHours: false,
  zeroPadTime: 2,
};

export function calcTimeDelta(
  date: Date | string | number,
  options: CountdownTimeDeltaOptions = {}
): CountdownTimeDelta {
  const { now = Date.now, precision = 0 } = options;
  let startTimestamp: number;

  if (typeof date === 'string') {
    startTimestamp = new Date(date).getTime();
  } else if (date instanceof Date) {
    startTimestamp = date.getTime();
  } else {
    startTimestamp = date;
  }

  const timeLeft = startTimestamp - now();
  const clampedPrecision = Math.min(20, Math.max(0, precision));
  const total = Math.round(
    parseFloat((Math.max(0, timeLeft) / 1000).toFixed(clampedPrecision)) * 1000
  );

  const seconds = Math.abs(total) / 1000;

  return {
    total,
    days: Math.floor(seconds / (3600 * 24)),
    hours: Math.floor((seconds / 3600) % 24),
    minutes: Math.floor((seconds / 60) % 60),
    seconds: Math.floor(seconds % 60),
    milliseconds: Number(((seconds % 1) * 1000).toFixed()),
    completed: total <= 0,
  };
}

export function formatTimeDelta(
  timeDelta: CountdownTimeDelta,
  options?: CountdownTimeDeltaFormatOptions
): CountdownTimeDeltaFormatted {
  const { days, hours, minutes, seconds } = timeDelta;
  const opts = {
    ...timeDeltaFormatOptionsDefaults,
    ...options,
  };
  const daysInHours = opts.daysInHours;
  const zeroPadTime = opts.zeroPadTime as number;
  const zeroPadDays = opts.zeroPadDays;

  const zeroPadTimeLength = Math.min(2, zeroPadTime);
  const formattedHours = daysInHours
    ? zeroPad(hours + days * 24, zeroPadTime)
    : zeroPad(hours, zeroPadTimeLength);

  return {
    days: daysInHours ? '' : zeroPad(days, zeroPadDays ?? zeroPadTime),
    hours: formattedHours,
    minutes: zeroPad(minutes, zeroPadTimeLength),
    seconds: zeroPad(seconds, zeroPadTimeLength),
  };
}
