/* eslint-disable */
export const validPassword = (
  message = 'Minimum 6 characters, one lowercase letter, one number and one special character'
) => (password: string): string | null => {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{6,}/;
  return password && !regex.test(password) ? message : null;
};

export const minValue = (min: number, message?: string) => (value: number) =>
  isNaN(value) || value >= min
    ? undefined
    : message ?? `Should be greater than ${min}`;

export const validEmail = (message = 'Email is invalid') => (
  email: string
): string | null => {
  // tslint:disable-next-line:max-line-length
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && !regex.test(email) ? message : null;
};

export const validURL = (message = 'URL is invalid') => (
  url: string
): string | null => {
  const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return url && !regex.test(url) ? message : null;
};

export const validPhone = (message = 'Invalid phone number') => (
  value: string
): string | null => {
  const regex = /^[0-9.+#\-()/ ]+$/;
  return value && !regex.test(value) ? message : null;
};

export const mustBeNumber = (
  message: string | null = 'Field must be a number'
) => (value: any): string | null => {
  return isNaN(value) ? message : null;
};

export const minLength = (
  len: number,
  message = `Must be larger or equal than ${len} characters`
) => (value: string): string | null => {
  return value && value.length < len ? message : null;
};

export const maxLength = (
  len: number,
  message = `Must be smaller than ${len} characters`
) => (value: string): string | null => {
  return value && value.length > len ? message : null;
};

export const trimValue = (event: any): string => {
  const { value } = event.target;
  return (event.target.value = value.trim());
};

export const required = (msg = 'Required field.') => (value: string) =>
  value ? undefined : msg;

export const isEmail = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const isUrl = (value: string) =>
  value &&
  !/^[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i.test(
    value
  )
    ? 'Invalid url'
    : undefined;

export const isPhone = (value: string) =>
  value &&
  !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)
    ? 'Invalid url'
    : undefined;
export const isNumber = (value: string) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce(
    (error: Error, validator: (value: any) => void) =>
      error || validator(value),
    undefined
  );
