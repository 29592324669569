/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

const useHeapAnalytics = (projectId: string | number) => {
  useEffect(() => {
    let r: any;
    if (projectId && !(window as any).heap) {
      (window as any).heap = (window as any).heap || [];
      (window as any).heap.load = (e: any, t: any) => {
        (window as any).heap.appid = e;
        (window as any).heap.config = t || {};

        r = document.createElement('script');
        r.type = 'text/javascript';
        r.async = true;
        r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`;

        const a: HTMLElement | null = document.getElementsByTagName(
          'script'
        )[0];
        a?.parentNode?.insertBefore(r, a);

        const p = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty',
        ];
        p.forEach((ev, ...args) => {
          (window as any).heap[ev] = (window as any).heap.push(
            [ev].concat(Array.prototype.slice.call(args, 0))
          );
        });
      };

      (window as any).heap.load(projectId);
    }

    return () => {
      if (r) r.remove();
    };
  }, [projectId]);
};

export default useHeapAnalytics;
