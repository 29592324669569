import React, { FC, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useViewerQuery } from '@src/apollo/hooks';
import Loader from '@atoms/Loader';
import ApplicationForm from '@molecules/ApplicationForm';
import ApplicationConfirmation from '@src/components/molecules/ApplicationForm/Steps/ConfirmationPage';
import { LawyerApplicationType } from '@molecules/ApplicationForm/LawyerApplicationType';
import ApplicationResultPage from '@src/components/molecules/ApplicationForm/Steps/ResultPage';

const LawyerApplication: FC = () => {
  const { data, loading } = useViewerQuery();
  const [
    applicationData,
    setApplicationData,
  ] = useState<LawyerApplicationType | null>(null);
  const [
    applicationResult,
    setApplicationResult,
  ] = useState<ApplicationCreationPayload | null>(null);
  const { t } = useTranslation();
  const viewer = data?.viewer;
  if (loading) return <Loader />;

  return (
    <>
      <Text
        color="white"
        fontSize="3xl"
        fontWeight="200"
        textAlign="center"
        mt={16}
        mb={20}
      >
        {t('lawyer_application.title')}
      </Text>

      <Box
        backgroundColor="blue.50"
        backgroundPosition="center"
        w="full"
        h="auto"
        minHeight="100vh"
        zIndex={2}
      >
        <Box maxW="6xl" mx="auto" px={6}>
          <Box
            position="relative"
            top="-50"
            d="flex"
            w="full"
            flexDirection="column"
            justifyContent="top"
            bgColor="white"
            padding={{ base: 6, md: 50 }}
            rounded={15}
            shadow={'0px 0px 40px rgba(0, 0, 0, 0.15)'}
          >
            {!applicationData && (
              <ApplicationForm
                viewer={viewer}
                onSaveData={setApplicationData}
              />
            )}
            {applicationData && !applicationResult && (
              <ApplicationConfirmation
                onApplicationCreated={setApplicationResult}
                applicationData={applicationData}
              />
            )}
            {applicationResult && (
              <ApplicationResultPage application={applicationResult} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LawyerApplication;
