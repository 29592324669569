import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import RooLayout from '@src/components/organisms/RooLayout';
import NewBooking from './NewBooking';
import BookingPayment from './Payment';
import CustomRoute from '@src/Router';
import PrivateRoute from '@src/PrivateRoute';
import ThankyouPage from './Thankyou';

const Bookings: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <RooLayout theme="skyBlue">
      <CustomRoute
        exact
        path="/booking/new/:lawyer_id"
        Component={NewBooking}
      />
      <CustomRoute
        exact
        path="/booking/new/:lawyer_id/payment/:date/:time/:duration"
        Component={BookingPayment}
      />
      <PrivateRoute exact path={`${path}/thankyou`} prefixLocale={false}>
        <ThankyouPage />
      </PrivateRoute>
    </RooLayout>
  );
};

export default Bookings;
