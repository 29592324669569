import React, {
  useState,
  useEffect,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Icon,
  Link,
  Text,
  useDisclosure,
  FormControl,
  FormLabel,
  VStack,
  Stack,
} from '@chakra-ui/react';
import StripeInput from '@molecules/CardSetupForm/StripeInput';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { TextField } from '@src/components/atoms/Fields';

import { useDefaultPaymentMethod, useToast } from '@src/hooks';
import { formatError } from '@src/utils';
import { ReactComponent as ArrowForward } from '@src/icons/arrow-forward.svg';
import {
  CARD_ELEMENT_OPTIONS,
  CARD_NUMBER_ELEMENT_OPTIONS,
} from './LawyerApplicationType';

export interface ForwardedPaymentProps {
  children?: ReactNode;
}
export interface PaymentMethodProps {
  processMethod: () => void;
}
// eslint-disable-next-line react/display-name
const PaymentMethod = forwardRef<PaymentMethodProps, ForwardedPaymentProps>(
  ({ children }: ForwardedPaymentProps, ref) => {
    const { t } = useTranslation();
    const defaultPaymentMethod = useDefaultPaymentMethod();
    const showCreditCardForm = useDisclosure();
    const toast = useToast();
    const stripe = useStripe();
    const elements = useElements();

    const [paymentMethod, setPaymentMethod] = useState<
      PaymentMethodType | undefined | null
    >(defaultPaymentMethod as PaymentMethodType);

    useEffect(() => {
      if (defaultPaymentMethod) {
        // afterMethodAdded(defaultPaymentMethod);
        setPaymentMethod(defaultPaymentMethod);
      }
    }, [defaultPaymentMethod]);

    useImperativeHandle(ref, () => ({
      async processMethod() {
        if (!paymentMethod || showCreditCardForm.isOpen) {
          const cardElement = elements?.getElement(CardNumberElement);
          try {
            const payload = await stripe?.createPaymentMethod({
              type: 'card',
              card: cardElement!, // eslint-disable-line
            });
            if (payload?.paymentMethod) {
              const { paymentMethod } = payload;
              if (paymentMethod.card) {
                const paymentData = {
                  cardBrand: paymentMethod.card.brand,
                  cardLast4: paymentMethod.card.last4,
                  id: paymentMethod.id,
                  isDefault: false,
                  expMonth: paymentMethod.card.exp_month,
                  expYear: paymentMethod.card.exp_year,
                };
                setPaymentMethod(paymentData);
                toast.success({
                  title: t('common.success'),
                  description: t('my_payments.payment_methods.create_success'),
                  isClosable: true,
                });
                showCreditCardForm.onClose();
                return paymentData;
              }
            }
          } catch (err) {
            toast.warning({
              title: 'Error',
              description: formatError(err),
              isClosable: true,
            });
          }
        }
        return paymentMethod;
      },
    }));

    return (
      <Box my={4}>
        <Text textStyle="strongBlue" fontSize="lg" mb={2}>
          {t('lawyer_application.payment_method')}
        </Text>

        {paymentMethod ? (
          <Box justifyContent="space-between" width="100%" py={8}>
            <Text color="gray.600">
              {t('meetings.will_be_charged')}:{'  '}
              <Text as="strong">$100</Text>
            </Text>
            <Box
              d="flow"
              color="gray.600"
              borderBottomWidth={'1px'}
              borderBottomColor={'blue.200'}
              borderStyle={'dashed'}
            >
              {t('meetings.from_card_ending_with')}
              <Text as="strong">{paymentMethod.cardLast4}</Text>
            </Box>
            <Box d="flow" alignItems="center" mt={2}>
              {!showCreditCardForm.isOpen && (
                <Link
                  as="button"
                  fontSize="sm"
                  textStyle="strongYellow"
                  onClick={showCreditCardForm.onOpen}
                >
                  {t('meetings.change_payment_method')}{' '}
                  <Icon as={ArrowForward} ml="5px" textStyle="strongYellow" />
                </Link>
              )}
            </Box>
          </Box>
        ) : (
          <Text fontSize="sm" mb={4}>
            {t('lawyer_application.add_card')}
          </Text>
        )}

        {(showCreditCardForm.isOpen || !paymentMethod) && (
          <VStack alignItems="initial" spacing={4}>
            <TextField
              isRequired
              name="cardholderName"
              label={t('fields.cardholder_name.label')}
              placeholder={t('fields.cardholder_name.placeholder')}
              fontSize="md"
              _placeholder={{
                fontWeight: '400',
              }}
            />
            <FormControl>
              <FormLabel>{t('fields.card_number.label')}</FormLabel>
              <StripeInput
                as={CardNumberElement}
                options={CARD_NUMBER_ELEMENT_OPTIONS}
              />
            </FormControl>

            <Stack alignItems="initial" direction={['column', 'row']}>
              <FormControl>
                <FormLabel>{t('fields.card_expiry.label')}</FormLabel>
                <StripeInput
                  as={CardExpiryElement}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{t('fields.card_cvc.label')}</FormLabel>
                <StripeInput
                  as={CardCvcElement}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </FormControl>
            </Stack>
          </VStack>
        )}
        {children}
      </Box>
    );
  }
);

export default PaymentMethod;
