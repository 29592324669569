import React from 'react';
import { Form } from 'react-final-form';
import { Button, Box, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  EmailField,
  PasswordField,
  TextField,
} from '@src/components/atoms/Fields';
import { useFormValidation } from '@src/hooks';

export interface SignupFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface SignupFormProps {
  onSubmit: (v: SignupFormValues) => void;
}

const SignupForm = ({ onSubmit }: SignupFormProps) => {
  const { t } = useTranslation();
  const { required } = useFormValidation();
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" onSubmit={handleSubmit} pt={4}>
          <VStack spacing={4}>
            <TextField
              name="firstName"
              placeholder={t('fields.firstName.placeholder')}
              label={t('fields.firstName.label')}
              fieldProps={{ validate: required() }}
            />
            <TextField
              name="lastName"
              placeholder={t('fields.lastName.placeholder')}
              label={t('fields.lastName.label')}
              fieldProps={{ validate: required() }}
            />
            <EmailField
              placeholder={t('fields.email.placeholder')}
              label={t('fields.email.label')}
            />
            <PasswordField
              placeholder={t('fields.password.placeholder')}
              label={t('fields.password.label')}
            />
          </VStack>
          <Box
            d="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <Button
              colorScheme="orange"
              px={10}
              mt={2}
              isLoading={submitting}
              type="submit"
            >
              {t('common.signup')}
            </Button>
          </Box>
        </Box>
      )}
    />
  );
};

export default SignupForm;
