import React from 'react';
import { useField } from 'react-final-form';
import { FormErrorMessage } from '@chakra-ui/react';

export const Error = ({ name }: { name: string }) => {
  const {
    meta: { error },
  } = useField(name, { subscription: { error: true } });
  return <FormErrorMessage>{error}</FormErrorMessage>;
};
