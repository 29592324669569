import { useEffect, useState } from 'react';
import { RemoteParticipant } from '@src/video';
import useDominantSpeaker from './useDominantSpeaker';
import { useVideoContext } from '../../context/VideoContext';

export default function useParticipants() {
  const { meeting } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(
    Array.from(meeting.participants.values())
  );

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants((prevParticipants) => [
        dominantSpeaker,
        ...prevParticipants.filter(
          (participant) => participant !== dominantSpeaker
        ),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    meeting.on('participantConnected', participantConnected);
    meeting.on('participantDisconnected', participantDisconnected);
    return () => {
      meeting.off('participantConnected', participantConnected);
      meeting.off('participantDisconnected', participantDisconnected);
    };
  }, [meeting]);

  return participants;
}
