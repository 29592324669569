import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  ReactNode,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';
import SignupModal from '@components/organisms/SignupModal';
import SigninModal from '@components/organisms/SigninModal';
import ForgotPasswordModal from '@src/components/molecules/ForgotPasswordModal';

export interface AuthContext {
  signin: UseDisclosureReturn;
  signup: UseDisclosureReturn;
  forgotPassword: UseDisclosureReturn;
}

export const AuthModalContext = createContext<AuthContext | undefined>(
  undefined
);

export function useAuthModalContext() {
  const context = useContext(AuthModalContext);
  return context as AuthContext;
}

export function useSigninModal() {
  const { signin } = useAuthModalContext();
  const context = {
    open: signin.onOpen,
    close: signin.onClose,
  };
  return context;
}

export function useSignupModal() {
  const { signup } = useAuthModalContext();
  const context = {
    open: signup.onOpen,
    close: signup.onClose,
  };
  return context;
}

export function useForgotPasswordModal() {
  const { forgotPassword, signin } = useAuthModalContext();
  const onOpen = useCallback(() => {
    forgotPassword.onOpen();
    signin.onClose();
  }, [forgotPassword, signin]);
  const context = {
    open: onOpen,
    close: forgotPassword.onClose,
  };
  return context;
}

export type AuthModalContextProviderProps = { children: ReactNode };

export const AuthModalContextProvider = ({
  children,
}: AuthModalContextProviderProps) => {
  const history = useHistory();
  const signupModal = useDisclosure();
  const signinModal = useDisclosure();
  const forgotPasswordModal = useDisclosure();

  const contextValue = useMemo(() => {
    return {
      signup: signupModal,
      signin: signinModal,
      forgotPassword: forgotPasswordModal,
    };
  }, [signupModal, signinModal, forgotPasswordModal]);

  const handleSigninClick = useCallback(() => {
    signupModal.onClose();
    signinModal.onOpen();
  }, [signupModal, signinModal]);

  const handleSignupClick = useCallback(() => {
    signinModal.onClose();
    signupModal.onOpen();
  }, [signupModal, signinModal]);

  const signinOnClose = useCallback(() => {
    signinModal.onClose();
    history.replace({ ...history.location, state: {} });
  }, [signinModal, history]);
  return (
    <AuthModalContext.Provider value={contextValue}>
      {children}
      <SignupModal
        isOpen={signupModal.isOpen}
        onSigninClick={handleSigninClick}
        onClose={signupModal.onClose}
      />
      <SigninModal
        isOpen={signinModal.isOpen}
        onSignupClick={handleSignupClick}
        onClose={signinOnClose}
      />
      <ForgotPasswordModal />
    </AuthModalContext.Provider>
  );
};
