import React from 'react';
import {
  chakra,
  Heading,
  Icon,
  IconButton,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { FieldArray } from 'react-final-form-arrays';
import { ReactComponent as PlusIcon } from '@src/icons/plus.svg';
import { ReactComponent as CloseIcon } from '@src/icons/close.svg';
import { TextField } from '@src/components/atoms/Fields';
import { useTranslation } from 'react-i18next';
import { useFormValidation } from '@src/hooks';

export interface EducationSectionProps {
  onAdd(): void;
}

export default function EducationSection({ onAdd }: EducationSectionProps) {
  const { t } = useTranslation();
  const { required } = useFormValidation();
  return (
    <chakra.div pos="relative" mx="auto" maxW="3xl" py={12}>
      <chakra.div px={{ base: 3, sm: 6 }}>
        <Heading as="h3" fontSize="22px" fontWeight="bold" mb={10}>
          {t('common.education')}
        </Heading>

        <VStack w="full" spacing={{ base: 8, md: 12 }}>
          <FieldArray name="educationalBackground">
            {({ fields }) =>
              fields.map((name, index) => (
                <SimpleGrid
                  columns={{ base: 1, sm: 2 }}
                  spacing={3}
                  key={name}
                  w="full"
                >
                  <TextField
                    name={`${name}.title`}
                    label={t('fields.titles_title.label')}
                    placeholder={t('fields.titles_title.placeholder')}
                    fieldProps={{ validate: required() }}
                  />
                  <TextField
                    name={`${name}.institution`}
                    label={t('fields.titles_institution.label')}
                    placeholder={t('fields.titles_institution.placeholder')}
                    fieldProps={{ validate: required() }}
                  />
                  <chakra.div>
                    <TextField
                      name={`${name}.graduationYear`}
                      type="number"
                      label={t('fields.year.label')}
                      placeholder={t('fields.year.placeholder')}
                      fieldProps={{ validate: required() }}
                      formControlProps={{
                        w: { base: '80%', sm: 56 },
                      }}
                    />
                    <chakra.div
                      d="inline-flex"
                      ml={3}
                      flexDir="column"
                      alignItems="center"
                      justifyContent="center"
                      h="full"
                    >
                      <IconButton
                        size="xs"
                        isRound
                        colorScheme="primary"
                        aria-label="Add title"
                        onClick={onAdd}
                      >
                        <Icon as={PlusIcon} h={3} w={3} />
                      </IconButton>
                      {index !== 0 && (
                        <IconButton
                          mt={1}
                          size="xs"
                          isRound
                          colorScheme="red"
                          aria-label="trash"
                          onClick={() => fields.remove(index)}
                        >
                          <Icon as={CloseIcon} h={2} w={2} />
                        </IconButton>
                      )}
                    </chakra.div>
                  </chakra.div>
                </SimpleGrid>
              ))
            }
          </FieldArray>
        </VStack>
      </chakra.div>
    </chakra.div>
  );
}
