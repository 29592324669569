import React, { ReactNode } from 'react';
import { Box, Heading, Image } from '@chakra-ui/react';

export interface HomeCardProps {
  title: string;
  image: string;
  children: ReactNode;
}

const HomeCard = ({ title, image, children }: HomeCardProps) => {
  return (
    <Box
      p={[6, 12]}
      background="linear-gradient(180deg, #D2E3FB 0%, rgba(255, 255, 255, 0) 100%)"
      rounded="2xl"
      flex="1 1 0px"
      marginX="auto"
    >
      <Heading as="h4" fontSize={{ base: 'lg', lg: 'xl' }} lineHeight="normal">
        {title}
      </Heading>
      <Image mt={6} src={image} alt={title} />
      {children}
    </Box>
  );
};

export default HomeCard;
